import React, { useEffect, useMemo, useRef, useState } from 'react'
import './scss/scss.scss'
import SidebarLeft from './SidebarLeft/SidebatLeft'
import RightBlock from './RightBlock/RightBlock'
import { useDispatch, useSelector } from 'react-redux'
import {
  getSelectorMessagesSelectedId,
  getSelectorSetMessagePrivetChats,
  selectorAdminSelectedChatType,
  sendMessagesSelector,
} from '../../../store/reducer/messages/selectors'

import { selectRole } from '../../../store/reducer/token/selector'
import useWebSocket from '../../../socket/useWebSocket'

import { sendMessages, setMessagesSelectedId, setMessagesSelectedStaffFalse, setMessagesSelectedUser } from 'store/reducer/messages/reducer'
import { findMatchingContent } from './func/findMatchingContent'
import { selectClients, selectModels, selectStaffs } from 'store/reducer/user/selectors'
import { findPrivateChat } from './func/findPrivateChat'
import { useSearchQuery } from 'store/apiMain/mainApi'
import { setRooms } from 'store/reducer/rooms/reducer'
import { getSubscribeUser } from 'store/reducer/socket/selector'
import useSubscribeToNewUsers from '../../../hooks/socketTs/useSubscribeToNewUsers'

const Messages = () => {
  const dispatch = useDispatch()
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const dataChatSelect = useSelector(getSelectorSetMessagePrivetChats)

  const { data: dataRooms } = useSearchQuery()

  useEffect(() => {
    if (dataRooms) {
      dispatch(setRooms(dataRooms))
    }
  }, [dataRooms])

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const messagesSelectedId = useSelector(getSelectorMessagesSelectedId)
  const [rightBlockActive, setRightBlockActive] = useState(true)

  useEffect(() => {
    if (messagesSelectedId !== null) {
      setRightBlockActive(false)
    }
  }, [messagesSelectedId])

  const role = useSelector(selectRole)
  const selectedChatType = useSelector(selectorAdminSelectedChatType)
  const { sendSubscribe } = useWebSocket()

  const userId = useMemo(() => {
    return dataChatSelect?.content
      ?.flatMap(e => {
        if (role === 'MODEL') {
          return [e.privateChat.clientId || e.privateChat.staffId]
        } else if (role === 'STAFF') {
          if (selectedChatType?.byRole === undefined) {
            return [e.privateChat.modelId, e.privateChat.clientId]
          }
          if (selectedChatType.byRole === 'CLIENT') {
            return [e.privateChat.clientId]
          }
          if (selectedChatType.byRole === 'MODEL') {
            return [e.privateChat.modelId]
          }
        } else {
          return [e.privateChat.modelId || e.privateChat.staffId]
        }
      })
      .filter((id): id is string => Boolean(id))
  }, [dataChatSelect, role, selectedChatType])
  const subscribeUser = useSelector(getSubscribeUser)
  useSubscribeToNewUsers({ userId, sendSubscribe, subscribeUser })
  const sendMessage = useSelector(sendMessagesSelector)
  const clients = useSelector(selectClients)
  const models = useSelector(selectModels)
  const staffs = useSelector(selectStaffs)
  const [dataReady, setDataReady] = useState(false)

  useEffect(() => {
    const nonNullCount = [clients, models, staffs].filter(item => item != null).length
    if (nonNullCount >= 2 && dataChatSelect) {
      setDataReady(true)
    }
  }, [clients, models, staffs, dataChatSelect])

  useEffect(() => {
    if (dataReady && dataChatSelect) {
      if (dataChatSelect.content.length > 0 && sendMessage.recipientId !== '' && sendMessage.senderId !== '') {
        const privateChat = findPrivateChat(sendMessage.senderId, sendMessage.recipientId, dataChatSelect, () => {
          const obg = {
            recipientId: '',
            senderId: '',
          }
          setDataReady(false)
          dispatch(sendMessages(obg))
        })
        dispatch(setMessagesSelectedStaffFalse())
        if (privateChat) {
          // @ts-ignore
          dispatch(setMessagesSelectedId(privateChat.privateChat.id))
          const { matchingClientContent, matchingModelContent, matchingStaffContent } = findMatchingContent(
            privateChat,
            clients,
            models,
            staffs,
            selectedChatType,
            role,
          )
          if (matchingClientContent) dispatch(setMessagesSelectedUser(matchingClientContent))
          if (matchingModelContent) dispatch(setMessagesSelectedUser(matchingModelContent))
          if (matchingStaffContent) {
            dispatch(setMessagesSelectedUser(matchingStaffContent))
          }
        }
      }
    }
  }, [dataReady, sendMessage, dispatch])

  return (
    <>
      <main className='message__main sidebar'>
        <div style={windowWidth < 992 && !rightBlockActive ? { display: 'none' } : {}} className='sidebar'>
          <SidebarLeft />
        </div>
        <div style={windowWidth < 992 && rightBlockActive ? { display: 'none' } : {}} className='RightBlock'>
          <RightBlock windowWidth={windowWidth} setRightBlockActive={setRightBlockActive} />
        </div>
      </main>
    </>
  )
}
export default Messages
