import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { FormControl } from 'layouts-elements/FormControl/FormControl'
import './scss/index.scss'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useModelRequestApproveByIdMutation,
  useFileCreateMutation,
  useModelRequestGetByIdQuery,
  useModelGetFullByIdQuery,
} from 'store/apiMain/mainApi'
import { useGetFileMutation } from '../../../../store/apiMain/emptyApi'
import PhotoAdminDocuments from '../../../../UI/Photo/PhotoAdminDocuments'
import Photo from '../../../../UI/Photo/Photo'
import { toastSuccess } from '../../../../elements/Notification/Notification'
const Documents: React.FC = () => {
  const { modelRequestId } = useParams()
  const [approve, { isSuccess: approveIsSuccess }] = useModelRequestApproveByIdMutation()

  const { modelId } = useParams()

  const queryArg = {
    modelId: String(modelId),
    idType: 'ID',
  }

  // @ts-ignore
  const { data: modelData } = useModelGetFullByIdQuery(queryArg, {
    skip: modelId === undefined,
  })

  const {
    data: modelRequest,
    refetch,
    isLoading,
    isError,
  } = useModelRequestGetByIdQuery({ modelRequestId: String(modelRequestId) }, { skip: !modelRequestId })
  const [getFile] = useGetFileMutation()
  const [addFile] = useFileCreateMutation()
  const initialValues = {
    dataType: 'SECRET_DATA ',
    realName: '',
    photos: [],
  }

  const [photos, setPhotos] = useState<any[]>([])
  const [photoIds, setPhotoIds] = useState<string[]>([])
  const validationSchema = yup.object().shape({
    dataType: yup.string().required('Data type is required'),
  })

  const onSubmit = (values: any) => {
    approve({ modelRequestId: String(modelRequestId), type: 'SECRET_DATA' })
  }
  useEffect(() => {
    if (approveIsSuccess) {
      toastSuccess('Данные апрувлены')
      refetch()
    }
  }, [approveIsSuccess])
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })
  useEffect(() => {
    if (modelData !== null && modelData !== undefined) {
      const arrayPhoto = modelRequest?.secretData?.documents || modelData?.secretData?.documents
      const idPhotos = arrayPhoto?.map(item => item?.file?.id)
      // @ts-ignore
      setPhotoIds(idPhotos)
      formik.resetForm({
        values: {
          dataType: 'SECRET_DATA',
          realName: modelRequest?.secretData?.realName || modelData?.secretData?.realName || '',
          photos: [],
        },
      })
    }
  }, [modelData])
  useEffect(() => {
    if ('item' !== null) {
      const arrayPhoto = modelRequest?.secretData?.documents || modelData?.secretData?.documents
      const idPhotos = arrayPhoto?.map(item => item?.file?.id)
      // @ts-ignore
      setPhotoIds(idPhotos)
      formik.resetForm({
        values: {
          // dataType: item.mainInfo.dataType,
          dataType: 'SECRET_DATA',
          realName: modelRequest?.secretData?.realName || modelData?.secretData?.realName || '',
          photos: [],
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, ['item'])
  const navigate = useNavigate()
  useEffect(() => {
    const secretApproved = modelRequest?.secretData?.isApproved
    const mainApproved = modelRequest?.mainInfo?.isApproved
    const requestState = modelRequest?.request?.state === 'CREATE_NEW'

    // Логика проверки
    const shouldNavigate =
      !requestState && // Если requestState true, редирект не делаем
      ((secretApproved === true && mainApproved === true) || // Оба true
        (secretApproved === true && mainApproved === undefined) || // Только secretApproved true, mainApproved отсутствует
        (mainApproved === true && secretApproved === undefined)) // Только mainApproved true, secretApproved отсутствует

    if (shouldNavigate) {
      navigate('/admin/models-request')
    }
  }, [approveIsSuccess, modelRequest, navigate])
  return (
    <div className='step1-information'>
      <h2>Documents Admins</h2>
      <form onSubmit={formik.handleSubmit}>
        <div className='step1-information-block'>
          <div className='step1-information-block_box'>
            <p>Data Type:</p>
            <FormControl name='dataType' type='text' formik={formik} />
          </div>
          <div className='step1-information-block_box'>
            <p>Real Name:</p>
            <FormControl name='realName' type='text' formik={formik} />
          </div>
          <div className='step1-information-block_box'>
            <p>Photos:</p>
            <Photo photoIds={photoIds} photos={photos} setPhotos={setPhotos} addFile={addFile} getFile={getFile} setPhotoIds={setPhotoIds} />
          </div>
          <div className='step1-information-block__button'>
            <button type='submit'>{!modelRequest?.secretData?.isApproved ? 'Approved Documents info' : 'Заупрувлены'}</button>
          </div>
        </div>
      </form>

      {/*<div>*/}
      {/*	Status:{' '}*/}
      {/*	{item?.request.state === 'CREATE_SECOND_STAGE'*/}
      {/*		? 'Admin approved your data'*/}
      {/*		: item?.request.state}*/}
      {/*</div>*/}
    </div>
  )
}

export default Documents
