import React from 'react'
import './scss/index.scss'

const Legal = () => {
  return (
    <div className='legal'>
      <h2>Юридическая информация для моделей</h2>
      <p>
        На этой странице вы найдете все необходимые юридические документы и политики, которые регулируют вашу деятельность в качестве модели на нашем
        сайте. Мы стремимся создать прозрачную и безопасную рабочую среду, поэтому рекомендуем вам ознакомиться с каждым из следующих разделов.
      </p>
      <div className='legal-block'>
        <div className='legal-block__item'>
          <p>
            Ознакомьтесь с основными условиями и положениями, которые регулируют ваше сотрудничество с нами. Этот документ содержит важную информацию
            о ваших правах и обязанностях.
          </p>
          <a href='/info/model/terms-and-conditions' target='_blank' rel='noopener noreferrer' className='btn'>
            Условия и положения
          </a>
        </div>
        <div className='legal-block__item'>
          <p>
            В нашей политике конфиденциальности описано, как мы собираем, используем и защищаем вашу личную информацию. Понимание этих аспектов
            критически важно для вашей безопасности в интернете.
          </p>
          <a href='/info/model/privacy-policy' target='_blank' rel='noopener noreferrer' className='btn'>
            Политика конфиденциальности
          </a>
        </div>
        <div className='legal-block__item'>
          <p>
            Заявление 2257 содержит важную информацию о требованиях к возрастной верификации и записи, установленных для защиты несовершеннолетних.
            Убедитесь, что вы полностью понимаете эти требования.
          </p>
          <a
            href='/info/model/privacy-policy' // Обновите эту ссылку, если это необходимо для другого документа
            target='_blank'
            rel='noopener noreferrer'
            className='btn'
          >
            Заявление 2257
          </a>
        </div>
      </div>
    </div>
  )
}

export default Legal
