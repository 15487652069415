import { FC, ReactNode } from 'react'
import './scss/index.scss'

interface Props {
  title: string
  button_number: number
  text?: string
  children?: ReactNode
}

export const HiddenItem: FC<Props> = ({ button_number, text, title, children }) => {
  return (
    <div className='accordion-item'>
      <button id={'accordion-button-' + button_number} aria-expanded='false'>
        <span className='accordion-title'>{title}</span>
        <span className='icon' aria-hidden='true'></span>
      </button>
      <div className='accordion-content'>
        <p>{children || text}</p>
      </div>
    </div>
  )
}
