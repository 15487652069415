import React, { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LocalUserChoices, PreJoin } from '@livekit/components-react'
import ActiveRoom from '../ActiveRoom/ActiveRoom'
import '@livekit/components-styles'
import '@livekit/components-styles/prefabs'
import { CreateRoom1ApiArg, useCreateRoom1Mutation, useUserGetFullMeQuery } from 'store/apiMain/mainApi'
import './../../styles/custom.scss'
import { useDispatch, useSelector } from 'react-redux'
import { selectRole } from 'store/reducer/token/selector'
import { resetVideoChatState, setVideoChat } from 'store/reducer/videoChat/reducer'
import { selectVideoChatObg } from 'store/reducer/videoChat/selector'

interface IProps {
  secured?: string
}

const JoinRoomA: FC<IProps> = () => {
  const navigate = useNavigate()
  const { roomId } = useParams()
  const [preJoinChoices, setPreJoinChoices] = useState<LocalUserChoices | undefined>(undefined)
  const { data: userMe } = useUserGetFullMeQuery()
  const [preJoinDefaults, setPreJoinDefaults] = useState({
    username: '',
    videoEnabled: true,
    audioEnabled: true,
  })

  const dispatch = useDispatch()
  const role = useSelector(selectRole)
  const videoChatObg = useSelector(selectVideoChatObg)
  const [getJoinRoom] = useCreateRoom1Mutation()

  const [isRoomJoined, setIsRoomJoined] = useState(false)

  useEffect(() => {
    setPreJoinDefaults(prevDefaults => {
      let newUsername
      switch (role) {
        case 'CLIENT':
          newUsername = userMe?.client?.name
          break
        case 'MODEL':
          newUsername = userMe?.model?.name
          break
        case 'STAFF':
          newUsername = 'www'
          break
        default:
          newUsername = ''
          break
      }
      return {
        ...prevDefaults,
        username: newUsername,
      }
    })
  }, [userMe, role])

  useEffect(() => {
    if (!isRoomJoined && videoChatObg === null && preJoinDefaults.username && roomId) {
      const fetchToken = async () => {
        const payload: CreateRoom1ApiArg = {
          roomId: roomId,
          ...(role === 'STAFF' && { secured: true }),
        }
        try {
          const result = await getJoinRoom(payload).unwrap()
          dispatch(setVideoChat(result))
          setIsRoomJoined(true)
        } catch (error) {
          console.error('Error joining room:', error)
        }
      }
      fetchToken()
    }
  }, [isRoomJoined, videoChatObg, roomId, preJoinDefaults.username, getJoinRoom, dispatch])

  const handlePreJoinSubmit = useCallback((values: LocalUserChoices) => {
    setPreJoinChoices(values)
  }, [])
  useEffect(() => {
    setPreJoinChoices({
      videoEnabled: true,
      audioEnabled: true,
      videoDeviceId: '',
      audioDeviceId: '',
      username: 'www',
    })
  }, [])
  const onPreJoinError = useCallback((e: any) => {
    console.error(e)
  }, [])

  const onLeave = useCallback(() => {
    dispatch(resetVideoChatState())
    navigate('/')
  }, [navigate])

  return (
    <>
      <div className='videoChat'>
        <main style={{ height: '100%' }} data-lk-theme='default'>
          {preJoinDefaults.username === '' ? null : (
            <>
              {preJoinChoices && videoChatObg?.token?.token ? (
                <ActiveRoom roomName={videoChatObg?.token?.token} userChoices={preJoinChoices} onLeave={onLeave} />
              ) : (
                <>null</>
              )}
            </>
          )}
        </main>
      </div>
    </>
  )
}

export default JoinRoomA
