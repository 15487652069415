import { SvGStep } from 'images/svg'
import { useNavigate } from 'react-router-dom'

type StepNavigationProps = {
  status: string
  name: string
  index: number
  stepId: string | undefined
}

const StepNavigation = ({ status, name, index, stepId }: StepNavigationProps) => {
  const navigate = useNavigate()

  return (
    <div
      onClick={() => {
        navigate(`/model/registration/step/${index + 1}`)
      }}
      className={`step ${status}`}
    >
      <span className={index + 1 === Number(stepId) ? `step__column active` : `step__column`}>
        {status === 'COMPLETED' ? <SvGStep /> : <span className='step__circle'>{index + 1}</span>}
        <label>{name}</label>
      </span>
      {index === 1 ? null : <span className='step__line'></span>}
    </div>
  )
}

export default StepNavigation
