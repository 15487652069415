import React, { useState, useEffect } from 'react'
import './scss/index.scss'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getSelectorMessagesSelectedId } from 'store/reducer/messages/selectors'
import HeaderRightAuthorizedMobile from '../../Global/Header/HeaderRightAuthorized/HeaderRightAuthorizedMobile'

const Header = () => {
  const location = useLocation() // Получаем текущий URL
  const messagesSelectedId = useSelector(getSelectorMessagesSelectedId)
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 997) // Проверяем ширину экрана при загрузке

  // Обновляем состояние при изменении размера окна
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 997)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // Проверяем условия: если URL содержит 'messages', выбран messageId и экран меньше 997
  if (isMobileView && location.pathname.includes('messages') && messagesSelectedId) {
    return null
  }

  return (
    <>
      <HeaderRightAuthorizedMobile />
    </>
  )
}

export default Header
