import {
  ClientDto,
  ClientFullDto,
  ClientViewDto,
  ModelFileDto,
  ModelFullDto,
  ModelViewDto,
  PrivateChatResponse,
  StaffDto,
  StaffFullDto,
  StaffViewDto,
} from 'store/apiMain/mainApi'
import React from 'react'

export interface MessageAttributes {
  image: React.ReactNode
  position: 'LEFT' | 'RIGHT'
}

const getMessageAttributesByUserId = (
  currentUser: ClientFullDto | ModelFullDto | StaffFullDto | null,
  privateChat: PrivateChatResponse,
  staffImageResolver: (staff: StaffDto) => React.ReactNode,
  modelImageResolver: (model: ModelFileDto) => React.ReactNode,
  clientImageResolver: (client: ClientDto) => React.ReactNode,
  clients?: ClientViewDto[],
  models?: ModelViewDto[],
  staffs?: StaffViewDto[],
): Map<string, MessageAttributes> => {
  const client = clients?.find(it => it.user?.id === privateChat.clientId)
  const model = models?.find(it => it.user?.id === privateChat.modelId)
  const staff = staffs?.find(it => it.user?.id === privateChat.staffId)
  const result = new Map<string, MessageAttributes>()

  switch (currentUser?.userData?.user?.role) {
    case 'STAFF': {
      const currentStaff = currentUser as StaffFullDto
      result.set(currentStaff.userData?.user?.id!, {
        position: 'RIGHT',
        image: staffImageResolver(currentStaff.staff!),
      })
      break
    }
    case 'CLIENT': {
      const currentClient = currentUser as ClientFullDto
      result.set(currentClient.userData?.user?.id!, {
        position: 'RIGHT',
        image: clientImageResolver(currentClient.client!),
      })
      break
    }
    case 'MODEL': {
      const currentModel = currentUser as ModelFullDto
      result.set(currentModel.userData?.user?.id!, {
        position: 'RIGHT',
        image: modelImageResolver(currentModel.profilePhoto!),
      })
      break
    }
  }

  if (client) {
    result.set(client.user?.id!, {
      position:
        currentUser?.userData?.user?.role === 'CLIENT' || (currentUser?.userData?.user?.role === 'STAFF' && privateChat.type !== 'SUPPORT')
          ? 'RIGHT'
          : 'LEFT',
      image: clientImageResolver(client!.client!),
    })
  }

  if (model) {
    result.set(model.user?.id!, {
      position: currentUser?.userData?.user?.role === 'MODEL' ? 'RIGHT' : 'LEFT',
      image: modelImageResolver(model!.profilePhoto!),
    })
  }

  if (staff) {
    result.set(staff.user?.id!, {
      position: currentUser?.userData?.user?.role === 'STAFF' ? 'RIGHT' : 'LEFT',
      image: staffImageResolver(staff!.staff!),
    })
  }

  return result
}

export default getMessageAttributesByUserId
