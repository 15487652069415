// videoChatSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IParticipants, IVideoChatStore } from 'store/reducer/videoChat/types'
import { RoomWithAccessResponse } from 'store/apiMain/mainApi'

const initialState: IVideoChatStore = {
  videoChatObg: null,
  videoChatParticipants: null,
}

const videoChatSlice = createSlice({
  name: 'videoChat',
  initialState,
  reducers: {
    setVideoChat: (state, action: PayloadAction<RoomWithAccessResponse>) => {
      state.videoChatObg = action.payload
    },
    setVideoMembers: (state, action: PayloadAction<IParticipants>) => {
      state.videoChatParticipants = action.payload
    },
    resetVideoChatState: () => initialState,
  },
})

export const { setVideoMembers, resetVideoChatState, setVideoChat } = videoChatSlice.actions

export default videoChatSlice.reducer
