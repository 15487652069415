import React, { useEffect, useState } from 'react'
import { TransactionResponse, useTransactionGetAllByUserIdQuery } from 'store/apiMain/mainApi'
import './scss/index.scss'

interface IProps {
  userId: string
  update: boolean
}

const TransactionalController: React.FC<IProps> = ({ userId, update }) => {
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')
  const { data, refetch, error, isLoading } = useTransactionGetAllByUserIdQuery({ userId, page, pageSize })

  useEffect(() => {
    refetch()
  }, [update, pageSize])

  const handleNextPage = () => {
    if (data && page < data.totalPages - 1) {
      setPage(page + 1)
    }
  }

  const handlePrevPage = () => {
    if (page > 0) {
      setPage(page - 1)
    }
  }

  const handlePageSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPageSize(parseInt(event.target.value, 10))
    setPage(0) // Reset to first page when page size changes
  }

  const handleSortOrderChange = () => {
    setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'))
  }

  const sortedData = data
    ? [...data.content].sort((a: TransactionResponse, b: TransactionResponse) => {
        if (sortOrder === 'asc') {
          return a.coinsDelta - b.coinsDelta
        } else {
          return b.coinsDelta - a.coinsDelta
        }
      })
    : []

  return (
    <div className='TransactionalController'>
      <h1>Transactional Controller</h1>
      {isLoading && <p>Loading...</p>}
      {error && <p>Error loading transactions</p>}
      <div className='pagination'>
        <button onClick={handlePrevPage} disabled={page === 0}>
          Previous
        </button>
        <span>
          Page {page + 1} of {data?.totalPages}
        </span>
        <button onClick={handleNextPage} disabled={!data || page >= data.totalPages - 1}>
          Next
        </button>
      </div>
      <div className='pageSizeSelector'>
        <label htmlFor='pageSize'>Page Size: </label>
        <select id='pageSize' value={pageSize} onChange={handlePageSizeChange}>
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={9999}>9999</option>
        </select>
      </div>

      <div className='sortOrder'>
        <label htmlFor='sortOrder'>Sort by Coins Delta: </label>
        <button onClick={handleSortOrderChange}>{sortOrder === 'asc' ? 'Ascending' : 'Descending'}</button>
      </div>

      {sortedData.length ? (
        <ul>
          {sortedData.map((transaction: TransactionResponse) => (
            <li key={transaction.id}>
              <p>ID: {transaction.id}</p>
              <p>User ID: {transaction.userId}</p>
              <p>Created At: {new Date(transaction.createdAt).toLocaleString()}</p>
              <p>Coins Delta: {transaction.coinsDelta}</p>
              <p>Type: {transaction.type}</p>
              {transaction.details && <p>Details: {transaction.details}</p>}
            </li>
          ))}
        </ul>
      ) : (
        <p>No transactions found</p>
      )}
    </div>
  )
}

export default TransactionalController
