import React from 'react'
import { useClientGetFullByIdQuery, useModelGetFullByIdQuery } from 'store/apiMain/mainApi'
import { useParams } from 'react-router-dom'
import Item from './Item/Item'
import './css/index.scss'

const ModelSettings = () => {
  const { modelId } = useParams()
  const queryArg = {
    modelId: String(modelId),
    idType: 'ID',
  }
  // @ts-ignore
  // const {data} = useModelGetFullByIdQuery({...queryArg})
  const {
    data: modelData,
    isLoading,
    isError,
    refetch: refetchModel,
    // @ts-ignore
  } = useModelGetFullByIdQuery({ ...queryArg })

  return <div className='client'>{modelData !== null ? <Item item={modelData} refetch={refetchModel} /> : null}</div>
}

export default ModelSettings
