import { createSelector } from 'reselect'
import { RootState } from '../../types'

const selectSocketState = (state: RootState) => state.socket
export const getSocket = createSelector([selectSocketState], state => state.socket)
export const getSocketToken = createSelector([selectSocketState], state => state.socketToken)
export const getSocketIsConnected = createSelector([selectSocketState], state => state.isConnected)
export const getSocketActivityInfo = createSelector([selectSocketState], state => state.activityInfoSocket)
export const getSocketNotification = createSelector([selectSocketState], state => state.notificationSocket)
export const getRefreshToken = createSelector([selectSocketState], state => state.refreshToken)
export const changeProfileModel = createSelector([selectSocketState], state => state.changeProfileModel)

export const getSocketStepApprovedModel = createSelector([selectSocketState], state => state.approvedModel)

// new
export const getSubscribeUser = createSelector([selectSocketState], state => state.subscribeUser)

export const selectorSocketEventGift = createSelector([selectSocketState], state => state.socketEventGift)
