import React, { useState } from 'react'
import './scss/index.scss'
import { useTranslation } from 'react-i18next' // Import useTranslation

const Tabs = ({ activeTab, setActiveTab }: any) => {
  const { t } = useTranslation() // Initialize the translation hook

  return (
    <div className='tabsFriends'>
      <div className='tabsFriends-block'>
        <div className={`tabsFriends-block_box ${activeTab === undefined ? 'active' : ''}`} onClick={() => setActiveTab(undefined)}>
          <p>{t('tabs.all')}</p>
        </div>
        <div className={`tabsFriends-block_box ${activeTab === 'Sent' ? 'active' : ''}`} onClick={() => setActiveTab('Sent')}>
          <p>{t('tabs.sent')}</p>
        </div>
        <div className={`tabsFriends-block_box ${activeTab === 'Received' ? 'active' : ''}`} onClick={() => setActiveTab('Received')}>
          <p>{t('tabs.received')}</p>
        </div>
      </div>
    </div>
  )
}

export default Tabs
