import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { FormControl } from 'layouts-elements/FormControl/FormControl'
import { IPropsItem } from '../types'
import { MyFormValues } from './types'
import { UserDisableByIdApiArg, useUserDisableByIdMutation, useUserEnableByIdMutation, useUserPartialUpdateByIdMutation } from 'store/apiMain/mainApi'
import { toastSuccess } from '../../../../../elements/Notification/Notification'

const UserInformation: React.FC<IPropsItem> = ({ item, refetch }) => {
  // const [editFunc,{isSuccess}] = useUserPartialUpdateByIdMutation()
  const [disableEdit, { isSuccess: isSuccessDisable }] = useUserDisableByIdMutation()
  const [enableEdit, { isSuccess: isSuccessEnable }] = useUserEnableByIdMutation()
  const [editCheck, setEditCheck] = useState(false)

  useEffect(() => {
    if (isSuccessDisable) {
      refetch()
      toastSuccess('Data disable!')
    }
  }, [isSuccessDisable, refetch])

  useEffect(() => {
    if (isSuccessEnable) {
      refetch()
      toastSuccess('Data Enable!')
    }
  }, [isSuccessEnable, refetch])

  const initialValues: MyFormValues = {
    isDisabled: item?.userData?.user?.isDisabled,
  }
  useEffect(() => {
    if (item !== undefined) {
      formik.resetForm({
        values: {
          isDisabled: item?.userData?.user?.isDisabled,
        },
      })
    }
  }, [item])

  const validationSchema = yup.object().shape({
    isDisabled: yup.boolean().required(),
  })

  const onSubmit = (values: MyFormValues) => {
    setEditCheck(!editCheck)

    const payload: UserDisableByIdApiArg = {
      userId: String(item?.userData?.user?.id),
    }

    if (item?.userData?.user?.isDisabled) {
      enableEdit(payload)
    } else {
      disableEdit(payload)
    }
  }
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })
  return (
    <>
      {editCheck ? (
        <form onSubmit={formik.handleSubmit}>
          <h2>User Information</h2>
          <div className='item-info'>
            <strong>ID:</strong> {item?.userData?.user.id}
          </div>
          <div className='item-info'>
            <strong>Email:</strong> {item?.userData?.user.email}
          </div>
          <div className='item-info'>
            <strong>Role:</strong> {item?.userData?.user.role}
          </div>
          <div className='item-info'>
            <strong>Created At:</strong> {item?.userData?.user.createdAt}
          </div>
          <div className='item-info'>
            <strong>Is Disable:</strong> <FormControl name='isDisabled' type='checkbox' formik={formik} />
          </div>
          <div className='item-info'>
            <strong>Is Deleted:</strong> {item?.userData?.user.isDeleted ? 'Yes' : 'No'}
          </div>
          <div className='item-info'>
            <button
              onClick={() => {
                setEditCheck(!editCheck)
              }}
              type='submit'
            >
              Close
            </button>
            <button type='submit'>Save</button>
          </div>
        </form>
      ) : (
        <>
          <h2>User Information</h2>
          <div className='item-info'>
            <strong>ID:</strong> {item?.userData?.user.id}
          </div>
          <div className='item-info'>
            <strong>Email:</strong> {item?.userData?.user.email}
          </div>
          <div className='item-info'>
            <strong>Role:</strong> {item?.userData?.user.role}
          </div>
          <div className='item-info'>
            <strong>Created At:</strong> {item?.userData?.user.createdAt}
          </div>
          <div className='item-info'>
            <strong>Is Blocked:</strong> {item?.userData?.user.isDisabled ? 'Yes' : 'No'}
          </div>
          <div className='item-info'>
            <strong>Is Deleted:</strong> {item?.userData?.user.isDeleted ? 'Yes' : 'No'}
          </div>
          <div className='item-info'>
            <button
              onClick={() => {
                setEditCheck(!editCheck)
              }}
              type='submit'
            >
              Edit
            </button>
          </div>
        </>
      )}
    </>
  )
}

export default UserInformation
