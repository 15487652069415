import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IFileArrayStore } from './types'

export const initialState: IFileArrayStore = {
  photo: {
    info: [],
    idArray: [],
  },
  photoDocuments: {
    info: [],
    idArray: [],
  },
  audio: {
    info: [],
    idArray: [],
  },
  video: {
    info: [],
    idArray: [],
  },
}

export const fileArraySlice = createSlice({
  name: '@@fileArray',
  initialState,
  reducers: {
    addPhoto: (state, action: PayloadAction<any>) => {
      if (['JPEG', 'PNG', 'ICO', 'GIF', 'TIFF', 'WebP', 'EPS', 'SVG'].includes(action.payload.type)) {
        state.photo.info = state.photo.info.concat(action.payload)
        state.photo.idArray = state.photo.idArray.concat(action.payload.id)
      }
    },
    addPhotoFile: (state, action: PayloadAction<any>) => {
      if (state.photo.info.length) {
        // получаем последний элемент массива
        const index = action.payload.index
        // добавляем к нему изображение
        state.photo.info[index].file = action.payload.result
      }
    },
    deletePhoto: (state, action: PayloadAction<string>) => {
      const idToDelete = action.payload

      state.photo.info = state.photo.info.filter(photo => photo.id !== idToDelete)

      state.photo.idArray = state.photo.idArray.filter(id => id !== idToDelete)
    },
    // Photo documents
    addPhotoDocuments: (state, action: PayloadAction<any>) => {
      if (['JPEG', 'PNG', 'ICO', 'GIF', 'TIFF', 'WebP', 'EPS', 'SVG'].includes(action.payload.type)) {
        state.photoDocuments.info = state.photoDocuments.info.concat(action.payload)
        state.photoDocuments.idArray = state.photoDocuments.idArray.concat(action.payload.id)
      }
    },
    addPhotoDocumentsFile: (state, action: PayloadAction<any>) => {
      if (state.photoDocuments.info.length) {
        // получаем последний элемент массива
        const index = action.payload.index
        // добавляем к нему изображение
        state.photoDocuments.info[index].file = action.payload.result
      }
    },
    deletePhotoDocuments: (state, action: PayloadAction<string>) => {
      const idToDelete = action.payload

      state.photoDocuments.info = state.photoDocuments.info.filter(photo => photo.id !== idToDelete)

      state.photoDocuments.idArray = state.photoDocuments.idArray.filter(id => id !== idToDelete)
    },
    // audio //////////////////////////////////
    addAudio: (state, action: PayloadAction<any>) => {
      if (['MP3'].includes(action.payload.type)) {
        state.audio.info = state.audio.info.concat(action.payload)
        state.audio.idArray = state.audio.idArray.concat(action.payload.id)
      }
      if (['MP4'].includes(action.payload.type)) {
        state.audio.info = state.audio.info.concat(action.payload)
        state.audio.idArray = state.audio.idArray.concat(action.payload.id)
      }
    },
    addAudioFile: (state, action: PayloadAction<any>) => {
      if (state.audio.info.length) {
        // получаем последний элемент массива
        const lastFileIndex = state.audio.info.length - 1
        // добавляем к нему изображение
        state.audio.info[lastFileIndex].file = action.payload
      }
    },
    // video //////////////////////////////////
    addVideo: (state, action: PayloadAction<any>) => {
      if (['MP3'].includes(action.payload.type)) {
        state.video.info = state.video.info.concat(action.payload)
        state.video.idArray = state.video.idArray.concat(action.payload.id)
      }
      if (['MP4'].includes(action.payload.type)) {
        state.video.info = state.video.info.concat(action.payload)
        state.video.idArray = state.video.idArray.concat(action.payload.id)
      }
    },
    addVideoFile: (state, action: PayloadAction<any>) => {
      if (state.video.info.length) {
        // получаем последний элемент массива
        const lastFileIndex = state.video.info.length - 1
        // добавляем к нему изображение
        state.video.info[lastFileIndex].file = action.payload
      }
    },

    fileArrayInitState: () => initialState,
  },
})
export default fileArraySlice.reducer

export const {
  addPhoto,
  addPhotoFile,
  deletePhoto,
  addPhotoDocuments,
  addPhotoDocumentsFile,
  deletePhotoDocuments,
  addAudio,
  addAudioFile,
  addVideo,
  addVideoFile,
  fileArrayInitState,
} = fileArraySlice.actions
