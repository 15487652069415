import Header from 'Components/Model/Header/Header'
import React from 'react'
import MainHeader from '../MainHeader/MainHeader'
import './scss/index.scss'
import { IProps } from './types'

const MainContent: React.FC<IProps> = ({ children }) => {
  return (
    <>
      <MainHeader>
        <Header />
      </MainHeader>
      <div className='MainContent'>
        <div className='MainContent-container'>{children}</div>
      </div>
    </>
  )
}

export default MainContent
