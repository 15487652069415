/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUsersStore } from './types'
import { ClientViewDto, ModelViewDto } from 'store/apiMain/mainApi' // Предположим, что User - это тип данных пользователя

// ==========================================:
export const initialState: IUsersStore = {
  clients: null,
  models: null,
  staffs: null,
}

// ==========================================:
const usersSlice = createSlice({
  name: '@@users',
  initialState,
  reducers: {
    setClients(state, action: PayloadAction<ClientViewDto[]>) {
      const newClients = action.payload

      // Создаем карту из текущих клиентов для быстрого доступа по user.id
      const clientsMap = new Map<string, ClientViewDto>(
        (state.clients || []).filter(client => client.user?.id).map(client => [client.user!.id!, client]),
      )

      // Обновляем или добавляем клиентов
      for (const newClient of newClients) {
        const userId = newClient.user?.id
        if (userId) {
          // Если клиент с таким id уже есть - заменим
          // Если нет - добавим
          clientsMap.set(userId, newClient)
        }
      }

      // Преобразуем карту обратно в массив
      state.clients = Array.from(clientsMap.values())
    },
    setModels(state, action: PayloadAction<ModelViewDto[]>) {
      const newModels = action.payload

      // Создаем карту из текущих моделей для быстрого доступа по user.id
      const modelsMap = new Map<string, ModelViewDto>((state.models || []).filter(model => model.user?.id).map(model => [model.user!.id!, model]))

      // Обновляем или добавляем модели
      for (const newModel of newModels) {
        const userId = newModel.user?.id
        if (userId) {
          // Если модель с таким id уже есть - заменим
          // Если нет - добавим
          modelsMap.set(userId, newModel)
        }
      }

      // Преобразуем карту обратно в массив
      state.models = Array.from(modelsMap.values())
    },
    deleteClient(state, action: PayloadAction<string>) {
      const userIdToDelete = action.payload

      // Удаляем клиента с указанным id
      state.clients = (state.clients || []).filter(client => client.user?.id !== userIdToDelete)
    },
    deleteModel(state, action: PayloadAction<string>) {
      const userIdToDelete = action.payload

      // Удаляем модель с указанным id
      state.models = (state.models || []).filter(model => model.user?.id !== userIdToDelete)
    },
    setStaffs(state, action: PayloadAction<any>) {
      state.staffs = action.payload
    },
    clearUsers(state) {
      state.clients = null
      state.models = null
      state.staffs = null
    },
  },
})

export default usersSlice.reducer
export const { setClients, setModels, setStaffs, clearUsers, deleteClient, deleteModel } = usersSlice.actions
