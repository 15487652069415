export interface ILanguage {
  code: string
  name: string
  backendName: string
}

export interface IRegionLanguages {
  region: string
  languages: ILanguage[]
}

const languages: IRegionLanguages[] = [
  {
    region: 'Европа',
    languages: [
      { code: 'BG', name: 'български', backendName: 'BULGARIAN' },
      { code: 'CS', name: 'čeština', backendName: 'CZECH' },
      { code: 'DA', name: 'dansk', backendName: 'DANISH' },
      { code: 'DE', name: 'Deutsch', backendName: 'GERMAN' },
      { code: 'EL', name: 'ελληνικά', backendName: 'GREEK' },
      {
        code: 'EN-GB',
        name: 'English (British)',
        backendName: 'ENGLISH_BRITISH',
      },
      {
        code: 'EN-US',
        name: 'English (American)',
        backendName: 'ENGLISH_AMERICAN',
      },
      { code: 'ES', name: 'español', backendName: 'SPANISH' },
      { code: 'ET', name: 'eesti', backendName: 'ESTONIAN' },
      { code: 'FI', name: 'suomi', backendName: 'FINNISH' },
      { code: 'FR', name: 'français', backendName: 'FRENCH' },
      { code: 'HU', name: 'magyar', backendName: 'HUNGARIAN' },
      { code: 'IT', name: 'italiano', backendName: 'ITALIAN' },
      { code: 'LT', name: 'lietuvių', backendName: 'LITHUANIAN' },
      { code: 'LV', name: 'latviešu', backendName: 'LATVIAN' },
      { code: 'NB', name: 'norsk bokmål', backendName: 'NORWEGIAN_BOKMAL' },
      { code: 'NL', name: 'Nederlands', backendName: 'DUTCH' },
      { code: 'PL', name: 'polski', backendName: 'POLISH' },
      {
        code: 'PT-PT',
        name: 'português (Europeu)',
        backendName: 'PORTUGUESE_PORTUGAL',
      },
      { code: 'RO', name: 'română', backendName: 'ROMANIAN' },
      { code: 'RU', name: 'русский', backendName: 'RUSSIAN' },
      { code: 'SK', name: 'slovenčina', backendName: 'SLOVAK' },
      { code: 'SL', name: 'slovenščina', backendName: 'SLOVENIAN' },
      { code: 'SV', name: 'svenska', backendName: 'SWEDISH' },
      { code: 'UK', name: 'українська', backendName: 'UKRAINIAN' },
    ],
  },
  {
    region: 'Азия',
    languages: [
      { code: 'AR', name: 'العربية', backendName: 'ARABIC' },
      { code: 'JA', name: '日本語', backendName: 'JAPANESE' },
      { code: 'KO', name: '한국어', backendName: 'KOREAN' },
      {
        code: 'ZH-HANS',
        name: '中文（简体）',
        backendName: 'CHINESE_SIMPLIFIED',
      },
      {
        code: 'ZH-HANT',
        name: '中文（繁體）',
        backendName: 'CHINESE_TRADITIONAL',
      },
      { code: 'TR', name: 'Türkçe', backendName: 'TURKISH' },
    ],
  },
  {
    region: 'Америка',
    languages: [
      {
        code: 'EN-US',
        name: 'English (American)',
        backendName: 'ENGLISH_AMERICAN',
      },
      {
        code: 'PT-BR',
        name: 'português (Brasileiro)',
        backendName: 'PORTUGUESE_BRAZILIAN',
      },
      { code: 'ES', name: 'español', backendName: 'SPANISH' },
    ],
  },
  {
    region: 'Юго-Восточная Азия',
    languages: [{ code: 'ID', name: 'Bahasa Indonesia', backendName: 'INDONESIAN' }],
  },
]

export default languages
