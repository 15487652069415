/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'
import { IFriendsStore } from './types'

// ==========================================:
export const initialState: IFriendsStore = {
  updateFriends: false,
}

// ==========================================:
const friendsSlice = createSlice({
  name: '@@friends',
  initialState,
  reducers: {
    setFriendsBoolean: state => {
      state.updateFriends = !state.updateFriends
    },
  },
})
export default friendsSlice.reducer
export const { setFriendsBoolean } = friendsSlice.actions
