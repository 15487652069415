import React from 'react'

interface TextDisplayProps {
  text: string
}

const processText = (text: string | undefined): JSX.Element[] => {
  if (!text) {
    return []
  }

  return text.split('\n').map((line, index) => <p key={index}>{line}</p>)
}

export default processText
