import React, { useState, useEffect } from 'react'
import MicRecorder from 'mic-recorder-to-mp3'
import './scss/index.scss'
import { useTranslation } from 'react-i18next'

const Mp3Recorder = new MicRecorder({ bitRate: 128 })

interface MyAudio extends File {
  preview?: string
  id?: string
}

interface VoiceProps {
  audios: MyAudio[]
  setAudios: React.Dispatch<React.SetStateAction<MyAudio[]>>
  addFile: any
  getFile: any
  setAudioIds: any
  audioIds: any[]
}

const Audio: React.FC<VoiceProps> = ({ audios, setAudios, addFile, getFile, setAudioIds, audioIds }) => {
  const { t } = useTranslation()

  const [isRecording, setIsRecording] = useState<boolean>(false)
  const [isBlocked, setIsBlocked] = useState<boolean>(false)

  useEffect(() => {
    navigator.mediaDevices
      ?.getUserMedia({ audio: true })
      .then(() => {
        setIsBlocked(false)
      })
      .catch(() => {
        console.warn('Permission Denied')
        setIsBlocked(true)
      })
  }, [])

  const startRecording = () => {
    setAudios([]) // Очистить предыдущие аудиозаписи при начале новой записи
    if (isBlocked) {
      console.warn('Permission Denied')
    } else {
      Mp3Recorder.start()
        .then(() => {
          setIsRecording(true)
        })
        .catch((e: Error) => console.error(e))
    }
  }

  const stopRecording = async () => {
    try {
      const [_, blob] = await Mp3Recorder.stop().getMp3()
      const blobURL = URL.createObjectURL(blob)
      setIsRecording(false)

      const formData = new FormData()
      formData.append('file', blob, 'recording.mp3')
      const result = await addFile({ body: formData }).unwrap()

      if (result && result.id) {
        setAudioIds([result.id]) // Хранить только текущий идентификатор аудиозаписи
        // @ts-ignore
        setAudios([{ preview: blobURL, id: result.id }]) // Хранить только текущую аудиозапись
      }
    } catch (e) {
      console.log(e)
    }
  }

  const removeAudio = (index: number, audio: any) => {
    setAudioIds((prevAudioIds: any) => prevAudioIds.filter((id: any) => id !== audio.id))
    setAudios(prev => prev.filter((_, i) => i !== index))
  }

  useEffect(() => {
    const fetchAudiosFromIds = async () => {
      const fetchedAudios: MyAudio[] = []
      for (const audioId of audioIds) {
        const audioUrl = await getFile({ fileId: audioId }).unwrap()
        if (audioUrl) {
          fetchedAudios.push({
            id: audioId,
            name: audioId,
            type: 'audio/mpeg',
            preview: audioUrl,
          } as MyAudio)
        }
      }
      setAudios(fetchedAudios)
    }
    if (audioIds.length !== 0) {
      fetchAudiosFromIds()
    }
  }, [audioIds, getFile, setAudios])

  const audioPreviews = audios.map((audio, index) => (
    <div key={index}>
      <div>
        <audio src={audio.preview} controls></audio>
      </div>
      <button type='button' onClick={() => removeAudio(index, audio)}>
        {t('audioComponent.delete')}
      </button>
    </div>
  ))

  return (
    <div className='audio'>
      <button type='button' onClick={isRecording ? stopRecording : startRecording}>
        {isRecording ? t('audioComponent.stopRecording') : t('audioComponent.startRecording')}
      </button>
      <aside>
        <h4>{t('audioComponent.recordings')}</h4>
        <ul>{audioPreviews}</ul>
      </aside>
    </div>
  )
}

export default Audio
