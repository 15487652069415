import img1 from '../../images/img/profileClient/1.png'
import img2 from '../../images/img/profileClient/2.png'
import img3 from '../../images/img/profileClient/3.png'
import img4 from '../../images/img/profileClient/4.png'
import img5 from '../../images/img/profileClient/5.png'
import img6 from '../../images/img/profileClient/6.png'

export const photoViewClient = (photoId: number) => {
  if (photoId === 1) {
    return <img src={img1} alt='Profile 1' />
  }
  if (photoId === 2) {
    return <img src={img2} alt='Profile 2' />
  }
  if (photoId === 3) {
    return <img src={img3} alt='Profile 3' />
  }
  if (photoId === 4) {
    return <img src={img4} alt='Profile 4' />
  }
  if (photoId === 5) {
    return <img src={img5} alt='Profile 5' />
  }
  if (photoId === 6) {
    return <img src={img6} alt='Profile 6' />
  }
  return null
}
