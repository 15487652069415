import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

// Import the JSON files for each language
import ar from './languages/languages/ar.json'
import bg from './languages/languages/bg.json'
import cs from './languages/languages/cs.json'
import da from './languages/languages/da.json'
import de from './languages/languages/de.json'
import el from './languages/languages/el.json'
import enGB from './languages/languages/en-GB.json'
import enUS from './languages/languages/en-US.json'
import es from './languages/languages/es.json'
import et from './languages/languages/et.json'
import fi from './languages/languages/fi.json'
import fr from './languages/languages/fr.json'
import hu from './languages/languages/hu.json'
import id from './languages/languages/id.json'
import it from './languages/languages/it.json'
import ja from './languages/languages/ja.json'
import ko from './languages/languages/ko.json'
import lt from './languages/languages/lt.json'
import lv from './languages/languages/lv.json'
import nb from './languages/languages/nb.json'
import nl from './languages/languages/nl.json'
import pl from './languages/languages/pl.json'
import ptBR from './languages/languages/pt-BR.json'
import ptPT from './languages/languages/pt-PT.json'
import ro from './languages/languages/ro.json'
import ru from './languages/languages/ru.json'
import sk from './languages/languages/sk.json'
import sl from './languages/languages/sl.json'
import sv from './languages/languages/sv.json'
import tr from './languages/languages/tr.json'
import uk from './languages/languages/uk.json'
import zhHANS from './languages/languages/zh-HANS.json'
import zhHANT from './languages/languages/zh-HANT.json'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      ar: { translation: ar },
      bg: { translation: bg },
      cs: { translation: cs },
      da: { translation: da },
      de: { translation: de },
      el: { translation: el },
      'en-GB': { translation: enGB },
      'en-US': { translation: enUS },
      es: { translation: es },
      et: { translation: et },
      fi: { translation: fi },
      fr: { translation: fr },
      hu: { translation: hu },
      id: { translation: id },
      it: { translation: it },
      ja: { translation: ja },
      ko: { translation: ko },
      lt: { translation: lt },
      lv: { translation: lv },
      nb: { translation: nb },
      nl: { translation: nl },
      pl: { translation: pl },
      'pt-BR': { translation: ptBR },
      'pt-PT': { translation: ptPT },
      ro: { translation: ro },
      ru: { translation: ru },
      sk: { translation: sk },
      sl: { translation: sl },
      sv: { translation: sv },
      tr: { translation: tr },
      uk: { translation: uk },
      'zh-HANS': { translation: zhHANS },
      'zh-HANT': { translation: zhHANT },
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
