import React, { useState, useEffect, ChangeEvent } from 'react'
import { MessageRuleRequest, useMessageRulesGetQuery, useMessageRulesSetMutation } from 'store/apiMain/mainApi'
import './scss/index.scss'

const MessageReplenishment: React.FC = () => {
  const { data, error, isLoading } = useMessageRulesGetQuery()
  const [updateRules, { isLoading: isUpdating }] = useMessageRulesSetMutation()

  const [rules, setRules] = useState<MessageRuleRequest[]>([])
  const [editMode, setEditMode] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  useEffect(() => {
    if (data) {
      const fetchedRules: MessageRuleRequest[] = data.rules.map(rule => ({
        coinsDeltaFrom: rule.coinsDeltaFrom,
        coinsDeltaTo: rule.coinsDeltaTo,
        messages: rule.messages,
      }))
      setRules(fetchedRules)
    }
  }, [data])

  const handleInputChange = (index: number, field: keyof MessageRuleRequest, value: number) => {
    const newRules = [...rules]
    newRules[index][field] = value
    setRules(newRules)
  }

  const handleAddRule = () => {
    setRules([...rules, { coinsDeltaFrom: 0, coinsDeltaTo: undefined, messages: 0 }])
    setEditMode(true)
  }

  const validateRules = (rules: MessageRuleRequest[]): boolean => {
    for (let i = 0; i < rules.length - 1; i++) {
      if (rules[i].coinsDeltaTo === undefined) {
        return false
      }
    }
    return true
  }

  const handleSave = async () => {
    if (!validateRules(rules)) {
      setErrorMessage('Rule with coinsDeltaTo = null must be the last rule')
      return
    }
    try {
      await updateRules({ messageRulesSetRequest: { rules } }).unwrap()
      setEditMode(false)
      setErrorMessage(null)
    } catch (error) {
      console.error('Failed to update rules', error)
    }
  }

  if (isLoading) return <div>Loading...</div>
  if (error) return <div>Error loading message rules</div>

  return (
    <div className='MessageReplenishment'>
      <h1>Message Rules</h1>
      {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
      <table>
        <thead>
          <tr>
            <th>Coins Delta From</th>
            <th>Coins Delta To</th>
            <th>Messages</th>
          </tr>
        </thead>
        <tbody>
          {rules.map((rule, index) => (
            <tr key={index}>
              <td>
                {editMode ? (
                  <input
                    type='number'
                    value={rule.coinsDeltaFrom}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(index, 'coinsDeltaFrom', parseInt(e.target.value))}
                  />
                ) : (
                  rule.coinsDeltaFrom
                )}
              </td>
              <td>
                {editMode ? (
                  <input
                    type='number'
                    value={rule.coinsDeltaTo !== undefined ? rule.coinsDeltaTo : ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(index, 'coinsDeltaTo', parseInt(e.target.value))}
                  />
                ) : (
                  rule.coinsDeltaTo
                )}
              </td>
              <td>
                {editMode ? (
                  <input
                    type='number'
                    value={rule.messages}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(index, 'messages', parseInt(e.target.value))}
                  />
                ) : (
                  rule.messages
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {editMode ? (
        <button onClick={handleSave} disabled={isUpdating}>
          {isUpdating ? 'Saving...' : 'Save'}
        </button>
      ) : (
        <button onClick={() => setEditMode(true)}>Edit</button>
      )}
      <button onClick={handleAddRule}>Add Rule</button>
    </div>
  )
}

export default MessageReplenishment
