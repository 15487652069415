import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { TransactionGetByAccountReplenishApiArg, useTransactionGetByAccountReplenishQuery } from 'store/apiMain/mainApi'
import './scss/index.scss'
import dataFormatString from '../../../utils/dataFormatString'

export type Filters = Omit<TransactionGetByAccountReplenishApiArg, 'referralId'>

const ReferralControllerTransactions = () => {
  const { referralId } = useParams<{ referralId: string }>()

  const [filters, setFilters] = useState<Filters>({
    userRole: 'CLIENT',
    fromDate: '',
    toDate: '',
    page: 0,
    pageSize: 10,
  })

  const { data, error, isLoading } = useTransactionGetByAccountReplenishQuery({
    referralId: referralId || '', // Обязательный параметр
    ...filters,
    fromDate: filters.fromDate || undefined,
    toDate: filters.toDate || undefined,
  })

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target
    setFilters(prev => ({
      ...prev,
      [name]: name === 'page' || name === 'pageSize' ? Number(value) : value,
    }))
  }

  if (!referralId) {
    return <div>Error: referralId is missing from the URL</div>
  }

  if (isLoading) return <div>Loading transactions...</div>
  if (error) return <div>Error fetching transactions: {error.toString()}</div>
  if (!data || !data.content) return <div>No transactions found.</div>

  return (
    <div className='ReferralControllerTransactions'>
      <h1>Transaction List for Referral ID: {referralId}</h1>
      <span>
        {' '}
        <strong>Total Coins Delta:</strong> {data.content.reduce((total, transaction) => total + transaction.coinsDelta, 0)}
      </span>
      <div className='filters'>
        <label>
          User Role:
          <select name='userRole' value={filters.userRole} onChange={handleFilterChange}>
            <option value='CLIENT'>Client</option>
            <option value='MODEL'>Model</option>
            <option value='STAFF'>Staff</option>
          </select>
        </label>
        <label>
          From Date:
          <input type='date' name='fromDate' value={filters.fromDate} onChange={handleFilterChange} />
        </label>
        <label>
          To Date:
          <input type='date' name='toDate' value={filters.toDate} onChange={handleFilterChange} />
        </label>
        <label>
          Page:
          <input type='number' name='page' value={filters.page} onChange={handleFilterChange} min={0} />
        </label>
        <label>
          Page Size:
          <input type='number' name='pageSize' value={filters.pageSize} onChange={handleFilterChange} min={1} />
        </label>
      </div>
      {/* Таблица */}
      <table>
        <thead>
          <tr>
            <th>Type</th>
            <th>Coins Delta</th>
            <th>Created At</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          {data.content.map((transaction: any) => (
            <tr key={transaction.id}>
              <td>{transaction.type}</td>
              <td>{transaction.coinsDelta}</td>
              <td>{dataFormatString(transaction.createdAt)}</td>
              <td>{transaction.details || 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <p>
        Page {data.currentPage + 1} of {data.totalPages}
      </p>
    </div>
  )
}

export default ReferralControllerTransactions
