import {
  SvgIcons8Body5011,
  SvgIcons8Bra501,
  SvgIcons8Calendar501,
  SvgIcons8Globe501,
  SvgIcons8Hair501,
  SvgIcons8Hair5011,
  SvgIcons8Height501,
  SvgIcons8Sex501,
  SvgIcons8Weight501,
  SvgQuestion,
} from 'images/svg'
import React, { FC } from 'react'
import { ModelFullDto } from 'store/apiMain/mainApi'
import AudioGirlView from 'UI/Audio/AudioGirlView'
import { getParsedLanguages } from 'utils/languageCodes'
import { useTranslation } from 'react-i18next'
import Tippy from '@tippyjs/react'
import TextTooltipInfo from '../../../UI/Tooltip/TextTooltipInfo/TextTooltipInfo'
import ButtonInfo from '../../../UI/ButtonInfo/ButtonInfo'

interface Props {
  data?: ModelFullDto
  age: number
}

export const BoxList: FC<Props> = ({ data, age }) => {
  const { t } = useTranslation()

  return (
    <div className='box-list'>
      <div className='block'>
        <h6>{data?.model?.name}</h6>
      </div>
      <div className='box-list__audio'>
        <AudioGirlView audio={data?.audios?.[0]} positionsButton='left' modelId={data?.model?.id} />
      </div>
      <ul>
        <li>
          <div className='item__svg'>
            <SvgIcons8Calendar501 />
          </div>
          <div className='box-list__box'>
            <strong>Возраст:</strong> <p>{age} age</p>
          </div>
        </li>
        <li>
          <div className='item__svg'>
            <SvgIcons8Weight501 />
          </div>
          <div className='box-list__box'>
            <strong>Вес:</strong> <p>{data?.model?.weight} kg</p>
          </div>
        </li>
        <li>
          <div className='item__svg'>
            <SvgIcons8Height501 />
          </div>
          <div className='box-list__box'>
            <strong>Рост:</strong> <p>{data?.model?.height} cm</p>
          </div>
        </li>
        <li>
          <div className='item__svg'>
            <SvgIcons8Height501 />
          </div>
          <div className='box-list__box'>
            <strong>Объемы:</strong>
            <p>
              {data?.model?.chest}-{data?.model?.waist}-{data?.model?.hips}
            </p>
          </div>
        </li>
        <li>
          <div className='item__svg'>
            <SvgIcons8Bra501 />
          </div>
          <div className='box-list__box'>
            <strong>Размер Груди:</strong> <p>{data?.model?.breastSize}</p>
          </div>
        </li>
        <li>
          <div className='item__svg'>
            <SvgIcons8Sex501 />
          </div>
          <div className='box-list__box'>
            <strong>Ориентация:</strong> <p>{t(data?.model?.sexualOrientation ?? '')}</p>
          </div>
        </li>
        <li>
          <div className='item__svg'>
            <SvgIcons8Body5011 />
          </div>
          <div className='box-list__box'>
            <strong>Телосложение:</strong> <p>{t(data?.model?.bodyType ?? '')}</p>
          </div>
        </li>
        <li>
          <div className='item__svg'>
            <SvgIcons8Hair5011 />
          </div>
          <div className='box-list__box'>
            <strong>Цвет Волос:</strong> <p>{t(data?.model?.hairColor ?? '')}</p>
          </div>
        </li>
        <li>
          <div className='item__svg'>
            <SvgIcons8Hair501 />
          </div>
          <div className='box-list__box'>
            <strong>Длина Волос:</strong> <p>{t(data?.model?.hairLength ?? '')}</p>
          </div>
        </li>
        <li>
          <div className='item__svg'>
            <SvgIcons8Globe501 />
          </div>
          <div className='box-list__box'>
            <strong>Языки:</strong>
            {data?.languages && data.languages.length > 2 ? (
              <div className='box-list__languages'>
                <p>{data.languages.length}</p>
                <ButtonInfo text={getParsedLanguages(data?.languages)} />
              </div>
            ) : (
              <p>{getParsedLanguages(data?.languages)}</p>
            )}
          </div>
        </li>
      </ul>
    </div>
  )
}
