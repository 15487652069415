import React, { useEffect, useState, useRef } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import './scss/index.scss'
import Photo from 'UI/Photo/Photo'
import { FormControl } from 'layouts-elements/FormControl/FormControl'
import { useModelRequestCreateMutation, useFileCreateMutation, useUserGetFullMeQuery, useModelRequestGetMeQuery } from 'store/apiMain/mainApi'
import Audio from 'UI/Audio/Audio'
import {
  optionsBirthYear,
  optionsWeight,
  optionsHeight,
  optionsChest,
  optionsWaist,
  optionsBreastSize,
  optionsHairColor,
  optionsSexualOrientation,
  optionsBodyType,
  optionsHairLength,
  optionsHips,
} from 'UI/SelectCustom/constValue'

import { toastSuccess } from 'elements/Notification/Notification'
import { useGetFileMutation } from 'store/apiMain/emptyApi'
import { useNavigate } from 'react-router-dom'
import Video from 'UI/Video/Video'
import { useDispatch, useSelector } from 'react-redux'
import { selectSteps1 } from 'store/reducer/steps/selector'
import { setSteps1 } from 'store/reducer/steps/reducer'
import { useTranslation } from 'react-i18next'
import CropeMain from '../../../Global/Crope/CropeMain'
import CustomTextArea from '../../../../UI/CustomTextArea/CustomTextArea'
import SelectItem from './SelectItem'
import LanguagesSelect from '../LanguagesSelect'

export interface RawContent {
  blocks?: Array<{
    text: string
  }>
}

export const countChars = (rawContent?: RawContent): number => {
  if (!rawContent || !rawContent.blocks) return 0
  return rawContent.blocks.reduce((total, block) => total + (block?.text?.length || 0), 0)
}

const Step1 = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { data: modelData, isLoading, isError } = useUserGetFullMeQuery()
  const { data: modelRequest, isLoading: byIdLoading, isError: byIdError, refetch } = useModelRequestGetMeQuery()
  const [checkedProverka, setCheckedProverka] = useState(false)
  const steps1 = useSelector(selectSteps1)

  // Создание ref для каждого поля
  const refs = {
    name: useRef<HTMLLabelElement>(null),
    birthYear: useRef<HTMLDivElement>(null),
    weight: useRef<HTMLDivElement>(null),
    height: useRef<HTMLDivElement>(null),
    chest: useRef<HTMLDivElement>(null),
    waist: useRef<HTMLDivElement>(null),
    hips: useRef<HTMLDivElement>(null),
    breastSize: useRef<HTMLDivElement>(null),
    hairColor: useRef<HTMLDivElement>(null),
    sexualOrientation: useRef<HTMLDivElement>(null),
    bodyType: useRef<HTMLDivElement>(null),
    hairLength: useRef<HTMLDivElement>(null),
    languages: useRef<HTMLDivElement>(null),
    profilePhoto: useRef<HTMLDivElement>(null),
    photos: useRef<HTMLDivElement>(null),
    videos: useRef<HTMLDivElement>(null),
    audios: useRef<HTMLDivElement>(null),
    selfInformation: useRef<HTMLDivElement>(null),
  }

  useEffect(() => {
    if (modelRequest?.request?.state === 'CREATE_NEW' && steps1 === false) {
      navigate('/model/registration/step/2')
      dispatch(setSteps1(true))
    }
  }, [modelRequest])

  useEffect(() => {
    if (modelRequest !== null && modelRequest !== undefined && modelRequest?.mainInfo !== undefined) {
      setCheckedProverka(true)
      const profilePhoto = modelRequest?.mainInfo?.profilePhoto
      const profilePhotoID = profilePhoto?.file?.id
      // @ts-ignore
      setProfilePhotoIds(profilePhotoID)

      const arrayPhoto = modelRequest?.mainInfo?.photos
      const idPhotos = arrayPhoto?.map(item => item?.file?.id)
      // @ts-ignore
      setPhotoIds(idPhotos)

      const arrayVideos = modelRequest?.mainInfo?.videos
      const idVideo = arrayVideos?.map(item => item?.file?.id)
      // @ts-ignore
      setVideosIds(idVideo)

      const arrayAudios = modelRequest?.mainInfo?.audios
      const idAudio = arrayAudios?.map(item => item?.file?.id)
      // @ts-ignore
      setAudiosIds(idAudio)
      formik.resetForm({
        values: {
          // @ts-ignore
          languages: modelRequest?.mainInfo?.languages || [],
          dataType: modelRequest?.mainInfo?.dataType || 'MAIN_INFO',
          // @ts-ignore
          name: modelRequest?.mainInfo?.name || '',
          // @ts-ignore
          selfInformation: JSON.parse(modelRequest?.mainInfo?.selfInformation) || '',
          // @ts-ignore
          birthYear: modelRequest?.mainInfo?.birthYear || '',
          // @ts-ignore
          weight: modelRequest?.mainInfo?.weight || '',
          // @ts-ignore
          height: modelRequest?.mainInfo?.height || '',
          // @ts-ignore
          chest: modelRequest?.mainInfo?.chest || '',
          // @ts-ignore
          waist: modelRequest?.mainInfo?.waist || '',
          // @ts-ignore
          hips: modelRequest?.mainInfo?.hips || '',
          // @ts-ignore
          hairColor: modelRequest?.mainInfo?.hairColor || '',
          // @ts-ignore
          breastSize: modelRequest?.mainInfo?.breastSize || '',
          // @ts-ignore
          sexualOrientation: modelRequest?.mainInfo?.sexualOrientation || '',
          // @ts-ignore
          bodyType: modelRequest?.mainInfo?.bodyType || '',
          // @ts-ignore
          hairLength: modelRequest?.mainInfo?.hairLength || '',
          // @ts-ignore
          profilePhoto: modelRequest?.mainInfo?.profilePhoto || null,
          // @ts-ignore
          photos: modelRequest?.mainInfo?.photos || [],
          // @ts-ignore
          videos: modelRequest?.mainInfo?.videos || [],
          // @ts-ignore
          audios: modelRequest?.mainInfo?.audios || [],
        },
      })
    }
  }, [modelRequest])

  const [getFile] = useGetFileMutation()
  const [addFile] = useFileCreateMutation()
  const [addRequest, { isSuccess }] = useModelRequestCreateMutation()

  const [profilePhoto, setProfilePhoto] = useState<string | null>(null)
  const [profilePhotoIds, setProfilePhotoIds] = useState<string | null>(null)

  const [photos, setPhotos] = useState<any[]>([])
  const [photoIds, setPhotoIds] = useState<string[]>([])

  const [videos, setVideos] = useState<any[]>([])
  const [videosIds, setVideosIds] = useState<string[]>([])

  const [audios, setAudios] = useState<any[]>([])
  const [audiosIds, setAudiosIds] = useState<string[]>([])

  const initialValues = {
    languages: [],
    dataType: 'MAIN_INFO',
    name: '',
    selfInformation: '',
    birthYear: '',
    weight: '',
    height: '',
    chest: '',
    waist: '',
    hips: '',
    hairColor: '',
    breastSize: '',
    sexualOrientation: '',
    bodyType: '',
    hairLength: '',
    profilePhoto: null,
    photos: [],
    videos: [],
    audios: [],
  }

  const validationSchema = yup.object().shape({
    dataType: yup.string().required(t('stepOne.dataTypeRequired')),
    name: yup
      .string()
      .required(t('stepOne.nameRequired'))
      .min(2, t('stepOne.nameMin'))
      .max(50, t('stepOne.nameMax'))
      // @ts-ignore
      .matches(/^[\p{L}-]+$/u, t('stepOne.nameInvalid')),
    languages: yup
      .array()
      .of(yup.string().min(1, t('stepOne.languageMin')).max(50, t('stepOne.languageMax')))
      .required(t('stepOne.languageRequired'))
      .min(1, t('stepOne.languageAtLeastOne')),
    selfInformation: yup
      .mixed<RawContent>()
      .test('selfInformationRequired', t('stepOne.selfInformationRequired'), value => !!value && countChars(value as RawContent) > 0)
      .test('selfInformationMin', t('stepOne.selfInformationMin'), value => !!value && countChars(value as RawContent) >= 10)
      .test('selfInformationMax', t('stepOne.selfInformationMax'), value => !!value && countChars(value as RawContent) <= 10000),
    birthYear: yup
      .number()
      .required(t('stepOne.birthYearRequired'))
      .min(1900, t('stepOne.birthYearMin'))
      .max(new Date().getFullYear(), t('stepOne.birthYearMax')),
    weight: yup.number().required(t('stepOne.weightRequired')),
    height: yup.number().required(t('stepOne.heightRequired')),
    chest: yup.number().required(t('stepOne.chestRequired')),
    waist: yup.number().required(t('stepOne.waistRequired')),
    hips: yup.number().required(t('stepOne.hipsRequired')),
    hairColor: yup.string().required(t('stepOne.hairColorRequired')),
    breastSize: yup.number().required(t('stepOne.breastSizeRequired')),
    sexualOrientation: yup.string().required(t('stepOne.sexualOrientationRequired')),
    bodyType: yup.string().required(t('stepOne.bodyTypeRequired')),
    hairLength: yup.string().required(t('stepOne.hairLengthRequired')),
    profilePhoto: yup.mixed().required(t('stepOne.mainPhotoRequired')).notOneOf([null], t('stepOne.mainPhotoRequired')),
    photos: yup.array().of(yup.string()).min(2, t('mustHaveAtLeastTwoPhotos')).required(t('stepOne.photosRequired')),
    videos: yup.array().of(yup.string()).required(t('stepOne.videosRequired')),
    audios: yup.array().of(yup.string()).required(t('stepOne.audiosRequired')),
  })

  const onSubmit = (values: any) => {
    const body = {
      ...values,
      selfInformation: JSON.stringify(values.selfInformation),
      profilePhoto: values.profilePhoto,
      photos: values.photos,
      videos: values.videos,
      audios: values.audios,
    }
    addRequest({ body })
  }

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Данные отправлены')
      refetch()
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [isSuccess])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  useEffect(() => {
    if (formik.submitCount > 0 && Object.keys(formik.errors).length > 0) {
      const errorFields = Object.keys(formik.errors)
      if (errorFields.length > 0) {
        const fieldName = errorFields[0]
        const fieldRef = refs[fieldName]
        if (fieldRef && fieldRef.current) {
          fieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
      }
    }
  }, [formik.submitCount])

  // Обновление значений formik при изменении идентификаторов
  useEffect(() => {
    formik.setFieldValue('photos', photoIds)
  }, [photoIds])

  useEffect(() => {
    formik.setFieldValue('profilePhoto', profilePhotoIds)
  }, [profilePhotoIds])

  useEffect(() => {
    formik.setFieldValue('videos', videosIds)
  }, [videosIds])

  useEffect(() => {
    formik.setFieldValue('audios', audiosIds)
  }, [audiosIds])

  const proverkaInfo = () => {
    if (checkedProverka && modelRequest?.mainInfo?.isApproved === false) {
      return (
        <div className='checkSteps'>
          <div className='checkSteps-block'>
            <p>{t('stepOne.infoPending')}</p>
          </div>
        </div>
      )
    }
    if (modelRequest?.mainInfo?.isApproved === true) {
      return (
        <div className='checkSteps'>
          <div className='checkSteps-block'>
            <p>{t('stepOne.infoApproved')}</p>
          </div>
        </div>
      )
    }
    return null
  }

  const [clickSubmit, setClickSubmit] = useState(false)

  return (
    <div className='step1-information'>
      {proverkaInfo()}
      <form onSubmit={formik.handleSubmit}>
        <label className='name' ref={refs.name}>
          <span>{t('Name')}</span>
          <FormControl name='name' type='text' formik={formik} placeholder='' />
        </label>
        <div className='step1-information-block'>
          <div className='step1-information-block__item'>
            <label>Birth year</label>
            <div className='step1-information-block__item_box' ref={refs.birthYear}>
              <SelectItem name='birthYear' options={optionsBirthYear} formik={formik} placeholder={t('Select...')} />
            </div>
          </div>
          <div className='step1-information-block__item'>
            <label>Weight</label>
            <div className='step1-information-block__item_box' ref={refs.weight}>
              <SelectItem name='weight' options={optionsWeight} formik={formik} placeholder={t('Select...')} />
            </div>
          </div>
          <div className='step1-information-block__item'>
            <label>Height</label>
            <div className='step1-information-block__item_box' ref={refs.height}>
              <SelectItem name='height' options={optionsHeight} formik={formik} placeholder={t('Select...')} />
            </div>
          </div>
          <div className='step1-information-block__item'>
            <label>Chest</label>
            <div className='step1-information-block__item_box' ref={refs.chest}>
              <SelectItem name='chest' options={optionsChest} formik={formik} placeholder={t('Select...')} />
            </div>
          </div>
          <div className='step1-information-block__item'>
            <label>Waist</label>
            <div className='step1-information-block__item_box' ref={refs.waist}>
              <SelectItem name='waist' options={optionsWaist} formik={formik} placeholder={t('Select...')} />
            </div>
          </div>
          <div className='step1-information-block__item'>
            <label>Hips</label>
            <div className='step1-information-block__item_box' ref={refs.hips}>
              <SelectItem name='hips' options={optionsHips} formik={formik} placeholder={t('Select...')} />
            </div>
          </div>
          <div className='step1-information-block__item'>
            <label>Breast size</label>
            <div className='step1-information-block__item_box' ref={refs.breastSize}>
              <SelectItem name='breastSize' options={optionsBreastSize} formik={formik} placeholder={t('Select...')} />
            </div>
          </div>
          <div className='step1-information-block__item'>
            <label>Hair color</label>
            <div className='step1-information-block__item_box' ref={refs.hairColor}>
              <SelectItem name='hairColor' options={optionsHairColor} formik={formik} placeholder={t('Select...')} />
            </div>
          </div>
          <div className='step1-information-block__item'>
            <label>Sexual orientation</label>
            <div className='step1-information-block__item_box' ref={refs.sexualOrientation}>
              <SelectItem name='sexualOrientation' options={optionsSexualOrientation} formik={formik} placeholder={t('Select...')} />
            </div>
          </div>
          <div className='step1-information-block__item'>
            <label>Body type</label>
            <div className='step1-information-block__item_box' ref={refs.bodyType}>
              <SelectItem name='bodyType' options={optionsBodyType} formik={formik} placeholder={t('Select...')} />
            </div>
          </div>
          <div className='step1-information-block__item'>
            <label>Hair length</label>
            <div className='step1-information-block__item_box' ref={refs.hairLength}>
              <SelectItem name='hairLength' options={optionsHairLength} formik={formik} placeholder={t('Select...')} />
            </div>
          </div>
          <div className='step1-information-block__item'>
            <label>Spoken languages</label>
            <div className='step1-information-block__item_box' ref={refs.languages}>
              <LanguagesSelect name='languages' formik={formik} placeholder={t('Select...')} />
            </div>
          </div>
          <div className='step1-information-block__item'>
            <label>Main photo</label>
            <div className='step1-photo profile-photo' ref={refs.profilePhoto}>
              <CropeMain
                formik={formik}
                name='profilePhoto'
                nameText={formik.values.name}
                profilePhoto={profilePhoto}
                setProfilePhoto={setProfilePhoto}
                addFile={addFile}
                getFile={getFile}
                setProfilePhotoIds={setProfilePhotoIds}
                profilePhotoIds={profilePhotoIds}
              />
            </div>
          </div>
          <div className='step1-information-block__item photos'>
            <label>Additional photos</label>
            <div className='step1-information-block__item_box step1-photo' ref={refs.photos}>
              <Photo photoIds={photoIds} photos={photos} setPhotos={setPhotos} addFile={addFile} getFile={getFile} setPhotoIds={setPhotoIds} />
            </div>
            {formik.errors.photos && formik.touched.photos && (
              <div className='errorPhoto'>
                <div className='errorPhoto-block'>
                  <p>{formik.errors.photos}</p>
                </div>
              </div>
            )}
          </div>
          <div className='step1-information-block__item'>
            <label>Video files</label>
            <div className='step1-information-block__item_box step1-video' ref={refs.videos}>
              <Video videoIds={videosIds} videos={videos} setVideos={setVideos} addFile={addFile} getFile={getFile} setVideoIds={setVideosIds} />
            </div>
          </div>
          <div className='step1-information-block__item'>
            <label>Voice</label>
            <div className='step1-information-block__item_box step1-audio' ref={refs.audios}>
              <Audio audioIds={audiosIds} audios={audios} setAudios={setAudios} addFile={addFile} getFile={getFile} setAudioIds={setAudiosIds} />
            </div>
          </div>
          <div className='step1-information-block__item'>
            <label>Personal info</label>
            <div className='step1-information-block__item_box step1-textarea' ref={refs.selfInformation}>
              <CustomTextArea
                formik={formik}
                initialRawContent={formik.values.selfInformation}
                onChangeRaw={rawContent => formik.setFieldValue('selfInformation', rawContent)}
              />
            </div>
          </div>
          <div className='step1-information-block__button'>
            <button
              onClick={() => {
                setClickSubmit(true)
              }}
              type='submit'
            >
              {t('Save')}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Step1
