import React, { useState, ChangeEvent } from 'react'

import './scss/index.scss'
import { PageUserDto, UserGetAllApiArg, useUserGetAllQuery } from 'store/apiMain/mainApi'

const Users: React.FC = () => {
  const [filter, setFilter] = useState<UserGetAllApiArg>({
    isOnline: undefined,
    showDeleted: undefined,
    showDisabled: undefined,
    ids: undefined,
    page: 1,
    pageSize: 10,
  })

  const { data, error, isLoading } = useUserGetAllQuery({
    isOnline: filter.isOnline,
    showDeleted: filter.showDeleted,
    showDisabled: filter.showDisabled,
    ids: filter.ids,
    page: filter.page,
    pageSize: filter.pageSize,
  })

  const handleChangeCheckbox = (field: keyof UserGetAllApiArg) => (event: ChangeEvent<HTMLInputElement>) => {
    setFilter(prev => ({
      ...prev,
      [field]: event.target.checked || undefined,
      page: 1,
    }))
  }

  const handleChangeIds = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim()
    setFilter(prev => ({
      ...prev,
      ids: value ? value.split(',').map(id => id.trim()) : undefined,
      page: 1,
    }))
  }

  const handleChangePageSize = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value)
    setFilter(prev => ({
      ...prev,
      pageSize: value,
      page: 1,
    }))
  }

  const handleChangePage = (newPage: number) => {
    setFilter(prev => ({
      ...prev,
      page: newPage,
    }))
  }

  return (
    <div className='usersStaff'>
      <div className='users-container'>
        <h2>Фильтрация</h2>
        <div className='filters'>
          <label>
            <input type='checkbox' checked={filter.isOnline ?? false} onChange={handleChangeCheckbox('isOnline')} />
            Online
          </label>
          <label>
            <input type='checkbox' checked={filter.showDeleted ?? false} onChange={handleChangeCheckbox('showDeleted')} />
            Показывать удалённых
          </label>
          <label>
            <input type='checkbox' checked={filter.showDisabled ?? false} onChange={handleChangeCheckbox('showDisabled')} />
            Показывать отключенных
          </label>
          <label>
            IDs (через запятую):
            <input type='text' onChange={handleChangeIds} />
          </label>
          <label>
            Page Size:
            <input type='number' value={filter.pageSize} onChange={handleChangePageSize} />
          </label>
        </div>

        <h2>Список пользователей</h2>
        {isLoading && <p>Загрузка...</p>}
        {error && <p>Ошибка при загрузке данных</p>}
        {!isLoading && data && (
          <div>
            <table className='users-table'>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Email</th>
                  <th>Роль</th>
                  <th>Язык</th>
                  <th>Email Вериф.</th>
                  <th>Отключён</th>
                  <th>Удалён</th>
                  <th>Создан</th>
                </tr>
              </thead>
              <tbody>
                {data.content.map(user => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>{user.email}</td>
                    <td>{user.role}</td>
                    <td>{user.language}</td>
                    <td>{user.isEmailVerified ? 'Да' : 'Нет'}</td>
                    <td>{user.isDisabled ? 'Да' : 'Нет'}</td>
                    <td>{user.isDeleted ? 'Да' : 'Нет'}</td>
                    <td>{user.createdAt ? new Date(user.createdAt).toLocaleString() : ''}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Пагинация */}
            <div className='pagination-container'>
              <div className='pagination-buttons'>
                {Array.from({ length: data.totalPages }, (_, i) => i + 1).map(pageNumber => (
                  <button
                    key={pageNumber}
                    onClick={() => handleChangePage(pageNumber)}
                    className={`pagination-button ${pageNumber === filter.page ? 'active' : ''}`}
                  >
                    {pageNumber}
                  </button>
                ))}
              </div>
              <div className='total-elements'>Всего: {data.totalElements}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export default Users
