import { SvgSearch4 } from 'images/svg'
import React, { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import './scss/index.scss'

interface IPropsSearch {
  text: string
  handleNameSearchChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const SearchTooltip: React.FC<IPropsSearch> = ({ text, handleNameSearchChange }) => {
  const { t } = useTranslation()

  return (
    <div className='search'>
      <div className='search-block'>
        <h3>{t('models.searchPlaceholder')}</h3>
        <SvgSearch4 />
        {text && text.length >= 10 ? (
          <div className='error'>
            <p>{t('models.error.tooManyCharacters')}</p>
          </div>
        ) : null}
      </div>

      <input type='text' placeholder={t('models.searchPlaceholder')} value={text} maxLength={10} onChange={handleNameSearchChange} />
    </div>
  )
}

export default SearchTooltip
