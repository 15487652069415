import React from 'react'

const TermsAndConditions = () => {
  let email = 'VirtualHouse@gmail.com'
  let url1 = 'https://www.VirtualHouse.com/policies'
  let url = 'https://www.VirtualHouse.com'
  return (
    <>
      <div className='info'>
        <div className='info-block'>
          <h2>Условия и положения</h2>
          <h3> Последнее изменение: 15.01.2021</h3>
          <p>
            1. Предварительные положения
            <br />
            1.1. Введение
            <br />
            Добро пожаловать в Соглашение на нашем веб-сайте (далее «Положения и условия» или «Соглашение»). Положения настоящего Соглашения будут
            регулировать использование вами Нашего (-ых) веб-сайта (-ов), а также всех аффилированных веб-сайтов и других услуг, и поэтому вам следует
            потратить некоторое время, чтобы внимательно его прочитать. Мы надеемся, что Вам в полной мере понравятся наши услуги, и надеемся, что Вы
            найдете наш сайт полезным и информативным. Если у вас есть какие-либо вопросы или комментарии относительно нашего веб-сайта или его
            политики, пожалуйста, свяжитесь с нами по адресу : {email}
            <br />
            1.2. Мы устанавливаем юридические условия на использование вами этого веб-сайта
            <br />
            1.3. Нашим первым условием является то, что Вы должны согласиться со всеми условиями настоящего Соглашения. Вам не нужно использовать наш
            веб-сайт, поэтому, если вы не хотите соблюдать все положения настоящего Соглашения, вы не можете использовать этот веб-сайт и должны
            покинуть его и использовать другой сервис
            <br />
            1.4. Если Вы не понимаете всех условий настоящего Соглашения, Вам следует проконсультироваться с юристом перед использованием Веб-сайта
            <br />
            <br />
            <br />
            25. Заявление 18 USC 2257.
            <br />
            {url} требует, чтобы все участники и пользователи, которые получают доступ или используют любую часть сайта {url}, были не моложе
            восемнадцати (18) лет.
            <br />
            <br />
            {url} действует как технологическая служба, позволяющая Участникам обмениваться информацией, развлекательными услугами или другими
            продуктами или услугами между собой. {url} никоим образом не оценивает, не предоставляет, не производит и не контролирует Услуги для
            Участников, а также информацию или обмены между Участниками. {url} сам по себе не предоставляет какой-либо контент, информацию,
            развлекательные услуги или другие продукты или услуги, а также не проверяет, не гарантирует и не делает никаких заявлений относительно
            личности или квалификации любого Участника. {url} не принимает редакционных или управленческих решений в отношении содержимого списков
            Участников, откровенно сексуального или иного.
            <br />
            <br />
            Используя службу {url}, участники в соответствии с соглашением обязываются соблюдать все применимые федеральные, государственные и местные
            законы, постановления и постановления, касающиеся непристойного и непристойного содержания, сообщений и обязательств по ведению
            документации. Участники несут единоличную ответственность за предоставленный контент и контент в списках, созданных в их учетных записях
            профиля {url}, и несут единоличную ответственность за обеспечение их соответствия всем применимым законам и постановлениям, включая,
            помимо прочего, предоставление определенной информации о местонахождении записи в 18 USC 2257 и соответствующие правила, если применимо.
            <br />
            <br />
            Там, где это применимо, Участники должны соблюдать требования законов о хранении записей, таких как 18 USC 2257 и применимый к ним свод
            правил, включая, помимо прочего, указание, где находятся или хранятся записи, требуемые в соответствии с этими законами и постановлениями,
            в отношении их {url} Учетные записи участников или предоставление информации, чтобы можно было легко установить такие места записи.
            <br />
          </p>
        </div>
      </div>
    </>
  )
}

export default TermsAndConditions
