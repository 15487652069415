import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/types'
import { IMessagesStore } from './types'
import { changeFullPrivateChats } from 'store/reducer/messages/reducer'

// ==========================================:
const getMessagesState = (state: RootState): IMessagesStore => state.messages

export const sendMessagesSelector = createSelector([getMessagesState], messages => messages.sendMessages)
export const getSelectorMessagesSelectedId = createSelector([getMessagesState], messages => messages.messagesSelectedId)
export const selectorAdminSelectedChatType = createSelector([getMessagesState], messages => messages.adminSelectedChatType)

export const getSelectorMessagesSelectedStaff = createSelector([getMessagesState], messages => messages.messagesSelectedStaff)

export const getSelectorMessagesSelectedUser = createSelector([getMessagesState], messages => messages.messagesSelectedUser)

export const getSelectorSetMessagePrivetChats = createSelector([getMessagesState], messages => messages.messagePrivetChats)
export const getSelectorFetchFromMessage = createSelector([getMessagesState], messages => messages.fetchFrom)

export const selectorChangeFullPrivateChats = createSelector([getMessagesState], messages => messages.changeFullPrivateChats)
export const selectorAllPrivateChatsSuccess = createSelector([getMessagesState], messages => messages.allPrivateChatsSuccess)

export const selectorClientsFetchAndBlock = createSelector([getMessagesState], messages => messages.clientsFetchAndBlock)
export const selectorChangeModelsFetchAndBlock = createSelector([getMessagesState], messages => messages.modelsFetchAndBlock)
