import React from 'react'
import Tippy from '@tippyjs/react'
import { useSelector } from 'react-redux'
import { selectRole } from 'store/reducer/token/selector'
import { selectVideoChatObg } from 'store/reducer/videoChat/selector'
import TooltipGift from 'UI/Tooltip/GiftTooltip/GiftTooltip'
import { SvgBlackGift1 } from '../../../../../../../../images/svg'

const Gift = () => {
  const role = useSelector(selectRole)
  const videoChat = useSelector(selectVideoChatObg)?.room

  const tooltipGiftId = (() => {
    if (videoChat?.ownerUserRole === 'CLIENT') {
      return videoChat.participantId
    }
    if (videoChat?.participantRole === 'CLIENT') {
      return videoChat.ownerUserId
    }
    return null
  })()

  return (
    <>
      {role === 'CLIENT' &&
        tooltipGiftId && ( // Показываем кнопку только если tooltipGiftId определен
          <button className='lk-button lk-chat-toggle'>
            <Tippy
              className='gift'
              interactive
              trigger='click'
              content={<TooltipGift id={tooltipGiftId} />} // Передаем определенный ID
              placement='bottom'
            >
              <div style={{ cursor: 'pointer' }} id='dots-vertical' className='messages-dialog_block__left___menu'>
                <SvgBlackGift1 />
              </div>
            </Tippy>
          </button>
        )}
    </>
  )
}

export default Gift
