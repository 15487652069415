import { useDispatch, useSelector } from 'react-redux'
import { selectTokenData } from 'store/reducer/token/selector'
import useWebSocket from '../socket/useWebSocket'
import { resetStateToken } from 'store/reducer/token/reducer'
import { emptySplitApi } from 'store/apiMain/emptyApi'
import { useTokenRevokeMutation } from 'store/apiMain/mainApi'
import { useEffect } from 'react'
import { toastError, toastInfo } from '../elements/Notification/Notification'
import { useNavigate } from 'react-router-dom'
import { setLogout } from 'store/reducer/logout/reducer'

const useLogout = () => {
  const dispatch = useDispatch()
  const token = useSelector(selectTokenData)

  const navigate = useNavigate()
  const [getTokenRevoke, { isSuccess: isSuccessRevoke, isError: isErrorRevoke }] = useTokenRevokeMutation()

  const { handleLogoutSocket } = useWebSocket()

  const logOut = () => {
    dispatch(emptySplitApi.util.resetApiState()) // чистим кэш
    dispatch(resetStateToken())
    dispatch(setLogout())
    handleLogoutSocket()

    if (token?.access_token) {
      const payload = {
        body: {
          client_id: 'general-client',
          client_secret: 'general',
          token: token.access_token,
        },
      }
      const payloadRefresh = {
        body: {
          client_id: 'general-client',
          client_secret: 'general',
          token: token.refresh_token,
        },
      }
      getTokenRevoke(payload)
      getTokenRevoke(payloadRefresh)
    }
  }
  useEffect(() => {
    if (isSuccessRevoke) {
      toastInfo('Вы успешно вышли из аккаунта!')
      setTimeout(() => {
        navigate('/login')
      }, 100)
    }
    if (isErrorRevoke) {
      toastError('Произошла ошибка при выходе из аккаунта.')
    }
  }, [isSuccessRevoke, isErrorRevoke])
  return logOut
}

export default useLogout
