import { useRef, useEffect } from 'react'

const useOutsideClick = <T extends HTMLElement>(callback: () => void, condition = true) => {
  const ref = useRef<T>(null)

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as T)) {
        callback()
      }

      event.stopPropagation()
    }

    if (condition) {
      document.addEventListener('click', handleClick, true)
    }

    return () => document.removeEventListener('click', handleClick, true)
  }, [ref, condition])

  return ref
}

export default useOutsideClick
