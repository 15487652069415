/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IModelItemPayload, IModelsStore } from './types'

// ==========================================:
const initialState: IModelsStore = {
  profiles: {},
  profilesModel: [],
}

const modelsSlice = createSlice({
  name: 'models',
  initialState,
  reducers: {
    setPhoto: (
      state,
      action: PayloadAction<{
        modelId: string
        fileId: string
        content: string
      }>,
    ) => {
      const { modelId, fileId, content } = action.payload
      const modelProfile = state.profiles[modelId] || { photos: {}, audios: {} }
      modelProfile.photos[fileId] = { id: fileId, content }
      state.profiles[modelId] = modelProfile
    },
    setAudio: (
      state,
      action: PayloadAction<{
        modelId: string
        fileId: string
        content: string
      }>,
    ) => {
      const { modelId, fileId, content } = action.payload
      const modelProfile = state.profiles[modelId] || { photos: {}, audios: {} }
      modelProfile.audios[fileId] = { id: fileId, content }
      state.profiles[modelId] = modelProfile
    },
    setProfilesModel: (state, action: PayloadAction<IModelItemPayload>) => {
      const array = state.profilesModel.filter((item: any) => Object.keys(item)[0] !== Object.keys(action.payload)[0])
      state.profilesModel = [...array, action.payload]
    },
    // Optionally, a reducer to initialize or reset a model's profile
    initModelProfile: (state, action: PayloadAction<{ modelId: string }>) => {
      const { modelId } = action.payload
      if (!state.profiles[modelId]) {
        state.profiles[modelId] = { photos: {}, audios: {} }
      }
    },
  },
})

export const { setPhoto, setAudio, initModelProfile, setProfilesModel } = modelsSlice.actions
export default modelsSlice.reducer
