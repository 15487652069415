import React, { useEffect, useRef, useState } from 'react'
import ImageCropper from './ImageCropper' // Путь к вашему компоненту
import './scss/index.scss'
import { FileCreateApiArg } from 'store/apiMain/mainApi'
import { t } from 'i18next'

interface IProps {
  formik: any
  name: any
  nameText: any
  profilePhoto: any
  setProfilePhoto: any
  addFile: any
  getFile: any
  setProfilePhotoIds: any
  profilePhotoIds: any
  ownerId?: any
}
const CropeMain: React.FC<IProps> = ({
  ownerId,
  formik,
  name,
  nameText,
  profilePhoto,
  setProfilePhoto,
  addFile,
  getFile,
  setProfilePhotoIds,
  profilePhotoIds,
}) => {
  const [selectedImage, setSelectedImage] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const [isImageCropped, setIsImageCropped] = useState(false)
  const fileInputRef = useRef(null)
  const [croppedBlob, setCroppedBlob] = useState(null)

  const handleCrop = (blob: Blob | MediaSource) => {
    const url = URL.createObjectURL(blob)
    // @ts-ignore
    setCroppedImage(url)
    // @ts-ignore
    setCroppedBlob(blob) // Save the blob for later use
    setIsImageCropped(true)
  }

  const handleImageChange = (e: { target: { files: string | any[] } }) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      const url = URL.createObjectURL(file)
      // @ts-ignore
      setSelectedImage(url)
      setIsImageCropped(false) // Новое изображение, обрезка не выполнена
    }
  }

  const handleClick = (e: { target: { id: string } }) => {
    // Реагируем на клики только по тексту и кнопке
    if (e.target.id === 'fileUploadTrigger' || e.target.id === 'changePhotoButton') {
      if (fileInputRef.current) {
        // @ts-ignore
        fileInputRef.current.click()
      }
    }
  }

  const handleDragOver = (e: { preventDefault: () => void }) => {
    e.preventDefault()
  }

  const handleDrop = (e: { preventDefault: () => void; dataTransfer: { files: string | any[]; clearData: () => void } }) => {
    e.preventDefault()
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0]
      const url = URL.createObjectURL(file)
      // @ts-ignore
      setSelectedImage(url)
      setIsImageCropped(false)
      e.dataTransfer.clearData()
    }
  }
  const changeSave = async () => {
    if (croppedBlob) {
      const formData = new FormData()
      if (croppedBlob) {
        formData.append('file', croppedBlob, 'profile.jpg')
      }
      const bodyParams: FileCreateApiArg = {
        ownerId: ownerId,
        // @ts-ignore
        body: formData,
      }
      const result = await addFile({ ...bodyParams }).unwrap()

      if (result && result.id) {
        const fileUrl = await getFile({ fileId: result.id }).unwrap()
        if (fileUrl) {
          setProfilePhotoIds(result.id)
          setProfilePhoto({ preview: fileUrl, id: result.id })
          formik.setFieldValue('profilePhoto', result.id)
        }
      }
    }
  }

  const isError = Boolean(formik.touched[name] && formik.errors[name])
  const timeoutRef = useRef(null)

  useEffect(() => {
    async function fetchData() {
      if (profilePhotoIds) {
        try {
          const fileUrl = await getFile({ fileId: profilePhotoIds }).unwrap()
          setCroppedImage(fileUrl)
        } catch (error) {
          console.error('Ошибка при получении URL файла: ', error)
        }
      }
    }
    fetchData()
  }, [profilePhotoIds])

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    // @ts-ignore
    timeoutRef.current = setTimeout(() => {
      if (croppedImage !== null) {
        changeSave()
      }
      // if(formik.values.profilePhoto) {
      //     changeSave();
      // }
    }, 3000)

    return () => {
      // @ts-ignore
      clearTimeout(timeoutRef.current)
    }
  }, [croppedImage])

  return (
    <div className='displayColum'>
      <div
        className='photo'
        // @ts-ignore
        onClick={handleClick}
        onDragOver={handleDragOver}
        // @ts-ignore
        onDrop={handleDrop}
      >
        <div id='fileUploadTrigger'>
          <input
            type='file'
            accept='image/*'
            // @ts-ignore
            onChange={handleImageChange}
            style={{ display: 'none' }}
            ref={fileInputRef}
          />
          {!selectedImage || !isImageCropped ? (
            // Используем тот же обработчик кликов для текста и кнопки
            <p id='fileUploadTrigger'>Click here or drag a file to upload</p>
          ) : null}
          {selectedImage && isImageCropped && (
            <button type='button' id='changePhotoButton'>
              {t('global.changePhoto')}
            </button>
          )}
        </div>
        {selectedImage && <ImageCropper imageUrl={selectedImage} onCrop={handleCrop} />}
        {croppedImage && (
          <div className='previewPhoto'>
            <p>{t('global.previewPhoto')}</p>
            <div className='previewPhoto-block'>
              <div className='previewPhoto-block_left'>
                <img src={croppedImage} alt='Cropped' />
              </div>
              <div className='previewPhoto-block_right'>
                <h1>{nameText !== null ? nameText : 'Name'}</h1>
                <h6 className='top-message__user-online'>online</h6>
              </div>
            </div>
          </div>
        )}
        <aside>
          <h4>{t('global.photoProfile')}</h4>
        </aside>
      </div>
      {isError && croppedImage === null && (
        <div className='errorPhoto'>
          <div className='errorPhoto-block'>
            <p>{formik.errors.profilePhoto}</p>
          </div>
        </div>
      )}
    </div>
  )
}

export default CropeMain
