import React from 'react'

import Item from './Item/Item'
import './css/index.scss'
import { useClientGetAllQuery } from 'store/apiMain/mainApi'

const Clients = () => {
  const payload = {
    isHaveRelationshipWithCurrentModel: undefined,
    relationshipStatuses: undefined,
    isOnline: undefined,
    showDeleted: undefined,
    showDisabled: true,
    ids: undefined,
    page: 0,
    pageSize: 9999,
  }

  const { data } = useClientGetAllQuery({ ...payload })
  return (
    <div className='clients'>
      <div className='user-data'>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Age Range</th>
              <th>User ID</th>
              <th>Email</th>
              <th>language</th>
              <th>Disabled</th>
              <th>Delete</th>
              <th>Email Verified</th>
            </tr>
          </thead>
          <tbody>
            {data?.content?.map(e => {
              return (
                <React.Fragment key={e?.user?.id}>
                  <Item item={e} />
                </React.Fragment>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Clients
