import React from 'react'
import Tippy from '@tippyjs/react'
import TextTooltipInfo from '../Tooltip/TextTooltipInfo/TextTooltipInfo'
import { SvgQuestion } from '../../images/svg'
import './scss/index.scss'

// Определение интерфейса для пропсов
interface IProps {
  text: string // Ожидаем, что text будет строкой
  onClick?: () => void
}

const ButtonInfo: React.FC<IProps> = ({ text, onClick }) => {
  return (
    <>
      <button type='button' className='buttonInfo' onClick={onClick}>
        <Tippy interactive trigger='mouseenter' content={<TextTooltipInfo text={text} />} placement='bottom'>
          <div>
            <SvgQuestion />
          </div>
        </Tippy>
      </button>
    </>
  )
}

export default ButtonInfo
