import sendMessageSound from '../sound/sendMessage.mp3'
import heartSound from '../sound/heart.mp3'
import giftSound from '../sound/gift.mp3'
import upBalanceSound from '../sound/upBalance.mp3'
import callsSound from '../sound/calls.mp3'
import { useSelector } from 'react-redux'
import { selectorGetClick } from 'store/reducer/global/selectors'
import { useRef } from 'react'
import soundManager from '../sound/SoundManager'

export type EEventType = 'sendMessage' | 'receiveMessage' | 'addFavorites' | 'gift' | 'upBalance' | 'calls'

const useSoundPlayer = (userInteractedRef: boolean) => {
  const hasUserInteractedRef = useRef(false)
  const getClick = useSelector(selectorGetClick)

  if (getClick && !hasUserInteractedRef.current) {
    hasUserInteractedRef.current = true
  }

  const soundsMap: { [key in EEventType]: string } = {
    sendMessage: sendMessageSound,
    receiveMessage: sendMessageSound,
    addFavorites: heartSound,
    gift: giftSound,
    upBalance: upBalanceSound,
    calls: callsSound,
  }

  const playSound = async (eventType: EEventType): Promise<void> => {
    if (!hasUserInteractedRef.current) {
      console.warn('Cannot play sound: user has not interacted with the site yet.')
      return
    }

    const soundPath = soundsMap[eventType]
    if (!soundPath) {
      console.warn(`Sound for event "${eventType}" not found.`)
      return
    }

    try {
      await soundManager.play(soundPath) // SoundManager может быть модифицирован для возврата Promise
    } catch (error) {
      console.error(`Error playing sound for event "${eventType}":`, error)
    }
  }
  const stopAllSounds = () => {
    soundManager.stopAll()
  }

  return { playSound, stopAllSounds }
}

export default useSoundPlayer
