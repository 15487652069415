import React, { useEffect, useLayoutEffect, useState } from 'react'
import './css/index.scss'
import { HiddenItem } from 'Components/Model/HiddenItem/HiddenItem'

const FAQ = () => {
  useEffect(() => {
    const toggleAccordion = (event: MouseEvent) => {
      const item = event.currentTarget as Element
      const itemToggle = item.getAttribute('aria-expanded')

      items.forEach(i => {
        i.setAttribute('aria-expanded', 'false')
      })

      if (itemToggle === 'false') {
        item.setAttribute('aria-expanded', 'true')
      }
    }

    const items = document.querySelectorAll('.accordion button')

    items.forEach(item => {
      // @ts-ignore
      item.addEventListener('click', toggleAccordion)
    })

    // Важно: используйте ту же ссылку на функцию для удаления обработчика
    return () => {
      items.forEach(item => {
        // @ts-ignore
        item.removeEventListener('click', toggleAccordion)
      })
    }
  }, [])

  return (
    <div className='Services'>
      <div className='Services-block'>
        <div className='container'>
          <h2>Услуги</h2>
          <div className='accordion'>
            <HiddenItem title='Вопрос первый?' button_number={1} text='Ответ на первый вопрос' />
            <HiddenItem title='Вопрос второй?' button_number={2} text='Ответ на второй вопрос' />
            <HiddenItem title='Вопрос третий?' button_number={3} text='Ответ на третий вопрос' />
            <HiddenItem title='Вопрос четвертый?' button_number={4} text='Ответ на четвертый вопрос' />
            <HiddenItem title='Вопрос пятый?' button_number={5} text='Ответ на пятый вопрос' />
            <HiddenItem title='Вопрос шестой?' button_number={6} text='Ответ на шестой вопрос' />
            <HiddenItem title='Вопрос седьмой?' button_number={7} text='Ответ на седьмой вопрос' />
            <HiddenItem title='Вопрос восьмой?' button_number={8} text='Ответ на восьмой вопрос' />
            <HiddenItem title='Вопрос девятый?' button_number={9} text='Ответ на девятый вопрос' />
            <HiddenItem title='Вопрос десятый?' button_number={10} text='Ответ на десятый вопрос' />
            <HiddenItem title='Вопрос одиннадцатый?' button_number={11} text='Ответ на одиннадцатый вопрос' />
            <HiddenItem title='Вопрос двенадцатый?' button_number={12} text='Ответ на двенадцатый вопрос' />
            <HiddenItem title='Вопрос тринадцатый?' button_number={13} text='Ответ на тринадцатый вопрос' />
            <HiddenItem title='Вопрос четырнадцатый?' button_number={14} text='Ответ на четырнадцатый вопрос' />
            <HiddenItem
              title='Вопрос пятнадцатый?'
              button_number={15}
              text='Ответ на пятнадцатый вопрос Ответ на пятнадцатый вопросОтвет на пятнадцатый вопросОтвет на пятнадцатый вопросОтвет на пятнадцатый вопросОтвет на пятнадцатый вопросОтвет на пятнадцатый вопросОтвет на пятнадцатый вопросОтвет на пятнадцатый вопросОтвет на пятнадцатый вопросОтвет на пятнадцатый вопросОтвет на пятнадцатый вопросОтвет на пятнадцатый вопросОтвет на пятнадцатый вопросОтвет на пятнадцатый вопросОтвет на пятнадцатый вопросОтвет на пятнадцатый вопросОтвет на пятнадцатый вопросОтвет на пятнадцатый вопросОтвет на пятнадцатый вопросОтвет на пятнадцатый вопросОтвет на пятнадцатый вопрос'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FAQ
