import React, { useState } from 'react'
import { TransactionGetAllApiArg, TransactionResponse, useTransactionGetAllQuery } from 'store/apiMain/mainApi'
import './scss/index.scss'
import dataFormatMessages from '../../../utils/dataFormatMessages'

const TransactionalController: React.FC = () => {
  const [userRole, setUserRole] = useState<TransactionGetAllApiArg['userRole']>('CLIENT')
  const [fromDate, setFromDate] = useState<string | undefined>(undefined)
  const [toDate, setToDate] = useState<string | undefined>(undefined)
  const [page, setPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)

  const { data, error, isLoading } = useTransactionGetAllQuery({
    userRole,
    fromDate,
    toDate,
    page,
    pageSize,
  })

  if (isLoading) return <div>Loading...</div>
  if (error) return <div>Error loading transactions</div>

  return (
    <div className='TransactionalController'>
      <h1>Transactions</h1>
      <div>
        <label>
          User Role:
          <select value={userRole} onChange={e => setUserRole(e.target.value as TransactionGetAllApiArg['userRole'])}>
            <option value='CLIENT'>Client</option>
            <option value='MODEL'>Model</option>
            <option value='STAFF'>Staff</option>
          </select>
        </label>
        <label>
          From Date:
          <input type='date' value={fromDate} onChange={e => setFromDate(e.target.value)} />
        </label>
        <label>
          To Date:
          <input type='date' value={toDate} onChange={e => setToDate(e.target.value)} />
        </label>
        <label>
          Page:
          <input type='number' value={page} onChange={e => setPage(Number(e.target.value))} />
        </label>
        <label>
          Page Size:
          <input type='number' value={pageSize} onChange={e => setPageSize(Number(e.target.value))} />
        </label>
      </div>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>User ID</th>
            <th>Created At</th>
            <th>Created By</th>
            <th>Coins Delta</th>
            <th>Type</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          {data?.content.map((transaction: TransactionResponse) => (
            <tr key={transaction.id}>
              <td>{transaction.id}</td>
              <td>{transaction.userId}</td>
              <td>{dataFormatMessages(transaction.createdAt)}</td>
              <td>{transaction.createdBy}</td>
              <td>{transaction.coinsDelta}</td>
              <td>{transaction.type}</td>
              <td>{transaction.details}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        <p>
          Page {data?.currentPage} of {data?.totalPages}
        </p>
        <button onClick={() => setPage(prev => Math.max(prev - 1, 0))} disabled={page === 0}>
          Previous
        </button>
        <button onClick={() => setPage(prev => Math.min(prev + 1, (data?.totalPages || 1) - 1))} disabled={page === (data?.totalPages || 1) - 1}>
          Next
        </button>
      </div>
    </div>
  )
}

export default TransactionalController
