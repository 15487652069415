import { useDispatch, useSelector } from 'react-redux'
import { useTokenGetMutation } from 'store/apiMain/emptyApi'
import { useEffect } from 'react'
import { resetStateToken, setError, setTokenData } from 'store/reducer/token/reducer'
import { selectTokenData, selectTokenError } from 'store/reducer/token/selector'
import notificationContainerError from 'utils/notificationContainerError/notificationContainerError'
import { useLocation, useNavigate } from 'react-router-dom'
import { toastInfo } from '../elements/Notification/Notification'

const useRefreshToken = () => {
  const [tokenGet, { isLoading, isError, data }] = useTokenGetMutation()
  const dispatch = useDispatch()
  const token = useSelector(selectTokenData)
  const tokenError = useSelector(selectTokenError)
  const location = useLocation()
  const navigate = useNavigate()

  const queryParams = new URLSearchParams(location.search)
  const hasUUID_URL = queryParams.has('UUID_URL')

  useEffect(() => {
    const excludedPaths = [
      '/model/profile/main-info',
      '/profile',
      '/girls',
      '/forgot-password',
      '/registration',
      '/girls&UUID_URL',
      '/admin/login',
      '/model/registration',
      '/info/2257',
      '/info/faq',
      '/info/privacy-policy',
      '/info/terms-and-conditions',
      '/info/contact',
      '/info/model/privacy-policy',
      '/info/model/terms-and-conditions',
    ]

    // Check if the current path needs to be excluded
    const isExcludedPath =
      excludedPaths.includes(location.pathname) || (location.pathname === '/girls' && hasUUID_URL) || location.pathname === '/girls&UUID_URL' // Add this condition

    if (tokenError?.text === 'Ошибка обновления токена') {
      if (!isExcludedPath) {
        if (tokenError?.url?.body?.includes('refresh_token')) {
          toastInfo('Your session has expired, please log in again')
          dispatch(
            setError({
              text: null,
              url: null,
            }),
          )
          navigate('/login')
        } else {
          dispatch(
            setError({
              text: null,
              url: null,
            }),
          )
        }
      }
    }

    if (!isExcludedPath && !token?.access_token) {
      if (tokenError?.text === null) {
        navigate('/login')
      }
    }
  }, [tokenError, location.pathname, location.search, token?.access_token, dispatch, navigate, hasUUID_URL])

  useEffect(() => {
    const refreshToken = async () => {
      if (!token?.refresh_token) return
      try {
        const body = {
          refresh_token: token.refresh_token,
          client_id: 'general-client',
          grant_type: 'refresh_token',
          client_secret: 'general',
        }
        const result = await tokenGet({ body }).unwrap()
        dispatch(setTokenData(result))
      } catch (error) {
        notificationContainerError(error)
        // @ts-ignore
        if (error?.status === 401) {
          dispatch(resetStateToken())
          window.location.href = '/login'
        }
      }
    }

    const interval = setInterval(() => {
      refreshToken()
    }, 50000)

    return () => clearInterval(interval)
  }, [tokenGet, token?.refresh_token, dispatch])

  return { isLoading, isError, data }
}

export default useRefreshToken
