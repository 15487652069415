import { useEffect, useRef } from 'react'

interface UseInfiniteScrollProps {
  isEnd: boolean
  dataNotifications: any
  setPayload: React.Dispatch<React.SetStateAction<{ token: string | undefined; pageSize: number }>>
}

const useInfiniteScroll = ({ isEnd, dataNotifications, setPayload }: UseInfiniteScrollProps) => {
  const notificationsBlockRef = useRef<HTMLDivElement>(null)

  const handleScroll = () => {
    if (!notificationsBlockRef.current || isEnd) return

    const { scrollTop, scrollHeight, clientHeight } = notificationsBlockRef.current

    if (scrollHeight - scrollTop === clientHeight) {
      setPayload(prevPayload => ({
        ...prevPayload,
        token: dataNotifications?.token,
      }))
    }
  }

  useEffect(() => {
    const notificationsBlock = notificationsBlockRef.current

    if (notificationsBlock) {
      notificationsBlock.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (notificationsBlock) {
        notificationsBlock.removeEventListener('scroll', handleScroll)
      }
    }
  }, [isEnd, dataNotifications])

  return notificationsBlockRef
}

export default useInfiniteScroll
