import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Tippy from '@tippyjs/react'
import { SvgBlackGift1, SvgBlackMoreVertical, SvgBlackPhone } from 'images/svg'
import ActionUserTooltip from '../../../../UI/Tooltip/ActionUserTooltip/ActionUserTooltip'
import TooltipGift from '../../../../UI/Tooltip/GiftTooltip/GiftTooltip'
import './scss/index.scss'
import Messages from './Messages/Messages'
import {
  getSelectorMessagesSelectedId,
  getSelectorMessagesSelectedStaff,
  getSelectorMessagesSelectedUser,
  getSelectorSetMessagePrivetChats,
} from '../../../../store/reducer/messages/selectors'
import { selectRole, selectUserMe } from '../../../../store/reducer/token/selector'
import ImgMessages from '../ImgMessages/ImgMessages'
import { getSubscribeUser } from '../../../../store/reducer/socket/selector'
import Status from '../../../../UI/Status/Status'
import NoId from './NoId/NoId'
import imgSupport from '../../../../images/img/imgSupport.png'
import MessagesStaff from './Messages/MessagesStaff'
import Typing from '../Typing/Typing'
import TooltipGiftModel from '../../../../UI/Tooltip/GiftTooltipModel/TooltipGiftModel'
import { useAccountGetByIdQuery, useUserGetFullMeQuery } from 'store/apiMain/mainApi'
import { setAccountClient } from 'store/reducer/accountController/reducer'
import { getAccountClients } from 'store/reducer/accountController/selectors'
import { setMessagesSelectedId } from 'store/reducer/messages/reducer'
import { useNavigate } from 'react-router-dom'
import NeedsToAddFriend from './NeedsToAddFriend/NeedsToAddFriend'
import { t } from 'i18next'
import { selectRooms } from 'store/reducer/rooms/selector'
import useWebSocket from '../../../../socket/useWebSocket'
import { popUpOpen, setPopUpData } from 'store/reducer/popUp/reducer'

interface IProps {
  windowWidth: number
  setRightBlockActive: (active: boolean) => void
}

const RightBlock = ({ windowWidth, setRightBlockActive }: IProps) => {
  const role = useSelector(selectRole)
  const messagesSelectedId = useSelector(getSelectorMessagesSelectedId)
  const messagesSelectedUser = useSelector(getSelectorMessagesSelectedUser)
  console.log(messagesSelectedUser)
  const selectorSetMessagePrivetChats = useSelector(getSelectorSetMessagePrivetChats)
  const subscribeUser = useSelector(getSubscribeUser)
  const onlineStatus = subscribeUser?.body?.find(user => user.userId === messagesSelectedUser?.user?.id)?.activityStatus ?? 'noStatus'
  const selectorStaff = useSelector(getSelectorMessagesSelectedStaff)
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  const navigate = useNavigate()
  const isStaff = messagesSelectedUser?.user?.role === 'STAFF'
  const isSupportStaff = selectorStaff !== null && selectorStaff
  const chatIndex = selectorSetMessagePrivetChats?.content.findIndex(chat => chat.privateChat.id === messagesSelectedId)
  const chatTyping =
    // @ts-ignore
    selectorSetMessagePrivetChats?.content[chatIndex]?.privateChat?.chatTyping

  const dispatch = useDispatch()
  // @ts-ignore
  const privateChat =
    // @ts-ignore
    selectorSetMessagePrivetChats?.content[chatIndex]?.privateChat
  // @ts-ignore
  const clientId =
    // @ts-ignore
    selectorSetMessagePrivetChats?.content[chatIndex]?.privateChat?.clientId
  const payloadAmount = {
    userId: clientId,
  }
  const { data: dataAmountClient, refetch: refetchAmount } = useAccountGetByIdQuery(payloadAmount, { skip: !clientId })

  useEffect(() => {
    if (clientId && dataAmountClient) {
      const obgAccountClient = {
        [clientId]: {
          ...dataAmountClient,
        },
      }
      dispatch(setAccountClient(obgAccountClient))
    }
  }, [dataAmountClient])

  const accountClient = useSelector(getAccountClients)[clientId]
  const rooms = useSelector(selectRooms)
  const roomsObg = rooms.find(item => item.ownerUserId === messagesSelectedUser?.user?.id)
  const [isModalOpen, setModalOpen] = useState(false)
  const { startCall } = useWebSocket()
  const renderUserInfo = () => {
    if (isSupportStaff) {
      return (
        <>
          <div style={windowWidth > 992 ? { display: 'none' } : {}} className='top-message__back'>
            <p
              onClick={() => {
                setRightBlockActive(true)
                dispatch(setMessagesSelectedId(null))
              }}
            >
              &#8592; Back
            </p>
          </div>
          <div className='top-message__user'>
            <div className='top-message__user-ava'>
              <div className='PhotoProfileMessage'>
                <div className='box-img'>
                  <img src={imgSupport} alt='' />
                </div>
              </div>
            </div>
            <div className='top-message__user-info'>
              <h6 className='top-message__user-name'></h6>
              <h6 className='top-message__user-name'>Support</h6>
              <div className={`item__detailes-online-right ${role}`}>
                <Status status={'ONLINE'} />
              </div>
            </div>
          </div>
        </>
      )
    } else if (isStaff) {
      return (
        <>
          <div style={windowWidth > 992 ? { display: 'none' } : {}} className='top-message__back'>
            <p
              onClick={() => {
                setRightBlockActive(true)
                dispatch(setMessagesSelectedId(null))
              }}
            >
              &#8592; Back
            </p>
          </div>
          <div className='top-message__user'>
            <div className='top-message__user-ava'>
              <div className='PhotoProfileMessage'>
                <div className='box-img'>
                  {/*// @ts-ignore*/}
                  <ImgMessages
                    recipientRole={messagesSelectedUser?.user?.role}
                    // @ts-ignore
                    idClient={messagesSelectedUser?.client?.photoId}
                    // @ts-ignore
                    imgUrl={messagesSelectedUser?.profilePhoto?.file?.url}
                    data={messagesSelectedUser}
                  />
                </div>
              </div>
            </div>
            <div className='top-message__user-info'>
              <h6 className='top-message__user-name'></h6>
              <h6 className='top-message__user-name'>
                {/*// @ts-ignore*/}
                {messagesSelectedUser?.user?.name ||
                  // @ts-ignore
                  messagesSelectedUser?.staff?.name}
              </h6>

              <div className={`item__detailes-online-right ${role}`}>
                {/*// @ts-ignore*/}
                <Status status={onlineStatus} />
                <div className='item__detailes-online-right_text'>
                  <Typing typing={chatTyping} privateChatId={privateChat?.id} targetUserId=''>
                    <p className={`statusText ${onlineStatus.toLowerCase()}`}>{onlineStatus.toLowerCase()}</p>
                  </Typing>
                </div>
              </div>
            </div>
          </div>
        </>
      )
    } else if (messagesSelectedId !== null) {
      return (
        <>
          <div style={windowWidth > 992 ? { display: 'none' } : {}} className='top-message__back'>
            <p
              onClick={() => {
                dispatch(setMessagesSelectedId(null))
                setRightBlockActive(true)
              }}
            >
              &#8592; {t('message.back')}
            </p>
          </div>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (role === 'CLIENT') {
                {
                  /*// @ts-ignore*/
                }
                // @ts-ignore
                navigate(`/girl/${messagesSelectedUser?.model?.identifier}`)
              }
            }}
            className='top-message__user'
          >
            <div className='top-message__user-ava'>
              <div className='PhotoProfileMessage'>
                <div className='box-img'>
                  <ImgMessages
                    recipientRole={messagesSelectedUser?.user?.role}
                    // @ts-ignore
                    idClient={messagesSelectedUser?.client?.photoId}
                    // @ts-ignore
                    imgUrl={messagesSelectedUser?.profilePhoto?.file?.url}
                    data={messagesSelectedUser}
                  />
                </div>
              </div>
            </div>
            <div className='top-message__user-info'>
              <h6 className='top-message__user-name'>
                {/*// @ts-ignore*/}
                {messagesSelectedUser?.client?.name}
              </h6>

              <h6 className='top-message__user-name'>
                {/*// @ts-ignore*/}
                {messagesSelectedUser?.model?.name}
              </h6>
              <div className={`item__detailes-online-right ${role}`}>
                {/*// @ts-ignore*/}
                <Status status={onlineStatus} />
                <div className='item__detailes-online-right_text'>
                  <Typing typing={chatTyping} privateChatId={messagesSelectedId} targetUserId=''>
                    <p className={`statusText ${onlineStatus.toLowerCase()}`}>{onlineStatus.toLowerCase()}</p>
                  </Typing>
                </div>
              </div>
            </div>
          </div>
          {role === 'MODEL' ? (
            <div className='top-message__user___amount'>
              <p>Coins: {accountClient?.coins}</p>
              <p>Message: {accountClient?.messages}</p>
            </div>
          ) : null}
          <div className='top-message__icons'>
            {role === 'CLIENT' && roomsObg ? (
              <button
                onClick={() => {
                  setModalOpen(true)
                  dispatch(popUpOpen('enterRoom'))
                  const payloadLocal = {
                    body: {
                      callerUserId: messagesSelectedUser?.user?.id,
                      callerUserRole: messagesSelectedUser?.user?.role,
                      roomId: roomsObg?.id,
                    },
                  }
                  dispatch(setPopUpData({ data: payloadLocal }))
                }}
                type='button'
                className='top-message__button-three'
              >
                Enter Room
              </button>
            ) : null}
            <div
              onClick={() => {
                if (messagesSelectedUser?.user?.id) {
                  startCall(messagesSelectedUser?.user?.id)
                }
              }}
            >
              <SvgBlackPhone />
            </div>
            {role === 'CLIENT' ? (
              <>
                {/*// @ts-ignore*/}
                <Tippy className='gift' interactive trigger='click' content={<TooltipGift id={messagesSelectedUser?.user?.id} />} placement='bottom'>
                  <div style={{ cursor: 'pointer' }} id='dots-vertical' className='messages-dialog_block__left___menu'>
                    <SvgBlackGift1 />
                  </div>
                </Tippy>
              </>
            ) : (
              <>
                {/*// @ts-ignore*/}
                <Tippy
                  interactive
                  trigger='click'
                  placement='bottom'
                  visible={isTooltipOpen}
                  onClickOutside={() => setIsTooltipOpen(false)}
                  render={() =>
                    isTooltipOpen && (
                      <TooltipGiftModel
                        clientId={clientId}
                        // @ts-ignore
                        name={messagesSelectedUser?.client?.name}
                      />
                    )
                  }
                >
                  <div
                    style={{ cursor: 'pointer' }}
                    id='dots-vertical'
                    className='messages-dialog_block__left___menu'
                    onClick={() => setIsTooltipOpen(!isTooltipOpen)}
                  >
                    <SvgBlackGift1 />
                  </div>
                </Tippy>
              </>
            )}

            <Tippy zIndex={1} interactive trigger='click' content={<ActionUserTooltip />} placement='bottom'>
              <div style={{ cursor: 'pointer' }} id='dots-vertical' className='messages-dialog_block__left___menu'>
                <SvgBlackMoreVertical />
              </div>
            </Tippy>
          </div>
        </>
      )
    }
    return null
  }
  const { data: userMe } = useUserGetFullMeQuery()

  return (
    <div className='message-user__content'>
      <div className='message-user__content-top top-message'>{renderUserInfo()}</div>
      <div className='message-user__window'>
        {userMe ? (
          <>
            {privateChat?.clientAttractAttentionCount > 0 && role === 'MODEL' ? (
              <NeedsToAddFriend
                privateChatId={privateChat?.id}
                relationshipWithCurrentUserId={privateChat?.matchingContent?.relationshipWithCurrentUser?.id}
              />
            ) : (
              <>
                {selectorSetMessagePrivetChats !== undefined && messagesSelectedId ? <Messages /> : null}
                {isSupportStaff ? <MessagesStaff /> : messagesSelectedId === null ? <NoId /> : null}
              </>
            )}
          </>
        ) : null}
      </div>
    </div>
  )
}

export default RightBlock
