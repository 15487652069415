import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import useWebSocket from '../../socket/useWebSocket'
import { getSocketIsConnected } from '../../store/reducer/socket/selector'
import { selectVideoChatParticipants } from 'store/reducer/videoChat/selector'
import { useUserGetFullMeQuery } from 'store/apiMain/mainApi'

const ActivityDetector: React.FC = () => {
  const { sendActivityUpdate } = useWebSocket()
  const socketIsConnected = useSelector(getSocketIsConnected)
  const activityTimeout = useRef<NodeJS.Timeout | null>(null)
  const active = useRef<boolean>(false)

  const videoChatParticipants = useSelector(selectVideoChatParticipants)
  const { data: userMe } = useUserGetFullMeQuery()
  const userId = userMe?.userData?.user?.id

  const sendStatusUpdate = () => {
    if (!socketIsConnected) return

    if (videoChatParticipants) {
      if (videoChatParticipants.localParticipant === userId && videoChatParticipants.remoteParticipant === null) {
        // Пользователь ожидает в комнате
        sendActivityUpdate('WAITING_IN_ROOM')
        return
      } else if (videoChatParticipants.localParticipant !== null && videoChatParticipants.remoteParticipant !== null) {
        // Пользователь на звонке
        sendActivityUpdate('ON_CALL')
        return
      }
    }

    // Если пользователь активен и не в видеочате
    if (active.current) {
      sendActivityUpdate('ONLINE')
    }
  }

  const handleActivity = () => {
    active.current = true
    if (activityTimeout.current) {
      clearTimeout(activityTimeout.current)
    }
    activityTimeout.current = setTimeout(() => {
      active.current = false
    }, 1000)
  }

  useEffect(() => {
    const events = ['mousemove', 'keydown', 'scroll']
    events.forEach(event => window.addEventListener(event, handleActivity))

    if (socketIsConnected) {
      handleActivity() // Начальный статус
    }

    const interval = setInterval(() => {
      sendStatusUpdate()
    }, 1000)

    return () => {
      events.forEach(event => window.removeEventListener(event, handleActivity))
      if (activityTimeout.current) {
        clearTimeout(activityTimeout.current)
      }
      clearInterval(interval)
    }
  }, [socketIsConnected, videoChatParticipants, userId])

  return null
}

export default ActivityDetector
