import React, { useEffect, useState } from 'react'
import { ReferralDeleteByIdApiArg, ReferralUpdateByIdApiArg, ReferralViewResponse, useReferralDeleteByIdMutation } from 'store/apiMain/mainApi'
import processText from '../../../../utils/displayText/displayText'
import { useCopyToClipboard } from 'usehooks-ts'
import CopyButton from '../../../../UI/CopyButton/CopyButton'
import { useNavigate } from 'react-router-dom'

type ItemProps = {
  item: ReferralViewResponse
  onDeleteReferral: (arg: ReferralDeleteByIdApiArg) => void
  handleUpdateReferral: (payload: ReferralUpdateByIdApiArg) => void
}

const baseUrl: string = process.env.REACT_APP_HOST ? `//${process.env.REACT_APP_HOST}/` : 'http://127.0.0.1:5000/'

const Item: React.FC<ItemProps> = ({ item, handleUpdateReferral, onDeleteReferral }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editedItem, setEditedItem] = useState(item.referral)
  const navigate = useNavigate()
  const handleEditToggle = () => {
    setIsEditing(!isEditing)
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setEditedItem(prevItem => ({
      ...prevItem,
      [name]: value,
    }))
  }

  const handleSave = () => {
    if (editedItem?.nickname && editedItem?.userSource && editedItem?.description && item.referral?.id) {
      const payload: ReferralUpdateByIdApiArg = {
        referralId: item.referral.id,
        referralUpdateRequest: {
          nickname: editedItem.nickname,
          userSource: editedItem.userSource,
          description: editedItem.description,
        },
      }
      handleUpdateReferral(payload)
    }

    setIsEditing(false)
  }

  const handleCancel = () => {
    setEditedItem(item.referral)
    setIsEditing(false)
  }

  const handleDelete = () => {
    if (item?.referral?.id && onDeleteReferral) {
      onDeleteReferral({ referralId: item.referral.id })
    }
    setIsModalOpen(false)
  }

  const handleDeleteConfirmation = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }
  // const copyToClipboard = (text: string) => {
  //   navigator.clipboard.writeText(text)
  //   alert(`Copied: ${text}`)
  // }
  const [copiedText, copyToClipboard] = useCopyToClipboard()
  const [hasCopied, setHasCopied] = useState(false)

  useEffect(() => {
    if (hasCopied) {
      const timeoutId = setTimeout(() => {
        setHasCopied(false)
      }, 3000)
      return () => clearTimeout(timeoutId)
    }
  }, [hasCopied])
  return (
    <div className='item'>
      <h1>Referral Details</h1>
      {isEditing ? (
        <>
          <p>
            <strong>ID:</strong> {editedItem?.id}
          </p>
          {['nickname', 'userSource', 'description'].map(field => (
            <p key={field}>
              <strong>{field.charAt(0).toUpperCase() + field.slice(1)}:</strong>
              {field === 'description' ? (
                <textarea name={field} value={editedItem?.[field] || ''} onChange={handleChange} />
              ) : (
                <input
                  type='text'
                  name={field}
                  // @ts-ignore
                  value={editedItem?.[field] || ''}
                  onChange={handleChange}
                />
              )}
            </p>
          ))}
          <p>
            <strong className={'Amount'}>Amount of Clients:{item?.clientIds?.length}</strong>{' '}
            {item?.clientIds?.map(e => {
              return <span>{e}</span>
            })}
          </p>
          <p>
            <strong className={'Amount'}>Amount of Models:{item?.modelIds?.length}</strong>{' '}
            {item?.modelIds?.map(e => {
              return <span>{e}</span>
            })}
          </p>
          <button className='save-btn' onClick={handleSave}>
            Save
          </button>
          <button className='cancel-btn' onClick={handleCancel}>
            Cancel
          </button>
        </>
      ) : (
        <>
          <p>
            <strong>ID:</strong> {item?.referral?.id}
          </p>
          <CopyButton textToCopy={`${baseUrl}/girls?UUID_URL=${item?.referral?.id}`} />
          <p>
            <strong>Created At:</strong> {item?.referral?.createdAt}
          </p>
          <p>
            <strong>Created By:</strong> {item?.referral?.createdBy}
          </p>
          <p>
            <strong>Updated At:</strong> {item?.referral?.updatedAt}
          </p>
          <p>
            <strong>Updated By:</strong> {item?.referral?.updatedBy}
          </p>
          <p>
            <strong>Nickname:</strong> {item?.referral?.nickname}
          </p>
          <p>
            <strong>User Source:</strong> {item?.referral?.userSource}
          </p>
          <p>
            <strong>Description:</strong> {processText(item?.referral?.description)}
          </p>
          <p style={{ display: 'flex', flexDirection: 'column' }}>
            <strong className={'Amount'}>Amount of Clients: {item?.clientIds?.length}</strong>
            {item?.clientIds?.map(e => (
              <span>
                {e} <CopyButton textToCopy={e} />
              </span>
            ))}
          </p>
          <p style={{ display: 'flex', flexDirection: 'column' }}>
            <strong className={'Amount'}>Amount of Models: {item?.modelIds?.length}</strong>
            {item?.modelIds?.map(e => (
              <span>
                {e} <CopyButton textToCopy={e} />
              </span>
            ))}
          </p>
          <button className='edit-btn' onClick={handleEditToggle}>
            Edit
          </button>
          <button className='edit-btn' onClick={handleEditToggle}>
            <strong onClick={() => navigate(`/admin/referral-controller/${item?.referral?.id}`)}>Details</strong>
          </button>
          <button className='delete-btn' onClick={handleDeleteConfirmation}>
            Delete
          </button>
        </>
      )}

      {isModalOpen && (
        <div className='modal'>
          <div className='modal-content'>
            <p>Are you sure you want to delete this item?</p>
            <button className='confirm-btn' onClick={handleDelete}>
              Yes
            </button>
            <button className='cancel-btn' onClick={closeModal}>
              No
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Item
