import React, { FC, ReactNode } from 'react'

interface TabsProps {
  children: ReactNode
}

const Tabs: FC<TabsProps> = ({ children }) => (
  <nav>
    <ul>{children}</ul>
  </nav>
)

export default Tabs
