import React from 'react'
import './scss/index.scss'
import Tippy from '@tippyjs/react'
import TextTooltipInfo from '../Tooltip/TextTooltipInfo/TextTooltipInfo'

type StatusType = 'OFFLINE' | 'ONLINE' | 'AWAY' | 'ON_CALL' | 'WAITING_IN_ROOM' | 'noStatus'

interface IProps {
  status: StatusType
}

const Status = ({ status }: IProps) => {
  const displayStatus = status ?? 'noStatus'

  return (
    <div className='status'>
      <div className='status__box'>
        <Tippy interactive trigger='mouseenter' content={<TextTooltipInfo text={status} />} placement='bottom'>
          <div className={`status-indicator ${displayStatus?.toLowerCase()}`}></div>
        </Tippy>
      </div>
    </div>
  )
}

export default Status
