/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'
import { IGlobalStore } from './types'

// ==========================================:
export const initialState: IGlobalStore = {
  menuMobile: false,
  audioPlay: null,
  requestModelError: false,

  firstClick: false,

  modelVerifications: null,
}

// ==========================================:
const globalSlice = createSlice({
  name: '@@global',
  initialState,
  reducers: {
    menuMobileSwitch: state => {
      const stateData = state
      stateData.menuMobile = !state.menuMobile
    },
    audioPlaySlice: (state, { payload }: any) => {
      const stateData = state
      stateData.audioPlay = payload
    },

    setFirstClick: state => {
      const stateData = state
      stateData.firstClick = true
    },
    setRequestModelError: (state, { payload }: { payload: boolean }) => {
      state.requestModelError = payload
    },
    setModelVerifications: (state, { payload }: { payload: boolean | null }) => {
      state.modelVerifications = payload
    },

    popUpInitState: () => initialState,
  },
})
export default globalSlice.reducer
export const { menuMobileSwitch, audioPlaySlice, setRequestModelError, setFirstClick, setModelVerifications } = globalSlice.actions
