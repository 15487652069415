import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ClientViewDto } from 'store/apiMain/mainApi'
import { convertStringRange } from '../../../../utils/ageRange'
import CopyButton from '../../../../UI/CopyButton/CopyButton'

interface ItemProps {
  item: ClientViewDto
}

const Item: React.FC<ItemProps> = ({ item }) => {
  const { client, user } = item || {}
  const navigate = useNavigate()

  return (
    <tr onClick={() => navigate(`/admin/client/${client?.id}`)}>
      <td id='id'>
        {client?.id}
        <CopyButton textToCopy={client?.id || ''} />
      </td>
      <td id='name'>{client?.name}</td>
      <td id='ageRange'>{convertStringRange(client?.ageRange)}</td>
      <td id='userId'>{client?.identifier}</td>
      <td id='email'>{user?.email}</td>
      <td id='language'>{user?.language}</td>
      <td id='disabled' style={{ color: user?.isDisabled ? 'green' : 'inherit' }}>
        {user?.isDisabled ? 'Yes' : 'No'}
      </td>
      <td id='deleted' style={{ color: user?.isDeleted ? 'green' : 'inherit' }}>
        {user?.isDeleted ? 'Yes' : 'No'}
      </td>
      <td id='emailVerified' style={{ color: user?.isEmailVerified ? 'green' : 'inherit' }}>
        {user?.isEmailVerified ? 'Yes' : 'No'}
      </td>
    </tr>
  )
}

export default Item
