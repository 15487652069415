import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Field, Form, Formik } from 'formik'
import {
  MessageCreateRequest,
  PrivateChatCreateApiArg,
  useMessageCreateMutation,
  usePrivateChatCreateMutation,
  useStaffGetAllQuery,
} from 'store/apiMain/mainApi'
import { getSelectorMessagesSelectedId, getSelectorSetMessagePrivetChats } from '../../../../../store/reducer/messages/selectors'
import { toastInfo } from '../../../../../elements/Notification/Notification'
import './scss/index.scss'
import { setMessagesSelectedId, setMessagesSelectedStaffFalse, setMessagesSelectedUser } from 'store/reducer/messages/reducer'
import { t } from 'i18next'
import { selectStaffs } from 'store/reducer/user/selectors'
import { findMatchingContent } from '../../func/findMatchingContent'

const MessagesStaff = () => {
  const [createAdminPrivateChat, { isSuccess }] = usePrivateChatCreateMutation()
  const dispatch = useDispatch()
  const dataPrivateChats = useSelector(getSelectorSetMessagePrivetChats)
  const staffs = useSelector(selectStaffs)

  useEffect(() => {
    if (isSuccess) {
      dispatch(setMessagesSelectedStaffFalse())
    }
  }, [isSuccess])
  const supportChat = dataPrivateChats?.content.find(e => e.privateChat.type === 'SUPPORT')
  // @ts-ignore
  const { matchingStaffContent } = findMatchingContent(supportChat, null, null, staffs)

  useEffect(() => {
    if (supportChat) {
      if (matchingStaffContent) {
        dispatch(setMessagesSelectedUser(matchingStaffContent))
        dispatch(setMessagesSelectedStaffFalse())
        dispatch(setMessagesSelectedId(`${supportChat.privateChat.id}`))
      }
    }
  }, [supportChat])
  return (
    <>
      <div className='window-dialog'>
        <div className='window'>
          <Formik
            initialValues={{ messageText: '' }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              if (!values.messageText.trim()) {
                setSubmitting(false)
                return
              }
              const payload: PrivateChatCreateApiArg = {
                privateChatCreateRequest: {
                  initialMessage: {
                    body: values.messageText,
                  },
                  type: 'SUPPORT',
                },
              }
              await createAdminPrivateChat(payload)
              resetForm()
              setSubmitting(false)
            }}
          >
            {({ isSubmitting }) => (
              <Form className='window-box'>
                <Field type='text' name='messageText' placeholder={t('message.addTextAdmin')} />
                <div className='input__volume'></div>
                <button type='submit' disabled={isSubmitting}>
                  {t('message.submitAdmin')}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}

export default MessagesStaff
