import React from 'react'
import img1 from 'images/img/gifts/1.png'
import img2 from 'images/img/gifts/2.png'
import img3 from 'images/img/gifts/3.png'
import img4 from 'images/img/gifts/4.png'
import img5 from 'images/img/gifts/5.png'
import img6 from 'images/img/gifts/6.png'

const giftIcons = [img1, img2, img3, img4, img5, img6]
const giftIconsName = ['Бриллиант', 'Кольцо', 'Машина', 'Роза', 'Клубника', 'Замок']
const giftStyles: Record<number, string> = {
  '6': 'z-0',
}

interface RenderGiftIconAndNameProps {
  index: number
  name?: boolean
}

const RenderGiftIconAndName: React.FC<RenderGiftIconAndNameProps> = ({ index }) => {
  const adjustedIndex = index - 1 // Уменьшаем на 1 для получения корректного индекса
  const giftIcon = giftIcons[adjustedIndex % giftIcons.length] // Зацикливание массива значков
  const giftName = giftIconsName[adjustedIndex % giftIconsName.length] // Зацикливание массива имен
  return (
    <div>
      <div style={{ zIndex: 0 }} className={`circle ${index % 2 ? 'blue' : 'red'}`}></div>
      <div className={`RenderGiftIconAndName ${giftStyles[index]}`} style={giftStyles[index] === undefined ? { zIndex: 6 } : undefined}>
        <div>
          <img src={giftIcon} alt={giftName} />
        </div>
      </div>
    </div>
  )
}

export default RenderGiftIconAndName
