import React from 'react'
import { NavLink, Route, Routes } from 'react-router-dom'
import StaffCreate from './StaffCreate/StaffCreate'
import StaffView from './StaffView/StaffView'
import './scss/index.scss'
import StaffProfile from './StaffProfile/StaffProfile'
import StaffVerifications from '../StaffVerifications/StaffVerifications'

const Staff = () => {
  return (
    <div className='staff'>
      <nav>
        <ul>
          <li>
            <NavLink to='create-staff' className={({ isActive }) => (isActive ? 'active' : '')}>
              Create Staff
            </NavLink>
          </li>
          <li>
            <NavLink to='staff-list' className={({ isActive }) => (isActive ? 'active' : '')}>
              Staff List
            </NavLink>
          </li>
        </ul>
      </nav>

      <Routes>
        <Route path='create-staff' element={<StaffCreate />} />
        <Route path='staff-list' element={<StaffView />} />
        <Route path='staff-list/:staffId' element={<StaffProfile />} />
        <Route path='verifications-code/:email' element={<StaffVerifications />} />
      </Routes>
    </div>
  )
}

export default Staff
