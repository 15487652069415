import React, { MutableRefObject, useRef, useState, useLayoutEffect, useEffect } from 'react'
import './scss/index.scss'

import { useKeenSlider, KeenSliderPlugin, KeenSliderInstance } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'
import { useGetFileMutation } from 'store/apiMain/emptyApi'
import { setProfilesModel } from 'store/reducer/models/reducer'
import { useDispatch, useSelector } from 'react-redux'
import { selectModelProfile } from 'store/reducer/models/selectors'
import { SvgLeft, SvgRight } from 'images/svg'
import { LoadingItem } from './LoadingItem'
import { ModelFileDto } from 'store/apiMain/mainApi'
import { FileItem } from 'store/reducer/models/types'
import Zoom from 'react-medium-image-zoom'

function ThumbnailPlugin(mainRef: MutableRefObject<KeenSliderInstance | null>): KeenSliderPlugin {
  return slider => {
    function removeActive() {
      slider.slides.forEach(slide => {
        slide.classList.remove('active')
      })
    }
    function addActive(idx: number) {
      slider.slides[idx].classList.add('active')
    }

    function addClickEvents() {
      slider.slides.forEach((slide, idx) => {
        slide.addEventListener('click', () => {
          if (mainRef.current) mainRef.current.moveToIdx(idx)
        })
      })
    }

    slider.on('created', () => {
      if (!mainRef.current) return
      addActive(slider.track.details.rel)
      addClickEvents()
      mainRef.current.on('animationStarted', (main: any) => {
        removeActive()
        const next = main.animator.targetIdx || 0
        addActive(main.track.absToRel(next))
        slider.moveToIdx(Math.min(slider.track.details.maxIdx, next))
      })
    })
  }
}

type File = {
  type: ModelFileDto['type']
  file: { id: any }
}

interface Props {
  photoArray: ModelFileDto[]
  videoArray: ModelFileDto[]
  idModel?: string
}

export default function Slider({ photoArray = [], videoArray = [], idModel = '' }: Props) {
  const modelProfileCash = useSelector(selectModelProfile)
  const dispatch = useDispatch()
  const videoRef = useRef<HTMLVideoElement>(null)
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
  })
  const [thumbnailRef] = useKeenSlider<HTMLDivElement>(
    {
      initial: 0,
      slides: {
        perView: 4,
        spacing: 10,
      },
    },
    [ThumbnailPlugin(instanceRef)],
  )
  const [getFile] = useGetFileMutation()
  const [files, setFiles] = useState<Map<string, string>>(new Map())
  const [isLoadingVideo, setLoadingVideo] = useState(false)
  const fetchFilesRef = useRef(false)

  const updateFiles = (key: string, value: string) => {
    setFiles(files.set(key, value))
  }

  useEffect(() => {
    const filesArray = [...photoArray, ...videoArray] as File[]
    const fetchFiles = async () => {
      // Combine and deduplicate file IDs from photoArray and videoArray
      // Attempt to find the cached model profile by ID
      const object = modelProfileCash.find((item: {}) => Object.keys(item)[0] === idModel)
      // Check if the cached object exists
      if (object) {
        // Extract cached data for photos and videos from the object
        const cachedFilesBatch = object[idModel]
        const files = new Map<string, string>()
        for (const file of filesArray) {
          const value = cachedFilesBatch.find((item: { fileId: any; file: { id: any } }) => item.fileId === file.file.id)?.data
          if (value == undefined) {
            const fileId = file.file.id
            getFile({ fileId })
              .unwrap()
              .then(data => {
                dispatch(
                  setProfilesModel({
                    [idModel]: [...modelProfileCash, { fileId, data }],
                  }),
                )
                files.set(file.file.id, data)
              })
          }
          if (value) files.set(file.file.id, value)
        }

        // Use the cached photos and videos data
        setFiles(files)
      } else {
        const notFoundFiles = new Set<string>(filesArray.map(photo => photo.file.id))
        const keysCache = new Map<string, FileItem>()

        for (const fileId of Array.from(notFoundFiles.values())) {
          try {
            const response = {
              fileId,
              data: await getFile({ fileId }).unwrap(),
            }
            keysCache.set(fileId, response)

            dispatch(
              setProfilesModel({
                [idModel]: Array.from(keysCache.values()),
              }),
            )

            if (notFoundFiles.has(fileId)) {
              updateFiles(fileId, response.data)
              notFoundFiles.delete(fileId)
            }
          } catch (ex) {
            console.error(ex)
          }
        }
      }
    }
    if (!fetchFilesRef.current) {
      fetchFilesRef.current = true // Prevent re-fetching on every render
      fetchFiles().catch(error => console.log('Error for load files:', error))
    }
  }, [photoArray, videoArray])

  const playVideo = () => {
    try {
      if (videoRef.current && videoRef.current.paused) videoRef.current.play()
    } catch (e) {}
  }

  const stopVideo = () => {
    {
      if (videoRef.current && !videoRef.current.paused && isLoadingVideo) {
        try {
          videoRef.current.pause()
          videoRef.current.currentTime = 0
        } catch (e) {}
      }
    }
  }

  const handlerClickVideo = () => {
    if (videoRef.current && isLoadingVideo) {
      try {
        const func = videoRef.current.paused ? playVideo : stopVideo
        func()
      } catch (e) {}
    }
  }

  return (
    <div className={'slider'}>
      <div ref={sliderRef} className='keen-slider'>
        <button className='keen-slider__btn right' onClick={instanceRef.current?.next}>
          <SvgLeft />
        </button>
        <button className='keen-slider__btn left' onClick={instanceRef.current?.prev}>
          <SvgRight />
        </button>
        {photoArray.map((photo, index: number) => (
          <div key={`photo-${index}`} className={`keen-slider__slide number-slide${index + 1}`}>
            {files.has(photo.file?.id ?? '') ? (
              <Zoom>
                <img src={files.get(photo.file?.id ?? '') ?? '../../images/img/backgraund/img.png'} alt='' />
              </Zoom>
            ) : (
              <LoadingItem />
            )}
          </div>
        ))}

        {videoArray.map((video, index: number) => (
          <div key={`video-${index}`} className={`keen-slider__slide number-slide${photoArray.length + index + 1}`}>
            {files.has(video.file?.id ?? '') ? (
              <video
                ref={videoRef}
                src={files.get(video.file?.id ?? '')}
                controlsList='nodownload'
                controls
                onClick={handlerClickVideo}
                className='videos-over'
              ></video>
            ) : (
              <LoadingItem />
            )}
          </div>
        ))}
      </div>

      <div ref={thumbnailRef} className='keen-slider thumbnail'>
        {photoArray.map((photo, index: number) => (
          <div key={`photo-thumb-${index}`} className={`keen-slider__slide number-slide${index + 1}`}>
            {files.has(photo.file?.id ?? '') ? (
              <img src={files.get(photo.file?.id ?? '') ?? '../../images/img/backgraund/img.png'} alt='' />
            ) : (
              <LoadingItem />
            )}
          </div>
        ))}
        {videoArray.map((video, index: number) => (
          <div key={`video-${index}`} className={`keen-slider__slide number-slide${photoArray.length + index + 1} `}>
            {files.has(video.file?.id ?? '') ? (
              <video
                ref={videoRef}
                src={files.get(video.file?.id ?? '')}
                controlsList='nodownload'
                onClick={handlerClickVideo}
                className='videos-over'
                onMouseOver={playVideo}
                onMouseOut={stopVideo}
                onLoadedData={() => setLoadingVideo(true)}
              ></video>
            ) : (
              <LoadingItem />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
