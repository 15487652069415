import React, { useState } from 'react'
import { IPropsItem } from './types'
import ClientInformation from '../EditItem/ClientInformation/ClientInformation'
import UserInformation from '../EditItem/UserInformation/UserInformation'
import Permissions from '../EditItem/Permissions/Permissions'
import AccountController from '../AccountController/AccountController'
import TransactionalController from '../TransactionalController/TransactionalController'
const Item: React.FC<IPropsItem> = ({ item, refetch }) => {
  const [update, setUpdate] = useState(false)
  return (
    <div className='item'>
      <UserInformation item={item} refetch={refetch} />
      <ClientInformation id={item?.client?.id} name={item?.client?.name} ageRange={item?.client?.ageRange} refetch={refetch} />
      <Permissions item={item} refetch={refetch} />
      {item?.client?.id !== undefined ? <AccountController userId={item.client.id} update={update} setUpdate={setUpdate} /> : null}
      {item?.client?.id !== undefined ? <TransactionalController userId={item.client.id} update={update} /> : null}
    </div>
  )
}

export default Item
