import React from 'react'
import './scss/index.scss'
interface IProps {
  text: string
}

const TextTooltipInfo: React.FC<IProps> = ({ text }) => {
  // Функция для преобразования текста
  const formatText = (inputText: string): string => {
    // Заменяем символ подчеркивания на пробел и приводим текст к нижнему регистру
    const lowerCaseText = inputText.toLowerCase().replace(/_/g, ' ')
    // Преобразуем первую букву к верхнему регистру
    const formattedText = lowerCaseText.charAt(0).toUpperCase() + lowerCaseText.slice(1)
    return formattedText
  }

  return (
    <div className='TextTooltipInfo'>
      <span>{formatText(text)}</span>
    </div>
  )
}

export default TextTooltipInfo
