import React, { useState, useMemo, useCallback } from 'react'
import {
  LiveKitRoom,
  formatChatMessageLinks,
  LocalUserChoices,
  PreJoin,
  useLiveKitRoom,
  ControlBar,
  Chat,
  useParticipantInfo,
  useParticipants,
} from '@livekit/components-react'

import {
  DeviceUnsupportedError,
  ExternalE2EEKeyProvider,
  Room,
  RoomConnectOptions,
  RoomOptions,
  VideoCodec,
  VideoPresets,
  setLogLevel,
} from 'livekit-client'
import { useDispatch, useSelector } from 'react-redux'
import { selectVideoChatObg } from 'store/reducer/videoChat/selector'

import { decodePassphrase, useServerUrl } from '../../lib/client-utils'
import { DebugMode } from '../../lib/Debug'
import { SettingsMenu } from '../../lib/SettingsMenu'
import { resetVideoChatState } from 'store/reducer/videoChat/reducer'
import { VideoConference } from '../LiveKitCustom/VideoConference/VideoConference'

type ActiveRoomProps = {
  userChoices: LocalUserChoices
  roomName: string
  region?: string
  onLeave?: () => void
}

const ActiveRoom = ({ roomName, userChoices, onLeave }: ActiveRoomProps) => {
  const videoChatObg = useSelector(selectVideoChatObg)

  const { search } = window.location
  const params = new URLSearchParams(search)
  const region = params.get('region')
  const hq = params.get('hq')
  const codec = params.get('codec')
  const dispatch = useDispatch()
  const e2eePassphrase = ''

  const liveKitUrl = useServerUrl(region as string | undefined)

  // const liveKitUrl = 'http://localhost:7880';
  const worker = typeof window !== 'undefined' && e2eePassphrase && new Worker(new URL('livekit-client/e2ee-worker', import.meta.url))

  const e2eeEnabled = !!(e2eePassphrase && worker)
  const keyProvider = new ExternalE2EEKeyProvider()
  const roomOptions = useMemo((): RoomOptions => {
    let videoCodec: VideoCodec | undefined = (Array.isArray(codec) ? codec[0] : (codec ?? 'vp9')) as VideoCodec
    if (e2eeEnabled && (videoCodec === 'av1' || videoCodec === 'vp9')) {
      videoCodec = undefined
    }
    return {
      videoCaptureDefaults: {
        deviceId: userChoices.videoDeviceId ?? undefined,
        resolution: hq === 'true' ? VideoPresets.h2160 : VideoPresets.h720,
      },
      publishDefaults: {
        dtx: false,
        videoSimulcastLayers: hq === 'true' ? [VideoPresets.h1080, VideoPresets.h720] : [VideoPresets.h540, VideoPresets.h216],
        red: !e2eeEnabled,
        videoCodec,
      },
      audioCaptureDefaults: {
        deviceId: userChoices.audioDeviceId ?? undefined,
      },
      adaptiveStream: { pixelDensity: 'screen' },
      dynacast: true,
      e2ee: e2eeEnabled
        ? {
            keyProvider,
            worker,
          }
        : undefined,
    }
    // @ts-ignore
    setLogLevel('debug', 'lk-e2ee')
  }, [userChoices, hq, codec])

  const room = useMemo(() => new Room(roomOptions), [roomOptions])
  // console.log(room.numParticipants)
  // console.log(room)
  if (e2eeEnabled) {
    keyProvider.setKey(decodePassphrase(e2eePassphrase))
    room.setE2EEEnabled(true).catch(e => {
      if (e instanceof DeviceUnsupportedError) {
        alert(
          `You're trying to join an encrypted meeting, but your browser does not support it. Please update it to the latest version and try again.`,
        )
        console.error(e)
      }
    })
  }
  const connectOptions = useMemo((): RoomConnectOptions => {
    return {
      autoSubscribe: true,
    }
  }, [])
  const handleRoomError = useCallback((error: any) => {
    dispatch(resetVideoChatState())
  }, [])
  return (
    <>
      {liveKitUrl && (
        // @ts-ignore
        <LiveKitRoom
          room={room}
          token={videoChatObg?.token.token ? videoChatObg?.token.token : ''}
          serverUrl={liveKitUrl}
          connectOptions={connectOptions}
          video={userChoices.videoEnabled}
          audio={userChoices.audioEnabled}
          onDisconnected={onLeave}
          onError={handleRoomError}
        >
          <VideoConference
            chatMessageFormatter={formatChatMessageLinks}
            // SettingsComponent={SettingsMenu}
          />
          <DebugMode />
        </LiveKitRoom>
      )}
    </>
  )
}

export default ActiveRoom
