import React from 'react'
import './scss/index.scss'
import documentPassport from 'images/img/example-id.jpg'
import documentFaceData from 'images/img/example-face.jpg'

interface IProps {
  example: number
}

const PhotoVerificationTooltip = ({ example }: IProps) => {
  return (
    <div className='PhotoVerificationTooltip'>
      {example === 1 ? <img src={documentPassport} alt='' /> : null}
      {example === 2 ? <img src={documentFaceData} alt='' /> : null}
    </div>
  )
}

export default PhotoVerificationTooltip
