import React, { FC, useEffect } from 'react'
import Popup from 'reactjs-popup'
import { useDispatch, useSelector } from 'react-redux'
import { IPopUp } from '../types'
import './scss/index.scss'
import Loading from '../../../elements/Loading/Loading'

const LoadingPopUp: FC<IPopUp> = ({ open, closeModal }) => {
  return (
    <Popup lockScroll={true} open={open}>
      <div className='modal'>
        <div className='popup'>
          <Loading />
        </div>
      </div>
    </Popup>
  )
}

export default LoadingPopUp
