import React from 'react'
import './scss/index.scss'

interface IProps {
  ouro?: boolean
  text?: boolean
  fontSize?: string
}

// Пример использования интерфейса в компоненте:
const Loading: React.FC<IProps> = ({ ouro = true, text = true, fontSize = '22px' }) => {
  return (
    <div className='loading'>
      {ouro ? (
        <div>
          <span className='ouro ouro3'>
            <span className='left'>
              <span className='anim'></span>
            </span>
            <span className='right'>
              <span className='anim'></span>
            </span>
          </span>
        </div>
      ) : null}
      {text ? (
        <div className='load-wrapp'>
          <div className='load-6'>
            <div style={{ fontSize }} className='letter-holder'>
              <div className='l-1 letter'>L</div>
              <div className='l-2 letter'>o</div>
              <div className='l-3 letter'>a</div>
              <div className='l-4 letter'>d</div>
              <div className='l-5 letter'>i</div>
              <div className='l-6 letter'>n</div>
              <div className='l-7 letter'>g</div>
              <div className='l-8 letter'>.</div>
              <div className='l-9 letter'>.</div>
              <div className='l-10 letter'>.</div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default Loading
