import React, { FC, useEffect, useState } from 'react'
import Popup from 'reactjs-popup'
import { useDispatch, useSelector } from 'react-redux'
import { IPopUp } from '../types'
import './scss/index.scss'
import { getPopUpData } from 'store/reducer/popUp/selectors'
import useWebSocket from '../../../socket/useWebSocket'
import { useUserGetFullByIdQuery, useUserGetFullMeQuery } from 'store/apiMain/mainApi'
import { selectRole } from 'store/reducer/token/selector'
import { photoViewClient } from '../../Photo/photoViewClient'
import { ICallEvent } from '../../../hooks/socketTs/videoChat'
import imgSupport from '../../../images/img/imgSupport.png'
import { SvgBackground } from '../../../images/svg'
import useSoundPlayer from '../../../hooks/useSoundPlayer'

const Calls: FC<IPopUp> = ({ open, closeModal }) => {
  const dispatch = useDispatch()
  const { handleCall } = useWebSocket()
  const selectedDataPopUp = useSelector(getPopUpData)?.data as ICallEvent | undefined
  const [selectedDataPop, setSelectedDataPop] = useState<ICallEvent | null>(null)

  const { data: userMe } = useUserGetFullMeQuery()

  const { data: targetUserData } = useUserGetFullByIdQuery({
    // @ts-ignore
    userId:
      userMe?.userData?.user?.id === selectedDataPopUp?.body?.callerUserId
        ? selectedDataPopUp?.body.targetUserId
        : selectedDataPopUp?.body.callerUserId,
  })

  useEffect(() => {
    if (selectedDataPopUp) {
      setSelectedDataPop(selectedDataPopUp)
    }
  }, [selectedDataPopUp])
  const { playSound, stopAllSounds } = useSoundPlayer(false)

  const handleAccept = () => {
    stopAllSounds()
    if (selectedDataPop?.body?.callerUserId) {
      handleCall(selectedDataPop.body.callerUserId, 'CALL_ACCEPT')
    }
  }

  const handleDecline = () => {
    if (selectedDataPop?.body?.callerUserId) {
      handleCall(selectedDataPop.body.callerUserId, 'CALL_CANCEL')
    }
  }
  const role = useSelector(selectRole)
  const getPhotoAndName = () => {
    if (!targetUserData) return { photo: null, name: 'Unknown' }
    const callerUserRole = selectedDataPopUp?.body?.callerUserRole

    if (callerUserRole === 'CLIENT' && role === 'MODEL') {
      return {
        photo: photoViewClient(targetUserData?.client?.photoId),
        name: targetUserData?.client?.name || 'Unknown',
      }
    } else if (callerUserRole === 'CLIENT' && role === 'CLIENT') {
      return {
        photo: (
          <>
            <img src={targetUserData?.profilePhoto?.file?.url} alt='' />
          </>
        ),
        name: targetUserData?.model?.name || 'Unknown',
      }
    } else if (callerUserRole === 'MODEL' && role === 'CLIENT') {
      return {
        photo: (
          <>
            <img src={targetUserData?.profilePhoto?.file?.url} alt='' />
          </>
        ),
        name: targetUserData?.model?.name || 'Unknown',
      }
    } else if (callerUserRole === 'MODEL' && role === 'MODEL') {
      return {
        photo: photoViewClient(targetUserData?.client?.photoId),
        name: targetUserData?.client?.name || 'Unknown',
      }
    } else if (callerUserRole === 'STAFF') {
      return {
        photo: (
          <>
            {' '}
            <img src={imgSupport} alt='' />
          </>
        ),
        name: 'Admin',
      }
    } else if (role === 'STAFF') {
      return {
        photo: (
          <>
            {' '}
            <img src={imgSupport} alt='' />
          </>
        ),
        name: 'Admin',
      }
    } else {
      return { photo: null, name: 'Unknown1' }
    }
  }
  const { photo, name } = getPhotoAndName()

  return (
    <Popup closeOnDocumentClick={false} closeOnEscape={false} open={open}>
      <div className='modal'>
        <div className='popup'>
          <div className='popup-calls'>
            <div className='caller-info'>
              <div className='caller-details'>
                <h3>{name}</h3>
              </div>
              <div className='caller-info_background'>
                <SvgBackground />
              </div>
              <div className='box-img'>{photo}</div>
            </div>
            <div className='call-actions'>
              {userMe?.userData?.user?.id === selectedDataPopUp?.body?.callerUserId ? (
                <button className='decline-call' onClick={handleDecline}>
                  Откоонить / Завершить
                </button>
              ) : (
                <>
                  <button className='accept-call' onClick={handleAccept}>
                    Принять
                  </button>
                  <button className='decline-call' onClick={handleDecline}>
                    Откоонить / Завершить
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default Calls
