import React, { useEffect, useState } from 'react'
import './scss/index.scss'
import { GiftRecordsGetAllApiArg, useGiftGetAllQuery, useGiftRecordsGetAllQuery, useUserGetFullMeQuery } from 'store/apiMain/mainApi'
import RenderGiftIconAndName from 'utils/RenderGiftIconAndName/RenderGiftIconAndName'
import { useSelector } from 'react-redux'
import { selectorSocketEventGift } from 'store/reducer/socket/selector'

interface IProps {
  name: string
  clientId: string
}

interface GiftRecordResponse {
  giftId?: number
  // Other properties of GiftRecordResponse
}

const TooltipGiftModel: React.FC<IProps> = ({ name, clientId }) => {
  const { data: giftsData } = useGiftGetAllQuery()
  const { data: dataUserMe } = useUserGetFullMeQuery()
  const selectSocketEventGift = useSelector(selectorSocketEventGift)

  const [page, setPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(999)

  const payloadGifts: GiftRecordsGetAllApiArg = {
    fromClientId: clientId,
    modelId: dataUserMe?.userData?.user?.id,
    page,
    pageSize,
  }

  const {
    data: dataGiftsClient,
    refetch,
    error,
    isLoading,
  } = useGiftRecordsGetAllQuery(payloadGifts, {
    skip: dataUserMe?.userData?.user?.id === undefined,
  })

  useEffect(() => {
    refetch()
  }, [selectSocketEventGift])
  // Function to count gifts by giftId
  const countGifts = () => {
    const giftCounts: { [key: number]: number } = {}
    if (dataGiftsClient && dataGiftsClient.content) {
      dataGiftsClient.content.forEach((gift: GiftRecordResponse) => {
        if (gift.giftId !== undefined) {
          if (!giftCounts[gift.giftId]) {
            giftCounts[gift.giftId] = 0
          }
          giftCounts[gift.giftId]++
        }
      })
    }
    return giftCounts
  }

  const giftCounts = countGifts()

  return (
    <div className='TooltipGiftModel'>
      <div className='tooltips'>
        <div className='tooltips-gift'>
          <h2>{name} подарил вам столько подарков</h2>
          <div className='tooltips-gift_block'>
            {giftsData &&
              giftsData.map((gift, index) => (
                <div className='tooltips-gift_block__box' key={gift.id}>
                  <RenderGiftIconAndName index={index + 1} />
                  {/*// @ts-ignore*/}
                  <p>{giftCounts[gift.id] || 0} количество</p>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TooltipGiftModel
