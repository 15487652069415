import React, { useEffect, useState } from 'react'
import './scss/index.scss'
import SideBar from './SideBar/SideBar'
import Content from './Conetent/Content'
import { IFilterFriends } from './Conetent/types'
import { RelationshipGetCountApiArg, useRelationshipGetCountQuery } from 'store/apiMain/mainApi'
import { useSelector } from 'react-redux'
import { getSocketNotification } from '../../../store/reducer/socket/selector'
import { selectorUpdateFriends } from '../../../store/reducer/friends/selectors'

const Friends = () => {
  const [sideBarFilterValue, setSideBarFilterValue] = useState<IFilterFriends['sidebar']>('FRIENDS')

  const payloadRequest: RelationshipGetCountApiArg = {
    statuses: ['FRIEND_REQUEST'],
  }
  const { data: dataRequest, refetch: refetchTabs } = useRelationshipGetCountQuery(payloadRequest)
  const updateFriends = useSelector(selectorUpdateFriends)

  useEffect(() => {
    refetchTabs()
  }, [updateFriends])
  const socketNotification = useSelector(getSocketNotification)
  useEffect(() => {
    refetchTabs()
  }, [socketNotification])

  return (
    <>
      <main className='friendsFour__main'>
        <SideBar sideBarFilterValue={sideBarFilterValue} setSideBarFilterValue={setSideBarFilterValue} dataRequest={dataRequest} />
        <div className='friendsFour__content'>
          <Content filter={sideBarFilterValue} refetchTabs={refetchTabs} />
        </div>
      </main>
    </>
  )
}

export default Friends
