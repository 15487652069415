import React, { useEffect, useState } from 'react'
import VerifyEmail from '../../Model/Registration/VerifyEmail/VerifyEmail'
import { useParams } from 'react-router-dom'
import { setTokenData } from 'store/reducer/token/reducer'
import { useUserSendCurrentEmailConfirmationCodeMutation } from 'store/apiMain/mainApi'

const StaffVerifications = () => {
  const { email } = useParams()
  const [sendReq, setSendReq] = useState(false)
  const [sendEmail] = useUserSendCurrentEmailConfirmationCodeMutation()
  useEffect(() => {
    if (email) {
      setSendReq(true)
    }
  }, [email])
  useEffect(() => {
    if (email && sendReq) {
      const payload = {
        emailUpdateConfirmationRequest: {
          email: email,
        },
      }
      if (payload?.emailUpdateConfirmationRequest?.email) {
        sendEmail(payload).unwrap()
      }
    }
  }, [sendReq, email])

  return (
    <div>
      StaffVerifications
      {email ? <VerifyEmail email={email} /> : 'no email'}
    </div>
  )
}

export default StaffVerifications
