import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISocketTessDataStore } from './types'

const initialState: ISocketTessDataStore = {
  socketTestData: [],
}

const socketTestDataSlice = createSlice({
  name: 'socketTestData',
  initialState,
  reducers: {
    setSocketTestData: (state, action: PayloadAction<any>) => {
      state.socketTestData = [...state.socketTestData, action.payload]
    },
  },
})

export const { setSocketTestData } = socketTestDataSlice.actions

export default socketTestDataSlice.reducer
