import React, { FC, useEffect, useState } from 'react'
// @ts-ignore
import Popup from 'reactjs-popup'
import { useDispatch, useSelector } from 'react-redux'
import { IPopUp } from '../types'
import './scss/index.scss'
import { ComplaintCreateApiArg, useComplaintCreateMutation } from 'store/apiMain/mainApi'
import { getSelectorMessagesSelectedUser } from '../../../store/reducer/messages/selectors'
import { toastSuccess } from '../../../elements/Notification/Notification'
import { getPopUpData } from '../../../store/reducer/popUp/selectors'

const Complaint: FC<IPopUp> = ({ open, closeModal }) => {
  const dispatch = useDispatch()
  const selectedDataPopUp = useSelector(getPopUpData)
  useEffect(() => {
    setBlocked(selectedDataPopUp?.data ? selectedDataPopUp?.data : false)
  }, [selectedDataPopUp])
  const [messages, setMessages] = useState('')
  const [blocked, setBlocked] = useState(false)
  const [addComplaint, { isSuccess }] = useComplaintCreateMutation()
  const accusedId = useSelector(getSelectorMessagesSelectedUser)?.user?.id
  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Жалоба успешно отправлена!')
      closeModal()
    }
  }, [isSuccess])
  return (
    <>
      <Popup closeOnDocumentClick={false} closeOnEscape={true} open={open}>
        <div className='modal'>
          <div className='popup'>
            <div className='Complaint'>
              <div className='Complaint-block'>
                <div className='Complaint-block_box'>
                  <h1>Complaint</h1>
                  <textarea
                    value={messages}
                    onChange={e => {
                      setMessages(e.target.value)
                    }}
                  />
                  <div className='Complaint-block_box_checked'>
                    <p>Blocked</p>
                    <input
                      type='checkbox'
                      checked={blocked}
                      onChange={() => {
                        setBlocked(!blocked)
                      }}
                    />
                  </div>
                </div>

                <div className='Complaint-block_button'>
                  <button
                    onClick={() => {
                      closeModal()
                    }}
                    type='submit'
                  >
                    Close
                  </button>
                  <button
                    onClick={() => {
                      const payload: ComplaintCreateApiArg = {
                        complaintCreateDto: {
                          message: messages,
                          accused: accusedId ? accusedId : '1',
                          initiatedWithBlock: blocked,
                        },
                      }
                      addComplaint(payload)
                      // closeModal();
                    }}
                    type='submit'
                  >
                    Send a complaint
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popup>
    </>
  )
}

export default Complaint
