export function convertStringRange(argument: string | undefined): string {
  switch (argument) {
    case 'EIGHTEEN__TWENTY_THREE':
      return 'From 18 to 23'
    case 'TWENTY_FOUR__THIRTY_THREE':
      return 'From 24 to 33'
    case 'THIRTY_FOUR__FORTY_FOUR':
      return 'From 34 to 44'
    case 'FORTY_FIVE__MORE':
      return 'over 45'
    default:
      return 'errors'
  }
}
