import React from 'react'
import './scss/index.scss'

const ErrorInput = ({ error, touched }) => {
  if (!touched || !error) {
    return null
  }

  return (
    <div className='ErrorInput'>
      <div className='ErrorInput-block'>
        <p>{error}</p>
      </div>
    </div>
  )
}

export default ErrorInput
