// videoChatSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IVideoChatStore } from 'store/reducer/videoChat/types'
import { RoomWithAccessResponse } from 'store/apiMain/mainApi'
import { IVideoSettings } from 'store/reducer/videoSettings/types'
import { LocalUserChoices } from '@livekit/components-react'

const initialState: IVideoSettings = {
  videoSettings: null,
  videoSettingsCheck: false,
}

const videoSettingsSlice = createSlice({
  name: 'videoSettings',
  initialState,
  reducers: {
    setVideoSettings: (state, action: PayloadAction<LocalUserChoices>) => {
      state.videoSettings = action.payload
    },
    setVideoSettingsCheck: (state, action: PayloadAction<boolean>) => {
      state.videoSettingsCheck = action.payload
    },
    resetVideoSettings: () => initialState,
  },
})

export const { resetVideoSettings, setVideoSettings, setVideoSettingsCheck } = videoSettingsSlice.actions

export default videoSettingsSlice.reducer
