import './scss/LoadingItem.scss'
import React from 'react'

export const LoadingItem = () => {
  return (
    <div className='loader-item'>
      <div className={'loader-wrapper'}>
        <span className='loader'></span>
      </div>

      <div className='loader-item__img'></div>
    </div>
  )
}
