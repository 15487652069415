import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {
  steps1: false,
}

const stepsSlice = createSlice({
  name: 'steps',
  initialState,
  reducers: {
    setSteps1: (state, action: PayloadAction<any>) => {
      state.steps1 = action.payload
    },
  },
})

export const { setSteps1 } = stepsSlice.actions

export default stepsSlice.reducer
