import { SvgProfileMens1, SvgProfileMens2, SvgProfileMens3, SvgProfileMens4, SvgProfileMens5, SvgProfileMens6 } from '../../images/svg'

export const photoViewUser = (photoId: number) => {
  if (photoId === 1) {
    return <SvgProfileMens1 />
  }
  if (photoId === 2) {
    return <SvgProfileMens2 />
  }
  if (photoId === 3) {
    return <SvgProfileMens3 />
  }
  if (photoId === 4) {
    return <SvgProfileMens4 />
  }
  if (photoId === 5) {
    return <SvgProfileMens5 />
  }
  if (photoId === 6) {
    return <SvgProfileMens6 />
  }
  return null
}
