import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { FormControl } from '../../../../layouts-elements/FormControl/FormControl'
import {
  UserPartialUpdateByIdApiArg,
  UserSendCurrentEmailConfirmationCodeApiArg,
  useUserGetFullMeQuery,
  useUserPartialUpdateByIdMutation,
  useUserSendCurrentEmailConfirmationCodeMutation,
} from 'store/apiMain/mainApi'
import { MyFormValues } from './types'
import { useTranslation } from 'react-i18next'

const Password = () => {
  const { t } = useTranslation()

  const { data, refetch } = useUserGetFullMeQuery()
  const id = data?.userData?.user.id
  const [editCheck, setEditCheck] = useState(false)
  const [loading, setLoading] = useState(false)
  const [cooldown, setCooldown] = useState(0)

  const [confirmEmail, { isSuccess: isConfirmEmail }] = useUserSendCurrentEmailConfirmationCodeMutation()
  const [updatePassword, { isSuccess: isUpdatePasswordEmailSuccess, isError: isPasswordResetError, error: passwordResetError }] =
    useUserPartialUpdateByIdMutation()

  const initialValues = {
    password: '',
    confirmPassword: '',
    confirmationCode: '', // Added for confirmation code
  }

  const validationSchema = yup.object().shape({
    password: yup.string().min(4, t('password.minCharactersError')).required(t('password.passwordRequired')),
    confirmPassword: yup.string().oneOf([yup.ref('password'), ''], t('password.passwordsMustMatch')),
    confirmationCode: yup.string().required(t('password.confirmationCodeRequired')), // Added validation for confirmation code
  })

  const onSubmit = async (values: MyFormValues) => {
    setLoading(true)
    setEditCheck(false)
    const payload: UserPartialUpdateByIdApiArg = {
      userId: id,
      userPartialUpdateDto: {
        password: values.password,
        confirmationCode: values.confirmationCode,
      },
    }
    await updatePassword(payload)
    setLoading(false)
  }

  const handleEditClick = async () => {
    setLoading(true)
    const payload: UserSendCurrentEmailConfirmationCodeApiArg = {
      emailUpdateConfirmationRequest: {
        email: data.userData.user?.email,
      },
    }
    await confirmEmail(payload)
    setLoading(false)
    setCooldown(30) // Set cooldown to 30 seconds
  }

  useEffect(() => {
    if (isConfirmEmail) {
      setEditCheck(true)
    }
  }, [isConfirmEmail])

  useEffect(() => {
    if (cooldown > 0) {
      const timer = setInterval(() => {
        setCooldown(cooldown - 1)
      }, 1000)
      return () => clearInterval(timer)
    }
  }, [cooldown])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  })

  return (
    <div className='content__box-item'>
      {editCheck ? (
        <form onSubmit={formik.handleSubmit}>
          <h6>{t('Password')}</h6>
          <label className='password'>
            <span>{t('New password')}</span>
            <FormControl name='password' type='password' formik={formik} placeholder='' />
          </label>
          <label className='confirm-password'>
            <span>{t('Confirm new password')}</span>
            <FormControl name='confirmPassword' type='password' formik={formik} placeholder='' />
          </label>
          <label className='confirmation-code'>
            <span>{t('Confirmation code')}</span>
            <FormControl name='confirmationCode' type='text' formik={formik} placeholder='' />
          </label>
          <div className='item-buttons'>
            <button
              onClick={() => {
                setEditCheck(false)
              }}
              type='button'
            >
              {t('Close')}
            </button>
            <button type='submit' disabled={loading}>
              {loading ? t('Loading...') : t('Save')}
            </button>
            <button onClick={handleEditClick} disabled={cooldown > 0 || loading}>
              {cooldown > 0 ? `${t('Resend code')} (${cooldown})` : t('Resend code')}
            </button>
          </div>
        </form>
      ) : (
        <>
          <h6>{t('Password')}</h6>
          <label>
            <span> {t('Password')}</span>
            <p>********</p> {/* you can use placeholders or just stars to represent the password */}
          </label>
          <div className='item-buttons'>
            <button onClick={handleEditClick} disabled={loading}>
              {loading ? t('Loading...') : t('Change password')}
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default Password
