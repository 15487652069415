import React, { useState, useEffect, useRef } from 'react'
import TimeStartStop from './Button/TimeStartStop'
import Coin from './Button/Coin/Coin'
import Gift from './Button/Gift/Gift'
import Language from '../../../../../../UI/Language/Language'
import { useSelector } from 'react-redux'
import { selectRole } from 'store/reducer/token/selector'
import { ChatIcon } from '@livekit/components-react'
import Messages from '../../../../../User/Messages/RightBlock/Messages/Messages'
import useSoundPlayer from '../../../../../../hooks/useSoundPlayer'
import Logo from '../../../../Logo/Logo'

const CustomButton: React.FC = () => {
  const role = useSelector(selectRole)
  const [chatView, setChatView] = useState(false)
  const { playSound, stopAllSounds } = useSoundPlayer(false)
  useEffect(() => {
    stopAllSounds()
  }, [])
  return (
    <div className='buttons-videoCall'>
      <div className='lk-control-bar'>
        <div className='lk-button-group'>
          <div className='lk-button-group-menu'>
            <button className='lk-button lk-chat-toggle'>
              <Language dark={true} />
            </button>
          </div>
        </div>
        {role === 'CLIENT' ? null : <TimeStartStop />}

        <Coin />
        {role === 'CLIENT' ? <Gift /> : null}
        <button
          className='lk-button'
          onClick={() => {
            setChatView(!chatView)
          }}
        >
          {<ChatIcon />}
        </button>
        <>
          {chatView ? (
            <div className='videoChat-text'>
              <Messages />
            </div>
          ) : null}
        </>
      </div>
    </div>
  )
}

export default CustomButton
