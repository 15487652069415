import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/types'
import { IGlobalStore } from './types'

// ==========================================:
const getGlobalState = (state: RootState): IGlobalStore => state.global
export const getGlobal = createSelector([getGlobalState], (global: IGlobalStore) => global)
export const getAudioPlaySelector = createSelector([getGlobalState], (global: IGlobalStore) => global.audioPlay)
export const selectorGetClick = createSelector([getGlobalState], (global: IGlobalStore) => global.firstClick)
export const selectorModelVerifications = createSelector([getGlobalState], (global: IGlobalStore) => global.modelVerifications)

export const getRequestModelErrorSelector = createSelector([getGlobalState], (global: IGlobalStore) => global.requestModelError)
