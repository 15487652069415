import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IChangeRelationshipPayload, IClientsStore } from './types'
import { ClientGetAllApiResponse, RelationshipDto } from 'store/apiMain/mainApi'

// ==========================================:
export const initialState: IClientsStore = {
  clients: null,
}

// ==========================================:
const clientsSlice = createSlice({
  name: '@@clients',
  initialState,
  reducers: {
    setClients(state, action: PayloadAction<ClientGetAllApiResponse>) {
      state.clients = action.payload
    },
    setClientsToChangeRelationship(state, action: PayloadAction<IChangeRelationshipPayload>) {
      const { operation, relationship } = action.payload

      if (!state.clients || !state.clients.content) return

      switch (operation) {
        case 'RELATIONSHIP_CREATED': {
          const clientIndex = state.clients.content.findIndex(client => client.client?.id === relationship.clientId)
          if (clientIndex !== -1) {
            state.clients.content[clientIndex].relationshipWithCurrentUser = relationship
          }
          break
        }
        case 'RELATIONSHIP_UPDATED': {
          const clientIndex = state.clients.content.findIndex(client => client.relationshipWithCurrentUser?.id === relationship.id)
          if (clientIndex !== -1) {
            state.clients.content[clientIndex].relationshipWithCurrentUser = relationship
          }
          break
        }
        case 'RELATIONSHIP_DELETED': {
          const clientIndex = state.clients.content.findIndex(client => client.client?.id === relationship.clientId)
          if (clientIndex !== -1) {
            state.clients.content[clientIndex].relationshipWithCurrentUser = undefined
          }
          break
        }
        default:
          break
      }
    },
  },
})

export default clientsSlice.reducer
export const { setClients, setClientsToChangeRelationship } = clientsSlice.actions
