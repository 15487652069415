import React from 'react'
import { ComplaintDto } from 'store/apiMain/mainApi'
import './scss/index.scss'
import CopyButton from '../../../../UI/CopyButton/CopyButton'
import dataFormatMessages from '../../../../utils/dataFormatMessages'

interface ItemProps {
  item: ComplaintDto
}

const Item: React.FC<ItemProps> = ({ item }) => {
  return (
    <tr className='item'>
      <td className='item-block'>
        {item.id} <CopyButton textToCopy={item.id} />
      </td>
      <td className='item-block'>
        {item.accuserUserId}
        <CopyButton textToCopy={item.accuserUserId} />
      </td>
      <td className='item-block'>
        {item.accusedUserId}
        <CopyButton textToCopy={item.accusedUserId} />
      </td>
      <td className='item-block'> {dataFormatMessages(item.createdAt)}</td>
      <td className='item-block'>{item.initiatedWithBlock ? 'Yes' : 'No'}</td>
      <td className='item-block'>{item.message}</td>
    </tr>
  )
}

export default Item
