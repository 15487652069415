import { SvgBlackGroup3, SvgBlackHeart1, SvgBlackProfile1, SvgClose, SvgGirlTestMessages, SvgSearch4 } from 'images/svg'
import React, { useEffect, useState } from 'react'
import './scss/index.scss'
import MessagesItem from './MessagesItem/MessagesItem'
import { useUserGetFullMeQuery } from 'store/apiMain/mainApi'
import { useDispatch, useSelector } from 'react-redux'
import { selectRole } from '../../../../store/reducer/token/selector'
import {
  getSelectorMessagesSelectedUser,
  getSelectorSetMessagePrivetChats,
  selectorAdminSelectedChatType,
} from '../../../../store/reducer/messages/selectors'
import { setMessagePrivetChats, setMessagesSelectedId } from '../../../../store/reducer/messages/reducer'
import { getSubscribeUser } from '../../../../store/reducer/socket/selector'
import Loading from '../../../../elements/Loading/Loading'
import { usePrivateChats } from '../../../../hooks/chat/usePrivateChats'

const SidebarLeft = () => {
  const dispatch = useDispatch()

  const role = useSelector(selectRole)
  const { data: userMe } = useUserGetFullMeQuery()
  const selectedUser = useSelector(getSelectorMessagesSelectedUser)
  const selectedChatType = useSelector(selectorAdminSelectedChatType)
  const subscribeUser = useSelector(getSubscribeUser)

  const dataChatSelect = useSelector(getSelectorSetMessagePrivetChats)
  const { isLoading } = usePrivateChats()
  const [filter, setFilter] = useState({
    all: true,
    online: false,
    favorites: false,
  })
  const [search, setSearch] = useState('')
  const [searchActive, setSearchActive] = useState(false)

  const toggleSearch = () => {
    setSearchActive(!searchActive)
  }

  const selectAll = () => {
    setFilter({ all: true, online: false, favorites: false })
  }

  const selectOnline = () => {
    const onlineStatus = subscribeUser?.body?.find(user => user.userId === selectedUser?.user?.id)?.activityStatus ?? 'noStatus'
    const isOnline = onlineStatus === 'ONLINE' || onlineStatus === 'AWAY'
    if (!isOnline) {
      // @ts-ignore
      dispatch(setMessagesSelectedId(null))
    }
    setFilter({ all: false, online: true, favorites: false })
  }

  const selectFavorites = () => {
    setFilter({ all: false, online: false, favorites: true })
  }
  return (
    <>
      <div className='sidebar-left'>
        <ul className='sidebar-left__list'>
          <li className={`sidebar-left__item ${filter.all ? 'active' : ''}`} onClick={selectAll}>
            <SvgBlackGroup3 />
          </li>
          <li className={`sidebar-left__item ${filter.online ? 'active' : ''}`} onClick={selectOnline}>
            <SvgBlackProfile1 />
          </li>
          <li className={`sidebar-left__item ${filter.favorites ? 'active' : ''}`} onClick={selectFavorites}>
            <SvgBlackHeart1 />
          </li>
        </ul>
      </div>
      <div className='sidebar-content'>
        <div className='sidebar-content__top'>
          <p className={`sidebar-content__top-text ${searchActive ? 'shrink' : ''}`}>
            Chats
            {!searchActive ? null : ':'}
          </p>
          {searchActive && (
            <input
              maxLength={10}
              onChange={e => {
                setSearch(e.target.value)
              }}
              type='text'
              value={search}
              placeholder='Search name...'
              className='sidebar-content__search-input'
            />
          )}
          {search && search.length >= 10 ? (
            <div className='error'>
              <p>Больше 10 символов нельзя вводить</p>
            </div>
          ) : null}
          <div
            className='sidebar-content__top-search'
            onClick={() => {
              if (search.length > 0) {
                setSearch('')
                toggleSearch()
              } else {
                toggleSearch()
              }
            }}
          >
            {searchActive ? <SvgClose /> : <SvgSearch4 />}
          </div>
        </div>
        <div className='messages-view'>
          {isLoading ? (
            <Loading />
          ) : dataChatSelect !== null ? (
            <MessagesItem searchName={search} filterOnline={filter.online} filterFavorites={filter.favorites} />
          ) : null}
        </div>
        <div className='sidebar-content__bottom'></div>
      </div>
    </>
  )
}
export default SidebarLeft
