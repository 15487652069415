import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { FormControl } from 'layouts-elements/FormControl/FormControl'
import { IRegistrationInitialValues } from './types'
import './scss/index.scss'
import { ClientCreateApiArg, useClientCreateMutation, useTicketCreateMutation } from 'store/apiMain/mainApi'
import { useDispatch, useSelector } from 'react-redux'
import { setRole, setTokenData } from 'store/reducer/token/reducer'
import imgLoginDecor from '../../../images/img/login-decor.svg'
import imgLogin from '../../../images/img/1.jpg'
import { useNavigate, Link } from 'react-router-dom'

import { Option } from '../Profile/types'
import Logo from '../../Global/Logo/Logo'
import { useTranslation } from 'react-i18next'
import Language from '../../../UI/Language/Language'
import SelectedAge from 'UI/SelectedAge/SelectedAge'
import { getUuidUrl } from '../../../store/reducer/age/selectors'
import Checkbox from '../../../UI/Checkbox/Checkbox'
import { selectLanguageData } from 'store/reducer/language/selector'
import { setSocketToken } from 'store/reducer/socket/reducer'

const Registration = () => {
  const uuidUrl = useSelector(getUuidUrl)
  const { t } = useTranslation()

  const selectLanguage = useSelector(selectLanguageData)
  const [addTicket, { data: dataSocket }] = useTicketCreateMutation()
  useEffect(() => {
    if (dataSocket) {
      dispatch(setSocketToken(dataSocket?.ticket))
      navigate('/models')
    }
  }, [dataSocket])
  // Options with translation
  const options: Option[] = [
    {
      value: 'From 18 to 23',
      label: t('ageRange.18To23'),
      name: 'EIGHTEEN__TWENTY_THREE',
    },
    {
      value: 'From 24 to 33',
      label: t('ageRange.24To33'),
      name: 'TWENTY_FOUR__THIRTY_THREE',
    },
    {
      value: 'From 34 to 44',
      label: t('ageRange.34To44'),
      name: 'THIRTY_FOUR__FORTY_FOUR',
    },
    {
      value: 'over 45',
      label: t('ageRange.over45'),
      name: 'FORTY_FIVE__MORE',
    },
  ]
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [createClient, { data, isLoading, error }] = useClientCreateMutation()

  const initialValues: IRegistrationInitialValues = {
    name: '',
    ageRange: 'EIGHTEEN__TWENTY_THREE',
    email: '',
    password: '',
    confirmPassword: '',
    agreement: false,
  }

  const validationSchema = yup.object({
    name: yup
      .string()
      .required(t('registration.nameRequired'))
      .min(2, t('registration.nameMinLength'))
      .max(50, t('registration.nameMaxLength'))
      .matches(
        /^[A-Za-z\s-]+$/,
        t('registration.nameEnglishOnly'), // сообщение об ошибке для английских символов
      )
      .matches(
        /^[A-Za-z-]+$/,
        t('registration.nameNoNumbers'), // сообщение об ошибке для исключения чисел
      ),
    agreement: yup.boolean().required(t('registration.agreementRequired')).oneOf([true], t('registration.agreementMustBeAccepted')),
    ageRange: yup.string().required(t('registration.ageRequired')),
    email: yup.string().required(t('registration.emailRequired')).email(t('registration.emailValid')),
    password: yup
      .string()
      .required(t('registration.passwordRequired'))
      .min(6, t('registration.passwordMinLength'))
      .matches(/^[A-Za-z\d$!%*?&"@#^=+)(_-]+$/, t('registration.passwordEnglishOnly'))
      .matches(/^(?=.*[A-Za-z])[A-Za-z\d$!%*?&"@#^=+)(_-]{6,}$/, t('registration.passwordCriteria')),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], t('registration.confirmPasswordMustMatch'))
      .required(t('registration.confirmPasswordRequired')),
  })
  const onSubmit = async (values: IRegistrationInitialValues) => {
    const body: ClientCreateApiArg = {
      clientCreateDto: {
        user: {
          email: values.email,
          password: values.password,
          referralId: uuidUrl,
          language: selectLanguage.backendName,
        },
        name: values.name,
        ageRange: values.ageRange,
        photoId: 1,
      },
    }
    try {
      const result = await createClient(body).unwrap()
      dispatch(setTokenData(result))
      dispatch(setRole('CLIENT'))
      addTicket()
    } catch (error) {
      console.error('Failed to create client', error)
    }
  }
  const optionFromAgeRange = options.find((option: { name: string }) => option?.name === 'EIGHTEEN__TWENTY_THREE')
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(optionFromAgeRange ? [optionFromAgeRange] : [])
  const handleSelect = (newSelectedOptions: Option[]) => {
    setSelectedOptions(newSelectedOptions)
  }
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })
  return (
    <div className='login'>
      <header className='login__header'>
        <Logo />
        <Language />
      </header>
      <div className='login__main'>
        <div className='login__main-left'>
          <div className='login__main-left_box'>
            <h1 className='login__title'>{t('Registration')}</h1>
            <form onSubmit={formik.handleSubmit} className='login__form'>
              <label>
                <span>{t('Name')}</span>
                <FormControl name='name' type='text' formik={formik} placeholder='' autoComplete='off' />
              </label>
              <label className='email'>
                <span>{t('Email')}</span>
                <FormControl name='email' type='email' formik={formik} placeholder='' autoComplete='off' />
              </label>
              <div className='user-age'>
                <span>{t('Age')}</span>
                <SelectedAge selectedOptions={selectedOptions} handleSelect={handleSelect} />
              </div>
              <label className='password'>
                <span>{t('Password')}</span>
                <FormControl name='password' type='password' formik={formik} placeholder='' autoComplete='off' />
              </label>
              <label className='confirm-password'>
                <span>{t('Confirm password')}</span>
                <FormControl name='confirmPassword' type='password' formik={formik} placeholder='' autoComplete='off' />
              </label>

              <div className='rules'>
                <div className='rules-block'>
                  <div className='rules-block_checkbox'>
                    <Checkbox name='agreement' formik={formik} />
                  </div>
                  <div className='rules-block_text'>
                    <p>
                      {t('message.18YearsIAgree')} <Link to='/info/terms-and-conditions'>{t('message.termsAndConditions')}</Link> и{' '}
                      <Link to='/info/privacy-policy'>{t('message.privacyPolicy')}</Link>.
                    </p>
                  </div>
                </div>
                <div className='custom-input'>
                  <div className={`error-input${formik.touched['agreement'] && formik.errors['agreement'] ? ' visible' : ''}`}>
                    <p>{formik.touched['agreement'] && formik.errors['agreement'] ? formik.errors['agreement'] : ''}</p>
                  </div>
                </div>
              </div>
              <button type='submit' className='login__form-button'>
                {t('Sign up')}
              </button>
              <div className='login__footer'>
                <span className='login__footer-link'>&nbsp;</span>
                <Link to='/login' className='login__footer-link'>
                  {t('Log in')}
                </Link>
                {/*<Link to="/forgot-password" className="login__footer-link">*/}
                {/*  {t("Forgot password")}*/}
                {/*</Link>*/}
              </div>
            </form>
            <div className='login__decor'>
              <img src={imgLoginDecor} alt={t('login.decorAuthAlt')} />
            </div>
          </div>
        </div>
        <div className='login__main-img'>
          <img src={imgLogin} alt={t('login.logoAlt')} />
        </div>
      </div>
    </div>
  )
}

export default Registration
