import React, { FC } from 'react'
// @ts-ignore
import Popup from 'reactjs-popup'
import { IPopUp } from '../types'
import './scss/index.scss'
import { ObjectInspector } from 'react-inspector'
import { useSelector } from 'react-redux'
import { getSocketTestDataSelector } from 'store/reducer/socketTest/selector'
import { create } from 'istanbul-reports'

const SocketTest: FC<IPopUp> = ({ open, closeModal }) => {
  const socketDataTest = useSelector(getSocketTestDataSelector)
  console.log(socketDataTest)
  return (
    <Popup closeOnDocumentClick={false} closeOnEscape={false} open={open}>
      <div className='modal'>
        <div className='popup'>
          <div className='SocketTest'>
            <div className='SocketTest-block'>
              <div className='SocketTest-block'>Test Socket</div>
              <button onClick={closeModal}>CLose</button>
              <ObjectInspector data={socketDataTest} theme='chromeDark' />
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default SocketTest
