import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Item from './Item/Item'
import './css/index.scss'

const Metrics = () => {
  return (
    <div className='clients'>
      <p>Metrics</p>
      <button>Add Metrics</button>
      <div className='user-data'>
        <table>
          <tr>
            <th>ID</th>
            <th>Nickname</th>
            <th>UUID_URL</th>
            <th>CreatedAt</th>
            <th>Descriptions</th>
          </tr>
        </table>
      </div>
    </div>
  )
}
export default Metrics
