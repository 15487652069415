import {
  SvgProfileMens1Mobile1,
  SvgProfileMens2Mobile1,
  SvgProfileMens3Mobile1,
  SvgProfileMens4Mobile1,
  SvgProfileMens5Mobile1,
  SvgProfileMens6Mobile1,
} from 'images/svg'

export const photoViewUserMobile1 = (photoId: number) => {
  if (photoId === 1) {
    return <SvgProfileMens1Mobile1 />
  }
  if (photoId === 2) {
    return <SvgProfileMens2Mobile1 />
  }
  if (photoId === 3) {
    return <SvgProfileMens3Mobile1 />
  }
  if (photoId === 4) {
    return <SvgProfileMens4Mobile1 />
  }
  if (photoId === 5) {
    return <SvgProfileMens5Mobile1 />
  }
  if (photoId === 6) {
    return <SvgProfileMens6Mobile1 />
  }
  return null
}
