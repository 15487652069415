import { CursorPagePrivateChatViewResponseMe } from 'store/reducer/messages/types'

export function findPrivateChat(
  senderId: string,
  recipientId: string,
  dataChatSelect: CursorPagePrivateChatViewResponseMe,
  callback: (result: any) => void,
) {
  if (senderId === '' || recipientId === '') {
    callback(null)
    return null
  }
  let result = null
  for (let chat of dataChatSelect.content) {
    const matches = [chat.privateChat.modelId, chat.privateChat.clientId, chat.privateChat.staffId].filter(
      id => id === senderId || id === recipientId,
    )

    if (matches.length === 2) {
      // @ts-ignore
      result = chat
      break
    }
  }
  callback(result)
  return result
}
