import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ModelFullDto, RelationshipDeleteByIdApiArg, useTranslateMutation } from 'store/apiMain/mainApi'
import {
  SvGArrowBlackLeft,
  SvgDown,
  SvgGiftAll,
  SvgHeart2,
  SvgIcons8Calendar501,
  SvgIcons8Globe501,
  SvgIcons8Hair501,
  SvgIcons8Hair5011,
  SvgIcons8Height501,
  SvgIcons8Sex501,
  SvgIcons8Weight501,
  SvgPlusAdd,
  SvgQuestion,
  SvgTranslate,
} from 'images/svg'
import './scss/index.scss'
import Slider from '../../../UI/Slider/Slider'
import TooltipGift from '../../../UI/Tooltip/GiftTooltip/GiftTooltip'
import { useDispatch, useSelector } from 'react-redux'
import { sendMessages } from 'store/reducer/messages/reducer'
import { selectUserMe } from 'store/reducer/token/selector'
import { BoxList } from './BoxList'
import { GirlInfo } from './GirlInfo'
import { selectLanguageData } from 'store/reducer/language/selector'
import ButtonInfo from '../../../UI/ButtonInfo/ButtonInfo'
import PopUp from '../../../UI/PopUp/PopUp'

type GirlProps = {
  data: ModelFullDto
  addFriends?: any
  addFriendsRequest?: any
  addFavorites?: any
  addDelete?: any
  role: 'model' | 'client'
}

const Item: React.FC<GirlProps> = ({ data, addFriends, addFriendsRequest, addFavorites, addDelete, role }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const currentYear = new Date().getFullYear()
  // @ts-ignore
  const age = currentYear - data?.model?.birthYear
  const userMe = useSelector(selectUserMe)

  const renderButton = (onClick: () => void, label: string) => (
    <button onClick={onClick} className='btn-add__friends'>
      <p>{label}</p>
      <div className='plus'>
        <SvgPlusAdd />
      </div>
    </button>
  )

  const language = useSelector(selectLanguageData)
  const buttonState = () => {
    const { status, initiatedBy } = data?.relationshipWithCurrentUser || {}
    switch (status) {
      case 'FRIENDS':
        return (
          <>
            {renderButton(() => {
              // @ts-ignore
              if (data?.userData?.user?.id && userMe?.userData?.user?.id) {
                const obg = {
                  recipientId: data?.userData?.user?.id,
                  // @ts-ignore
                  senderId: userMe?.userData?.user?.id,
                }
                dispatch(sendMessages(obg))
                navigate('/messages')
              }
            }, 'Send')}
            {renderButton(addDelete, 'Убрать из друзей')}
          </>
        )
      case 'BLOCKED':
        if (initiatedBy !== 'MODEL') {
          return <>{renderButton(addDelete, 'Разблокировать')}</>
        }
        return null
      case 'FRIEND_REQUEST':
        if (initiatedBy === 'MODEL') {
          return (
            <>
              {renderButton(addFriendsRequest, 'Принять запрос')}
              {renderButton(addDelete, 'Отклонить запрос')}
            </>
          )
        }
        if (initiatedBy === 'CLIENT') {
          return <>{renderButton(addDelete, 'Отменить запрос')}</>
        }
        break
      default:
        return <>{renderButton(addFriends, 'Add to friends')}</>
    }
  }

  const favoriteState = () => {
    if (data?.relationshipWithCurrentUser?.isClientFavorite) {
      return (
        <div
          onClick={() => {
            addFavorites()
          }}
          className='favoriteLike'
        >
          <p>Remove from favorites</p>
          <SvgHeart2 />
        </div>
      )
    } else {
      return (
        <div
          onClick={() => {
            addFavorites()
          }}
          className='favoriteNoLike'
        >
          <p>Add to favorites</p>
          <SvgHeart2 />
        </div>
      )
    }
  }

  return (
    <>
      <div className='ProfileGirl'>
        <div className={'pop-up-wrapper'}>
          <PopUp />
        </div>
        <main className='account__main'>
          <div className='container'>
            {role === 'model' ? null : (
              <div
                onClick={() => {
                  navigate(-1)
                }}
                className='account__nav'
              >
                <SvGArrowBlackLeft />
                <p>Back</p>
              </div>
            )}
            <div className='account__top'>
              <div className='account-slider'>
                <Slider idModel={data?.model?.id} photoArray={data?.photos ?? []} videoArray={data?.videos ?? []} />
                <div className='account-slider__main-gradient'></div>
              </div>
              <div className='account__top-content'>
                <div className='container__info'>
                  <div className='boxProfileGirl'>
                    <div className='box'>
                      {buttonState()}
                      <div className='box-action_svg'>{favoriteState()}</div>
                      <div className='right'>
                        <p className='right-box'>
                          The first 3 minutes — {3 * (data?.model?.minutePrice ?? 1)} coins, then {data?.model?.minutePrice} coins per minute <br />
                        </p>
                        <ButtonInfo
                          text={
                            'Наша система тарификации работает следующим образом: первые 3 минуты пребывания стоят 30 монет — это минимальная продолжительность и стоимость. После этих 3 минут каждая последующая минута будет стоить 10 монет.'
                          }
                        />
                      </div>
                    </div>
                    <TooltipGift id={data.userData?.user?.id} isPopUp={true} status={data?.relationshipWithCurrentUser?.status} />
                  </div>
                  <BoxList data={data} age={age} />
                </div>
                <GirlInfo info={data?.model?.selfInformation ?? ''} language={language.backendName} />
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}

export default Item
