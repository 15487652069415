// toast.ts
import { toast } from 'react-toastify'
import './scss/index.scss'
import { SvgInfo, SvgSuccess, SvgError, SvgCloseNotifications } from './../../images/svg'

export function toastSuccess(message: any): void {
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
    icon: <SvgSuccess />,
    closeButton: <SvgCloseNotifications />,
  })
}

export function toastError(message: any, autoClose?: number): void {
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT,
    icon: <SvgError />,
    closeButton: <SvgCloseNotifications />,
    autoClose,
  })
}

export function toastInfo(message: string): void {
  toast.info(message, {
    position: toast.POSITION.TOP_RIGHT,
    icon: <SvgInfo />,
    closeButton: <SvgCloseNotifications />,
  })
}

export function toastWarn(message: string): void {
  toast.warn(message, {
    position: toast.POSITION.TOP_RIGHT,
  })
}
