import React, { useState, ChangeEvent, useEffect } from 'react'
import { useStaffGetFullByIdQuery, useStaffPartialUpdateByIdMutation } from 'store/apiMain/mainApi'
import { toastSuccess } from '../../../../elements/Notification/Notification'
import { useParams } from 'react-router-dom'

export type Permission =
  | 'CLIENT_VIEW'
  | 'CLIENT_UPDATE'
  | 'CLIENT_UPDATE_NAME'
  | 'MODEL_REQUEST_VIEW'
  | 'MODEL_REQUEST_CREATE'
  | 'MODEL_REQUEST_DELETE'
  | 'MODEL_REQUEST_APPROVE_MAIN_INFO'
  | 'MODEL_REQUEST_APPROVE_SECRET_DATA'
  | 'MODEL_VIEW'
  | 'MODEL_VIEW_SECRET_DATA'
  | 'MODEL_UPDATE_MINUTE_PRICE'
  | 'SERVICE_VIEW_RECOMMENDED_PRICE'
  | 'SERVICE_CREATE'
  | 'SERVICE_UPDATE'
  | 'SERVICE_DELETE'
  | 'MODEL_SERVICE_SET'
  | 'FILE_CREATE_TEMP'
  | 'FILE_SET_OWNER_ID'
  | 'FILE_VIEW_TEMP'
  | 'FILE_VIEW_DELETED'
  | 'NOTIFICATION_VIEW'
  | 'NOTIFICATION_CREATE'
  | 'COMPLAINT_VIEW'
  | 'RELATIONSHIP_VIEW'
  | 'RELATIONSHIP_CREATE'
  | 'RELATIONSHIP_DELETE'
  | 'RELATIONSHIP_UPDATE'
  | 'RELATIONSHIP_IS_CLIENT_FAVORITE_VIEW'
  | 'RELATIONSHIP_IS_MODEL_FAVORITE_VIEW'
  | 'RELATIONSHIP_IS_CLIENT_FAVORITE_SET'
  | 'RELATIONSHIP_IS_MODEL_FAVORITE_SET'
  | 'STAFF_VIEW'
  | 'STAFF_CREATE'
  | 'STAFF_UPDATE'
  | 'USER_VIEW'
  | 'USER_UPDATE'
  | 'USER_UPDATE_EMAIL'
  | 'USER_UPDATE_EMAIL_SELF'
  | 'USER_UPDATE_PASSWORD'
  | 'USER_UPDATE_PASSWORD_SELF'
  | 'USER_DELETE'
  | 'USER_DELETED_VIEW'
  | 'USER_MANAGE_DISABLE_STATE'
  | 'USER_DISABLED_VIEW'
  | 'USER_MANAGE_PERMISSION'
  | 'FIELD_FILE_OWNER_ID_VIEW'
  | 'FIELD_EMAIL_VIEW'
  | 'FIELD_AGE_RANGE_VIEW'
  | 'FIELD_PERMISSION_VIEW'
  | 'FIELD_ROOT_VIEW'
  | 'FIELD_AUDIT_VIEW'
  | 'FIELD_ACTIVE_UPDATE_REQUEST_ID_VIEW'
  | 'WORD_BLACK_LIST_VIEW'
  | 'WORD_BLACK_LIST_CREATE'
  | 'WORD_BLACK_LIST_DELETE'
  | 'GLOBAL_CONFIG_VIEW'
  | 'GLOBAL_CONFIG_SET'
  | 'PERMISSION_VIEW'
  | 'PRIVATE_CHAT_VIEW'
  | 'PRIVATE_CHAT_DISABLE'
  | 'PRIVATE_CHAT_ASSIGN'
  | 'MESSAGE_CREATE_SELF'
  | 'MESSAGE_UPDATE'
  | 'MESSAGE_UPDATE_SELF'
  | 'MESSAGE_VIEW'
  | 'MESSAGE_VIEW_DELETED'
  | 'MESSAGE_CLEAR'
  | 'MESSAGE_CLEAR_SELF'
  | 'REFERRAL_CREATE'
  | 'REFERRAL_UPDATE'
  | 'REFERRAL_DELETE'
  | 'REFERRAL_VIEW'
  | 'ACCOUNT_VIEW'
  | 'ACCOUNT_UPDATE'
  | 'TRANSACTION_VIEW'
  | 'MESSAGE_RULE_SET'
  | 'GIFT_RECORDS_VIEW'

const allPermissions: Permission[] = [
  'CLIENT_VIEW',
  'CLIENT_UPDATE',
  'CLIENT_UPDATE_NAME',
  'MODEL_REQUEST_VIEW',
  'MODEL_REQUEST_CREATE',
  'MODEL_REQUEST_DELETE',
  'MODEL_REQUEST_APPROVE_MAIN_INFO',
  'MODEL_REQUEST_APPROVE_SECRET_DATA',
  'MODEL_VIEW',
  'MODEL_VIEW_SECRET_DATA',
  'MODEL_UPDATE_MINUTE_PRICE',
  'SERVICE_VIEW_RECOMMENDED_PRICE',
  'SERVICE_CREATE',
  'SERVICE_UPDATE',
  'SERVICE_DELETE',
  'MODEL_SERVICE_SET',
  'FILE_CREATE_TEMP',
  'FILE_SET_OWNER_ID',
  'FILE_VIEW_TEMP',
  'FILE_VIEW_DELETED',
  'NOTIFICATION_VIEW',
  'NOTIFICATION_CREATE',
  'COMPLAINT_VIEW',
  'RELATIONSHIP_VIEW',
  'RELATIONSHIP_CREATE',
  'RELATIONSHIP_DELETE',
  'RELATIONSHIP_UPDATE',
  'RELATIONSHIP_IS_CLIENT_FAVORITE_VIEW',
  'RELATIONSHIP_IS_MODEL_FAVORITE_VIEW',
  'RELATIONSHIP_IS_CLIENT_FAVORITE_SET',
  'RELATIONSHIP_IS_MODEL_FAVORITE_SET',
  'STAFF_VIEW',
  'STAFF_CREATE',
  'STAFF_UPDATE',
  'USER_VIEW',
  'USER_UPDATE',
  'USER_UPDATE_EMAIL',
  'USER_UPDATE_EMAIL_SELF',
  'USER_UPDATE_PASSWORD',
  'USER_UPDATE_PASSWORD_SELF',
  'USER_DELETE',
  'USER_DELETED_VIEW',
  'USER_MANAGE_DISABLE_STATE',
  'USER_DISABLED_VIEW',
  'USER_MANAGE_PERMISSION',
  'FIELD_FILE_OWNER_ID_VIEW',
  'FIELD_EMAIL_VIEW',
  'FIELD_AGE_RANGE_VIEW',
  'FIELD_PERMISSION_VIEW',
  'FIELD_ROOT_VIEW',
  'FIELD_AUDIT_VIEW',
  'FIELD_ACTIVE_UPDATE_REQUEST_ID_VIEW',
  'WORD_BLACK_LIST_VIEW',
  'WORD_BLACK_LIST_CREATE',
  'WORD_BLACK_LIST_DELETE',
  'GLOBAL_CONFIG_VIEW',
  'GLOBAL_CONFIG_SET',
  'PERMISSION_VIEW',
  'PRIVATE_CHAT_VIEW',
  'PRIVATE_CHAT_DISABLE',
  'PRIVATE_CHAT_ASSIGN',
  'MESSAGE_CREATE_SELF',
  'MESSAGE_UPDATE',
  'MESSAGE_UPDATE_SELF',
  'MESSAGE_VIEW',
  'MESSAGE_VIEW_DELETED',
  'MESSAGE_CLEAR',
  'MESSAGE_CLEAR_SELF',
  'REFERRAL_CREATE',
  'REFERRAL_UPDATE',
  'REFERRAL_DELETE',
  'REFERRAL_VIEW',
  'ACCOUNT_VIEW',
  'ACCOUNT_UPDATE',
  'TRANSACTION_VIEW',
  'MESSAGE_RULE_SET',
  'GIFT_RECORDS_VIEW',
]

const StaffProfile: React.FC = () => {
  const { staffId } = useParams()
  const { data } = useStaffGetFullByIdQuery({ staffId: staffId || '' }, { skip: !staffId })
  const [updateStaff, { isSuccess }] = useStaffPartialUpdateByIdMutation()

  const [formData, setFormData] = useState<any>({
    user: {
      email: '',
      password: '',
    },
    permissions: [],
    isRoot: false,
  })

  useEffect(() => {
    if (data) {
      const newFormData = {
        user: {
          email: data?.userData?.user?.email || '',
          password: '',
        },
        permissions: data?.userData?.permissions || [],
      }
      setFormData(newFormData)
    }
  }, [data])

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      user: {
        ...prevFormData.user,
        [name]: type === 'checkbox' ? checked : value,
      },
    }))
  }

  const handlePermissionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target
    setFormData((prevFormData: any) => {
      const updatedPermissions = checked
        ? [...prevFormData.permissions, value as Permission]
        : prevFormData.permissions.filter((permission: Permission) => permission !== value)
      return { ...prevFormData, permissions: updatedPermissions }
    })
  }

  const handleRootChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target
    setFormData((prevFormData: any) => ({ ...prevFormData, isRoot: checked }))
  }

  const handleSelectAllPermissions = () => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      permissions: [...allPermissions],
    }))
  }

  const handleDeselectAllPermissions = () => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      permissions: [],
    }))
  }

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Staff updated successfully')
    }
  }, [isSuccess])

  const handleSubmit = () => {
    if (staffId) {
      updateStaff({
        staffId,
        staffPartialUpdateDto: {
          ...formData,
        },
      })
    }
  }

  return (
    <div className='staff'>
      <h2>View Staff / Update Staff</h2>
      <div>
        <div>
          <label>Email:</label>
          <input type='email' name='email' value={formData.user.email} onChange={handleInputChange} required />
        </div>
        <div>
          <label>Password:</label>
          <input type='password' name='password' value={formData.user.password} onChange={handleInputChange} />
        </div>
        <div>
          <label>Permissions:</label>
          <div>
            <button type='button' onClick={handleSelectAllPermissions}>
              Select All
            </button>
            <button type='button' onClick={handleDeselectAllPermissions}>
              Deselect All
            </button>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }} className='permissions'>
            {allPermissions.map((permission, index) => (
              <label key={permission}>
                {index + 1}.
                <input type='checkbox' value={permission} checked={formData.permissions.includes(permission)} onChange={handlePermissionChange} />
                {permission}
              </label>
            ))}
          </div>
        </div>
        <div>
          <label>
            Is Root:
            <input type='checkbox' name='isRoot' checked={formData.isRoot} onChange={handleRootChange} />
          </label>
        </div>
        <button onClick={handleSubmit} type='submit'>
          Update Staff
        </button>
      </div>
    </div>
  )
}

export default StaffProfile
