import React from 'react'
import Select from 'react-dropdown-select'
import { FormikHandlers, FormikValues } from 'formik'
import './scss/index.scss'
import { optionsLanguage } from '../SelectCustom/constValue'

interface SelectedCustomProps {
  name: string // имя поля для Formik
  placeholder: string // имя поля для Formik
  formik: {
    setFieldValue: (field: string, value: any) => void
    values: FormikValues
    handleChange: FormikHandlers['handleChange']
    handleBlur: FormikHandlers['handleBlur']
    touched: FormikValues
    errors: FormikValues
  }
}

const SelectedLanguage: React.FC<SelectedCustomProps> = ({ name, formik, placeholder }) => {
  const handleSelected = (selectedOptions: any[]) => {
    const values = selectedOptions.map(option => option.value)
    formik.setFieldValue(name, values)
  }

  const selectedValues =
    formik.values[name]?.map((e: any) => {
      const matchedOption = optionsLanguage.find(option => option?.backendName === e)
      return {
        label: matchedOption ? matchedOption.name : e,
        value: matchedOption ? matchedOption.backendName : e,
      }
    }) || [] // Ensure it's initialized

  const isError = Boolean(formik.touched[name] && formik.errors[name])

  return (
    <div className='language-selected'>
      <Select
        options={optionsLanguage} // Check optionsLanguage is correct
        values={selectedValues} // Check selectedValues is correct
        onChange={handleSelected}
        placeholder={placeholder}
        multi={true}
        searchable={false}
      />
      {isError && <div className='error-message'>{formik.errors[name]}</div>}
    </div>
  )
}

export default SelectedLanguage
