import React from 'react'

import './scss/index.scss'
import { useSearchQuery } from 'store/apiMain/mainApi'
import Item from './Item/Item'

const ParentComponent: React.FC = () => {
  const { data: dataRooms } = useSearchQuery()

  if (!dataRooms) {
    return <div>Loading...</div> // Handle loading state
  }

  return (
    <div className='adminRooms'>
      <h1>Data Rooms</h1>
      <Item dataRooms={dataRooms} />
    </div>
  )
}

export default ParentComponent
