import { useEffect } from 'react'

function useLockBodyScroll(open: boolean): void {
  useEffect(() => {
    if (open) {
      // Вычисляем ширину полосы прокрутки
      const scrollBarWidth: number = window.innerWidth - document.documentElement.clientWidth
      // Запрещаем прокрутку фона
      document.documentElement.style.overflow = 'hidden'
      // Добавляем отступ справа
      document.documentElement.style.paddingRight = `${scrollBarWidth}px`
    } else {
      // Разрешаем прокрутку фона
      document.documentElement.style.overflow = ''
      document.documentElement.style.paddingRight = ''
    }

    // Очистка эффекта при размонтировании компонента
    return () => {
      document.documentElement.style.overflow = ''
      document.documentElement.style.paddingRight = ''
    }
  }, [open])
}

export default useLockBodyScroll
