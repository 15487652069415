// videoChatWithdrawalMoneySlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IVideoChatWithdrawalMoneyStore } from './types'

const initialState: IVideoChatWithdrawalMoneyStore = {
  time: 0,
  isRunning: false,
}

const videoChatWithdrawalMoneySlice = createSlice({
  name: 'videoChatWithdrawalMoney',
  initialState,
  reducers: {
    startTimer(state) {
      state.isRunning = true
    },
    pauseTimer(state) {
      state.isRunning = false
    },
    resetTimer(state) {
      state.time = 0
      state.isRunning = false
    },
    tick(state) {
      state.time += 1
    },
  },
})

export const { startTimer, pauseTimer, resetTimer, tick } = videoChatWithdrawalMoneySlice.actions

export default videoChatWithdrawalMoneySlice.reducer
