import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useModelGetIdsMutation } from 'store/apiMain/mainApi'
import { selectRole } from 'store/reducer/token/selector'
import { getSelectorSetMessagePrivetChats, selectorAdminSelectedChatType, selectorChangeModelsFetchAndBlock } from 'store/reducer/messages/selectors'

import { setModels } from 'store/reducer/user/reducer'
import { selectModels } from 'store/reducer/user/selectors'
import { changeModelsFetchAndBlock } from 'store/reducer/messages/reducer'

const useFetchModelData = () => {
  const dispatch = useDispatch()
  const role = useSelector(selectRole)
  const dataPrivateChats = useSelector(getSelectorSetMessagePrivetChats)
  const [modelGetIds] = useModelGetIdsMutation()
  const selectedChatType = useSelector(selectorAdminSelectedChatType)
  const dataModel = useSelector(selectModels)
  const dataModelIds = dataModel?.map(item => item.user.id)
  const dataPrivateChatModelIds = dataPrivateChats?.content.map(item => item.privateChat.modelId)

  const modelsFetchAndBlock = useSelector(selectorChangeModelsFetchAndBlock)
  useEffect(() => {
    let dialogChatsId = []
    if (role === 'STAFF') {
      if (modelsFetchAndBlock) {
        return
      }

      // Устанавливаем флаг, чтобы предотвратить повторные запросы
      dispatch(changeModelsFetchAndBlock())
    }
    if (dataPrivateChats) {
      const content = dataPrivateChats.content
      if (selectedChatType.active === 'view_client' || (role !== 'STAFF' && selectedChatType.active !== 'view_model')) {
        // @ts-ignore
        dialogChatsId = content.map(e => {
          if (role === 'MODEL') return e.privateChat.clientId || e.privateChat.staffId
          if (role === 'STAFF') return e.privateChat.clientId || e.privateChat.modelId
          if (role === 'CLIENT') return e.privateChat.modelId
          return e.privateChat.modelId || e.privateChat.staffId
        })
      } else if (selectedChatType.active === 'view_model' || role === 'STAFF') {
        // @ts-ignore
        dialogChatsId = content.flatMap(e => {
          if (role === 'STAFF') return [e.privateChat.clientId, e.privateChat.modelId].filter(Boolean)
          if (role === 'MODEL') return e.privateChat.clientId || e.privateChat.staffId
          if (role === 'CLIENT') return e.privateChat.modelId
          return e.privateChat.modelId || e.privateChat.staffId
        })
      }
    }

    // Удаляем дубликаты из массива ids
    const uniqueIds = Array.from(new Set(dialogChatsId.filter(Boolean)))

    // Фильтруем только те ID, которые есть в dataPrivateChatModelIds, но отсутствуют в dataModelIds
    const idsToFetch = uniqueIds.filter(id => !dataModelIds?.includes(id))

    if (idsToFetch.length > 0 && role !== 'MODEL') {
      const fetchClientData = async () => {
        try {
          const response = await modelGetIds({
            page: undefined,
            pageSize: 99999,
            modelFilterDto: {
              ids: idsToFetch,
            },
          }).unwrap()
          dispatch(setModels(response.content))
        } catch (err) {
          console.error('Ошибка при получении данных models:', err)
        }
      }

      fetchClientData()
    }
  }, [dataPrivateChats, dataModelIds, selectedChatType, role]) // Обновленный массив зависимостей
}

export default useFetchModelData
