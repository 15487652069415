import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ILanguage, ILanguageStore } from './types'

const initialState: ILanguageStore = {
  languageData: {
    code: '',
    name: '',
    backendName: 'DANISH',
  },
}

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguageData: (state, action: PayloadAction<ILanguage>) => {
      state.languageData = action.payload
    },
  },
})

export const { setLanguageData } = languageSlice.actions

export default languageSlice.reducer
