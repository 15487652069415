import moment from 'moment'
import 'moment/locale/ru' // Подключение русской локализации

interface FormattedTimeProps {
  time: string | undefined
}

/**
 * Форматирует время сообщения в зависимости от его возраста.
 *
 * @param time - Время сообщения в формате строки.
 * @param useOldFormat - Опциональный параметр. Если true, для сообщений старше недели используется старый формат `DD.MM.YYYY в HH:mm`.
 * @returns Отформатированная строка времени.
 */
const dataFormatMessages = (time: string | undefined, useOldFormat = false): string => {
  if (!time) return ''

  const now = moment()
  const messageTime = moment(time)
  const timeDifference = now.diff(messageTime)

  if (timeDifference < moment.duration(1, 'days').asMilliseconds()) {
    // Менее 1 дня назад: отображается только время
    return messageTime.format('HH:mm')
  } else if (timeDifference < moment.duration(2, 'days').asMilliseconds()) {
    // От 1 до 2 дней назад: отображается "вчера в HH:mm"
    return `вчера в ${messageTime.format('HH:mm')}`
  } else if (timeDifference < moment.duration(1, 'weeks').asMilliseconds()) {
    // От 2 дней до недели назад: отображается количество дней назад с правильным склонением
    const daysAgo = Math.floor(timeDifference / moment.duration(1, 'days').asMilliseconds())
    const dayText = getDayText(daysAgo)
    return `${daysAgo} ${dayText} назад`
  } else {
    // Более недели назад: выбор формата в зависимости от параметра useOldFormat
    return useOldFormat ? messageTime.format('DD.MM.YYYY в HH:mm') : messageTime.format('DD.MM.YYYY')
  }
}

/**
 * Возвращает правильное склонение слова "день" в зависимости от числа.
 *
 * @param days - Количество дней назад.
 * @returns Строка со склонением слова "день".
 */
const getDayText = (days: number): string => {
  if (days % 10 === 1 && days % 100 !== 11) {
    return 'день'
  } else if ([2, 3, 4].includes(days % 10) && ![12, 13, 14].includes(days % 100)) {
    return 'дня'
  } else {
    return 'дней'
  }
}

export default dataFormatMessages
