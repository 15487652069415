import React, { memo } from 'react'
import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectRole } from 'store/reducer/token/selector'

const ProtectedRouteStaff = ({ children }) => {
  const role = useSelector(selectRole)
  const isStaff = role === 'STAFF'

  if (isStaff) {
    return children
  } else {
    return <Navigate to='/login' replace />
  }
}

export default memo(ProtectedRouteStaff)
