import React from 'react'
import { IProps } from './types'
import './scss/index.scss'

const MainHeader: React.FC<IProps> = ({ children }) => {
  return (
    <div className='MainHeader'>
      <div className='MainHeader-container'>{children}</div>
    </div>
  )
}

export default MainHeader
