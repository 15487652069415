import React from 'react'
import './css/index.scss'

const Statement2257 = () => {
  let name = 'VirtualHouse'
  let email = 'VirtualHouse@gmail.com'
  let url1 = 'https://www.VirtualHouse.com/policies'
  let url = 'https://www.VirtualHouse.com'
  return (
    <>
      <div className='info'>
        <div className='info-block'>
          <h2>Заявление 18 USC 2257</h2>
          <p>
            {' '}
            {url} требует, чтобы все участники и пользователи, которые получают доступ или используют любую часть сайта {url}, были не моложе
            восемнадцати (18) лет.
          </p>
          <p>
            <br /> {url} действует как технологическая служба, позволяющая Участникам обмениваться информацией, развлекательными услугами или другими
            продуктами или услугами между собой. {url} никоим образом не оценивает, не предоставляет, не производит и не контролирует Услуги для
            Участников, а также информацию или обмены между Участниками. {url} сам по себе не предоставляет какой-либо контент, информацию,
            развлекательные услуги или другие продукты или услуги, а также не проверяет, не гарантирует и не делает никаких заявлений относительно
            личности или квалификации любого Участника. {url} не принимает редакционных или управленческих решений в отношении содержания списка
            Участников, откровенно сексуального или иного.
            <br />
            <br /> Используя службу {url}, Участники в соответствии с Соглашением об участии обязаны соблюдать все применимые федеральные,
            государственные и местные законы, постановления и постановления, касающиеся непристойного и непристойного содержания, сообщений и
            обязательств по ведению документации.
            <br />
            <br /> Участники несут единоличную ответственность за предоставленный контент и контент в списках, созданных под их учетными записями
            профиля {url}, и несут единоличную ответственность за обеспечение того, чтобы они соответствовали всем применимым законам и
            постановлениям, включая, помимо прочего, предоставление определенной информации о местоположении записи в соответствии с 18 USC 2257 и
            соответствующие правила, если применимо.
            <br />
            <br /> Там, где это применимо, Участники должны соблюдать требования законов о ведении документации, таких как 18 USC 2257 и применимый к
            ним свод правил, включая, помимо прочего, указание, где находятся или хранятся записи, требуемые этими законами и постановлениями, в
            отношении их {url} Учетные записи Участников или предоставление информации, чтобы можно было легко установить местонахождение таких
            записей.
            <br />
            <br /> Для получения информации о хранителе документации обращайтесь: {email}
          </p>
        </div>
      </div>
    </>
  )
}

export default Statement2257
