import React, { useEffect, useLayoutEffect, useState } from 'react'
import './scss/index.scss'
import { HiddenItem } from '../../HiddenItem/HiddenItem'
import { t } from 'i18next'

const Recommendations = () => {
  useEffect(() => {
    const toggleAccordion = (event: { currentTarget: any }) => {
      const item = event.currentTarget
      const content = item.nextElementSibling // Предполагая, что .accordion-content следует непосредственно за кнопкой
      const itemToggle = item.getAttribute('aria-expanded') === 'true'

      // Закрыть все элементы
      items.forEach(i => {
        i.setAttribute('aria-expanded', 'false')
        // @ts-ignore
        i.nextElementSibling.style.maxHeight = null // Сброс высоты контента
      })

      // Если элемент был закрыт, раскрыть его
      if (!itemToggle) {
        item.setAttribute('aria-expanded', 'true')
        // Устанавливаем max-height равным высоте содержимого плюс небольшой дополнительный отступ
        content.style.maxHeight = content.scrollHeight + 'px'
      }
    }

    const items = document.querySelectorAll('.accordion button')

    items.forEach(item => {
      item.addEventListener('click', toggleAccordion)
    })

    // Важно: используйте ту же ссылку на функцию для удаления обработчика
    return () => {
      items.forEach(item => {
        item.removeEventListener('click', toggleAccordion)
      })
    }
  }, [])

  return (
    <div className='recommendations'>
      <div className='Services'>
        <div className='Services-block'>
          <div className='container'>
            <h2>{t('recommendation.recommendationsServices')}</h2>
            <p className='recommendations-text'>{t('recommendation.recommendationsServicesText')}</p>
            <div className='accordion'>
              <HiddenItem title='JOI' button_number={1}>
                <p>
                  <strong>JOI - Jerking Off Instruction</strong> (инструкция дрочки или инструкция по дрочке): не только медленно, быстро, но и какую
                  часть члена ему надо трогать (яйца, головку), дрочить всей рукой или 1-2 пальцами. Если понаблюдать за парнями-соло, то можно
                  заметить определенные движения, которые помогают достичь оргазма и дрочат все по разному (кто-то только ласкает только головку
                  члена, обильно смачивая слюной или любрикантом, кто-то мнет яйца, кто-то играется еще и с попкой, кто-то теребит соски и т.д.).
                </p>
                <p>
                  Во время <strong>JOI</strong> ты можешь не только говорить/печать/смотреть и управлять, но также тизить (как правило, если он хочет
                  увидеть тебя обнаженной, он сам об этом скажет).
                </p>
                <p>
                  <strong>JOI</strong> может быть, как с аналом, так и без. в разговоре упомянет).
                </p>
              </HiddenItem>
              <HiddenItem title={t('recommendation.sexStories')} button_number={2} text={t('recommendation.sexStoriesText')} />
              <HiddenItem title={t('recommendation.sexGames')} button_number={3} text={t('recommendation.sexGamesText')} />
              <HiddenItem title={t('recommendation.dirtyTalk')} button_number={4} text={t('recommendation.dirtyTalkText')} />
              <HiddenItem title={t('recommendation.lookAtBeauty')} button_number={5} text={t('recommendation.lookAtBeauty')} />
              <HiddenItem title={t('recommendation.dominationGirl')} button_number={5} text={t('recommendation.dominationGirlText')} />
              <HiddenItem title={t('recommendation.subordination')} button_number={5} text={t('recommendation.subordinationText')} />
              <HiddenItem title={t('recommendation.ejaculationControl')} button_number={5} text={t('recommendation.ejaculationControlText')} />
              <HiddenItem title={t('recommendation.showYourself')} button_number={5} text={t('recommendation.showYourselfText')} />
              <HiddenItem title={t('recommendation.additionalServices')} button_number={5} text={t('recommendation.additionalServicesText')} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Recommendations
