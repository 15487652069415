import { useCreateRoomMutation } from 'store/apiMain/mainApi'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setVideoChat } from 'store/reducer/videoChat/reducer'
import { useNavigate } from 'react-router-dom'
import { selectVideoChatObg } from 'store/reducer/videoChat/selector'
import { selectRole } from 'store/reducer/token/selector' // Добавьте правильный импорт для вашего селектора

export function useCreateRoom() {
  const dispatch = useDispatch()
  const [createRoom, { isLoading, isError, isSuccess, error }] = useCreateRoomMutation()
  const navigate = useNavigate()
  const videoChatToken = useSelector(selectVideoChatObg)?.room?.id
  const role = useSelector(selectRole)
  // Функция для создания комнаты
  const handleCreateRoom = async (callerUserId?: string) => {
    if (role === 'MODEL' || 'STAFF') {
      try {
        const result = await createRoom({ callerUserId }).unwrap()
        if (result) dispatch(setVideoChat(result))
        console.log('Комната успешно создана:', result)
        return result
      } catch (err) {
        console.error('Ошибка при создании комнаты:', err)
        throw err
      }
    }
  }
  // Навигация при изменении videoChatToken
  useEffect(() => {
    // if (videoChatToken && role ==='MODEL') {
    //     navigate(`/model/profile/main-info/room/${videoChatToken}`);
    // }
    if (videoChatToken && role === 'MODEL') {
      navigate(`/room/${videoChatToken}`)
    }
  }, [videoChatToken, navigate])

  // Возвращаем функцию и состояния
  return { handleCreateRoom, isLoading, isError, isSuccess, error }
}
