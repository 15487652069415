import React, { useState } from 'react'
import { GlobalConfigSaveDto, GlobalConfigSetApiArg, useGlobalConfigGetQuery, useGlobalConfigSetMutation } from 'store/apiMain/mainApi'

const ModelPrice = () => {
  const [price, setPrice] = useState('')
  const [bonus, setBonus] = useState('')
  const [obligatoryMinutesForRoom, setObligatoryMinutesForRoom] = useState('')
  const [coefficientForRoom, setCoefficientForRoom] = useState('')
  const [obligatoryMinutesForCallFromClient, setObligatoryMinutesForCallFromClient] = useState('')
  const [coefficientForCallFromClient, setCoefficientForCallFromClient] = useState('')
  const [obligatoryMinutesForCallFromModel, setObligatoryMinutesForCallFromModel] = useState('')
  const [coefficientForCallFromModel, setCoefficientForCallFromModel] = useState('')

  const { data } = useGlobalConfigGetQuery()
  const params: GlobalConfigSetApiArg = {
    globalConfigSaveDto: {
      defaultModelMinutePrice: Number(price),
      clientEmailVerificationCoinsBonus: Number(bonus),
      obligatoryMinutesForRoomWithClientAndModel: Number(obligatoryMinutesForRoom),
      coefficientForObligatoryMinutesForRoomWithClientAndModel: Number(coefficientForRoom),
      obligatoryMinutesForCallFromClientToModel: Number(obligatoryMinutesForCallFromClient),
      coefficientForObligatoryMinutesForCallFromClientToModel: Number(coefficientForCallFromClient),
      obligatoryMinutesForCallFromModelToClient: Number(obligatoryMinutesForCallFromModel),
      coefficientForObligatoryMinutesForCallFromModelToClient: Number(coefficientForCallFromModel),
    },
  }

  const [editFunc, { isSuccess }] = useGlobalConfigSetMutation()

  return (
    <div>
      <div>Model Price: {data?.defaultModelMinutePrice}</div>
      <div>Client Email Verification Coins Bonus: {data?.clientEmailVerificationCoinsBonus}</div>
      <div>Obligatory Minutes for Room with Client and Model: {data?.obligatoryMinutesForRoomWithClientAndModel}</div>
      <div>Coefficient for Obligatory Minutes for Room with Client and Model: {data?.coefficientForObligatoryMinutesForRoomWithClientAndModel}</div>
      <div>Obligatory Minutes for Call from Client to Model: {data?.obligatoryMinutesForCallFromClientToModel}</div>
      <div>Coefficient for Obligatory Minutes for Call from Client to Model: {data?.coefficientForObligatoryMinutesForCallFromClientToModel}</div>
      <div>Obligatory Minutes for Call from Model to Client: {data?.obligatoryMinutesForCallFromModelToClient}</div>
      <div>Coefficient for Obligatory Minutes for Call from Model to Client: {data?.coefficientForObligatoryMinutesForCallFromModelToClient}</div>

      <div>
        <input value={price} type='text' placeholder='Enter model price' onChange={e => setPrice(e.target.value)} />
      </div>

      <div>
        <input value={bonus} type='text' placeholder='Enter bonus' onChange={e => setBonus(e.target.value)} />
      </div>

      <div>
        <input
          value={obligatoryMinutesForRoom}
          type='text'
          placeholder='Enter obligatory minutes for room'
          onChange={e => setObligatoryMinutesForRoom(e.target.value)}
        />
      </div>

      <div>
        <input
          value={coefficientForRoom}
          type='text'
          placeholder='Enter coefficient for room minutes'
          onChange={e => setCoefficientForRoom(e.target.value)}
        />
      </div>

      <div>
        <input
          value={obligatoryMinutesForCallFromClient}
          type='text'
          placeholder='Enter obligatory minutes for call from client'
          onChange={e => setObligatoryMinutesForCallFromClient(e.target.value)}
        />
      </div>

      <div>
        <input
          value={coefficientForCallFromClient}
          type='text'
          placeholder='Enter coefficient for call from client minutes'
          onChange={e => setCoefficientForCallFromClient(e.target.value)}
        />
      </div>

      <div>
        <input
          value={obligatoryMinutesForCallFromModel}
          type='text'
          placeholder='Enter obligatory minutes for call from model'
          onChange={e => setObligatoryMinutesForCallFromModel(e.target.value)}
        />
      </div>

      <div>
        <input
          value={coefficientForCallFromModel}
          type='text'
          placeholder='Enter coefficient for call from model minutes'
          onChange={e => setCoefficientForCallFromModel(e.target.value)}
        />
      </div>

      <button onClick={() => editFunc(params)}>Добавить общий прайс</button>
    </div>
  )
}

export default ModelPrice
