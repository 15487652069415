// websocketActions.ts

import { IWebSocketMessage, ISubscribeRequest, IChatTyping, ActivityStatusType } from './types'
import { setWebSocket, setSocket, setCloseSocket } from 'store/reducer/socket/reducer'
import { setSocketTestData } from 'store/reducer/socketTest/reducer'

export const sendActivityUpdate = (socket: WebSocket | null, status: ActivityStatusType) => {
  if (socket) {
    const message: IWebSocketMessage = {
      body: {
        activityStatus: status,
      },
      type: 'SET_ACTIVITY',
      timestamp: new Date(Date.now() - 1000).toISOString(),
    }

    if (socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(message))
    } else {
      socket.addEventListener(
        'open',
        () => {
          socket.send(JSON.stringify(message))
        },
        { once: true },
      )
    }
  }
}

export const sendSubscribe = (socket: WebSocket | null, subscribeToUserIds: string[]) => {
  if (socket && subscribeToUserIds?.length > 0) {
    const message: ISubscribeRequest = {
      body: {
        subscribeToUserIds: [...subscribeToUserIds],
      },
      type: 'SUBSCRIBE',
      timestamp: new Date(Date.now() - 1000).toISOString(),
    }

    if (socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(message))
    } else {
      socket.addEventListener(
        'open',
        () => {
          socket.send(JSON.stringify(message))
        },
        { once: true },
      )
    }
  }
}

export const sendChatTyping = (socket: WebSocket | null, { privateChatId, targetUserId }: { privateChatId: string; targetUserId: string }) => {
  if (socket) {
    const message: IChatTyping = {
      body: {
        privateChatId,
        targetUserId,
      },
      type: 'NOTIFY_ABOUT_CHAT_TYPING',
      timestamp: new Date().toISOString(),
    }

    if (socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(message))
    } else {
      socket.addEventListener(
        'open',
        () => {
          socket.send(JSON.stringify(message))
        },
        { once: true },
      )
    }
  }
}

export const startCall = (socket: WebSocket | null, targetUserId: string) => {
  if (socket) {
    const message = {
      body: {
        targetUserId,
      },
      type: 'CALL_START',
      timestamp: new Date().toISOString(),
    }

    if (socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(message))
    } else {
      socket.addEventListener(
        'open',
        () => {
          socket.send(JSON.stringify(message))
        },
        { once: true },
      )
    }
  }
}

export const handleCall = (socket: WebSocket | null, callerUserId: string, actionType: 'CALL_START' | 'CALL_CANCEL' | 'CALL_ACCEPT') => {
  if (socket) {
    const message = {
      body: {
        callerUserId,
      },
      type: actionType,
      timestamp: new Date().toISOString(),
    }

    if (socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(message))
    } else {
      socket.addEventListener(
        'open',
        () => {
          socket.send(JSON.stringify(message))
        },
        { once: true },
      )
    }
  }
}

export const handleLogoutSocket = (socket: WebSocket | null, dispatch: any) => {
  if (socket) {
    socket.close()
    dispatch(setWebSocket(null))
    dispatch(setSocket(false))
    dispatch(setCloseSocket())
  }
}
