import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import './scss/scss.scss'
import { selectorAdminSelectedChatType } from '../../../store/reducer/messages/selectors'
import { setAdminSelectedChatType } from '../../../store/reducer/messages/reducer'
import { selectRole, selectTokenData } from '../../../store/reducer/token/selector'
import { TokenRevokeApiArg, useTokenRevokeMutation, useUserGetFullMeQuery } from 'store/apiMain/mainApi'
import { resetStateToken } from '../../../store/reducer/token/reducer'
import { useTranslation } from 'react-i18next'
import useWebSocket from '../../../socket/useWebSocket'
import { popUpOpen } from 'store/reducer/popUp/reducer'
import { toastError, toastInfo } from '../../../elements/Notification/Notification'
import { emptySplitApi } from 'store/apiMain/emptyApi'
import useLogout from '../../../hooks/useLogout'

const Header = () => {
  const { t } = useTranslation()
  const { handleLogoutSocket } = useWebSocket()
  const token = useSelector(selectTokenData)

  const location = useLocation()
  const dispatch = useDispatch()
  const selectedChatType = useSelector(selectorAdminSelectedChatType)
  const role = useSelector(selectRole)
  const [containsMessages, setContainsMessages] = useState(false)
  const [searchId, setSearchId] = useState('')
  const [searchType, setSearchType] = useState('CLIENT')
  const [dropdownValue, setDropdownValue] = useState('Enabled')
  const { data: userMe, isLoading } = useUserGetFullMeQuery(undefined, {
    skip: !(role === 'STAFF'),
  })

  useEffect(() => {
    const disabled = dropdownValue === 'Disabled' ? true : dropdownValue === 'Enabled' ? false : undefined
    dispatch(
      setAdminSelectedChatType({
        approvedByClient: undefined,
        approvedByModel: undefined,
        approvedByStaff: undefined,
        clientId: undefined,
        modelId: undefined,
        staffId: undefined,
        byRole: undefined,
        disabled: disabled,
      }),
    )
  }, [dropdownValue])

  useEffect(() => {
    // Check if the URL contains the word 'messages'
    setContainsMessages(location.pathname.includes('messages'))
  }, [location])

  useEffect(() => {
    const disabled = dropdownValue === 'Disabled' ? true : dropdownValue === 'Enabled' ? false : undefined
    dispatch(
      setAdminSelectedChatType({
        approvedByClient: undefined,
        approvedByModel: undefined,
        approvedByStaff: undefined,
        clientId: undefined,
        modelId: undefined,
        staffId: undefined,
        byRole: undefined,
        disabled: disabled,
      }),
    )
  }, [])

  const handleSearchIdChange = (e: any) => {
    setSearchId(e.target.value)
  }

  const handleSearchTypeChange = (e: any) => {
    setSearchType(e.target.value)
  }

  const handleDropdownChange = (e: any) => {
    setDropdownValue(e.target.value)
  }

  const handleSearchIdSubmit = () => {
    if (searchId) {
      const payload = {
        clientId: searchType === 'CLIENT' ? searchId : undefined,
        modelId: searchType === 'MODEL' ? searchId : undefined,
        staffId: searchType === 'STAFF' ? searchId : undefined,
        disabled: dropdownValue === 'Disabled' ? true : dropdownValue === 'Enabled' ? false : undefined,
      }
      dispatch(setAdminSelectedChatType(payload))
    }
  }
  const handleClearIdSubmit = () => {
    setSearchId('')
    const payload = {
      clientId: undefined,
      modelId: undefined,
      staffId: undefined,
      checkboxValue: dropdownValue === 'Disabled' ? true : dropdownValue === 'Enabled' ? false : undefined,
    }
    dispatch(setAdminSelectedChatType(payload))
  }
  const logOutHook = useLogout()
  return (
    <>
      <div className='header'>
        <div className='header-block'>
          <div className='header-block_logo'>
            <p>Virt House</p>
          </div>
          {containsMessages ? (
            <div className='button-block'>
              <button
                className={selectedChatType?.byRole === undefined ? 'active' : ''}
                onClick={() => {
                  dispatch(
                    setAdminSelectedChatType({
                      active: undefined,
                      approvedByClient: undefined,
                      approvedByModel: undefined,
                      approvedByStaff: undefined,
                      clientId: undefined,
                      modelId: undefined,
                      staffId: undefined,
                      byRole: undefined,
                      pageSize: 99,
                      disabled: false,
                    }),
                  )
                }}
              >
                ALL
              </button>
              <button
                className={selectedChatType?.byRole === 'STAFF' && selectedChatType.active === undefined ? 'active' : ''}
                onClick={() => {
                  dispatch(
                    setAdminSelectedChatType({
                      active: undefined,
                      approvedByClient: undefined,
                      approvedByModel: undefined,
                      approvedByStaff: undefined,
                      clientId: undefined,
                      modelId: undefined,
                      staffId: userMe?.staff?.id,
                      byRole: 'STAFF',
                      pageSize: 99,
                      disabled: dropdownValue === 'Disabled' ? true : dropdownValue === 'Enabled' ? false : undefined,
                    }),
                  )
                }}
              >
                STAFF
              </button>
              <button
                className={selectedChatType?.byRole === 'CLIENT' && selectedChatType.active === undefined ? 'active' : ''}
                onClick={() => {
                  dispatch(
                    setAdminSelectedChatType({
                      active: undefined,
                      approvedByClient: undefined,
                      approvedByModel: undefined,
                      approvedByStaff: undefined,
                      clientId: undefined,
                      modelId: undefined,
                      staffId: userMe?.staff?.id,
                      byRole: 'CLIENT',
                      pageSize: 99,
                      disabled: dropdownValue === 'Disabled' ? true : dropdownValue === 'Enabled' ? false : undefined,
                    }),
                  )
                }}
              >
                CLIENT
              </button>
              <button
                className={selectedChatType?.byRole === 'MODEL' && selectedChatType.active === undefined ? 'active' : ''}
                onClick={() => {
                  dispatch(
                    setAdminSelectedChatType({
                      active: undefined,
                      approvedByClient: undefined,
                      approvedByModel: undefined,
                      approvedByStaff: undefined,
                      clientId: undefined,
                      modelId: undefined,
                      staffId: userMe?.staff?.id,
                      byRole: 'MODEL',
                      pageSize: 99,
                      disabled: dropdownValue === 'Disabled' ? true : dropdownValue === 'Enabled' ? false : undefined,
                    }),
                  )
                }}
              >
                MODEL
              </button>
              <div className='button-block_line'></div>

              <button
                className={selectedChatType?.active === 'view_client' ? 'active' : ''}
                onClick={() => {
                  setTimeout(() => {
                    dispatch(
                      setAdminSelectedChatType({
                        approvedByClient: true,
                        approvedByModel: true,
                        approvedByStaff: undefined,
                        clientId: undefined,
                        modelId: undefined,
                        staffId: undefined,
                        byRole: 'CLIENT',
                        active: 'view_client',
                        pageSize: 99,
                        disabled: dropdownValue === 'Disabled' ? true : dropdownValue === 'Enabled' ? false : undefined,
                      }),
                    )
                  }, 100)
                }}
              >
                View client
              </button>
              <button
                className={selectedChatType?.active === 'view_model' ? 'active' : ''}
                onClick={() => {
                  setTimeout(() => {
                    dispatch(
                      setAdminSelectedChatType({
                        approvedByClient: true,
                        approvedByModel: true,
                        approvedByStaff: undefined,
                        clientId: undefined,
                        modelId: undefined,
                        staffId: undefined,
                        byRole: 'MODEL',
                        active: 'view_model',
                        pageSize: 99,
                        disabled: dropdownValue === 'Disabled' ? true : dropdownValue === 'Enabled' ? false : undefined,
                      }),
                    )
                  }, 100)
                }}
              >
                View model
              </button>

              <div className='search-block'>
                <select value={searchType} onChange={handleSearchTypeChange}>
                  <option value='CLIENT'>Client</option>
                  <option value='MODEL'>Model</option>
                  <option value='STAFF'>Staff</option>
                </select>
                <input type='text' value={searchId} onChange={handleSearchIdChange} placeholder='Search by ID' />
                <button onClick={handleSearchIdSubmit}>Search</button>
                <button onClick={handleClearIdSubmit}>Clear</button>
                <label>
                  <select value={dropdownValue} onChange={handleDropdownChange}>
                    <option value='All'>All</option>
                    <option value='Enabled'>Enabled</option>
                    <option value='Disabled'>Disabled</option>
                  </select>
                </label>
              </div>
              <button
                onClick={() => {
                  dispatch(popUpOpen('AdminCreatePrivateChat'))
                }}
              >
                Create private chat
              </button>
            </div>
          ) : null}
          <div></div>
          <div className='header-block_box'>
            <p>Admin</p>
            <div>
              <button onClick={logOutHook} type='submit'>
                {t('profile.logout')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
