import React, { useEffect, useState } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import UsersRoutes from './Routes/UsersRoutes'
import { useDispatch, useSelector } from 'react-redux'
import { selectRole, selectTokenData } from './store/reducer/token/selector'
import { setUserMe } from './store/reducer/token/reducer'
import ModelRoutes from 'Routes/ModelRoutes'
import AdminRoutes from './Routes/AdminRoutes'

import './scss/index.scss'
import { getAge, getUuidUrl } from 'store/reducer/age/selectors'
import { getPopUpClose, popUpOpen } from 'store/reducer/popUp/reducer'

import { setSocketToken } from 'store/reducer/socket/reducer'
import { getSocketIsConnected, getSocketToken } from './store/reducer/socket/selector'
import useWebSocket from './socket/useWebSocket'
import { setUuidUrl } from 'store/reducer/age/reducer'
import Footer from './Components/Global/Footer/Footer'
import InfoRouters from 'Routes/InfoRoutes'
import ActivityDetector from './utils/ActivityDetector/ActivityDetector'
import { useTicketCreateMutation, useUserGetFullMeQuery } from 'store/apiMain/mainApi'
import { setFirstClick, setModelVerifications } from 'store/reducer/global/reducer'
import { selectorGetClick, selectorModelVerifications } from 'store/reducer/global/selectors'
import useRefreshToken from './hooks/useRefreshToken'
import { toastInfo } from './elements/Notification/Notification'
import PopupModal from './Components/Global/VideoChat/PopUpVideoChat/PopUpVideoChat'
import Home from './Components/Global/VideoChat/Components/Home/Home'
import JoinRoomA from './Components/Global/VideoChat/Components/JoinRoomA/JoinRoomA'
import MainContent from './layouts-elements/MainContent/MainContent'
import { selectVideoChatObg } from 'store/reducer/videoChat/selector'
import { getSocketTestDataSelector } from 'store/reducer/socketTest/selector'
import useRedirectIfAuthenticated from './hooks/useRedirectIfAuthenticated'

function App() {
  useRefreshToken()
  useRedirectIfAuthenticated()
  const navigate = useNavigate()
  const ageConfirmation = useSelector(getAge)
  const dispatch = useDispatch()
  const getClick = useSelector(selectorGetClick)
  useEffect(() => {
    if (!ageConfirmation.ageConfirmation) {
      dispatch(popUpOpen('ageBlock'))
    }
  }, [ageConfirmation, dispatch])
  const socketToken = useSelector(getSocketToken)
  const token = useSelector(selectTokenData)
  const {
    data: userMe,
    isLoading: isLoadingUserMe,
    isSuccess: isSuccessUserMe,
  } = useUserGetFullMeQuery(undefined, {
    skip: token === null,
  })
  useEffect(() => {
    if (isLoadingUserMe) {
      dispatch(popUpOpen('loading'))
    }
  }, [isLoadingUserMe])
  useEffect(() => {
    if (isSuccessUserMe) {
      dispatch(getPopUpClose())
    }
  }, [isSuccessUserMe])
  const modelVerifications = useSelector(selectorModelVerifications)
  const [addTicket, { data: dataSocket, error }] = useTicketCreateMutation()
  useEffect(() => {
    // @ts-ignore
    if (error?.data?.type === 'USER_EMAIL_NOT_VERIFIED') {
      if (role === 'MODEL' && modelVerifications === null) {
        toastInfo('На почту пришел код!')
        navigate('model/registration')
        dispatch(setModelVerifications(true))
      }
      if (role === 'STAFF' && userMe?.userData?.user?.email) {
        toastInfo('На почту пришел код!')
        navigate(`admin/staff/verifications-code/${userMe?.userData?.user?.email}`)
        dispatch(setModelVerifications(true))
      }
    }
  }, [error, userMe?.userData?.user?.email])
  useEffect(() => {
    if (dataSocket) {
      dispatch(setSocketToken(dataSocket?.ticket))
    }
  }, [dataSocket, dispatch])

  useEffect(() => {
    if (token?.access_token !== undefined) {
      addTicket()
    }
  }, [token, addTicket])

  useEffect(() => {
    if (userMe) {
      dispatch(setUserMe(userMe))
    }
  }, [userMe, dispatch])

  const { connectWebSocket } = useWebSocket()
  useEffect(() => {
    if (socketToken) {
      connectWebSocket()
    }
  }, [socketToken, connectWebSocket])

  const [searchParams] = useSearchParams()
  const location = useLocation()
  useEffect(() => {
    const uuidValue = searchParams.get('UUID_URL')
    if (uuidValue) {
      dispatch(setUuidUrl(uuidValue))

      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.delete('UUID_URL')
      const newQueryString = newSearchParams.toString()
      const newUrl = newQueryString ? `${location.pathname}?${newQueryString}` : location.pathname
      window.history.replaceState({}, '', newUrl)
    }
  }, [searchParams, location.pathname, dispatch])

  useEffect(() => {
    if (getClick === false) {
      const handleFirstInteraction = () => {
        dispatch(setFirstClick())
        window.removeEventListener('click', handleFirstInteraction)
        window.removeEventListener('scroll', handleFirstInteraction)
      }

      window.addEventListener('click', handleFirstInteraction)
      window.addEventListener('scroll', handleFirstInteraction)

      return () => {
        window.removeEventListener('click', handleFirstInteraction)
        window.removeEventListener('scroll', handleFirstInteraction)
      }
    }
  }, [selectorGetClick, dispatch])
  const socketStatus = useSelector(getSocketIsConnected)
  const role = useSelector(selectRole)
  const rooms = useSelector(selectVideoChatObg)
  const socketDataTest = useSelector(getSocketTestDataSelector)
  console.log('12312312312313')
  return (
    <>
      <div className='App'>
        <div
          style={{
            position: 'absolute',
            marginLeft: '40px',
            zIndex: '999999',
            display: 'flex',
            cursor: 'pointer',
          }}
        >
          {socketStatus ? 'Подключены' : 'Отключены'}-{role}-
          <div
            onClick={() => {
              dispatch(popUpOpen('socketTest'))
            }}
          >
            <p>Socket counter-{socketDataTest.length}</p>
          </div>
        </div>
        <Routes>
          <Route path='/*' element={<UsersRoutes />} />
          <Route path='/info/*' element={<InfoRouters />} />
          <Route path='/model/*' element={<ModelRoutes />} />
          <Route path='/admin/*' element={<AdminRoutes />} />
          {/*<Route path='/' element={<UsersRoutes />} index />*/}
          <Route path='*' element={<Navigate to='/login' replace />} />

          <Route path='/room/:roomId' element={<JoinRoomA />} />
          <Route path='/video/:roomId' element={<JoinRoomA />} />
        </Routes>
        <Footer />
      </div>
      <ActivityDetector />
    </>
  )
}

export default App
