import React from 'react'
import { StaffViewDto, useStaffGetAllQuery } from 'store/apiMain/mainApi' // adjust the import path as necessary
import './scss/index.scss'
import { useNavigate } from 'react-router-dom'

const StaffView = () => {
  const navigate = useNavigate()
  const { data, error, isLoading } = useStaffGetAllQuery({})

  if (isLoading) return <div>Loading...</div>
  if (error) return <div>Error loading data</div>

  return (
    <div className='staff-view'>
      <div className='filters'>
        <h2>Staff List</h2>
      </div>

      {data?.content.map((staffItem: StaffViewDto) => {
        return (
          <div
            onClick={() => {
              navigate(`/admin/staff/staff-list/${staffItem?.user?.id}`)
            }}
            key={staffItem?.user?.id}
            className='staff-item'
          >
            <h3>{staffItem?.user?.email}</h3>
            <p>Role: {staffItem?.user?.role}</p>
            <p>Is Deleted: {staffItem?.user?.isDeleted ? 'Yes' : 'No'}</p>
            <p>Is Disabled: {staffItem?.user?.isDisabled ? 'Yes' : 'No'}</p>
          </div>
        )
      })}
    </div>
  )
}

export default StaffView
