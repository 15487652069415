import React from 'react'
import { useClientGetFullByIdQuery } from 'store/apiMain/mainApi'
import { useParams } from 'react-router-dom'
import Item from './Item/Item'
import './css/index.scss'

const Client = () => {
  const { clientId } = useParams()
  const queryArg = {
    clientId: clientId,
    idType: 'ID',
  }
  // @ts-ignore
  const { data, refetch } = useClientGetFullByIdQuery({ ...queryArg })

  return <div className='client'>{data !== null ? <Item item={data} refetch={refetch} /> : null}</div>
}

export default Client
