import React from 'react'
import { useNavigate } from 'react-router-dom'
import dataFormatMessages from '../../../../utils/dataFormatMessages'
import CopyButton from '../../../../UI/CopyButton/CopyButton'

const Item: React.FC<any> = ({ item }) => {
  const navigate = useNavigate()
  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
      <tr>
        <td id='id'>
          {item.model.id} <CopyButton textToCopy={item.model.id} />
        </td>
        <td id='name'>{item.model.name}</td>
        <td id='name'>{item.user.email}</td>
        <td id='BirthYear'>{item.model.birthYear}</td>
        <td id='CreatedAt'>{dataFormatMessages(item.model.createdAt)}</td>
        <td id='delete'>{item.user.isDeleted ? 'Удаленный' : '-'}</td>
        <td id='disable'>{item.user.isDisabled ? 'Невктивный' : '-'}</td>
        <td onClick={() => navigate(`/admin/model/${item?.model.id}`)} id='settigs'>
          <button>Настройки профиля</button>
        </td>
        <td onClick={() => navigate(`/admin/models/model-settings/${item?.model.id}`)} id='settigs'>
          <button>Настройки</button>
        </td>
      </tr>
    </>
  )
}

export default Item
