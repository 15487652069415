import { SvgBlackBlockedUser, SvgBlackBlockUser, SvgBlackGroup3, SvgBlackHeart1, SvgBlackProfile1, SvgBlackVector } from 'images/svg'
import React from 'react'
import { RelationshipGetCountApiArg, useRelationshipGetCountQuery } from 'store/apiMain/mainApi'
import './scss/index.scss'
import { IPropsSidebar } from './types'

const SideBar: React.FC<IPropsSidebar> = ({ sideBarFilterValue, setSideBarFilterValue, dataRequest }) => {
  const payload: RelationshipGetCountApiArg = {
    isFavorite: undefined,
    name: undefined,
    initiatedBy: undefined,
    statuses: undefined,
    isOnline: undefined,
    showDeleted: undefined,
    showDisabled: undefined,
    ids: undefined,
    userId: undefined,
    pointOfViewRole: undefined,
  }

  const { data: dataCount } = useRelationshipGetCountQuery(payload)

  return (
    <aside className='friendsFour__sidebar'>
      <div
        onClick={() => {
          setSideBarFilterValue('FRIENDS')
        }}
        className={`friendsFour__sidebar-link ${sideBarFilterValue === 'FRIENDS' ? 'active' : ''}`}
      >
        <SvgBlackGroup3 />
      </div>
      <div
        onClick={() => {
          setSideBarFilterValue('ONLINE')
        }}
        className={`friendsFour__sidebar-link ${sideBarFilterValue === 'ONLINE' ? 'active' : ''}`}
      >
        <SvgBlackProfile1 />
      </div>
      <div
        onClick={() => {
          setSideBarFilterValue('FAVORITES')
        }}
        className={`friendsFour__sidebar-link ${sideBarFilterValue === 'FAVORITES' ? 'active' : ''}`}
      >
        <SvgBlackHeart1 />
      </div>
      <div
        onClick={() => {
          setSideBarFilterValue('FRIEND_REQUEST')
        }}
        className={`friendsFour__sidebar-link ${sideBarFilterValue === 'FRIEND_REQUEST' ? 'active' : ''}`}
      >
        <SvgBlackVector />
        {dataRequest?.amount === 0 ? null : <span>{dataRequest?.amount}</span>}
      </div>
      <div
        onClick={() => {
          setSideBarFilterValue('BLOCKED')
        }}
        className={`friendsFour__sidebar-link ${sideBarFilterValue === 'BLOCKED' ? '' : ''}`}
      >
        {sideBarFilterValue === 'BLOCKED' ? <SvgBlackBlockedUser /> : <SvgBlackBlockUser />}
      </div>
    </aside>
  )
}

export default SideBar
