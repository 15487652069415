const notificationsHandler = (text: string | undefined): string | undefined => {
  switch (text) {
    case 'PERMISSIONS_UPDATED':
      return 'Your details are up to date.'
    case 'FRIEND_REQUEST':
      return 'You have received a new friend request.'
    case 'USER_DISABLED':
      return 'You have been blocked.'
    case 'FRIEND_REQUEST_APPROVED':
      return 'Your friend request has been approved.'
    case 'MODEL_REQUEST_MAIN_INFO_APPROVED':
      return 'Your model main information request has been approved.'
    case 'MODEL_REQUEST_SECRET_DATA_APPROVED':
      return 'Your model secret data request has been approved.'
    case 'NEW_MODEL_REQUEST_MAIN_INFO':
      return 'A new model main information request has been created.'
    case 'NEW_MODEL_REQUEST_SECRET_DATA':
      return 'A new model secret data request has been created.'
    case 'MODEL_DELETED':
      return 'A model has been deleted.'
    case 'STAFF_DELETED':
      return 'A staff member has been deleted.'
    case 'MODEL_FULLY_APPROVED_AND_CREATED':
      return 'Your model has been fully approved and created.'
    case 'MODEL_REGISTERED':
      return 'A new model has been registered.'
    case 'COMPLAINT_CREATED':
      return 'A new complaint has been created.'
    case 'SERVICE_ADDED':
      return 'A new service has been added.'
    case 'SERVICE_UPDATED':
      return 'A service has been updated.'
    case 'SERVICE_DELETED':
      return 'A service has been deleted.'
    case 'MODEL_FULLY_APPROVED':
      return 'A model has been fully approved.'
    case 'ACCOUNT_COINS_BALANCE_CHANGED':
      return 'Your account coins balance has changed.'
    case 'ACCOUNT_MESSAGES_BALANCE_CHANGED':
      return 'Your account messages balance has changed.'
    case 'GIFT_RECEIVED':
      return 'You have received a gift.'
    // case 'NOTIFICATION_VIEW':
    //   return 'Your notification has been viewed.';
    // case 'NOTIFICATION_SUBSCRIPTIONS_VIEW':
    //   return 'Your subscriptions have been viewed.';
    // case 'NOTIFICATION_CREATE':
    //   return 'A new notification has been created.';
    // case 'NOTIFICATION_SUBSCRIBE_PERMISSIONS_UPDATED':
    //   return 'You are now subscribed to permissions updates.';
    // case 'NOTIFICATION_SUBSCRIBE_FRIEND_REQUEST':
    //   return 'You are now subscribed to friend requests notifications.';
    // case 'NOTIFICATION_SUBSCRIBE_FRIEND_REQUEST_APPROVED':
    //   return 'You are now subscribed to friend request approval notifications.';
    // case 'NOTIFICATION_SUBSCRIBE_MODEL_REQUEST_MAIN_INFO_APPROVED':
    //   return 'You are now subscribed to main info request approval notifications.';
    // case 'NOTIFICATION_SUBSCRIBE_MODEL_REQUEST_SECRET_DATA_APPROVED':
    //   return 'You are now subscribed to secret data request approval notifications.';
    // case 'NOTIFICATION_SUBSCRIBE_NEW_MODEL_REQUEST_MAIN_INFO':
    //   return 'You are now subscribed to new main info request notifications.';
    // case 'NOTIFICATION_SUBSCRIBE_NEW_MODEL_REQUEST_SECRET_DATA':
    //   return 'You are now subscribed to new secret data request notifications.';
    // case 'NOTIFICATION_SUBSCRIBE_MODEL_DELETED':
    //   return 'You are now subscribed to model deletion notifications.';
    // case 'NOTIFICATION_SUBSCRIBE_STAFF_DELETED':
    //   return 'You are now subscribed to staff deletion notifications.';
    // case 'NOTIFICATION_SUBSCRIBE_MODEL_FULLY_APPROVED_AND_CREATED':
    //   return 'You are now subscribed to notifications for model approval and creation.';
    // case 'NOTIFICATION_SUBSCRIBE_MODEL_REGISTERED':
    //   return 'You are now subscribed to model registration notifications.';
    // case 'NOTIFICATION_SUBSCRIBE_COMPLAINT_CREATED':
    //   return 'You are now subscribed to complaint creation notifications.';

    default:
      return 'xxxxxxxxxxxxxxxx'
  }
}

export default notificationsHandler
