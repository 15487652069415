import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import './../../locales/i18n'
import './scss/index.scss'
import { useDispatch, useSelector } from 'react-redux'
import { selectLanguageData } from '../../store/reducer/language/selector'
import { popUpOpen } from 'store/reducer/popUp/reducer'
import languageIcons from '../languageData/icons'
import languages, { ILanguage, IRegionLanguages } from '../languageData/languages'
import { SvgChevronDown } from '../../images/svg'

import { setLanguageData } from 'store/reducer/language/reducer'
import { useUserGetFullMeQuery } from 'store/apiMain/mainApi'
import { selectTokenData } from 'store/reducer/token/selector'

export const findLanguage = (identifier: string) => {
  for (let region of languages) {
    for (let language of region.languages) {
      if (language.code === identifier || language.backendName === identifier) {
        return language
      }

      // Check if the identifier is in the form of 'XX-XX' and matches the language code
      if (identifier.includes('-') && language.code === identifier.split('-')[0]) {
        return language
      }
    }
  }
  return null
}
interface LanguageProps {
  dark?: boolean
}

const Language: React.FC<LanguageProps> = React.memo(({ dark = false }) => {
  const dispatch = useDispatch()
  const selectedLanguage = useSelector(selectLanguageData)
  const [active, setActive] = useState(false)
  const { i18n } = useTranslation()
  const token = useSelector(selectTokenData)
  const { data: user } = useUserGetFullMeQuery(undefined, { skip: !token })
  const handleLanguageChange = useCallback(
    (language: ILanguage) => {
      dispatch(
        setLanguageData({
          code: language.code,
          name: language.name,
          // @ts-ignore
          backendName: language.backendName,
        }),
      )
      i18n.changeLanguage(language.code.toLowerCase())
    },
    [dispatch, i18n],
  )

  useEffect(() => {
    const languageCode = user?.userData?.user?.language || selectedLanguage.name || i18n.language.toUpperCase()
    const language = findLanguage(languageCode)

    if (language && language.code !== selectedLanguage.code) {
      handleLanguageChange(language)
    }
  }, [user, i18n.language, selectedLanguage.code, handleLanguageChange, dispatch])

  const toggleLanguagePopup = useCallback(() => {
    setActive(!active)
    dispatch(popUpOpen('language'))
  }, [active, dispatch])

  return (
    <div onClick={toggleLanguagePopup} className={`${dark ? 'language-header_dark' : 'language-header'}`}>
      <div className='flag'>{selectedLanguage && languageIcons[selectedLanguage.code]}</div>
      <label>{selectedLanguage.name}</label>
      <div className='dropdown-arrow'>
        <SvgChevronDown />
      </div>
    </div>
  )
})

export default Language
