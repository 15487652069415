import React from 'react'
import {
  SvgUkraine,
  SvgBulgaria,
  SvgCzech,
  SvgDenmark,
  SvgGermany,
  SvgGreece,
  SvgUK,
  SvgSpain,
  SvgEstonia,
  SvgFinland,
  SvgFrance,
  SvgHungary,
  SvgItaly,
  SvgLithuania,
  SvgLatvia,
  SvgNorway,
  SvgNetherlands,
  SvgPoland,
  SvgPortugal,
  SvgRomania,
  SvgRussia,
  SvgSlovakia,
  SvgSlovenia,
  SvgSweden,
  SvgArabic,
  SvgJapan,
  SvgKorea,
  SvgChinaSimplified,
  SvgChinaTraditional,
  SvgTurkey,
  SvgBrazil,
  SvgIndonesia,
  SvgUS,
} from 'images/svg'

const languageIcons: { [key: string]: React.ReactElement } = {
  BG: React.createElement(SvgBulgaria),
  CS: React.createElement(SvgCzech),
  DA: React.createElement(SvgDenmark),
  DE: React.createElement(SvgGermany),
  EL: React.createElement(SvgGreece),
  'EN-GB': React.createElement(SvgUK),
  'EN-US': React.createElement(SvgUS),
  ES: React.createElement(SvgSpain),
  ET: React.createElement(SvgEstonia),
  FI: React.createElement(SvgFinland),
  FR: React.createElement(SvgFrance),
  HU: React.createElement(SvgHungary),
  IT: React.createElement(SvgItaly),
  LT: React.createElement(SvgLithuania),
  LV: React.createElement(SvgLatvia),
  NB: React.createElement(SvgNorway),
  NL: React.createElement(SvgNetherlands),
  PL: React.createElement(SvgPoland),
  'PT-PT': React.createElement(SvgPortugal),
  'PT-BR': React.createElement(SvgBrazil),
  RO: React.createElement(SvgRomania),
  RU: React.createElement(SvgRussia),
  SK: React.createElement(SvgSlovakia),
  SL: React.createElement(SvgSlovenia),
  SV: React.createElement(SvgSweden),
  UK: React.createElement(SvgUkraine),
  AR: React.createElement(SvgArabic),
  JA: React.createElement(SvgJapan),
  KO: React.createElement(SvgKorea),
  'ZH-HANS': React.createElement(SvgChinaSimplified),
  'ZH-HANT': React.createElement(SvgChinaTraditional),
  TR: React.createElement(SvgTurkey),
  ID: React.createElement(SvgIndonesia),
}

type LanguageIconsType = typeof languageIcons
type LanguageIconKey = keyof LanguageIconsType

export type FlagIcon = LanguageIconsType[LanguageIconKey]

export default languageIcons
