import { useState, useEffect, useRef, RefObject } from 'react'

const useNotifications = () => {
  const [active, setActive] = useState(false)
  const [closing, setClosing] = useState(false)

  const notificationsBlockRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const openNotificationWindow = () => {
    setActive(true)
    setClosing(false)
  }

  const closeNotificationWindow = () => {
    setClosing(true)
    setTimeout(() => {
      setActive(false)
      setClosing(false)
    }, 290)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (notificationsBlockRef.current && notificationsBlockRef.current.contains(event.target as Node)) {
      return
    }
    if (wrapperRef.current && wrapperRef.current.contains(event.target as Node)) {
      return
    }
    closeNotificationWindow()
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return {
    active,
    closing,
    notificationsBlockRef,
    wrapperRef,
    openNotificationWindow,
    closeNotificationWindow,
  }
}

export default useNotifications
