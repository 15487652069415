import React from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { FormControl } from 'layouts-elements/FormControl/FormControl'
import { setRole, setTokenData } from 'store/reducer/token/reducer'
import { toastSuccess } from 'elements/Notification/Notification'
import { useTokenGetMutation } from 'store/apiMain/emptyApi'

const Login = () => {
  const dispatch = useDispatch()
  const initialValues: any = {
    username: 'root@email.com',
    password: 'root@email.com',
  }
  const [tokenGet, { isLoading, error }] = useTokenGetMutation()
  const validationSchema = yup.object().shape({
    username: yup.string().required('username is required'),
    password: yup.string().required('Password is required').min(6, 'Password must be at least 6 characters long'),
  })

  const onSubmit = (values: any) => {
    const fetchData = async () => {
      const body = {
        username: values.username,
        password: values.password,
        client_id: 'general-client',
        grant_type: 'password',
        client_secret: 'general',
      }
      try {
        const result = await tokenGet({ body }).unwrap()
        if (result) {
          dispatch(setTokenData(result))
          dispatch(setRole('STAFF'))
          toastSuccess('You have successfully logged in!')
        }
        if (!result) {
          throw result
        }
      } catch (error) {
        // toastError('Failed');
        console.error('Failed to create client', error)
      }
    }
    fetchData()
  }
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  return (
    <div className='login'>
      <h2>Login</h2>
      <form onSubmit={formik.handleSubmit}>
        <div className='login-block'>
          <div className='login-block_box'>
            <p>Username:</p>
            <FormControl name='username' type='username' formik={formik} />
          </div>
          <div className='login-block_box'>
            <p>Password:</p>
            <FormControl name='password' type='password' formik={formik} />
          </div>
          <div className='login-block__button'>
            <button type='submit'>Login</button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Login
