/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAgeConfirmationStore } from './types'

// ==========================================:
export const initialState: IAgeConfirmationStore = {
  ageConfirmation: false,
  //
  uuidUrl: '',
}

// ==========================================:
const ageConfirmationSlice = createSlice({
  name: '@@ageConfirmation',
  initialState,
  reducers: {
    ageConfirmationCounter: state => {
      const stateData = state
      stateData.ageConfirmation = true
    },
    setUuidUrl: (state, action: PayloadAction<string>) => {
      state.uuidUrl = action.payload
    },

    ageConfirmationCounterInitState: () => initialState,
  },
})

export default ageConfirmationSlice.reducer
export const { ageConfirmationCounter, setUuidUrl, ageConfirmationCounterInitState } = ageConfirmationSlice.actions
