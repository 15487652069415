import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react'
import { StaffCreateDto, useStaffCreateMutation } from 'store/apiMain/mainApi'
import './scss/index.scss'
import { toastSuccess } from '../../../../elements/Notification/Notification'
import { useSelector } from 'react-redux'
import { selectLanguageData } from 'store/reducer/language/selector'

export type Permission =
  | 'CLIENT_VIEW'
  | 'CLIENT_UPDATE'
  | 'CLIENT_UPDATE_NAME'
  | 'MODEL_REQUEST_VIEW'
  | 'MODEL_REQUEST_CREATE'
  | 'MODEL_REQUEST_DELETE'
  | 'MODEL_REQUEST_APPROVE_MAIN_INFO'
  | 'MODEL_REQUEST_APPROVE_SECRET_DATA'
  | 'MODEL_VIEW'
  | 'MODEL_VIEW_SECRET_DATA'
  | 'MODEL_UPDATE_MINUTE_PRICE'
  | 'SERVICE_VIEW_RECOMMENDED_PRICE'
  | 'SERVICE_CREATE'
  | 'SERVICE_UPDATE'
  | 'SERVICE_DELETE'
  | 'MODEL_SERVICE_SET'
  | 'FILE_CREATE_TEMP'
  | 'FILE_SET_OWNER_ID'
  | 'FILE_VIEW_TEMP'
  | 'FILE_VIEW_DELETED'
  | 'NOTIFICATION_VIEW'
  | 'NOTIFICATION_CREATE'
  | 'COMPLAINT_VIEW'
  | 'RELATIONSHIP_VIEW'
  | 'RELATIONSHIP_CREATE'
  | 'RELATIONSHIP_DELETE'
  | 'RELATIONSHIP_UPDATE'
  | 'RELATIONSHIP_IS_CLIENT_FAVORITE_VIEW'
  | 'RELATIONSHIP_IS_MODEL_FAVORITE_VIEW'
  | 'RELATIONSHIP_IS_CLIENT_FAVORITE_SET'
  | 'RELATIONSHIP_IS_MODEL_FAVORITE_SET'
  | 'STAFF_VIEW'
  | 'STAFF_CREATE'
  | 'STAFF_UPDATE'
  | 'USER_VIEW'
  | 'USER_UPDATE'
  | 'USER_UPDATE_EMAIL'
  | 'USER_UPDATE_EMAIL_SELF'
  | 'USER_UPDATE_PASSWORD'
  | 'USER_UPDATE_PASSWORD_SELF'
  | 'USER_DELETE'
  | 'USER_DELETED_VIEW'
  | 'USER_MANAGE_DISABLE_STATE'
  | 'USER_DISABLED_VIEW'
  | 'USER_MANAGE_PERMISSION'
  | 'FIELD_FILE_OWNER_ID_VIEW'
  | 'FIELD_EMAIL_VIEW'
  | 'FIELD_AGE_RANGE_VIEW'
  | 'FIELD_PERMISSION_VIEW'
  | 'FIELD_ROOT_VIEW'
  | 'FIELD_AUDIT_VIEW'
  | 'FIELD_ACTIVE_UPDATE_REQUEST_ID_VIEW'
  | 'WORD_BLACK_LIST_VIEW'
  | 'WORD_BLACK_LIST_CREATE'
  | 'WORD_BLACK_LIST_DELETE'
  | 'GLOBAL_CONFIG_VIEW'
  | 'GLOBAL_CONFIG_SET'
  | 'PERMISSION_VIEW'
  | 'PRIVATE_CHAT_VIEW'
  | 'PRIVATE_CHAT_DISABLE'
  | 'PRIVATE_CHAT_ASSIGN'
  | 'MESSAGE_CREATE_SELF'
  | 'MESSAGE_UPDATE'
  | 'MESSAGE_UPDATE_SELF'
  | 'MESSAGE_VIEW'
  | 'MESSAGE_VIEW_DELETED'
  | 'MESSAGE_CLEAR'
  | 'MESSAGE_CLEAR_SELF'
  | 'REFERRAL_CREATE'
  | 'REFERRAL_UPDATE'
  | 'REFERRAL_DELETE'
  | 'REFERRAL_VIEW'
  | 'ACCOUNT_VIEW'
  | 'ACCOUNT_UPDATE'
  | 'TRANSACTION_VIEW'
  | 'MESSAGE_RULE_SET'
  | 'GIFT_RECORDS_VIEW'

const allPermissions: Permission[] = [
  'CLIENT_VIEW',
  'CLIENT_UPDATE',
  'CLIENT_UPDATE_NAME',
  'MODEL_REQUEST_VIEW',
  'MODEL_REQUEST_CREATE',
  'MODEL_REQUEST_DELETE',
  'MODEL_REQUEST_APPROVE_MAIN_INFO',
  'MODEL_REQUEST_APPROVE_SECRET_DATA',
  'MODEL_VIEW',
  'MODEL_VIEW_SECRET_DATA',
  'MODEL_UPDATE_MINUTE_PRICE',
  'SERVICE_VIEW_RECOMMENDED_PRICE',
  'SERVICE_CREATE',
  'SERVICE_UPDATE',
  'SERVICE_DELETE',
  'MODEL_SERVICE_SET',
  'FILE_CREATE_TEMP',
  'FILE_SET_OWNER_ID',
  'FILE_VIEW_TEMP',
  'FILE_VIEW_DELETED',
  'NOTIFICATION_VIEW',
  'NOTIFICATION_CREATE',
  'COMPLAINT_VIEW',
  'RELATIONSHIP_VIEW',
  'RELATIONSHIP_CREATE',
  'RELATIONSHIP_DELETE',
  'RELATIONSHIP_UPDATE',
  'RELATIONSHIP_IS_CLIENT_FAVORITE_VIEW',
  'RELATIONSHIP_IS_MODEL_FAVORITE_VIEW',
  'RELATIONSHIP_IS_CLIENT_FAVORITE_SET',
  'RELATIONSHIP_IS_MODEL_FAVORITE_SET',
  'STAFF_VIEW',
  'STAFF_CREATE',
  'STAFF_UPDATE',
  'USER_VIEW',
  'USER_UPDATE',
  'USER_UPDATE_EMAIL',
  'USER_UPDATE_EMAIL_SELF',
  'USER_UPDATE_PASSWORD',
  'USER_UPDATE_PASSWORD_SELF',
  'USER_DELETE',
  'USER_DELETED_VIEW',
  'USER_MANAGE_DISABLE_STATE',
  'USER_DISABLED_VIEW',
  'USER_MANAGE_PERMISSION',
  'FIELD_FILE_OWNER_ID_VIEW',
  'FIELD_EMAIL_VIEW',
  'FIELD_AGE_RANGE_VIEW',
  'FIELD_PERMISSION_VIEW',
  'FIELD_ROOT_VIEW',
  'FIELD_AUDIT_VIEW',
  'FIELD_ACTIVE_UPDATE_REQUEST_ID_VIEW',
  'WORD_BLACK_LIST_VIEW',
  'WORD_BLACK_LIST_CREATE',
  'WORD_BLACK_LIST_DELETE',
  'GLOBAL_CONFIG_VIEW',
  'GLOBAL_CONFIG_SET',
  'PERMISSION_VIEW',
  'PRIVATE_CHAT_VIEW',
  'PRIVATE_CHAT_DISABLE',
  'PRIVATE_CHAT_ASSIGN',
  'MESSAGE_CREATE_SELF',
  'MESSAGE_UPDATE',
  'MESSAGE_UPDATE_SELF',
  'MESSAGE_VIEW',
  'MESSAGE_VIEW_DELETED',
  'MESSAGE_CLEAR',
  'MESSAGE_CLEAR_SELF',
  'REFERRAL_CREATE',
  'REFERRAL_UPDATE',
  'REFERRAL_DELETE',
  'REFERRAL_VIEW',
  'ACCOUNT_VIEW',
  'ACCOUNT_UPDATE',
  'TRANSACTION_VIEW',
  'MESSAGE_RULE_SET',
  'GIFT_RECORDS_VIEW',
]

const StaffCreate: React.FC = () => {
  const [createStaff, { isSuccess }] = useStaffCreateMutation()
  const selectLanguage = useSelector(selectLanguageData)

  const [formData, setFormData] = useState<StaffCreateDto>({
    user: {
      email: '',
      password: '',
      language: selectLanguage.backendName,
    },
    permissions: allPermissions, // изначально все разрешения установлены
  })

  const [allPermissionsChecked, setAllPermissionsChecked] = useState(true)

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target
    setFormData(prevState => ({
      ...prevState,
      user: {
        ...prevState.user,
        [name]: type === 'checkbox' ? checked : value,
      },
    }))
  }

  const handlePermissionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target
    setFormData(prevState => {
      const permissions = prevState.permissions || []
      if (checked) {
        return {
          ...prevState,
          permissions: [...permissions, value as Permission],
        }
      } else {
        return {
          ...prevState,
          permissions: permissions.filter(permission => permission !== value),
        }
      }
    })
  }

  const handleToggleAllPermissions = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target
    setAllPermissionsChecked(checked)
    if (checked) {
      setFormData(prevState => ({
        ...prevState,
        permissions: allPermissions,
      }))
    } else {
      setFormData(prevState => ({
        ...prevState,
        permissions: [],
      }))
    }
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      await createStaff({ staffCreateDto: formData })
    } catch (error) {
      console.error('Failed to create staff', error)
    }
  }

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Staff created')
    }
  }, [isSuccess])

  return (
    <div className='staff'>
      <h2>Create Staff</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Email:</label>
          <input type='email' name='email' value={formData.user.email} onChange={handleInputChange} required />
        </div>
        <div>
          <label>Password:</label>
          <input type='password' name='password' value={formData.user.password} onChange={handleInputChange} required />
        </div>
        <div>
          <label>
            <input type='checkbox' checked={allPermissionsChecked} onChange={handleToggleAllPermissions} />
            Toggle All Permissions
          </label>
        </div>
        <div>
          <label>Permissions:</label>
          <div className='permissions'>
            {allPermissions.map(permission => (
              <label key={permission}>
                <input
                  type='checkbox'
                  value={permission}
                  checked={formData.permissions?.includes(permission) || false}
                  onChange={handlePermissionChange}
                />
                {permission}
              </label>
            ))}
          </div>
        </div>
        <button type='submit'>Create Staff</button>
      </form>
    </div>
  )
}

export default StaffCreate
