import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { NotificationResponse, CursorPageNotificationResponse } from 'store/apiMain/mainApi'
import { INotificationsStore } from 'store/reducer/notifications/types'
import { RootState } from 'store/types'

// Initial state
const initialState: INotificationsStore = {
  unreadCount: 0,
  notifications: {
    content: [],
    token: undefined,
    size: 0,
    pageSize: 6,
    isEnd: false,
  },
}

// Slice
const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setUnreadCount(state, action: PayloadAction<number>) {
      state.unreadCount = action.payload
    },
    incrementUnreadCount(state) {
      state.unreadCount += 1
    },
    decrementUnreadCount(state) {
      if (state.unreadCount > 0) {
        state.unreadCount -= 1
      }
    },
    addNotifications(state, action: PayloadAction<NotificationResponse[]>) {
      if (!state.notifications) {
        state.notifications = {
          content: [],
          token: undefined,
          size: 0,
          pageSize: 6,
          isEnd: false,
        }
      }

      const existingIds = new Set(state.notifications.content.map(notification => notification.id))
      const newNotifications = action.payload.filter(notification => !existingIds.has(notification.id))

      state.notifications.content = [...state.notifications.content, ...newNotifications]
      state.notifications.size = state.notifications.content.length
    },
    addNotification(state, action: PayloadAction<NotificationResponse>) {
      if (!state.notifications) {
        state.notifications = {
          content: [],
          token: undefined,
          size: 0,
          pageSize: 6,
          isEnd: false,
        }
      }

      const newNotification = action.payload
      const existingIds = new Set(state.notifications.content.map(notification => notification.id))

      if (!existingIds.has(newNotification.id)) {
        state.notifications.content.unshift(newNotification) // Add to the beginning
        state.notifications.size = state.notifications.content.length
      }
    },
    markNotificationsAsRead(state, action: PayloadAction<number[]>) {
      if (state.notifications && Array.isArray(state.notifications.content)) {
        const updatedContent = state.notifications.content.map(notification => {
          if (action.payload.includes(notification.id)) {
            return { ...notification, isRead: true }
          }
          return notification
        })

        return {
          ...state,
          notifications: {
            ...state.notifications,
            content: updatedContent,
          },
        }
      }

      // Если структура состояния неожиданная, возвращаем исходное состояние
      return state
    },
    resetNotifications(state) {
      state.notifications = {
        content: [],
        token: undefined,
        size: 0,
        pageSize: 6,
        isEnd: false,
      }
    },
  },
})

export const {
  addNotification,
  addNotifications,
  setUnreadCount,
  incrementUnreadCount,
  decrementUnreadCount,
  resetNotifications,
  markNotificationsAsRead,
} = notificationSlice.actions

export default notificationSlice.reducer
