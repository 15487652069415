import React, { useState } from 'react'
import { useModelRequestGetAllQuery } from 'store/apiMain/mainApi'
import './css/index.scss'
import Item from './Item/Item'

type StateType = 'CREATE_NEW' | 'UPDATE_EXISTED' | ''

const ModelsRequest: React.FC = () => {
  const [stateFilter, setStateFilter] = useState<StateType>('')

  const { data, error, isLoading } = useModelRequestGetAllQuery(
    {
      state: stateFilter || undefined,
      page: undefined,
      pageSize: undefined,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  )
  if (isLoading) {
    return <div>Loading...</div>
  }

  // Если возникла ошибка, отображаем сообщение об ошибке
  if (error) {
    return <div>Error:</div>
  }

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setStateFilter(event.target.value as StateType)
  }

  return (
    <div className='clients'>
      <div className='user-data'>
        <select value={stateFilter} onChange={handleChange}>
          <option value=''>All</option>
          <option value='CREATE_NEW'>Create new</option>
          <option value='UPDATE_EXISTED'>Update existing</option>
        </select>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>createdAt</th>
              <th>createdBy</th>
              <th>modelUserId</th>
              <th>state</th>
            </tr>
          </thead>
          <tbody>
            {data?.content?.map(e => {
              return (
                <React.Fragment key={e?.request?.id}>
                  <Item item={e} />
                </React.Fragment>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ModelsRequest
