import React from 'react'
import { SvgTelegram, SvgWhatsapp } from '../../images/svg'
import { setMessagesSelectedId, setMessagesSelectedStaffTrue } from 'store/reducer/messages/reducer'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
const Contact = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  return (
    <>
      <div className='info'>
        <div className='info-block'>
          <h2>Нужна помощь?</h2>
          <p>
            Мы очень ценим каждого клиента и благодарны за то, что Вы выбрали VirtualHouse. Чтобы получить квалифицированную помощь, пожалуйста,
            свяжитесь с нами одним из нижеперечисленных способом. Мы доступны круглосуточно, 7 дней в неделю!
          </p>
          <div className='contact'>
            <div className='contact-block'>
              <div className='contact-block_left'>
                <h2>Свяжитесь через сайт</h2>
                <p>Для быстрой помощи, вы можете задать сообщение в чатах(Нужно быть залогиненым)</p>
                <button
                  onClick={() => {
                    // @ts-ignore
                    dispatch(setMessagesSelectedId(`CREATE_NEW_CHAT_STAFF`))
                    dispatch(setMessagesSelectedStaffTrue())
                    navigate('/messages')
                  }}
                >
                  Написать сообщение
                </button>
              </div>
              <div className='contact-block_right'>
                <h2>Помощь по электронной почте</h2>
                <p>Пожалуйста, свяжитесь с нами, если хотите получить ответ по электронной почте.</p>
                <button>Послать нам сообщение</button>
              </div>
            </div>
            <div className='contact-block_center'>
              <h2>Связаться через онлайн сети</h2>
              <button>
                Telegram
                <SvgTelegram />
              </button>
              <button>
                WhatsApp
                <SvgWhatsapp />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contact
