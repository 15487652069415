import React, { FC } from 'react'
import { Link, useLocation } from 'react-router-dom'

interface TabProps {
  to: string
  label: string
}

const Tab: FC<TabProps> = ({ to, label }) => {
  const location = useLocation()
  const isActive = location.pathname.startsWith(`/model/profile/${to}`)

  return (
    <li>
      <Link to={to} className={isActive ? 'active' : ''}>
        {label}
      </Link>
    </li>
  )
}

export default Tab
