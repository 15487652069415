import React, { useEffect, useRef } from 'react'
// @ts-ignore
import Croppie from 'croppie'
import 'croppie/croppie.css'

const ImageCropper = ({ imageUrl, onCrop }: any) => {
  const croppieRef = useRef(null)
  const croppieInstance = useRef(null) // Use useRef to hold the instance of Croppie

  useEffect(() => {
    const image = new Image()
    image.onload = () => {
      if (croppieRef.current && !croppieInstance.current) {
        croppieInstance.current = new Croppie(croppieRef.current, {
          viewport: { width: 200, height: 200, type: 'circle' },
          boundary: { width: 300, height: 300 },
          showZoomer: true,
          enableOrientation: true,
        })

        // Bind image to croppie
        // @ts-ignore
        croppieInstance.current.bind({
          url: imageUrl,
        })

        // Listen for updates to the croppie and automatically update the crop result
        // @ts-ignore
        croppieInstance.current.element.addEventListener('update', () => {
          updateCropPreview()
        })
      }
    }
    image.src = imageUrl
    image.onerror = error => {
      console.error('Error loading image:', error)
    }
    return () => {
      if (croppieInstance.current) {
        // @ts-ignore
        croppieInstance.current.destroy()
        croppieInstance.current = null
      }
    }
  }, [imageUrl])

  const updateCropPreview = () => {
    croppieInstance.current
      // @ts-ignore
      ?.result({
        type: 'blob',
        quality: 1,
        circle: false,
      })
      .then((blob: any) => {
        onCrop(blob)
      })
      .catch((error: any) => console.error(error))
  }

  // Removed handleCrop function as cropping is now automatic

  return (
    <div>
      <div ref={croppieRef}></div>
      {/* Removed Crop button as cropping is now automatic */}
    </div>
  )
}

export default ImageCropper
