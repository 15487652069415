/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAccountControllerStore } from './types'
import { AccountResponse } from '../../apiMain/mainApi'

// ==========================================:
export const initialState: IAccountControllerStore = {
  account: null,
  accountClients: {},
}

// ==========================================:
const accountControllerSlice = createSlice({
  name: '@@accountController',
  initialState,
  reducers: {
    setAccountController: (state, action: PayloadAction<AccountResponse>) => {
      state.account = action.payload
    },
    setAccountClient: (state, action: PayloadAction<{ [clientId: string]: AccountResponse }>) => {
      const newClients = action.payload
      Object.keys(newClients).forEach(clientId => {
        state.accountClients[clientId] = newClients[clientId]
      })
    },
  },
})

export default accountControllerSlice.reducer
export const { setAccountController, setAccountClient } = accountControllerSlice.actions
