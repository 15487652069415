// TimeStartStop.tsx
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './scss/index.scss'
import { selectIsRunning, selectTime } from 'store/reducer/videoChatWithdrawalMoney/selector'
import { pauseTimer, resetTimer, startTimer, tick } from 'store/reducer/videoChatWithdrawalMoney/reducer'
import { formatTime } from '../../../../../../../utils/formatTime'

const TimeStartStop: React.FC = () => {
  const dispatch = useDispatch()
  const isRunning = useSelector(selectIsRunning)
  const time = useSelector(selectTime)

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null

    if (isRunning) {
      interval = setInterval(() => {
        dispatch(tick())
      }, 1000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [isRunning, dispatch])

  const toggleTimer = () => {
    if (isRunning) {
      dispatch(pauseTimer())
    } else {
      dispatch(startTimer())
    }
  }

  return (
    <div className='timeStartStop'>
      <p>{formatTime(time)}</p>
      <button className='lk-button lk-chat-toggle' onClick={toggleTimer}>
        {isRunning ? 'Pause' : 'Start'}
      </button>
    </div>
  )
}

export default TimeStartStop
