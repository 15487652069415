import React from 'react'
import './scss/index.scss'
interface IProps {
  text: string
}

const TextTooltipInfo = ({ text }: IProps) => {
  return (
    <div className='TextTooltip'>
      <span>{text}</span>
    </div>
  )
}

export default TextTooltipInfo
