import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useUserGetFullMeQuery } from 'store/apiMain/mainApi'
import './scss/index.scss'

const Documents = () => {
  const navigate = useNavigate()
  const { data, isLoading, isError } = useUserGetFullMeQuery()
  return (
    <div className='main-info-documents'>
      <h2>Documents</h2>
      <p>Это секретные данные, если у вас поменялся паспорт или имя в паспорте обязательно обновите данные!</p>
      <button
        onClick={() => {
          navigate('/model/profile/documents/edit')
        }}
      >
        Редактировать
      </button>
    </div>
  )
}

export default Documents
