import { useEffect, useRef } from 'react'
import { ISubscribeResponse } from 'store/reducer/socket/types'

interface ISubscribeProps {
  userId?: string[]
  sendSubscribe: (subscribeToUserIds: string[]) => void
  subscribeUser: ISubscribeResponse | null
}

const useSubscribeToNewUsers = ({ userId, sendSubscribe, subscribeUser }: ISubscribeProps): void => {
  const previousDialogChatsIdRef = useRef<Set<string>>(new Set())

  useEffect(() => {
    if (userId?.length === 0) return

    const uniqueDialogChatsId = Array.from(new Set(userId))

    const subscribedUserIds = subscribeUser?.body?.map(user => user.userId) || []

    const filteredDialogChatsId = uniqueDialogChatsId.filter(id => !subscribedUserIds.includes(id) && !previousDialogChatsIdRef.current.has(id))

    if (filteredDialogChatsId.length === 0) return

    const chunkSize = 50
    for (let i = 0; i < filteredDialogChatsId.length; i += chunkSize) {
      const chunk = filteredDialogChatsId.slice(i, i + chunkSize)
      sendSubscribe(chunk)
    }

    filteredDialogChatsId.forEach(id => previousDialogChatsIdRef.current.add(id))
  }, [userId, sendSubscribe, subscribeUser])
}

export default useSubscribeToNewUsers
