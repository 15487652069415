import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setMessagesSelectedId, setMessagesSelectedStaffFalse, setMessagesSelectedUser } from '../../../../../store/reducer/messages/reducer'
import ImgMessages from '../../ImgMessages/ImgMessages'
import Status from '../../../../../UI/Status/Status'
import Typing from '../../Typing/Typing'
import RenderGiftIconAndName from '../../../../../utils/RenderGiftIconAndName/RenderGiftIconAndName'
import dataFormatMessages from '../../../../../utils/dataFormatMessages'
import renderReadStatus from '../../../../../utils/renderReadStatus/renderReadStatus'
import { findMatchingContent } from '../../func/findMatchingContent'

interface ChatItemProps {
  chat: any
  role: any
  userMe: any
  searchName: any
  filterOnline: any
  filterFavorites: any
  subscribeUser: any
  dataFriends: any
  selectedChatType: any
  dataClient: any
  dataModel: any
  dataStaff: any
}

const Item: React.FC<ChatItemProps> = React.memo(
  ({
    chat,
    role,
    userMe,
    searchName,
    filterOnline,
    filterFavorites,
    subscribeUser,
    dataFriends,
    selectedChatType,
    dataClient,
    dataModel,
    dataStaff,
  }) => {
    const dispatch = useDispatch()
    const messagesSelectedId = useSelector((state: any) => state.messages.messagesSelectedId)
    const { matchingClientContent, matchingModelContent, matchingStaffContent } = findMatchingContent(
      chat,
      dataClient,
      dataModel,
      dataStaff,
      selectedChatType,
      role,
    )
    // staffOnlineEveryday нужна для того чтобы админ был в админке всегда online
    const staffOnlineEveryday = chat.privateChat?.matchingContent?.staff !== undefined
    const onlineStatus =
      subscribeUser?.body?.find((user: any) => user.userId === chat.privateChat?.matchingContent?.user?.id)?.activityStatus ?? 'noStatus'
    const isOnline = onlineStatus === 'ONLINE' || onlineStatus === 'AWAY'
    const shouldDisplayOnline = filterOnline ? isOnline : true
    const isUserInFavorites = (dataFriends: any, userId: any) =>
      dataFriends?.content?.some((item: any) => item.relationship.isClientFavorite && item.model.user.id === userId)
    const isFavorite = isUserInFavorites(dataFriends, chat.privateChat?.matchingContent?.user?.id)
    const shouldDisplayFavorite = filterFavorites ? isFavorite : true
    const shouldDisplay = shouldDisplayFavorite && shouldDisplayOnline

    let booleanAttractAttention = true
    if (role === 'MODEL' && chat.privateChat?.clientAttractAttentionCount > 0) {
      booleanAttractAttention = !chat.privateChat?.approvedByModel && chat.privateChat?.clientAttractAttentionCount > 0
    }

    if (!shouldDisplay || !booleanAttractAttention) return null

    const handleClick = React.useCallback(() => {
      dispatch(setMessagesSelectedStaffFalse())
      dispatch(setMessagesSelectedId(`${chat.privateChat.id}`))
      if (matchingClientContent) {
        dispatch(setMessagesSelectedUser(matchingClientContent))
      } else if (matchingModelContent) {
        dispatch(setMessagesSelectedUser(matchingModelContent))
      } else if (matchingStaffContent) {
        dispatch(setMessagesSelectedUser(matchingStaffContent))
      }
    }, [dispatch, chat, matchingClientContent, matchingModelContent, matchingStaffContent])

    return (
      <div
        className={`sidebar-content__item item 
                    ${messagesSelectedId === chat.privateChat.id ? 'active' : ''} 
                    ${chat?.privateChat?.clientAttractAttentionCount && messagesSelectedId !== chat.privateChat.id ? 'needsToAddFriend' : ''}`}
        onClick={handleClick}
      >
        <div className='item__img'>
          <div className='PhotoProfileMessage'>
            <div className='box-img'>
              <ImgMessages
                recipientRole={chat.privateChat?.matchingContent?.user?.role}
                idClient={chat.privateChat?.matchingContent?.client?.photoId}
                imgUrl={chat.privateChat?.matchingContent?.profilePhoto?.file.url}
                data={chat}
              />
            </div>
          </div>
        </div>
        <div className='item__content'>
          <div>
            <h6 className='item__content-name'>
              {chat.privateChat?.matchingContent?.client?.name ||
                chat.privateChat?.matchingContent?.model?.name ||
                chat.privateChat?.matchingContent?.staff?.name}
            </h6>
          </div>
          <Typing typing={chat?.privateChat?.chatTyping} privateChatId={chat?.privateChat?.id} targetUserId=''>
            {chat.lastMessage?.type === 'SYSTEM_GIFT_RECEIVED' && chat.lastMessage.body ? (
              <RenderGiftIconAndName index={JSON.parse(chat.lastMessage.body).giftId} name={false} />
            ) : (
              <p className='item__content-text'>{chat.lastMessage?.body}</p>
            )}
          </Typing>
        </div>
        {chat.unreadMessageCount > 0 && (
          <div className='unReadCount'>
            <p>{chat.unreadMessageCount}</p>
          </div>
        )}
        <div className='item__detailes-timeNew'>
          <p>{dataFormatMessages(chat.lastMessage?.createdAt)}</p>
        </div>
        <div className='item__detailes-renderRead'>{renderReadStatus(role, chat.lastMessage, userMe)}</div>
        <div className='item__detailes-online'>
          <Status status={staffOnlineEveryday && role === 'STAFF' ? 'ONLINE' : onlineStatus} />
        </div>
      </div>
    )
  },
  (prevProps, nextProps) => {
    for (let key in prevProps) {
      if (prevProps[key] !== nextProps[key]) {
        // console.log(`Prop "${key}" has changed`, {
        //   prev: prevProps[key],
        //   next: nextProps[key],
        // })
      }
    }
    return (
      prevProps.chat === nextProps.chat &&
      prevProps.role === nextProps.role &&
      prevProps.userMe === nextProps.userMe &&
      prevProps.searchName === nextProps.searchName &&
      prevProps.filterOnline === nextProps.filterOnline &&
      prevProps.filterFavorites === nextProps.filterFavorites &&
      prevProps.subscribeUser === nextProps.subscribeUser &&
      prevProps.dataFriends === nextProps.dataFriends &&
      prevProps.selectedChatType === nextProps.selectedChatType &&
      prevProps.dataClient === nextProps.dataClient &&
      prevProps.dataModel === nextProps.dataModel &&
      prevProps.dataStaff === nextProps.dataStaff
    )
  },
)

export default React.memo(Item)
