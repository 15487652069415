import React, { FC } from 'react'
// @ts-ignore
import Popup from 'reactjs-popup'
import { useDispatch } from 'react-redux'
import { IPopUp } from '../types'
import './scss/index.scss'
import { ageConfirmationCounter } from 'store/reducer/age/reducer'
import Language from 'UI/Language/Language'
import useLockBodyScroll from 'hooks/useLockBodyScroll'

const AgeBlockPopUp: FC<IPopUp> = ({ open, closeModal }) => {
  const dispatch = useDispatch()
  useLockBodyScroll(open)

  return (
    <Popup closeOnDocumentClick={false} closeOnEscape={false} open={open}>
      <div className='modal'>
        <div className='popup'>
          <div className='age-block'>
            <div className='age-block__block'>
              <div className='age-block__header'>
                <p className='age-block__age'>
                  <span>18+</span>
                </p>
                <div className='age-block__language'>
                  <Language />
                </div>
              </div>
              <div className='age-block__box'>
                <p>
                  Этот веб-сайт предоставляет доступ к материалам, информации, содержанию и комментариям сексуального характера (под названием
                  «Отображение материалов сексуального характера»). Доступ к этому сайту может привести к тому, что досядут 18 процентов, которые
                  покажутся вам в вашей юрисдикции, и которые вы просматриваете, и можете просматривать материалы сексуального характера. На нынешнем
                  сайте защищён, также важно видеть материалы сексуального характера образной жизни для себя, а также просматривать открытые материалы
                  сексуального характера, выбранные в соответствии с нормами и законами.
                </p>
                <p>
                  <span>Вирт Хаус содержит материалы для взрослых. На сайте вы подтверждаете, что вам больше 18 лет.</span>
                </p>
              </div>
              <div className='age-block__buttons'>
                <button type='button'>
                  <a href='https://www.google.com'>Покинуть сайт</a>
                </button>
                <button
                  onClick={() => {
                    closeModal()
                    dispatch(ageConfirmationCounter())
                  }}
                  type='submit'
                >
                  Мне уже 18 лет, войти на сайт
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default AgeBlockPopUp
