import React, { useRef, useState } from 'react'
import './scss/index.scss'

const WebcamRecorder: React.FC = () => {
  const [recording, setRecording] = useState<boolean>(false)
  const [videoURL, setVideoURL] = useState<string | null>(null)
  const [recorded, setRecorded] = useState<boolean>(false)
  const videoRef = useRef<HTMLVideoElement | null>(null)

  const startRecording = async () => {
    try {
      // Очищаем предыдущую запись
      setVideoURL(null)
      setRecorded(false)

      const stream = await navigator.mediaDevices?.getUserMedia({
        video: true,
        audio: true,
      })
      videoRef.current!.srcObject = stream
      videoRef.current!.play()

      const recorder = new MediaRecorder(stream)
      let recordedChunks: BlobPart[] = []

      recorder.ondataavailable = event => {
        if (event.data.size > 0) {
          recordedChunks.push(event.data)
        }
      }

      recorder.onstop = () => {
        const blob = new Blob(recordedChunks, { type: 'video/webm' })
        const url = URL.createObjectURL(blob)
        setVideoURL(url)
        setRecorded(true)
      }

      recorder.start()
      setRecording(true)
    } catch (error) {
      console.error('Error starting recording:', error)
    }
  }

  const stopRecording = () => {
    const recorder = (videoRef.current!.srcObject as MediaStream).getTracks()
    recorder.forEach(track => track.stop())
    setRecording(false)
  }

  return (
    <div className='webcam'>
      <div className='webcam-container'>
        <video className={!recorded ? 'display' : 'none'} ref={videoRef}></video>
        {recording ? <div className='overlay'></div> : null}
        {recording ? (
          <button type={'button'} onClick={stopRecording}>
            Stop Recording
          </button>
        ) : recorded ? (
          <>
            <button type={'button'} onClick={startRecording}>
              Записать ещё раз
            </button>
            <h2>Recorded Video</h2>
            {videoURL && <video src={videoURL} width='480' height='360' controls></video>}
            {videoURL && (
              <a href={videoURL} download='recorded.webm'>
                Download recorded video
              </a>
            )}
          </>
        ) : (
          <button type='button' onClick={startRecording}>
            Start Recording
          </button>
        )}
      </div>
    </div>
  )
}
export default WebcamRecorder
