import React, { useEffect, useState } from 'react'
import Item from './Item/Item'
import './css/index.scss'
import { ModelGetAllApiArg, useModelGetAllQuery } from 'store/apiMain/mainApi'

const Models = () => {
  const [showDeleted, setShowDeleted] = useState(true)
  const [showDisabled, setShowDisabled] = useState(true)
  const [nameFilter, setNameFilter] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [payload, setPayload] = useState<ModelGetAllApiArg>({
    languages: undefined,
    services: undefined,
    name: undefined,
    isHaveRelationshipWithCurrentClient: undefined,
    relationshipStatuses: undefined,
    isOnline: undefined,
    showDeleted: undefined,
    showDisabled: undefined,
    ids: undefined,
    page: undefined,
    pageSize: 99,
  })

  const { data, isLoading, isError } = useModelGetAllQuery({ ...payload })

  useEffect(() => {
    setPayload({
      ...payload,
      showDeleted,
      showDisabled,
      name: nameFilter || undefined,
    })
  }, [showDeleted, showDisabled, nameFilter, currentPage])

  return (
    <div className='clients'>
      <div className='user-data'>
        <div className='filter-options'>
          <p>Filter by Name</p>
          <input type='text' value={nameFilter} onChange={e => setNameFilter(e.target.value)} />

          <p>Show Deleted</p>
          <input type='checkbox' checked={showDeleted} onChange={() => setShowDeleted(!showDeleted)} />

          <p>Show Disabled</p>
          <input type='checkbox' checked={showDisabled} onChange={() => setShowDisabled(!showDisabled)} />
        </div>

        {isLoading && <p>Loading...</p>}
        {isError && <p>Error loading data.</p>}

        {!isLoading && !isError && (
          <>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>BirthYear</th>
                  <th>CreatedAt</th>
                  <th>Delete</th>
                  <th>Disabled</th>
                  <th>Settings profile</th>
                  <th>Settings</th>
                </tr>
              </thead>
              <tbody>{data?.content?.map((e: any) => <Item key={e.id} item={e} />)}</tbody>
            </table>
          </>
        )}
      </div>
    </div>
  )
}

export default Models
