import React from 'react'
import { useNavigate } from 'react-router-dom'
import dataFormatMessages from '../../../../utils/dataFormatMessages'
import CopyButton from '../../../../UI/CopyButton/CopyButton'

const Item: React.FC<any> = ({ item }) => {
  const navigate = useNavigate()
  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
      <tr onClick={() => navigate(`/admin/model-request/${item?.request.id}`)}>
        <td id='id'>{item.request.id}</td>
        <td id='createdAt'>{dataFormatMessages(item.request.createdAt)}</td>
        <td id='createdBy'>
          {item.request.createdBy} <CopyButton textToCopy={item.request.createdBy} />
        </td>
        <td id='email'>{item.user.email}</td>
        <td id='userId'>{item.request.state}</td>
      </tr>
    </>
  )
}

export default Item
