import React, { useEffect, useState } from 'react'
// import './index.scss'
import { RelationshipGetCountApiArg, useAccountGetByIdQuery, useUserGetFullMeQuery } from 'store/apiMain/mainApi'
import { useDispatch, useSelector } from 'react-redux'
import Client from '../Block/Client'
import Model from '../Block/Model'
import ModelStatusRegister from '../Block/ModelStatusRegister'
import { setAccountController } from '../../../../store/reducer/accountController/reducer'
import User from '../Block/User'
import { selectRole } from 'store/reducer/token/selector'
import { useParams } from 'react-router-dom'
import HeaderVideo from '../Block/HeaderVideo'

const HeaderRightAuthorizedMobile = () => {
  const dispatch = useDispatch()
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleMenuToggle = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    setIsMenuOpen(!isMenuOpen)
  }

  const payloadRequest: RelationshipGetCountApiArg = {
    statuses: ['FRIEND_REQUEST'],
  }
  const { roomId } = useParams()

  const { data, refetch } = useUserGetFullMeQuery()
  const selectedRole = useSelector(selectRole)
  const funcRole = () => {
    if (selectedRole !== undefined) {
      return selectedRole
    } else {
      return 'USER'
    }
  }
  const dataUser = {
    statusRegister: data?.model?.name === undefined && data?.client?.name === undefined ? 'step1' : '',
    role: funcRole(),
    photoId: data?.client?.photoId,
    name: data?.client?.name || data?.model?.name,
  }

  const payloadAmount = {
    userId: data?.userData?.user?.id,
  }
  const { data: dataAmount, refetch: refetchAmount } = useAccountGetByIdQuery(payloadAmount, { skip: !data?.userData?.user?.id })

  useEffect(() => {
    if (dataAmount) {
      dispatch(setAccountController(dataAmount))
    }
  }, [dataAmount])

  useEffect(() => {
    // Функция, которая устанавливает или убирает запрет на прокрутку body
    const handleBodyScroll = () => {
      if (isMenuOpen) {
        // Запретить прокрутку
        document.documentElement.style.overflow = 'hidden'
      } else {
        // Разрешить прокрутку
        document.documentElement.style.overflow = 'auto'
      }
    }

    // Вызываем функцию при монтировании компонента
    handleBodyScroll()

    // Вызываем функцию при изменении значения isMenuOpen
    // для обновления запрета на прокрутку
    return () => {
      document.documentElement.style.overflow = 'auto' // Восстанавливаем прокрутку при размонтировании
    }
  }, [isMenuOpen])

  const headerMenuFunc = () => {
    if (roomId) {
      return <HeaderVideo isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} data={data} dataUser={dataUser} handleMenuToggle={handleMenuToggle} />
    }
    if (dataUser?.role === 'USER') {
      return <User isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} data={data} dataUser={dataUser} handleMenuToggle={handleMenuToggle} />
    }
    if (dataUser?.role === 'MODEL' && dataUser?.statusRegister !== 'step1') {
      return <Model isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} data={data} dataUser={dataUser} handleMenuToggle={handleMenuToggle} />
    }
    if (dataUser?.role === 'CLIENT' && dataUser?.statusRegister !== 'step1') {
      return <Client isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} data={data} dataUser={dataUser} handleMenuToggle={handleMenuToggle} />
    }
    if ((dataUser?.statusRegister === 'step1' || dataUser?.statusRegister === 'step2') && dataUser.role === 'MODEL') {
      return (
        <ModelStatusRegister
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
          data={data}
          dataUser={dataUser}
          handleMenuToggle={handleMenuToggle}
        />
      )
    }
  }
  return (
    <div className='header-menu'>
      <div className='viewport'>{headerMenuFunc()}</div>
    </div>
  )
}
export default HeaderRightAuthorizedMobile
