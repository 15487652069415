import React from 'react'
import './scss/index.scss'
import { t } from 'i18next'

const NoId = () => {
  return (
    <div className='NoId'>
      <div className='NoId-block'>
        <p>{t('message.selectChat')}</p>
      </div>
    </div>
  )
}

export default NoId
