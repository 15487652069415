import React from 'react'
import { useNavigate } from 'react-router-dom'

interface DataRoom {
  id: string
  ownerUserId: string
  ownerUserRole: string
  isActive: boolean
  createdAt: string
  privateChatId?: string
}

interface IProps {
  dataRooms: DataRoom[]
}

const Item: React.FC<IProps> = ({ dataRooms }) => {
  const navigate = useNavigate()
  return (
    <div className='data-rooms'>
      {dataRooms.map(room => (
        <div className='data-room' key={room.id}>
          <h2>Room ID: {room.id}</h2>
          <p>Owner User ID: {room.ownerUserId}</p>
          <p>Owner User Role: {room.ownerUserRole}</p>
          <p>Is Active: {room.isActive ? 'Yes' : 'No'}</p>
          <p>Created At: {new Date(room.createdAt).toLocaleString()}</p>
          {room.privateChatId && <p>Private Chat ID: {room.privateChatId}</p>}
          <button
            onClick={() => {
              navigate(`/room/${room.id}`)
            }}
          >
            Подключится
          </button>
        </div>
      ))}
    </div>
  )
}

export default Item
