import React, { useEffect, useState } from 'react'
import { SvgProfileMens1 } from '../../../../../images/svg'
import { useDispatch, useSelector } from 'react-redux'
import { selectRole } from '../../../../../store/reducer/token/selector'
import { useGetFileMutation } from 'store/apiMain/emptyApi'
import { RelationshipDeleteByIdApiArg, RelationshipFullDto, RelationshipPartialUpdateByIdApiArg } from 'store/apiMain/mainApi'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { photoViewUser } from '../../../../../UI/PhotoViewUser/PhotoViewUser'
import { sendMessages } from 'store/reducer/messages/reducer' // Import useTranslation

const Item = ({ role, item, filter, friendsDelete, friendsUpdate }: any) => {
  const { t } = useTranslation() // Initialize the translation hook
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const dispatchRoleText = (roleActions: any) => {
    if (item.relationship.status === 'FRIEND_REQUEST') {
      if (roleActions === role) {
        return t('item.youSentRequest')
      } else {
        return t('item.youReceivedRequest')
      }
    }
    if (item.relationship.status === 'BLOCKED') {
      if (roleActions === role) {
        return t('item.youBlocked')
      } else {
        return t('item.youBlockedBy')
      }
    }
    if (item.relationship.status === 'FRIENDS') {
      return t('item.youAreFriends')
    }
  }

  const actionButton = (text: any, item: RelationshipFullDto) => {
    if (text === 'FRIENDS') {
      return (
        <>
          <button
            onClick={event => {
              event.stopPropagation()
              if (item.relationship?.clientId && item.relationship?.modelId) {
                const obg = {
                  recipientId: item.relationship?.clientId,
                  senderId: item.relationship?.modelId,
                }
                dispatch(sendMessages(obg))
                navigate('/messages')
              }
            }}
            className='item__button-blue'
          >
            {t('item.send')}
          </button>
          <div className='item__button_actions'>
            <button
              onClick={event => {
                event.stopPropagation()
                const payloadButton: RelationshipPartialUpdateByIdApiArg = {
                  // @ts-ignore
                  relationshipId: item.relationship.id,
                  relationshipPartialUpdateDto: {
                    type: 'BLOCK',
                  },
                }
                friendsUpdate(payloadButton)
              }}
              className='item__button-red'
            >
              {t('item.block')}
            </button>
            <button
              onClick={event => {
                event.stopPropagation()
                const payloadButton: RelationshipDeleteByIdApiArg = {
                  // @ts-ignore
                  relationshipId: item.relationship.id,
                }
                friendsDelete(payloadButton)
              }}
              className='item__button-grey'
            >
              {t('item.delete')}
            </button>
          </div>
        </>
      )
    }
    if (text === 'FRIEND_REQUEST') {
      if (item.relationship?.initiatedBy === role) {
        return (
          <div className='item__button'>
            <button
              onClick={event => {
                event.stopPropagation()
                const payloadButton: RelationshipDeleteByIdApiArg = {
                  // @ts-ignore
                  relationshipId: item.relationship.id,
                }
                friendsDelete(payloadButton)
              }}
              className='item__button-grey'
            >
              {t('item.cancelRequest')}
            </button>
          </div>
        )
      } else {
        return (
          <div className='item__button'>
            <button
              style={{ marginBottom: '15px' }}
              onClick={event => {
                event.stopPropagation()
                const payloadButton: RelationshipPartialUpdateByIdApiArg = {
                  // @ts-ignore
                  relationshipId: item.relationship.id,
                  relationshipPartialUpdateDto: {
                    type: 'APPROVE_FRIEND_REQUEST',
                  },
                }
                friendsUpdate(payloadButton)
              }}
              className='item__button-blue'
            >
              {t('item.accept')}
            </button>
            <button
              onClick={event => {
                event.stopPropagation()
                const payloadButton: RelationshipDeleteByIdApiArg = {
                  // @ts-ignore
                  relationshipId: item.relationship.id,
                }
                friendsDelete(payloadButton)
              }}
              className='item__button-grey'
            >
              {t('item.delete')}
            </button>
          </div>
        )
      }
    }
    if (text === 'ONLINE') {
      return (
        <>
          <button
            onClick={() => {
              navigate('/messages')
            }}
            className='item__button-blue'
          >
            {t('item.send')}
          </button>
          <div className='item__button_actions'>
            <button
              onClick={event => {
                event.stopPropagation()
                const payloadButton: RelationshipPartialUpdateByIdApiArg = {
                  // @ts-ignore
                  relationshipId: item.relationship.id,
                  relationshipPartialUpdateDto: {
                    type: 'BLOCK',
                  },
                }
                friendsUpdate(payloadButton)
              }}
              className='item__button-red'
            >
              {t('item.block')}
            </button>
            <button
              onClick={event => {
                event.stopPropagation()
                const payloadButton: RelationshipDeleteByIdApiArg = {
                  // @ts-ignore
                  relationshipId: item.relationship.id,
                }
                friendsDelete(payloadButton)
              }}
              className='item__button-grey'
            >
              {t('item.delete')}
            </button>
          </div>
        </>
      )
    }
    if (text === 'FAVORITES') {
      return (
        <>
          <button
            onClick={event => {
              event.stopPropagation()
              if (item.relationship?.clientId && item.relationship?.modelId) {
                const obg = {
                  recipientId: item.relationship?.clientId,
                  senderId: item.relationship?.modelId,
                }
                dispatch(sendMessages(obg))
                navigate('/messages')
              }
            }}
            className='item__button-blue'
          >
            {t('item.send')}
          </button>
          <div className='item__button_actions'>
            <button
              onClick={event => {
                event.stopPropagation()
                const payloadButton: RelationshipPartialUpdateByIdApiArg = {
                  // @ts-ignore
                  relationshipId: item.relationship.id,
                  relationshipPartialUpdateDto: {
                    type: 'BLOCK',
                  },
                }
                friendsUpdate(payloadButton)
              }}
              className='item__button-red'
            >
              {t('item.block')}
            </button>
            <button
              onClick={event => {
                event.stopPropagation()
                const payloadButton: RelationshipDeleteByIdApiArg = {
                  // @ts-ignore
                  relationshipId: item.relationship.id,
                }
                friendsDelete(payloadButton)
              }}
              className='item__button-grey'
            >
              {t('item.delete')}
            </button>
          </div>
        </>
      )
    }
    if (text === 'BLOCKED' && role === item?.relationship?.initiatedBy) {
      return (
        <>
          <button
            onClick={event => {
              event.stopPropagation()
              const payloadButton: RelationshipDeleteByIdApiArg = {
                // @ts-ignore
                relationshipId: item.relationship.id,
              }
              friendsDelete(payloadButton)
            }}
            className='item__button-grey'
          >
            {t('item.unblock')}
          </button>
        </>
      )
    }
  }
  return (
    <div
      onClick={event => {
        event.stopPropagation()
        if (role === 'CLIENT') {
          // navigate(`/girl/${item.model?.model.identifier}`)
        }
        if (role === 'MODEL') {
          navigate(`/girl/${item.client.client.id}`)
        }
      }}
      key={item.relationship?.id}
    >
      <div className='inner-content__box-item item'>
        <div className='flex'>
          <div className='item__img'>
            {role !== 'MODEL' ? <img src={item?.model?.profilePhoto?.file?.url} alt='' /> : photoViewUser(item?.client?.client?.photoId)}
          </div>
          <div className='item__content'>
            <h6 className='item__content-title'>{role === 'CLIENT' ? item.model?.model?.name : item.client?.client?.name}</h6>
            <p className='item__content-text'>{dispatchRoleText(item.relationship?.initiatedBy)}</p>
          </div>
        </div>
        <div className='item__button'>{actionButton(filter, item)}</div>
      </div>
    </div>
  )
}

export default Item
