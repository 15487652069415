import React, { useEffect, useState } from 'react'
import './scss/index.scss'
import { TransactionResponse, useTransactionGetAllByUserIdQuery, useUserGetFullMeQuery } from 'store/apiMain/mainApi'
import { t } from 'i18next'
import { SvGArrowBlackLeft } from '../../../images/svg'
import { useNavigate } from 'react-router-dom'

const Transactional = () => {
  const { data: userMe } = useUserGetFullMeQuery()
  const userId = userMe?.userData?.user?.id

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')
  const { data, refetch, error, isLoading } = useTransactionGetAllByUserIdQuery({ userId, page, pageSize })

  useEffect(() => {
    refetch()
  }, [pageSize])

  const handleNextPage = () => {
    if (data && page < data.totalPages - 1) {
      setPage(page + 1)
    }
  }

  const handlePrevPage = () => {
    if (page > 0) {
      setPage(page - 1)
    }
  }

  const handlePageSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPageSize(parseInt(event.target.value, 10))
    setPage(0) // Reset to first page when page size changes
  }

  const navigate = useNavigate()
  return (
    <div className='TransactionalController'>
      <div
        onClick={() => {
          navigate(-1)
        }}
        className='back-arrow'
      >
        <SvGArrowBlackLeft />
        <p>Back</p>
      </div>
      <h1>Transactional Controller1</h1>
      {isLoading && <p>Loading...</p>}
      {error && <p>Error loading transactions</p>}

      {data?.content.length ? (
        <ul>
          {data.content.map((transaction: TransactionResponse) => (
            <li key={transaction.id}>
              <p>ID: {transaction.id}</p>
              <p>User ID: {transaction.userId}</p>
              <p>Created At: {new Date(transaction.createdAt).toLocaleString()}</p>
              <p>Coins Delta: {transaction.coinsDelta}</p>
              <p>Type: {transaction.type}</p>
              {transaction.details && <p>Details: {transaction.details}</p>}
            </li>
          ))}
        </ul>
      ) : (
        <p>No transactions found</p>
      )}
      <div className='pagination'>
        <button onClick={handlePrevPage} disabled={page === 0}>
          Previous
        </button>
        <span>
          {t('model.page')} {page + 1} of {data?.totalPages}
        </span>
        <button onClick={handleNextPage} disabled={!data || page >= data.totalPages - 1}>
          {t('model.next')}
        </button>
      </div>
      <div className='pageSizeSelector'>
        <label htmlFor='pageSize'>{t('model.pageSize')}: </label>
        <select id='pageSize' value={pageSize} onChange={handlePageSizeChange}>
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={9999}>9999</option>
        </select>
      </div>
    </div>
  )
}

export default Transactional
