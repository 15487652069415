import React, { useEffect, useState } from 'react'
import {
  ReferralGetAllApiArg,
  ReferralResponse,
  ReferralViewResponse,
  useReferralGetAllQuery,
  useReferralUpdateByIdMutation,
  useReferralCreateMutation,
  ReferralCreateApiResponse,
  ReferralCreateApiArg,
  ReferralUpdateByIdApiArg,
  useReferralDeleteByIdMutation,
} from 'store/apiMain/mainApi'
import Item from './Item/Item'
import './scss/index.scss'
import { toastSuccess } from '../../../elements/Notification/Notification'

interface ReferralControllerProps {
  nicknameContains?: string
  page?: number
  pageSize?: number
}

const ReferralController: React.FC<ReferralControllerProps> = () => {
  const [payload, setPayload] = useState<ReferralGetAllApiArg>({
    nicknameContains: '',
    page: 0,
    isDeleted: false,
    pageSize: 999999999,
  })

  const { data, refetch, error, isLoading } = useReferralGetAllQuery(payload)
  const [createReferral, { isSuccess: isSuccessCreate }] = useReferralCreateMutation()
  const [editReferral, { isSuccess: isSuccessEdit }] = useReferralUpdateByIdMutation()
  const [onDeleteReferral, { isSuccess: isSuccessDelete }] = useReferralDeleteByIdMutation()
  useEffect(() => {
    if (isSuccessCreate) {
      toastSuccess('Ссылка на реферала создана')
      refetch()
    }
  }, [isSuccessCreate])
  useEffect(() => {
    if (isSuccessEdit) {
      toastSuccess('Данные изменены')
      refetch()
    }
  }, [isSuccessEdit])
  useEffect(() => {
    if (isSuccessDelete) {
      toastSuccess('Реферал удален')
      refetch()
    }
  }, [isSuccessDelete])

  const [newReferral, setNewReferral] = useState<ReferralResponse>({
    nickname: '',
    userSource: '',
    description: '',
  })

  const updatePayload = (key: keyof ReferralGetAllApiArg, value: string | number | boolean | undefined) => {
    setPayload(prevPayload => ({
      ...prevPayload,
      [key]: value,
    }))
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setNewReferral(prevReferral => ({
      ...prevReferral,
      [name]: value,
    }))
  }

  const handleCreateReferral = async () => {
    try {
      const result: ReferralCreateApiResponse = await createReferral({
        // @ts-ignore
        referralCreateRequest: newReferral,
      }).unwrap()
      console.log('Referral created successfully:', result)
      setNewReferral({ nickname: '', userSource: '', description: '' })
    } catch (err) {
      console.error('Failed to create referral:', err)
    }
  }

  const handleUpdateReferral = (payload: ReferralUpdateByIdApiArg) => {
    try {
      const result = editReferral({ ...payload }).unwrap()
      console.log('Referral updated successfully:', result)
      setNewReferral({ nickname: '', userSource: '', description: '' })
    } catch (err) {
      console.error('Failed to update referral:', err)
    }
  }

  return (
    <div className='referal'>
      <h1>Referrals/Metrick</h1>
      <div className='referal-filter'>
        <h1>Filter</h1>
        <input
          type='text'
          value={payload.nicknameContains || ''}
          onChange={e => updatePayload('nicknameContains', e.target.value)}
          placeholder='Nickname Contains'
        />
        <input type='number' value={payload.page || 1} onChange={e => updatePayload('page', parseInt(e.target.value))} placeholder='Page' />
        <input
          type='number'
          value={payload.pageSize || 10}
          onChange={e => updatePayload('pageSize', parseInt(e.target.value))}
          placeholder='Page Size'
        />
        <select
          value={payload.isDeleted === undefined ? '' : payload.isDeleted ? 'true' : 'false'}
          onChange={e => {
            const value = e.target.value === '' ? undefined : e.target.value === 'true'
            updatePayload('isDeleted', value)
          }}
        >
          <option value=''>All (undefined)</option>
          <option value='true'>Deleted</option>
          <option value='false'>Not Deleted</option>
        </select>
      </div>

      <div className='referral-create'>
        <h2>Create New Referral</h2>
        <input type='text' name='nickname' value={newReferral.nickname} onChange={handleChange} placeholder='Nickname' />
        <input type='text' name='userSource' value={newReferral.userSource} onChange={handleChange} placeholder='User Source' />
        <textarea name='description' value={newReferral.description} onChange={handleChange} placeholder='Description' />
        <button onClick={handleCreateReferral}>Create Referral</button>
      </div>

      {isLoading && <p>Loading...</p>}
      {/*// @ts-ignore*/}
      {error && <p>Error: {error.message}</p>}
      <div className='referal-block'>
        {data &&
          data.content &&
          data.content.map((item: ReferralViewResponse) => (
            <Item key={item?.referral?.id} item={item} handleUpdateReferral={handleUpdateReferral} onDeleteReferral={onDeleteReferral} />
          ))}
      </div>
    </div>
  )
}

export default ReferralController
