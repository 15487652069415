import React from 'react'
import './scss/index.scss'
import { t } from 'i18next'

const Withdrawal = () => {
  return (
    <>
      <div className='withdrawal'>
        <h2>{t('profile.withdrawal')}</h2>
      </div>
    </>
  )
}

export default Withdrawal
