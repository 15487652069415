import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import AudioGirlView from 'UI/Audio/AudioGirlView'
import Status from '../../../../UI/Status/Status'
import './scss/index.scss'
import { ModelViewDto } from 'store/apiMain/mainApi'
import { selectTokenData } from 'store/reducer/token/selector'
import { toastInfo } from '../../../../elements/Notification/Notification'

interface IProps {
  item: ModelViewDto
  onlineStatus: any
}

const Item: React.FC<IProps> = ({ item, onlineStatus }) => {
  const navigate = useNavigate()

  const token = useSelector(selectTokenData)

  return (
    <div
      onClick={() => {
        if (!token?.access_token) {
          toastInfo('Нужно быть пользователем для просмотра')
          navigate(`/login`)
        } else {
          navigate(`/girl/${item?.model?.identifier}`)
        }
      }}
      className='girl__box'
    >
      <h1>{item?.model?.name}</h1>
      {/*// @ts-ignore*/}
      <img src={item?.photos[0]?.file?.url} alt='Girl' />
      {token?.access_token ? <Status status={onlineStatus} /> : null}
      <div className='girl__audio'>
        {item.audios !== undefined ? (
          <AudioGirlView
            audio={item.audios[0]}
            // @ts-ignore
            modelId={item.user.id}
            positionsButton='left'
            // @ts-ignore
            gradient={item?.model?.identifier % 4}
          />
        ) : null}
      </div>
      {/*// @ts-ignore*/}
      <div className={`gradient_${item?.model?.identifier % 4}`}></div>
    </div>
  )
}

export default Item
