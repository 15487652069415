import { useNavigate, useParams } from 'react-router-dom'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { LocalUserChoices, PreJoin } from '@livekit/components-react'
import ActiveRoom from '../ActiveRoom/ActiveRoom'
import '@livekit/components-styles'
import '@livekit/components-styles/prefabs'
import { useUserGetFullMeQuery } from 'store/apiMain/mainApi'

import './../../styles/custom.scss'
import { useSelector } from 'react-redux'
import { selectRole } from 'store/reducer/token/selector'

const Home = () => {
  const { name: roomName } = useParams<{ name: string }>()
  const navigate = useNavigate()
  const [preJoinChoices, setPreJoinChoices] = useState<LocalUserChoices | undefined>(undefined)
  const { data: userMe } = useUserGetFullMeQuery()

  const [preJoinDefaults, setPreJoinDefaults] = useState({
    username: '',
    videoEnabled: true,
    audioEnabled: true,
  })
  const role = useSelector(selectRole)
  useEffect(() => {
    setPreJoinDefaults(prevDefaults => {
      let newUsername

      switch (role) {
        case 'CLIENT':
          newUsername = userMe?.client?.name
          break
        case 'MODEL':
          newUsername = userMe?.model?.name
          break
        case 'STAFF':
          newUsername = userMe?.staff?.name
          break
        default:
          newUsername = ''
          break
      }

      return {
        ...prevDefaults,
        username: newUsername,
      }
    })
  }, [userMe, role])

  const handlePreJoinSubmit = useCallback((values: LocalUserChoices) => {
    setPreJoinChoices(values)
  }, [])

  const onPreJoinError = useCallback((e: any) => {
    console.error(e)
  }, [])
  const onLeave = useCallback(() => navigate('/'), [navigate])
  return (
    <div className='videoChat'>
      <main data-lk-theme='default'>
        {preJoinDefaults.username === '' ? null : (
          <>
            {roomName && preJoinChoices ? (
              <ActiveRoom roomName={roomName} userChoices={preJoinChoices} onLeave={onLeave} />
            ) : (
              <div
                style={{
                  display: 'grid',
                  placeItems: 'center',
                  height: '100%',
                }}
              >
                <PreJoin onError={onPreJoinError} defaults={preJoinDefaults} onSubmit={handlePreJoinSubmit} joinLabel={'Войти в комнату'} />
              </div>
            )}
          </>
        )}
      </main>
    </div>
  )
}

export default Home
