import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { FormControl } from 'layouts-elements/FormControl/FormControl'
import * as yup from 'yup'
import SelectedAge from 'UI/SelectedAge/SelectedAge'
import { IProps, MyFormValues, Option } from './types'
import { convertStringRange } from '../../../../../utils/ageRange'
import { ClientPartialUpdateByIdApiArg, useClientPartialUpdateByIdMutation } from 'store/apiMain/mainApi'
import { toastSuccess } from '../../../../../elements/Notification/Notification'
import { useTranslation } from 'react-i18next'

const ClientInformation: React.FC<IProps> = ({ id, name, ageRange, refetch }) => {
  const { t } = useTranslation()

  // Options with translation
  const options: Option[] = [
    {
      value: 'From 18 to 23',
      label: t('ageRange.18To23'),
      name: 'EIGHTEEN__TWENTY_THREE',
    },
    {
      value: 'From 24 to 33',
      label: t('ageRange.24To33'),
      name: 'TWENTY_FOUR__THIRTY_THREE',
    },
    {
      value: 'From 34 to 44',
      label: t('ageRange.34To44'),
      name: 'THIRTY_FOUR__FORTY_FOUR',
    },
    {
      value: 'over 45',
      label: t('ageRange.over45'),
      name: 'FORTY_FIVE__MORE',
    },
  ]
  const [editCheck, setEditCheck] = useState(false)
  const optionFromAgeRange = options.find(option => option.name === ageRange)
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(optionFromAgeRange ? [optionFromAgeRange] : [])
  const [editFunc, { isSuccess }] = useClientPartialUpdateByIdMutation()

  useEffect(() => {
    if (isSuccess) {
      refetch()
      toastSuccess('Data changed!')
    }
  }, [isSuccess, refetch])
  useEffect(() => {
    if (!editCheck) {
      const optionFromAgeRangeUpdate = options.find(option => option.name === ageRange)
      setSelectedOptions(optionFromAgeRangeUpdate ? [optionFromAgeRangeUpdate] : [])
    }
  }, [editCheck, ageRange])
  const handleSelect = (newSelectedOptions: Option[]) => {
    setSelectedOptions(newSelectedOptions)
  }
  const initialValues: MyFormValues = {
    name,
  }

  const validationSchema = yup.object().shape({
    name: yup.string().required('Error').min(1, 'Min 1').max(50, 'Max 50'),
  })

  const onSubmit = (values: MyFormValues) => {
    setEditCheck(!editCheck)

    const payload: ClientPartialUpdateByIdApiArg = {
      clientId: String(id),
      clientPartialUpdateDto: {
        name: values.name,
        ageRange: selectedOptions[0].name as 'EIGHTEEN__TWENTY_THREE' | 'TWENTY_FOUR__THIRTY_THREE' | 'THIRTY_FOUR__FORTY_FOUR' | 'FORTY_FIVE__MORE',
      },
    }

    editFunc(payload) // If editFunc is a reference to partialUpdate3, then this will now work.
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  })
  return (
    <>
      {editCheck ? (
        <form onSubmit={formik.handleSubmit}>
          <h2>Client Information</h2>
          <div className='item-info'>
            <strong>ID:</strong> {id}
          </div>
          <div className='item-info'>
            <strong>Name:</strong> <FormControl name='name' type='text' formik={formik} />
          </div>
          <div className='item-info'>
            <SelectedAge selectedOptions={selectedOptions} handleSelect={handleSelect} />
          </div>

          <div className='item-info'>
            <button
              onClick={() => {
                setEditCheck(!editCheck)
                setSelectedOptions([])
              }}
              type='submit'
            >
              Close
            </button>
            <button type='submit'>Save</button>
          </div>
        </form>
      ) : (
        <>
          <h2>Client Information</h2>
          <div className='item-info'>
            <strong>ID:</strong> {id}
          </div>
          <div className='item-info'>
            <strong>Name:</strong> {name}
          </div>
          <div className='item-info'>
            <strong>Age Range:</strong> {convertStringRange(ageRange)}
          </div>
          <div className='item-info'>
            <button
              onClick={() => {
                setEditCheck(!editCheck)
              }}
              type='submit'
            >
              Edit
            </button>
          </div>
        </>
      )}
    </>
  )
}

export default ClientInformation
