import React, { useEffect, useState } from 'react'
import { useParticipants } from '@livekit/components-react'
import { setVideoMembers } from 'store/reducer/videoChat/reducer'
import { useDispatch, useSelector } from 'react-redux'

import { selectVideoChatParticipants } from 'store/reducer/videoChat/selector'
import { getSelectorSetMessagePrivetChats } from 'store/reducer/messages/selectors'
import { sendMessages, setMessagesSelectedId, setMessagesSelectedStaffFalse, setMessagesSelectedUser } from 'store/reducer/messages/reducer'
import { findPrivateChatByParticipants } from '../../../../../../utils/chat/findPrivateChatByParticipants'
import { startTimer } from 'store/reducer/videoChatWithdrawalMoney/reducer'
//
const HandlerParticipant = () => {
  const participants = useParticipants()
  const selectParticipants = useSelector(selectVideoChatParticipants)
  const dispatch = useDispatch()

  const dataPrivateChats = useSelector(getSelectorSetMessagePrivetChats)

  useEffect(() => {
    if (participants.length > 0 && dataPrivateChats) {
      const localParticipant = participants[0]?.identity || null
      const remoteParticipant = participants[1]?.identity || null

      dispatch(setVideoMembers({ localParticipant, remoteParticipant }))

      if (localParticipant && remoteParticipant) {
        const obg = {
          recipientId: localParticipant,
          senderId: remoteParticipant,
        }
        dispatch(sendMessages(obg))
      }
    }
  }, [participants, dispatch])
  const [privateChat, setPrivateChat] = useState('')
  useEffect(() => {
    if (dataPrivateChats && selectParticipants?.remoteParticipant && selectParticipants.localParticipant) {
      const result = findPrivateChatByParticipants(dataPrivateChats, selectParticipants.localParticipant, selectParticipants.remoteParticipant)
      dispatch(setMessagesSelectedStaffFalse())
      dispatch(setMessagesSelectedId(`${result?.privateChat.id}`))
    }
  }, [selectParticipants, dataPrivateChats])

  ////// Логика отвечающия за снятие денег
  useEffect(() => {
    if (selectParticipants?.remoteParticipant && selectParticipants.localParticipant) {
      dispatch(startTimer())
    }
  }, [selectParticipants])
  return null
}
export default HandlerParticipant
