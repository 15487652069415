import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import './scss/index.scss'
import AgeBlockPopUp from './AgeBlock/AgeBlockPopUp'
import { getPopUpClose } from 'store/reducer/popUp/reducer'
import { getPopUp } from 'store/reducer/popUp/selectors'
import Complaint from './Complaint/Complaint'
import AdminCreatePrivateChat from './AdminCreatePrivateChat/AdminCreatePrivateChat'
import Language from './Language/Language'
import Calls from './Calls/Calls'
import SettingsVideo from './SettingsVideo/SettingsVideo'
import EnterRoom from './EnterRoom/EnterRoom'
import { PopUpPresent } from './PresentModel/PresentModel'
import SocketTest from './SocketTest/SocketTest'
import LoadingPopUp from './Loading/LoadingPopUp'

const PopUp = () => {
  const dispatch = useDispatch()
  const popUpState = useSelector(getPopUp)
  const [open, setOpen] = useState(false)

  const closeModal = () => {
    setOpen(false)
    dispatch(getPopUpClose())
  }

  useEffect(() => {
    setOpen(true)
  }, [popUpState.popUpText])
  useEffect(() => {
    setOpen(false)
  }, [])
  const popUpContent = () => {
    switch (popUpState.popUpText) {
      case 'ageBlock':
        return <AgeBlockPopUp open={open} closeModal={closeModal} />
      case 'loading':
        return <LoadingPopUp open={open} closeModal={closeModal} />
      case 'language':
        return <Language open={open} closeModal={closeModal} />
      case 'complaint':
        return <Complaint open={open} closeModal={closeModal} />
      case 'AdminCreatePrivateChat':
        return <AdminCreatePrivateChat open={open} closeModal={closeModal} />
      case 'calls':
        return <Calls open={open} closeModal={closeModal} />
      case 'enterRoom':
        return <EnterRoom open={open} closeModal={closeModal} />
      case 'settingsVideo':
        return <SettingsVideo open={open} closeModal={closeModal} />
      case 'presentPopUp':
        return <PopUpPresent open={open} closeModel={closeModal} />
      case 'socketTest':
        return <SocketTest open={open} closeModal={closeModal} />
      default:
        return null
    }
  }
  return <div>{popUpContent()}</div>
}

export default PopUp
