import { useCallback } from 'react'
import { RegisterLiveTimeApiArg, useRegisterLiveTimeMutation } from 'store/apiMain/mainApi'

export type RegisterLiveTimeRequest = {
  minutes: number | undefined
}

const useWithdrawFunds = (roomId: string | undefined) => {
  const [registerLiveTime] = useRegisterLiveTimeMutation()

  const withdrawFunds = useCallback(
    async (minutes: number) => {
      if (!roomId) {
        console.error('roomId is undefined, cannot proceed with withdrawing funds')
        return
      }

      try {
        const payload: RegisterLiveTimeApiArg = {
          roomId,
          registerLiveTimeRequest: {
            minutes,
          },
        }
        await registerLiveTime(payload).unwrap()
        console.log(`Снято денег за: ${minutes} минут`)
      } catch (error) {
        console.error('Ошибка при снятии денег:', error)
      }
    },
    [registerLiveTime, roomId],
  )

  return { withdrawFunds }
}

export default useWithdrawFunds
