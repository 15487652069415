import React, { useEffect, useState } from 'react'
import { AccountPartialUpdateByIdApiArg, useAccountGetByIdQuery, useAccountPartialUpdateByIdMutation } from 'store/apiMain/mainApi'
import './scss/index.scss'

interface IProps {
  userId: string
  update: boolean
  setUpdate: (update: boolean) => void
}

const AccountController: React.FC<IProps> = ({ userId, update, setUpdate }) => {
  const payload = {
    userId,
  }
  const { data, refetch } = useAccountGetByIdQuery(payload)
  const [editAmount, { isSuccess }] = useAccountPartialUpdateByIdMutation()
  const [coins, setCoins] = useState<number | ''>('')
  const [messages, setMessages] = useState<number | ''>(1)
  const [details, setDetails] = useState<string | ''>('')

  useEffect(() => {
    if (isSuccess) {
      refetch()
      setCoins('')
      setMessages(1)
      setDetails('')
      setUpdate(!update)
    }
  }, [isSuccess])

  const handleChangeAmount = async () => {
    const payload: AccountPartialUpdateByIdApiArg = {
      userId,
      accountPartialUpdateRequest: {
        // @ts-ignore
        coinsDelta: coins,
        // @ts-ignore
        messagesDelta: messages,
        details: details,
      },
    }
    await editAmount(payload)
  }

  return (
    <div className='AccountController'>
      <div className='AccountController-block'>
        <div className='AccountController-block_coin'>
          <p>Coin:</p>
          <p>{data?.coins}</p>
          <input type='number' value={coins} onChange={e => setCoins(Number(e.target.value))} placeholder='Add Coins' />
        </div>
        <div className='AccountController-block_messages'>
          <p>Messages:</p>
          <p>{data?.messages}</p>
          <input type='number' value={messages} onChange={e => setMessages(Number(e.target.value))} placeholder='Add Messages' />
        </div>
        <div className='AccountController-block_details'>
          <p>Details:</p>
          <textarea value={details} onChange={e => setDetails(e.target.value)} placeholder='Add Details' />
        </div>
        <button onClick={handleChangeAmount}>Update Account</button>
      </div>
    </div>
  )
}

export default AccountController
