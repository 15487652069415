import React, { useEffect, useState } from 'react'
import { useCopyToClipboard } from 'usehooks-ts'
import { SvgCopy, SvgSuccess } from '../../images/svg'
import { toastSuccess, toastError } from '../../elements/Notification/Notification'
import './scss/index.scss'

type CopyProps = {
  textToCopy?: string
}

const CopyButton: React.FC<CopyProps> = ({ textToCopy }) => {
  const [, copyToClipboard] = useCopyToClipboard()
  const [hasCopied, setHasCopied] = useState(false)

  useEffect(() => {
    if (hasCopied) {
      toastSuccess('Успешно скопировано!')
      const timeoutId = setTimeout(() => {
        setHasCopied(false)
      }, 3000)
      return () => clearTimeout(timeoutId)
    }
  }, [hasCopied])

  const handleCopy = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    if (textToCopy) {
      copyToClipboard(textToCopy)
      setHasCopied(true)
    } else {
      toastError('Нечего копировать')
    }
  }

  return (
    <button disabled={hasCopied || !textToCopy} className='button-copy' onClick={handleCopy}>
      {hasCopied ? <SvgSuccess /> : <SvgCopy />}
    </button>
  )
}

export default CopyButton
