import React from 'react'
import './scss/index.scss'
import { SvGMessagesGalka } from '../../images/svg'

interface Message {
  type: string
  createdBy: number
  isReadByClient?: boolean
  isReadByModel?: boolean
  isReadByStaff?: boolean
  recipientRole?: string
  senderRole?: string
}

interface UserMe {
  userData?: {
    user?: {
      id: number
    }
  }
}

const renderReadStatus = (role: any, message: any, userMe: any) => {
  const userId = userMe?.userData?.user?.id
  if (!userId || message?.createdBy !== userId) {
    return null // Don't show SVG for GENERAL type messages or messages not created by the user
  }

  const { recipientRole, senderRole } = message
  let isDoubleCheck = false

  if (role === 'MODEL' && senderRole === 'MODEL' && recipientRole === 'CLIENT') {
    isDoubleCheck = message.isReadByClient
  } else if (role === 'CLIENT' && senderRole === 'CLIENT' && recipientRole === 'MODEL') {
    isDoubleCheck = message.isReadByModel
  } else if (role === 'STAFF' && senderRole === 'STAFF') {
    if (recipientRole === 'MODEL') {
      isDoubleCheck = message.isReadByModel
    } else if (recipientRole === 'CLIENT') {
      isDoubleCheck = message.isReadByClient
    } else if (recipientRole === 'STAFF') {
      isDoubleCheck = message.isReadByStaff
    }
  } else if (role === 'STAFF' && recipientRole === 'STAFF' && senderRole !== 'STAFF') {
    isDoubleCheck = message.isReadByStaff
  } else if (role === 'CLIENT' && senderRole === 'CLIENT' && recipientRole === 'STAFF') {
    isDoubleCheck = message.isReadByStaff
  } else if (role === 'MODEL' && senderRole === 'MODEL' && recipientRole === 'STAFF') {
    isDoubleCheck = message.isReadByStaff
  }

  if (isDoubleCheck) {
    return (
      <>
        <SvGMessagesGalka />
        <SvGMessagesGalka className='last-svg' />
      </>
    )
  } else {
    return <SvGMessagesGalka />
  }
}

export default renderReadStatus
