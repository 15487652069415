class SoundManager {
  private activeSounds: HTMLAudioElement[] = []

  play(soundPath: string, volume = 0.5): void {
    try {
      const sound = new Audio(soundPath)
      sound.volume = volume
      sound.play()
      this.activeSounds.push(sound)

      sound.onended = () => {
        this.activeSounds = this.activeSounds.filter(s => s !== sound)
      }
    } catch (error) {
      console.error('Error playing sound:', error)
    }
  }

  stopAll(): void {
    this.activeSounds.forEach(sound => {
      sound.pause()
      sound.currentTime = 0 // Сбросить позицию
    })
    this.activeSounds = []
    console.log('All sounds stopped.')
  }
}

const soundManager = new SoundManager()
export default soundManager
