import React from 'react'
import './scss/index.scss'
import { IProps } from './types'
import InfoHeader from '../InfoHeader/InfoHeader'
import MainHeader from '../MainHeader/MainHeader'

const MainContentInfo: React.FC<IProps> = ({ children }) => {
  return (
    <>
      <MainHeader>
        <InfoHeader />
      </MainHeader>
      <div className='MainContent'>
        <div className='MainContent-container'>{children}</div>
      </div>
    </>
  )
}

export default MainContentInfo
