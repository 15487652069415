import React from 'react'
import { Routes, Route } from 'react-router-dom'
import MainContentInfo from 'layouts-elements/MainContentInfo/MainContentInfo'
import Statement2257 from 'Components/Info/2257Statement'
import FAQ from '../Components/Info/FAQ'
import PrivacyPolicy from '../Components/Info/PrivacyPolicy'
import TermsAndConditions from '../Components/Info/TermsAndConditions'
import Contact from '../Components/Info/Contact'

const InfoRouters = () => {
  return (
    <div className='wrapper'>
      <Routes>
        <Route
          path='/2257'
          element={
            <MainContentInfo>
              <Statement2257 />
            </MainContentInfo>
          }
        />
      </Routes>
      <Routes>
        <Route
          path='/faq'
          element={
            <MainContentInfo>
              <FAQ />
            </MainContentInfo>
          }
        />
      </Routes>
      <Routes>
        <Route
          path='/privacy-policy'
          element={
            <MainContentInfo>
              <PrivacyPolicy />
            </MainContentInfo>
          }
        />
      </Routes>
      <Routes>
        <Route
          path='/terms-and-conditions'
          element={
            <MainContentInfo>
              <TermsAndConditions />
            </MainContentInfo>
          }
        />
      </Routes>
      <Routes>
        <Route
          path='/contact'
          element={
            <MainContentInfo>
              <Contact />
            </MainContentInfo>
          }
        />
      </Routes>
      {/*//model*/}
      <Routes>
        <Route
          path='model/privacy-policy'
          element={
            <MainContentInfo>
              <PrivacyPolicy />
            </MainContentInfo>
          }
        />
      </Routes>
      <Routes>
        <Route
          path='model/terms-and-conditions'
          element={
            <MainContentInfo>
              <TermsAndConditions />
            </MainContentInfo>
          }
        />
      </Routes>
    </div>
  )
}

export default InfoRouters
