import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { IPropsItem } from '../types'
import {
  ClientPartialUpdateByIdApiArg,
  UserPartialUpdateByIdApiArg,
  usePermissionGetAllQuery,
  useUserPartialUpdateByIdMutation,
} from 'store/apiMain/mainApi'
import { toastSuccess } from '../../../../../elements/Notification/Notification'

const Permissions: React.FC<IPropsItem> = ({ item, refetch }) => {
  const [editCheck, setEditCheck] = useState(false)
  setTimeout(() => {
    setEditCheck(true)
  }, 200)
  const { data: itemsPermissions } = usePermissionGetAllQuery()
  const [editFunc, { isSuccess }] = useUserPartialUpdateByIdMutation()

  useEffect(() => {
    if (isSuccess) {
      refetch()
      toastSuccess('Data changed!')
    }
  }, [isSuccess, refetch])
  return (
    <Formik
      enableReinitialize
      initialValues={{ permissions: item?.userData?.permissions }}
      onSubmit={values => {
        const payload: UserPartialUpdateByIdApiArg = {
          userId: String(item?.userData?.user.id),
          userPartialUpdateDto: {
            permissions: [...values.permissions],
          },
        }
        editFunc(payload)
        setEditCheck(!editCheck)
      }}
    >
      {({ values, resetForm }) => (
        <Form>
          <>
            {editCheck ? (
              <div>
                <h2>Permissions All</h2>
                <ul className='item-permissions'>
                  {itemsPermissions?.map((permission: any, index) => (
                    <div>
                      {permission?.allowedFor.map((e: any) => {
                        if (e === 'MODEL') {
                          return (
                            <li key={permission?.name}>
                              {index + 1}.
                              <label>
                                <Field
                                  type='checkbox'
                                  name='permissions'
                                  value={permission.name}
                                  checked={values?.permissions?.includes(permission?.name)}
                                />
                                {permission.name.replace('__', ' - ').replace(/_/g, ' ').toLowerCase()}
                              </label>
                            </li>
                          )
                        }
                        return null
                      })}
                    </div>
                  ))}
                </ul>
                <div className='item-info'>
                  <button
                    onClick={() => {
                      setEditCheck(!editCheck)
                      resetForm({
                        values: { permissions: item?.userData?.permissions },
                      })
                    }}
                    type='button'
                  >
                    Close
                  </button>
                  <button type='submit'>Save</button>
                </div>
              </div>
            ) : (
              <div>
                <h2>Permissions Client</h2>
                <ul className='item-permissions'>
                  {item?.userData?.permissions?.map((permission: any) => (
                    <li key={permission}>{permission.replace('__', ' - ').replace(/_/g, ' ').toLowerCase()}</li>
                  ))}
                </ul>
                <div className='item-info'>
                  <button onClick={() => setEditCheck(!editCheck)} type='button'>
                    Edit
                  </button>
                </div>
              </div>
            )}
          </>
        </Form>
      )}
    </Formik>
  )
}

export default Permissions
