import { useEffect } from 'react'
import { useSelector } from 'react-redux' // Если используете Redux
import { useNavigate, useLocation } from 'react-router-dom'
import { selectRole, selectTokenData } from 'store/reducer/token/selector'
import { useUserGetFullMeQuery } from 'store/apiMain/mainApi'

const useRedirectIfAuthenticated = () => {
  const token = useSelector(selectTokenData)
  const navigate = useNavigate()
  const location = useLocation()
  const { data: userMe } = useUserGetFullMeQuery()
  const role = useSelector(selectRole)
  useEffect(() => {
    const authPaths = ['/login', '/registration'] // Пути, на которых нужно выполнить проверку
    if (authPaths.includes(location.pathname) && token !== null && userMe) {
      if (role === 'CLIENT') {
        navigate('/profile')
      }
      if (role === 'MODEL') {
        navigate('/model/profile/main-info')
      }
    }
  }, [token, location.pathname, navigate])
}

export default useRedirectIfAuthenticated
