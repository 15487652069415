import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useGetFileMutation } from 'store/apiMain/emptyApi'
import { setAudio } from 'store/reducer/models/reducer'
import AudioVisualizer from 'UI/AudioVisualization/AudioVisualization'
import { selectModelsProfiles } from 'store/reducer/models/selectors' // Adjust path as necessary

interface AudioFile {
  modelIdentifier?: string
  file?: { id: string }
}

interface MyDropzoneProps {
  audio: any
  positionsButton?: string
  modelId?: string
  gradient?: number
}

const AudioGirlView: React.FC<MyDropzoneProps> = ({ audio = {}, modelId, positionsButton, gradient }) => {
  const dispatch = useDispatch()
  const profiles = useSelector(selectModelsProfiles)
  const [getFile] = useGetFileMutation()
  const [isBlocked, setIsBlocked] = useState<boolean>(false)
  const [fileAudio, setFileAudio] = useState<string | undefined>()
  const fetchedFileIds = useRef<Set<string>>(new Set()) // Используем Set для хранения id файлов, для которых уже был выполнен запрос

  useEffect(() => {
    const fileId = audio?.file?.id
    if (fileId && !fetchedFileIds.current.has(fileId) && modelId) {
      const modelProfile = profiles?.[audio.modelIdentifier]
      if (!modelProfile?.audios?.[fileId]) {
        fetchedFileIds.current.add(fileId) // Добавляем fileId в Set, чтобы избежать повторных запросов
        ;(async () => {
          try {
            dispatch(setAudio({ modelId: modelId, fileId, content: audio?.file?.url }))
            setFileAudio(audio?.file?.url)
          } catch (error) {
            console.error('Failed to fetch audio file:', error)
          }
        })()
      } else {
        setFileAudio(modelProfile.audios[fileId].content)
      }
    }
  }, [audio, profiles, getFile, dispatch])

  // useEffect(() => {
  //     navigator.mediaDevices
  //         ?.getUserMedia({ audio: true })
  //         .then(() => setIsBlocked(false))
  //         .catch(() => {
  //             console.warn('Permission Denied');
  //             setIsBlocked(true);
  //         });
  // }, []);

  const handleClick = (event: any) => {
    event.stopPropagation()
  }

  return (
    <>
      {fileAudio ? (
        <div onClick={handleClick} className='audioMp3'>
          <div className='audioMp3-header'>
            <AudioVisualizer fileAudio={audio.file.url} positionsButton={positionsButton} gradient={gradient} />
          </div>
        </div>
      ) : null}
    </>
  )
}

export default AudioGirlView
