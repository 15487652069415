import React from 'react'
import Logo from '../../Components/Global/Logo/Logo'
import Language from '../../UI/Language/Language'
import './scss/index.scss'

const InfoHeader = () => {
  return (
    <div className='InfoHeader'>
      <header className='InfoHeader-block'>
        <Logo />
        <Language />
      </header>
    </div>
  )
}

export default InfoHeader
