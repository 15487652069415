import React from 'react'

const PrivacyPolicy = () => {
  let name = 'VirtualHouse'
  let email = 'VirtualHouse@gmail.com'
  let url1 = 'https://www.VirtualHouse.com/policies'
  let url = 'https://www.VirtualHouse.com'
  let many = ' БАНК ТАРАМ'
  return (
    <>
      <div className='info'>
        <div className='info-block'>
          <h2>Политика конфиденциальности</h2>
          <h3> Последнее изменение: 15.01.2021</h3>
          <p>
            <br /> Настоящая Политика конфиденциальности является частью Пользовательского соглашения и включена в него посредством ссылки. Термины,
            написанные с заглавной буквы, не определенные в данном документе, будут иметь значения, указанные в Пользовательском соглашении и
            соответствующих политиках. Настоящая Политика конфиденциальности распространяется на информацию, которую вы можете предоставить {url}{' '}
            через веб-сайт {url}:
            <br />
            <ul>
              <li>какая ваша личная информация собирается;</li>
              <li>какая организация собирает информацию;</li>
              <li>как используется информация;</li>
              <li>кому может быть предоставлена ​​информация;</li>
              <li>какие варианты доступны вам в отношении сбора, использования и распространения информации;</li>
              <li>
                какие существуют процедуры безопасности для защиты от потери, неправильного использования или изменения информации, находящейся под
                контролем компании; и
              </li>
              <li>Как вы можете исправить любые неточности в информации</li>
            </ul>
            <br /> Ваша конфиденциальность очень важна для нас на {url}. В {url} мы стремимся поддерживать доверие посетителей нашего веб-сайта. В
            частности, мы хотим, чтобы вы знали, что {url} не занимается продажей, арендой или обменом списками адресов электронной почты с другими
            компаниями и предприятиями в маркетинговых целях. Если у вас есть вопросы или опасения относительно этого заявления, вам следует связаться
            с {url}, отправив электронное письмо по адресу support@ {url}.
            <br />
            <br />
            <br />
            <br /> 6.2. Отказ. Если вы не можете повторно просмотреть настоящее Соглашение, как требуется, чтобы определить, изменились ли какие-либо
            из условий, вы принимаете на себя всю ответственность за такие упущения и соглашаетесь с тем, что такой отказ равносилен вашему
            утвердительному отказу от вашего права на просмотр измененного Соглашения. Мы не несем ответственности за пренебрежение вашими законными
            правами.
            <br />
            <br /> Если у вас есть какие-либо вопросы об этой Политике конфиденциальности, практике этого Сайта или ваших отношениях с {url},
            пожалуйста, свяжитесь с нами.
            <br />
            <br /> Почта: support@ {url}
          </p>
        </div>
      </div>
    </>
  )
}

export default PrivacyPolicy
