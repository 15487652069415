import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IError, ITokenStore } from './types'

const initialState: ITokenStore = {
  tokenData: null,
  role: undefined,
  userMe: null,
  error: {
    text: null,
    url: null,
  },
}

const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    setTokenData: (state, action: PayloadAction<any | null>) => {
      state.tokenData = action.payload
    },
    setRole: (state, action: PayloadAction<'CLIENT' | 'MODEL' | 'STAFF' | undefined>) => {
      state.role = action.payload
    },
    setUserMe: (state, action: PayloadAction<any>) => {
      state.userMe = action.payload
    },
    setError: (state, action: PayloadAction<IError>) => {
      state.error = action.payload
    },
    resetStateToken: () => initialState,
  },
})

export const { setTokenData, setError, setRole, resetStateToken, setUserMe } = tokenSlice.actions

export default tokenSlice.reducer
