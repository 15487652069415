import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useUserGetFullMeQuery } from 'store/apiMain/mainApi'
import Step1 from './Step1/Step1'
import Step2 from './Step2/Step2'
import { useDispatch } from 'react-redux'
import { setRole } from 'store/reducer/token/reducer'
import StepHeader from './StepHeader/StepHeader'
import { JSX } from 'react/jsx-runtime'

const Step = () => {
  const dispatch = useDispatch()

  const { stepId } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(setRole('MODEL'))
  }, [dispatch])

  let StepMainContent: () => JSX.Element

  switch (stepId) {
    case '1':
      StepMainContent = Step1
      break
    case '2':
      StepMainContent = Step2
      break
    default:
      StepMainContent = Step1
      break
  }

  const { data: modelData } = useUserGetFullMeQuery()

  useEffect(() => {
    if (modelData?.model !== null && modelData?.model !== undefined) {
      navigate('/model/profile')
    } else {
    }
  }, [modelData, navigate])

  useEffect(() => {
    if (modelData?.model !== null && modelData?.model !== undefined) {
      navigate('/model/profile')
    } else {
      navigate('/model/registration/step/1')
    }
  }, [])

  return (
    <>
      <StepHeader stepId={stepId} />
      <StepMainContent />
    </>
  )
}

export default Step
