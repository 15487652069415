import React from 'react'
import { ComplaintDto, ComplaintGetAllApiArg, useComplaintGetAllQuery } from 'store/apiMain/mainApi'
import Item from './Item/Item'

const Complaint = () => {
  const payload: ComplaintGetAllApiArg = {
    page: undefined,
    pageSize: undefined,
  }
  // @ts-ignore
  const { data: complaintData }: any = useComplaintGetAllQuery(payload)

  return (
    <div className='complaint'>
      <div className='complaint-data'>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>AccuserUserId</th>
              <th>AccusedUserId</th>
              <th>CreatedAt</th>
              <th>InitiatedWithBlock</th>
              <th>Message</th>
            </tr>
          </thead>
          <tbody>
            {complaintData?.content?.map((e: ComplaintDto) => {
              return <Item key={e?.id} item={e} />
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Complaint
