import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const logoutSlice = createSlice({
  name: 'logout',
  initialState,
  reducers: {
    setLogout: state => {},
  },
})

export const { setLogout } = logoutSlice.actions

export default logoutSlice.reducer
