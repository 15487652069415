import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNotificationGetAllQuery } from 'store/apiMain/mainApi'
import { selectIsEnd, selectNotifications } from 'store/reducer/notifications/selectors'
import { addNotifications } from 'store/reducer/notifications/reducer'
import { SvgInfo, SvgCloseNotifications } from './../../images/svg'
import notificationsHandler from '../../utils/notificationsHandler/notificationsHandler'
import dataFormatString from '../../utils/dataFormatString'
import useInfiniteScroll from '../../hooks/useInfiniteScroll'

interface Payload {
  token: string | undefined
  pageSize: number
}
interface IProps {
  unreadNotificationIds: Set<number>
  closeNotificationWindow: any
  setUnreadNotificationIds: React.Dispatch<React.SetStateAction<Set<number>>>
  handleNotificationRead: (notificationId: number) => void
}

const NotificationsBlockItems: React.FC<IProps> = ({
  closeNotificationWindow,
  unreadNotificationIds,
  setUnreadNotificationIds,
  handleNotificationRead,
}) => {
  const dispatch = useDispatch()
  const notifications = useSelector(selectNotifications)
  const isEnd = useSelector(selectIsEnd)
  const [payload, setPayload] = useState<Payload>({
    token: undefined,
    pageSize: 6,
  })
  const { data: dataNotifications, refetch: refetchNotification } = useNotificationGetAllQuery(payload)
  const notificationsBlockRef = useInfiniteScroll({
    isEnd,
    dataNotifications,
    setPayload,
  })
  const notificationRefs = useRef<(HTMLDivElement | null)[]>([])

  useEffect(() => {
    if (dataNotifications) {
      dispatch(addNotifications(dataNotifications.content))
    }
  }, [dataNotifications, dispatch])

  useEffect(() => {
    if (payload.token && !isEnd) {
      refetchNotification()
    }
  }, [payload.token, isEnd, refetchNotification])

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.intersectionRatio === 1) {
            const notificationId = entry.target.getAttribute('data-id')
            const isRead = entry.target.getAttribute('data-is-read') === 'true'
            if (notificationId && !isRead) {
              handleNotificationRead(Number(notificationId))
            }
          }
        })
      },
      { threshold: [0, 1] },
    )

    notificationRefs.current.forEach(ref => {
      if (ref) {
        observer.observe(ref)
      }
    })

    return () => {
      if (notificationRefs.current) {
        notificationRefs.current.forEach(ref => {
          if (ref) {
            observer.unobserve(ref)
          }
        })
      }
    }
  }, [handleNotificationRead, notifications])

  console.log(notifications.length)

  return (
    <div className='notifications-block'>
      <button className='close-notifications__button' onClick={closeNotificationWindow}>
        <SvgCloseNotifications />
      </button>
      <div className='notifications-block_top'>
        <div className='notifications-block_top__text'>
          <p>Notifications</p>
        </div>
      </div>
      <div className='notifications-block_hidden-corners'>
        <div ref={notificationsBlockRef} className='notifications-block_bottom'>
          {notifications.length > 0 ? (
            notifications.map((notification, index) => (
              <div
                key={index}
                className='notifications-block_bottom__box'
                data-id={notification.id}
                data-is-read={notification.isRead.toString()}
                ref={el => (notificationRefs.current[index] = el)}
              >
                <div className='notifications-block_bottom__box___img'>
                  <SvgInfo />
                  <span>{!notification.isRead ? 'New' : null}</span>
                </div>
                <div className='notifications-block_bottom__box___container'>
                  <div className='notifications-block_bottom__box___container____text'>
                    <p>{notificationsHandler(notification.type)}</p>
                  </div>
                  <div className='notifications-block_bottom__box___container____data'>
                    <p>{dataFormatString(notification.createdAt)}</p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className='notifications-block_empty'>
              <p>No notifications yet</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default NotificationsBlockItems
