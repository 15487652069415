import languages from '../UI/languageData/languages'

export function getParsedLanguages(backendNames: string[] | undefined): string {
  // Check if backendNames is undefined, and return an empty string if so
  if (!backendNames) {
    return ''
  }

  const parsedLanguages: string[] = []

  // Iterate over each region
  for (const region of languages) {
    // Iterate over each language in the region
    for (const language of region.languages) {
      // Check if the language's backendName is in the provided backendNames array
      if (backendNames.includes(language.backendName ?? '')) {
        // Add the language name to the parsedLanguages array
        parsedLanguages.push(language.name)
      }
    }
  }

  // Join the language names with ", " and return as a single string
  return parsedLanguages.join(', ')
}
