import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import './scss/index.scss'
import { useSelector } from 'react-redux'
import { selectRole } from 'store/reducer/token/selector'
import logo from 'images/svg/logo.svg'
import logoMobile from 'images/svg/logoMobile.svg'

const Logo = () => {
  const navigate = useNavigate()
  const role = useSelector(selectRole)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 500)

  const handleClick = () => {
    if (role === 'MODEL') {
      navigate('/model/messages')
    } else {
      navigate('/girls')
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 500)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div className='logo-main' onClick={handleClick}>
      <span>
        <img src={isMobile ? logoMobile : logo} alt='Logo' />
      </span>
    </div>
  )
}

export default Logo
