import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { FormControl } from 'layouts-elements/FormControl/FormControl'
import { setRole, setTokenData } from 'store/reducer/token/reducer'
import { toastError, toastSuccess } from 'elements/Notification/Notification'

import './scss/index.scss'

import imgLogin from './../../../images/img/login/1.jpg'
import imgLoginDecor from './../../../images/img/login-decor.svg'
import { useNavigate, Link } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import Logo from 'Components/Global/Logo/Logo'
import Language from '../../../UI/Language/Language'
import { useTranslation } from 'react-i18next'
import { useUserGetFullMeQuery } from 'store/apiMain/mainApi'
import { selectTokenData } from 'store/reducer/token/selector'
import { getRefreshToken } from 'store/reducer/socket/selector'
import { useTokenGetMutation } from 'store/apiMain/emptyApi'

interface DecodedToken {
  roles: string[]

  [key: string]: any
}

const Login = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const token = useSelector(selectTokenData)
  const [tokenGet, { isLoading, error }] = useTokenGetMutation()

  const [redirect, setRedirect] = useState(true)

  const { data, refetch } = useUserGetFullMeQuery()

  useEffect(() => {
    if (data?.userData?.user?.role === 'MODEL' && token) {
      navigate('/model/registration/step/1')
      dispatch(setRole('MODEL'))
    }
    if (data?.userData?.user?.role === 'CLIENT' && token) {
      navigate('/models')
      dispatch(setRole('CLIENT'))
    }
  }, [data, token])
  const initialValues: any = {
    email: '',
    password: '',
  }
  const validationSchema = yup.object().shape({
    email: yup.string().required(t('registration.emailRequired')).email(t('login.emailValid')),
    password: yup.string().required(t('login.passwordRequired')).min(6, t('login.passwordMinLength')),
  })
  const onSubmit = (values: any) => {
    const fetchData = async () => {
      const body = {
        username: values.email,
        password: values.password,
        client_id: 'general-client',
        grant_type: 'password',
        client_secret: 'general',
      }
      try {
        const result = await tokenGet({ body }).unwrap()
        if (result) {
          refetch()
          dispatch(setTokenData(result))
          toastSuccess(t('login.toastSuccess'))
          if (result?.access_token !== undefined) {
            setRedirect(false)
          }
        }
        if (!result) {
          throw result
        }
      } catch (error) {
        // @ts-ignore
        if (error?.data?.title?.includes('User was disabled')) {
          toastError(t('Вас заблокировали. Если у вас возникли вопросы или вы не понимаете причину, свяжитесь с нами любым удобным способом.'), 8000)
          navigate('/info/contact')
        } else {
          toastError(t('login.notCorrectedEmailAndPass'))
        }

        console.error('Failed to create client', error)
      }
    }

    fetchData()
  }
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  return (
    <div className='login'>
      <header className='login__header'>
        <Logo />
        <Language />
      </header>
      <div className='login__main'>
        <div className='login__main-left'>
          <div className='login__main-left_box'>
            <h1 className='login__title'>{t('login.title')}</h1>
            <form onSubmit={formik.handleSubmit} className='login__form'>
              <label className='email'>
                <span>{t('Email')}</span>
                <FormControl name='email' type='email' formik={formik} placeholder='' autoComplete='off' />
              </label>
              <label className='password'>
                <span>{t('Password')}</span>
                <FormControl name='password' type='password' formik={formik} placeholder='' autoComplete='off' />
              </label>
              {!isLoading ? (
                <button type='submit' className='login__form-button'>
                  {t('login.button')}
                </button>
              ) : (
                <button type='button' disabled={true} className='login__form-button'>
                  Loading...
                </button>
              )}
              <div className='login__footer'>
                <Link to='/forgot-password' className='login__footer-link'>
                  {t('login.forgotPassword')}
                </Link>
                <Link to='/registration' className='login__footer-link'>
                  {t('login.registration')}
                </Link>
              </div>
            </form>
          </div>
          <div className='login__decor'>
            <img src={imgLoginDecor} alt={t('login.decorAuthAlt')} />
          </div>
        </div>
        <div className='login__main-img'>
          <img src={imgLogin} alt={t('login.logoAlt')} />
        </div>
      </div>
    </div>
  )
}

export default Login
