import React, { useState } from 'react'

import './scss/index.scss'

import imgLogin from '../../../images/img/1.jpg'
import { useNavigate } from 'react-router-dom'
import Logo from '../../Global/Logo/Logo'
import Language from '../../../UI/Language/Language'
import { useTranslation } from 'react-i18next'
import Registration from './Registration/Registration'
import VerifyEmail from './VerifyEmail/VerifyEmail'
import { selectorModelVerifications } from 'store/reducer/global/selectors'
import { useSelector } from 'react-redux'

const RegistrationMain = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [email, setEmail] = useState('')

  const modelVerifications = useSelector(selectorModelVerifications)

  return (
    <div className='login'>
      <header className='login__header'>
        <Logo />
        <Language />
      </header>
      <div className='login__main'>
        {!modelVerifications ? <Registration setEmail={setEmail} /> : <VerifyEmail email={email} />}
        <div className='login__main-img'>
          <img src={imgLogin} alt='logo' />
        </div>
      </div>
    </div>
  )
}

export default RegistrationMain
