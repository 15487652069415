import Profile from 'Components/Model/Profile/Profile'
import Messages from 'Components/User/Messages/Messages'
import MainContent from 'layouts-elements/Model/MainContent/MainContent'

import { Route, Routes, Navigate } from 'react-router-dom'
import Step from '../Components/Model/Steps/Step'
import People from 'Components/Model/People/People'
import Friends from 'Components/Global/Friends/Friends'
import Login from 'Components/User/Login/Login'
import RegistrationMain from '../Components/Model/Registration/RegistrationMain'

const ModelRoutes = () => {
  return (
    <div className='wrapper'>
      <Routes>
        <Route path='/' element={<Navigate to='/messages' />} />
        <Route
          path='/messages'
          element={
            <MainContent>
              <Messages />
            </MainContent>
          }
        />
        <Route
          path='/messages'
          element={
            <MainContent>
              <Messages />
            </MainContent>
          }
        />
        <Route
          path='/people'
          element={
            <MainContent>
              <People />
            </MainContent>
          }
        />
        <Route
          path='/friends'
          element={
            <MainContent>
              <Friends />
            </MainContent>
          }
        />

        <Route path='/registration' element={<RegistrationMain />} />
        <Route path='/registration/step/:stepId' element={<Step />} />
        <Route
          path='/profile/*'
          element={
            <MainContent>
              <Profile />
            </MainContent>
          }
        />
        <Route path='/login' element={<Login />} />

        <Route path='*' element={<Navigate to='/login' replace />} />
      </Routes>
    </div>
  )
}

export default ModelRoutes
