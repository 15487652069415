import React from 'react'
import './scss/index.scss'

import { SvgProfileMens1, SvgProfileMens2, SvgProfileMens3, SvgProfileMens4, SvgProfileMens5, SvgProfileMens6 } from 'images/svg'

function EditPhotoTooltip({ onClose, onChangePhoto }: any) {
  return (
    <div className='tooltip'>
      <div className='tooltip-block'>
        <div className='tooltip-block_box'>
          <SvgProfileMens1 />
          <button
            type='button'
            onClick={() => {
              onClose()
              onChangePhoto(1)
            }}
          >
            Выбрать
          </button>
        </div>
        <div className='tooltip-block_box'>
          <SvgProfileMens2 />
          <button
            type='button'
            onClick={() => {
              onClose()
              onChangePhoto(2)
            }}
          >
            Выбрать
          </button>
        </div>
        <div className='tooltip-block_box'>
          <SvgProfileMens3 />
          <button
            type='button'
            onClick={() => {
              onClose()
              onChangePhoto(3)
            }}
          >
            Выбрать
          </button>
        </div>
        <div className='tooltip-block_box'>
          <SvgProfileMens4 />
          <button
            type='button'
            onClick={() => {
              onClose()
              onChangePhoto(4)
            }}
          >
            Выбрать
          </button>
        </div>
        <div className='tooltip-block_box'>
          <SvgProfileMens5 />
          <button
            type='button'
            onClick={() => {
              onClose()
              onChangePhoto(5)
            }}
          >
            Выбрать
          </button>
        </div>
        <div className='tooltip-block_box'>
          <SvgProfileMens6 />
          <button
            type='button'
            onClick={() => {
              onClose()
              onChangePhoto(6)
            }}
          >
            Выбрать
          </button>
        </div>
      </div>
    </div>
  )
}

export default EditPhotoTooltip
