import React, { useEffect, useState, useRef } from 'react'
import * as yup from 'yup'
import { useFormik, FormikHelpers } from 'formik'
import { useNavigate } from 'react-router-dom'
import { FormControl } from 'layouts-elements/FormControl/FormControl'
import { toastError, toastSuccess } from 'elements/Notification/Notification'
import './scss/index.scss'
import { useTranslation } from 'react-i18next'
import {
  UserSendCurrentEmailConfirmationCodeApiArg,
  useTicketCreateMutation,
  useUserSendCurrentEmailConfirmationCodeMutation,
  useUserVerifyCurrentEmailMutation,
} from 'store/apiMain/mainApi'
import { useDispatch, useSelector } from 'react-redux'
import { selectRole } from 'store/reducer/token/selector'
import useLogout from '../../../../hooks/useLogout'
import { setSocketToken } from 'store/reducer/socket/reducer'

interface IProps {
  email: string
}

interface FormValues {
  confirmationCode: string
}

const VerifyEmail: React.FC<IProps> = ({ email }) => {
  const logOutHook = useLogout()
  const { t } = useTranslation()
  const [verifyEmail] = useUserVerifyCurrentEmailMutation()
  const navigate = useNavigate()
  const [resetCode] = useUserSendCurrentEmailConfirmationCodeMutation()
  const [cooldown, setCooldown] = useState(30)
  const [loading, setLoading] = useState(false)
  const intervalRef = useRef<number | null>(null)
  const role = useSelector(selectRole)
  const [addTicket, { data: dataSocket, error }] = useTicketCreateMutation()
  const dispatch = useDispatch()
  useEffect(() => {
    if (dataSocket) {
      dispatch(setSocketToken(dataSocket?.ticket))
      if (role === 'MODEL') {
        navigate('/model/registration/step/1')
      }
      if (role === 'STAFF') {
        navigate('/admin/staff')
      }
    }
  }, [dataSocket])

  useEffect(() => {
    startCooldown()
    return () => {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current)
      }
    }
  }, [])

  const startCooldown = () => {
    setCooldown(30)
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current)
    }
    intervalRef.current = window.setInterval(() => {
      setCooldown(prevCooldown => {
        if (prevCooldown > 0) {
          return prevCooldown - 1
        } else {
          if (intervalRef.current !== null) {
            clearInterval(intervalRef.current)
          }
          return 0
        }
      })
    }, 1000)
  }

  const resendCode = async () => {
    setLoading(true)
    const payload: UserSendCurrentEmailConfirmationCodeApiArg = {
      emailUpdateConfirmationRequest: {
        email: email,
      },
    }
    try {
      await resetCode(payload).unwrap()
      toastSuccess(t('email.resendSuccess'))
      startCooldown()
    } catch (error) {
      toastError(t('email.resendError'))
    } finally {
      setLoading(false)
    }
  }

  const handleVerifyEmail = async (code: string) => {
    const payload = {
      emailVerificationRequest: {
        confirmationCode: code,
      },
    }
    try {
      await verifyEmail(payload).unwrap()
      if (role) {
      }
      toastSuccess(t('email.verifySuccess'))
      addTicket()
    } catch (error) {
      toastError(t('email.verifyError'))
    }
  }

  const initialValues: FormValues = {
    confirmationCode: '',
  }

  const validationSchema = yup.object().shape({
    confirmationCode: yup.string().required(t('email.codeRequired')),
  })

  const formik = useFormik<FormValues>({
    initialValues,
    validationSchema,
    onSubmit: (values, { setSubmitting }: FormikHelpers<FormValues>) => {
      handleVerifyEmail(values.confirmationCode)
      setSubmitting(false)
    },
  })

  return (
    <div className='login__main-left'>
      <div className='login__main-left_box'>
        <h1 className='login__title'>{t('email.confirmTitle')}</h1>
        <form onSubmit={formik.handleSubmit} className='login__form'>
          <label className='code'>
            <span>{t('Code')}</span>
            <FormControl name='confirmationCode' type='text' formik={formik} placeholder={t('email.codePlaceholder')} />
          </label>
          <button type='button' className='login__form-button' onClick={resendCode} disabled={cooldown > 0 || loading}>
            {loading ? t('Loading...') : cooldown > 0 ? `${t('Resend code')} (${cooldown})` : t('Resend code')}
          </button>
          <button type='submit' className='login__form-button'>
            {t('email.confirmButton')}
          </button>
          <button className='login__form-button logout' onClick={logOutHook} type='submit'>
            {t('profile.logout')}
          </button>
        </form>
      </div>
    </div>
  )
}

export default VerifyEmail
