import React, { useEffect } from 'react'
import './scss/index.scss'
import { RelationshipGetCountApiArg, useRelationshipGetCountQuery } from 'store/apiMain/mainApi'
import { useSelector } from 'react-redux'
import { selectorUpdateFriends } from 'store/reducer/friends/selectors'

const UnreadCountFriends = () => {
  const payloadRequest: RelationshipGetCountApiArg = {
    statuses: ['FRIEND_REQUEST'],
  }

  const { data: dataRequest, refetch: refetchTabs } = useRelationshipGetCountQuery(payloadRequest)
  const updateFriends = useSelector(selectorUpdateFriends)

  useEffect(() => {
    refetchTabs()
  }, [updateFriends])
  // Render nothing if the amount is zero
  if (!dataRequest?.amount) {
    return null
  }

  return (
    <span className='UnreadCountFriends'>
      <div className='notifications-count long-length'>
        <span>{dataRequest.amount}</span>
      </div>
    </span>
  )
}

export default UnreadCountFriends
