import React from 'react'
import { photoViewUserMobileChat } from '../../../../UI/PhotoViewUserChat/PhotoViewUserMobile'
import { photoViewUser } from '../../../../UI/PhotoViewUser/PhotoViewUser'
import imgStaff from '../../../../images/img/staff3.png'
import imgRequestGirls from '../../../../images/img/imgRequestGirls.png'
import { useSelector } from 'react-redux'
import { selectRole } from '../../../../store/reducer/token/selector'
import { photoViewClient } from '../../../../UI/Photo/photoViewClient'

interface IProps {
  recipientRole?: 'CLIENT' | 'MODEL' | 'STAFF'
  senderRole?: 'CLIENT' | 'MODEL' | 'STAFF'
  idClient: number
  imgUrl?: string
  data: any
}
const ImgMessages = ({ recipientRole, senderRole, idClient, imgUrl, data }: IProps) => {
  const role = useSelector(selectRole)
  const renderImage = () => {
    if (recipientRole === 'CLIENT') {
      if (senderRole === 'CLIENT') {
        return imgUrl ? <img src={imgUrl} alt='' /> : null
      }
      return photoViewClient(idClient)
    }
    if (recipientRole === 'MODEL') {
      if (senderRole === 'MODEL') {
        return photoViewClient(idClient)
      }
      if (data?.model?.identifier === undefined && role === 'STAFF' && (data?.user?.role === 'MODEL' || data?.user?.role)) {
        return <img src={imgRequestGirls} alt='' />
      } else {
        return imgUrl ? <img src={imgUrl} alt='' /> : null
      }
    }
    if (recipientRole === 'STAFF') {
      return <img src={imgStaff} alt='' />
    }

    if (role === 'CLIENT') {
      return photoViewClient(idClient)
    }
    if (role === 'MODEL') {
      return photoViewClient(idClient)
    }
    if (role === 'STAFF') {
      return <img src={imgStaff} alt='' />
    }
    return null
  }

  return <>{renderImage()}</>
}

export default ImgMessages
