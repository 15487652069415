import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import { FormControl } from 'layouts-elements/FormControl/FormControl'
import {
  useModelRequestCreateMutation,
  useModelRequestApproveByIdMutation,
  useFileCreateMutation,
  useModelRequestGetByIdQuery,
  useModelRequestDeleteByIdMutation,
  ModelRequestDeleteByIdApiArg,
} from 'store/apiMain/mainApi'
import VideoRecored from 'UI/Video/Video'
import { selectorAudio, selectorPhoto, selectorPhotoDocuments, selectorVideo } from 'store/reducer/fileArray/selectors'
import { useGetFileMutation } from 'store/apiMain/emptyApi'
import { useNavigate, useParams } from 'react-router-dom'
import PhotoAdmin from '../../../UI/Photo/PhotoAdmin'
import { addAudio, addPhoto, addPhotoDocuments, addVideo } from '../../../store/reducer/fileArray/reducer'
import VoiceRecorderAdmin from 'UI/Audio/VoiceRecorderAdmin'
import Documents from './Documents/Documents'
import PhotoGirlView from 'UI/Photo/PhotoGirlView'
import PhotoGirlViewAdmin from 'UI/Photo/PhotoGirlViewAdmin'
import SelectedCustom from '../../../UI/SelectCustom/SelectCustom'
import {
  optionsBirthYear,
  optionsBodyType,
  optionsBreastSize,
  optionsChest,
  optionsHairColor,
  optionsHairLength,
  optionsHeight,
  optionsHips,
  optionsLanguage,
  optionsSexualOrientation,
  optionsWaist,
  optionsWeight,
} from '../../../UI/SelectCustom/constValue'
import Photo from '../../../UI/Photo/Photo'
import Audio from '../../../UI/Audio/Audio'
import { toastSuccess } from '../../../elements/Notification/Notification'
import Video from 'UI/Video/Video'
import SelectedLanguage from '../../../UI/SelectedLanguage/SelectedLanguage'
import { useTranslation } from 'react-i18next'
import CropeMain from '../../Global/Crope/CropeMain'
import CustomTextArea from '../../../UI/CustomTextArea/CustomTextArea'
import { countChars, RawContent } from '../../Model/Steps/Step1/Step1'
import mainInfo from '../../Model/Profile/MainInfo/MainInfo'
import CopyButton from '../../../UI/CopyButton/CopyButton'

const ModelRequest: React.FC = () => {
  const dispatch = useDispatch()
  const { modelRequestId } = useParams()
  const { t } = useTranslation()
  const { data: modelRequest, refetch, isLoading, isError } = useModelRequestGetByIdQuery({ modelRequestId: String(modelRequestId) })
  const [useUpdatePhoto, setUseUpdatePhoto] = useState()
  const [approve, { isSuccess: approveIsSuccess }] = useModelRequestApproveByIdMutation()
  const [requestDeleteFunc, { isSuccess: isSuccessDelete }] = useModelRequestDeleteByIdMutation()
  const navigate = useNavigate()
  useEffect(() => {
    if (isSuccessDelete) {
      refetch()
      toastSuccess('Реквест удаленн!')
      navigate('/admin/models-request')
    }
  }, [isSuccessDelete])
  useEffect(() => {
    if (approveIsSuccess) {
      refetch()
      toastSuccess('Данные апрувлены')
    }
  }, [approveIsSuccess])
  const [getFile] = useGetFileMutation()
  const [addFile] = useFileCreateMutation()

  const [profilePhoto, setProfilePhoto] = useState<string | null>(null)
  const [profilePhotoIds, setProfilePhotoIds] = useState<string | null>(null)

  const [addRequest, { isSuccess: addRequestIsSuccess }] = useModelRequestCreateMutation()
  const [photos, setPhotos] = useState<any[]>([])
  const [photoIds, setPhotoIds] = useState<string[]>([])

  const [videos, setVideos] = useState<any[]>([])
  const [videosIds, setVideosIds] = useState<string[]>([])

  const [audios, setAudios] = useState<any[]>([])
  const [audiosIds, setAudiosIds] = useState<string[]>([])

  useEffect(() => {
    if (modelRequest !== undefined) {
      const profilePhoto = modelRequest?.mainInfo?.profilePhoto
      const profilePhotoID = profilePhoto?.file?.id
      // @ts-ignore
      setProfilePhotoIds(profilePhotoID)

      const arrayPhoto = modelRequest?.mainInfo?.photos || []
      const idPhotos = arrayPhoto?.map(item => item?.file?.id)
      // @ts-ignore
      setPhotoIds(idPhotos)
      //

      const arrayVideos = modelRequest?.mainInfo?.videos || []
      const idVideo = arrayVideos?.map(item => item?.file?.id)
      // @ts-ignore
      setVideosIds(idVideo)
      //
      const arrayAudios = modelRequest?.mainInfo?.audios || []
      const idAudio = arrayAudios?.map(item => item?.file?.id)
      // @ts-ignore
      setAudiosIds(idAudio)
      if (modelRequest?.mainInfo) {
        formik.resetForm({
          values: {
            languages: modelRequest?.mainInfo?.languages,
            dataType: modelRequest?.mainInfo?.dataType,
            name: modelRequest?.mainInfo?.name,
            // @ts-ignore
            selfInformation:
              // @ts-ignore
              JSON.parse(modelRequest?.mainInfo?.selfInformation) || '',
            birthYear: modelRequest?.mainInfo?.birthYear,
            weight: modelRequest?.mainInfo?.weight,
            height: modelRequest?.mainInfo?.height,
            chest: modelRequest?.mainInfo?.chest,
            waist: modelRequest?.mainInfo?.waist,
            hips: modelRequest?.mainInfo?.hips,
            hairColor: modelRequest?.mainInfo?.hairColor,
            breastSize: modelRequest?.mainInfo?.breastSize,
            sexualOrientation: modelRequest?.mainInfo?.sexualOrientation,
            bodyType: modelRequest?.mainInfo?.bodyType,
            hairLength: modelRequest?.mainInfo?.hairLength,
            profilePhoto: modelRequest?.mainInfo?.profilePhoto,
            photos: modelRequest?.mainInfo?.photos,
            videos: modelRequest?.mainInfo?.videos,
            audios: modelRequest?.mainInfo?.audios,
          },
        })
      }
    }
  }, [modelRequest])
  const initialValues = {
    languages: modelRequest?.mainInfo?.languages,
    dataType: modelRequest?.mainInfo?.dataType,
    name: modelRequest?.mainInfo?.name,
    selfInformation: modelRequest?.mainInfo?.selfInformation,
    birthYear: modelRequest?.mainInfo?.birthYear,
    weight: modelRequest?.mainInfo?.weight,
    height: modelRequest?.mainInfo?.height,
    chest: modelRequest?.mainInfo?.chest,
    waist: modelRequest?.mainInfo?.waist,
    hips: modelRequest?.mainInfo?.hips,
    hairColor: modelRequest?.mainInfo?.hairColor,
    breastSize: modelRequest?.mainInfo?.breastSize,
    sexualOrientation: modelRequest?.mainInfo?.sexualOrientation,
    bodyType: modelRequest?.mainInfo?.bodyType,
    hairLength: modelRequest?.mainInfo?.hairLength,
    photos: modelRequest?.mainInfo?.photos,
    profilePhoto: modelRequest?.mainInfo?.profilePhoto,
    videos: modelRequest?.mainInfo?.videos,
    audios: modelRequest?.mainInfo?.audios,
  }
  // const initialValues: IRegistrationInitialValues = {
  // 	languages: [],
  // 	dataType: '',
  // 	name: '',
  // 	selfInformation: ' ',
  // 	birthYear: 0,
  // 	weight: 0,
  // 	height: 0,
  // 	chest: 0,
  // 	waist: 0,
  // 	hips: 0,
  // 	hairColor: '',
  // 	breastSize: 0,
  // 	sexualOrientation: '',
  // 	bodyType: '',
  // 	hairLength: '',
  // 	photos: [],
  // 	videos: [],
  // 	audios: [],
  // 	cameraAvailable: true,
  // 	microphoneAvailable: true,
  // 	virtualSexAvailable: true,
  // };
  const validationSchema = yup.object().shape({
    dataType: yup.string().required('Data type is required'),
    name: yup
      .string()
      .required(t('stepOne.nameRequired'))
      .min(2, t('stepOne.nameMin'))
      .max(50, t('stepOne.nameMax'))
      .matches(/^[\p{L}-]+$/u, t('stepOne.nameInvalid')),
    languages: yup.array().of(yup.string().min(1).max(50)).required('Language is required').min(1, 'At least one language is required'),
    selfInformation: yup
      .mixed<RawContent>()
      .test(
        'selfInformationRequired',
        t('stepOne.selfInformationRequired'),
        value => !!value && countChars(value as RawContent) > 0, // Проверка на наличие текста
      )
      .test(
        'selfInformationMin',
        t('stepOne.selfInformationMin'),
        value => !!value && countChars(value as RawContent) >= 10, // Минимум 10 символов
      )
      .test(
        'selfInformationMax',
        t('stepOne.selfInformationMax'),
        value => !!value && countChars(value as RawContent) <= 10000, // Максимум 10000 символов
      ),
    birthYear: yup.number().required('Birth year is required').min(1900).max(new Date().getFullYear()),
    weight: yup.number().required('Weight is required'),
    height: yup.number().required('Height is required'),
    chest: yup.number().required('Chest is required'),
    waist: yup.number().required('Waist is required'),
    hips: yup.number().required('Hips is required'),
    hairColor: yup.string().required('Hair color is required'),
    breastSize: yup.number().required('Breast size is required'),
    sexualOrientation: yup.string().required('Sexual orientation is required'),
    bodyType: yup.string().required('Body type is required'),
    hairLength: yup.string().required('Hair length is required'),
    profilePhoto: yup.mixed().required(t('stepOne.mainPhotoRequired')).notOneOf([null], t('stepOne.mainPhotoRequired')),
    photos: yup.array().required('At least one photo is required'),
    videos: yup.array().required('At least one video is required'),
    audios: yup.array().required('At least one audio is required'),
  })

  const onSubmit = (values: any) => {
    const body = {
      ...values,
      selfInformation: JSON.stringify(values.selfInformation),
      profilePhoto: profilePhotoIds,
      photos: [...photoIds],
      videos: [...videosIds],
      audios: [...audiosIds],
    }
    addRequest({ body, modelUserId: modelRequest?.mainInfo?.ownerId })
  }
  useEffect(() => {
    if (addRequestIsSuccess) {
      toastSuccess('Данные реквеста изменены')
    }
  }, [addRequestIsSuccess])

  useEffect(() => {
    const secretApproved = modelRequest?.secretData?.isApproved
    const mainApproved = modelRequest?.mainInfo?.isApproved
    const requestState = modelRequest?.request?.state === 'CREATE_NEW'

    // Логика проверки
    const shouldNavigate =
      !requestState && // Если requestState true, редирект не делаем
      ((secretApproved === true && mainApproved === true) || // Оба true
        (secretApproved === true && mainApproved === undefined) || // Только secretApproved true, mainApproved отсутствует
        (mainApproved === true && secretApproved === undefined)) // Только mainApproved true, secretApproved отсутствует

    if (shouldNavigate) {
      navigate('/admin/models-request')
    }
  }, [approveIsSuccess, modelRequest, navigate])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })
  return (
    <div className='step1-information'>
      <h2>
        Info request - ID request {modelRequestId} <CopyButton textToCopy={modelRequestId} />{' '}
      </h2>
      <h3>
        Info request model - ID model {modelRequest?.request?.modelUserId} <CopyButton textToCopy={modelRequest?.request?.modelUserId} />{' '}
      </h3>
      {modelRequest?.mainInfo !== undefined ? (
        <>
          <form onSubmit={formik.handleSubmit}>
            <div className='step1-information-block'>
              <div className='step1-information-block_box custom-selected name'>
                <FormControl name='name' type='text' placeholder='Enter Name' formik={formik} />
              </div>
              <div className='step1-information-block_box'>
                <SelectedCustom name='birthYear' options={optionsBirthYear} formik={formik} placeholder='Выберите год рождения' />
              </div>
              <div className='step1-information-block_box'>
                <SelectedCustom name='weight' options={optionsWeight} formik={formik} placeholder='Выберите приблизительный вес' />
              </div>
              <div className='step1-information-block_box'>
                <SelectedCustom name='height' options={optionsHeight} formik={formik} placeholder='Выберите приблизительный рост' />
              </div>
              <div className='step1-information-block_box'>
                <SelectedCustom name='chest' options={optionsChest} formik={formik} placeholder='Выберите приблизительный грудь' />
              </div>
              <div className='step1-information-block_box'>
                <SelectedCustom name='waist' options={optionsWaist} formik={formik} placeholder='Выберите приблизительный талия' />
              </div>
              <div className='step1-information-block_box'>
                <SelectedCustom name='hips' options={optionsHips} formik={formik} placeholder='Выберите приблизительный Бедра' />
              </div>
              <div className='step1-information-block_box'>
                <SelectedCustom name='breastSize' options={optionsBreastSize} formik={formik} placeholder='Выберите приблизительный грудь' />
              </div>
              <div className='step1-information-block_box'>
                <SelectedCustom name='hairColor' options={optionsHairColor} formik={formik} placeholder='Выберите приблизительный цвет волос' />
              </div>
              <div className='step1-information-block_box'>
                <SelectedCustom name='sexualOrientation' options={optionsSexualOrientation} formik={formik} placeholder='Выберите орентацию' />
              </div>
              <div className='step1-information-block_box'>
                <SelectedCustom name='bodyType' options={optionsBodyType} formik={formik} placeholder='Выберите телосложение' />
              </div>
              <div className='step1-information-block_box'>
                <SelectedCustom name='hairLength' options={optionsHairLength} formik={formik} placeholder='Выберите длину волос' />
              </div>
              <div className='step1-information-block_box language'>
                <SelectedLanguage name='languages' formik={formik} placeholder='Разговорный язык' />
              </div>
              <CropeMain
                ownerId={modelRequest?.mainInfo?.ownerId}
                formik={formik}
                name='profilePhoto'
                nameText={formik.values.name}
                profilePhoto={profilePhoto}
                setProfilePhoto={setProfilePhoto}
                addFile={addFile}
                getFile={getFile}
                setProfilePhotoIds={setProfilePhotoIds}
                profilePhotoIds={profilePhotoIds}
              />

              <div className='step1-information-block_box Photo'>
                <Photo photoIds={photoIds} photos={photos} setPhotos={setPhotos} addFile={addFile} getFile={getFile} setPhotoIds={setPhotoIds} />
              </div>
              <div className='step1-information-block_box Video'>
                <Video videoIds={videosIds} videos={videos} setVideos={setVideos} addFile={addFile} getFile={getFile} setVideoIds={setVideosIds} />
              </div>
              <div className='step1-information-block_box Audio'>
                <Audio audioIds={audiosIds} audios={audios} setAudios={setAudios} addFile={addFile} getFile={getFile} setAudioIds={setAudiosIds} />
              </div>

              <div className='step1-information-block_box selfInformation'>
                <CustomTextArea
                  formik={formik}
                  initialRawContent={formik.values.selfInformation}
                  onChangeRaw={
                    rawContent => formik.setFieldValue('selfInformation', rawContent) // Установка значения в formik
                  }
                />
              </div>
              <div className='step1-information-block__button'>
                <button type='submit'>Save</button>
              </div>
            </div>
          </form>
          <div className='registration-information-block__button'>
            {modelRequest?.mainInfo?.isApproved ? (
              <button disabled={true} type='button'>
                Вы заупрувели
              </button>
            ) : (
              <button
                onClick={() => {
                  approve({
                    modelRequestId: String(modelRequestId),
                    type: 'MAIN_INFO',
                  })
                }}
                type='button'
              >
                Approve
              </button>
            )}
          </div>
        </>
      ) : undefined}
      {/*<div>Status: {modelRequest?.request?.state === 'CREATE_NEW' ? 'Admin approved your data' : modelRequest?.request?.state}</div>*/}
      <div>
        {modelRequest?.secretData ? (
          modelRequest?.request?.state === 'CREATE_NEW' ? (
            <Documents />
          ) : (
            modelRequest?.request?.state
          )
        ) : modelRequest?.request?.state === 'UPDATE_EXISTED' ? (
          <Documents />
        ) : (
          modelRequest?.request?.state
        )}
        {!modelRequest?.secretData ? (
          modelRequest?.request?.state === 'CREATE_NEW' ? (
            <Documents />
          ) : (
            modelRequest?.request?.state
          )
        ) : modelRequest?.request?.state === 'UPDATE_EXISTED' ? (
          <Documents />
        ) : (
          modelRequest?.request?.state
        )}
        <button
          type={'button'}
          onClick={() => {
            if (modelRequestId) {
              const payload: ModelRequestDeleteByIdApiArg = {
                modelRequestId: modelRequestId,
              }
              requestDeleteFunc(payload)
            }
          }}
        >
          Delete request
        </button>
      </div>
    </div>
  )
}

export default ModelRequest
