import React, { useEffect, useState } from 'react'
import { ModelPartialUpdateByIdApiArg, ModelPartialUpdateDto, useModelPartialUpdateByIdMutation } from 'store/apiMain/mainApi'
import './scss/index.scss'
import { toastSuccess } from '../../../../elements/Notification/Notification'

const PriceModel = ({ modelId, minutePriceProps, refetch }: any) => {
  const [minutePrice, setMinutePrice] = useState(0)
  const [patchModelPrice, { isSuccess }] = useModelPartialUpdateByIdMutation()
  useEffect(() => {
    setMinutePrice(minutePriceProps)
  }, [minutePriceProps])
  useEffect(() => {
    if (isSuccess) {
      refetch()
      toastSuccess('Прайс успешно изминен!')
    }
  }, [isSuccess])
  return (
    <div className='priceModel'>
      <div className='priceModel-block'>
        <p>Price 1min model</p>
        <input
          value={minutePrice}
          onChange={e => {
            setMinutePrice(Number(e.target.value))
          }}
          type='text'
        />
        <button
          onClick={() => {
            const payload: ModelPartialUpdateByIdApiArg = {
              modelId: modelId,
              modelPartialUpdateDto: {
                minutePrice: Number(minutePrice),
              },
            }
            patchModelPrice(payload)
          }}
        >
          Новый прайс
        </button>
      </div>
    </div>
  )
}

export default PriceModel
