import React from 'react'
import Select from 'react-dropdown-select'
import { FormikHandlers, FormikValues } from 'formik'
import './scss/index.scss'
import { processString } from '../../utils/processString'

interface SelectedCustomProps {
  options: any[]
  name: string // имя поля для Formik
  placeholder: string // имя поля для Formik
  formik: {
    setFieldValue: (field: string, value: any) => void
    values: FormikValues
    handleChange: FormikHandlers['handleChange']
    handleBlur: FormikHandlers['handleBlur']
    touched: FormikValues
    errors: FormikValues
  }
}

const SelectedCustom: React.FC<SelectedCustomProps> = ({ options, name, formik, placeholder }) => {
  const handleSelected = (values: any[]) => {
    if (values.length > 0) {
      formik.setFieldValue(name, values[0].value)
    } else {
      formik.setFieldValue(name, null)
    }
  }

  // const selectedValues = formik.values[name] ? [{ value: formik.values[name], label: formik.values[name] }] : [];
  const selectedValues = formik.values[name]
    ? [
        {
          value: formik.values[name],
          label: options.find(option => option.value.toString() === formik.values[name].toString())?.label,
        },
      ]
    : []

  const isError = Boolean(formik.touched[name] && formik.errors[name])

  return (
    <div className='custom-selected'>
      <Select options={options} values={selectedValues} onChange={handleSelected} placeholder={placeholder} multi={false} searchable={false} />
      {isError && (
        <div className='error-message'>
          <p>{formik.errors[name]}</p>
        </div>
      )}
    </div>
  )
}

export default SelectedCustom
