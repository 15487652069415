import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Logo from '../Logo/Logo'
import './scss/index.scss'
import { t } from 'i18next'

const Footer = () => {
  const currentUrl = window.location.href
  const location = useLocation()
  const navigate = useNavigate()
  // Check if the current path matches /room/:roomId or /video/:roomId
  const shouldRenderFooter = !(location.pathname.startsWith('/room/') || location.pathname.startsWith('/video/'))

  const getPath = (path: string) => `/info${currentUrl.includes('model') ? '' : ''}/${path}`

  const openInNewTab = (path: string) => {
    const fullPath = getPath(path)
    window.open(fullPath, '_blank', 'noopener,noreferrer')
  }

  // Return null if footer should not be rendered
  if (!shouldRenderFooter) {
    return null
  }

  return (
    <footer>
      <div className='footer'>
        <div className='footer-block'>
          <div className='footer-block_box'>
            <div className='footer-block_box__title'>
              <h5>Legal</h5>
            </div>
            <div className='footer-block_box__content'>
              <p onClick={() => openInNewTab(`terms-and-conditions`)}>{t('message.termsAndConditions')}</p>
              <p onClick={() => openInNewTab(`privacy-policy`)}>{t('message.privacyPolicy')}</p>
              <p onClick={() => openInNewTab(`2257`)}> 2257 {t('profile.statement')}</p>
            </div>
          </div>
          <div className='footer-block_box'>
            <div className='footer-block_box__title'>
              <h5>Info</h5>
            </div>
            <div className='footer-block_box__content'>
              <p onClick={() => openInNewTab(`faq`)}>FAQ</p>
              <p>{t('global.infoSite')}</p>
              <p>{t('global.virtualHouse')}</p>
              <p onClick={() => navigate('/model/registration')}>Model registration</p>
            </div>
          </div>
          <div className='footer-block_box'>
            <div className='footer-block_box__title'>
              <h5>Contact</h5>
            </div>
            <div className='footer-block_box__content'>
              <p onClick={() => openInNewTab(`contact`)}>{t('global.contactUp')}</p>
              <p>{t('global.support')}</p>
            </div>
          </div>
        </div>
        <div className='footer-last'>
          <Logo />
          <p>
            © Virtual House 2024. <span>All rights reserved</span>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
