import {
  SvgProfileMens1,
  SvgProfileMens1Mobile,
  SvgProfileMens2,
  SvgProfileMens2Mobile,
  SvgProfileMens3,
  SvgProfileMens3Mobile,
  SvgProfileMens4,
  SvgProfileMens4Mobile,
  SvgProfileMens5,
  SvgProfileMens5Mobile,
  SvgProfileMens6,
  SvgProfileMens6Mobile,
} from '../../images/svg'

export const photoViewUserMobile = (photoId: number) => {
  if (photoId === 1) {
    return <SvgProfileMens1Mobile />
  }
  if (photoId === 2) {
    return <SvgProfileMens2Mobile />
  }
  if (photoId === 3) {
    return <SvgProfileMens3Mobile />
  }
  if (photoId === 4) {
    return <SvgProfileMens4Mobile />
  }
  if (photoId === 5) {
    return <SvgProfileMens5Mobile />
  }
  if (photoId === 6) {
    return <SvgProfileMens6Mobile />
  }
  return null
}
