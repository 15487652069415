import Girl from 'Components/User/Girl/Girl'
import Girls from 'Components/User/Girls/Girls'
import Login from 'Components/User/Login/Login'
import LoginAdmin from 'Components/Admin/Login/Login'
import Messages from 'Components/User/Messages/Messages'
import Profile from 'Components/User/Profile/Profile'
import MainContent from 'layouts-elements/MainContent/MainContent'
import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Registration from '../Components/User/Registration/Registration'
import Friends from '../Components/Global/Friends/Friends'
import ForgotPassword from '../Components/User/ForgotPassword/ForgotPassword'
import Transactional from '../Components/Global/Transactional/Transactional'

const UsersRoutes = () => {
  return (
    <>
      <div className='wrapper'>
        <Routes>
          <Route path='/' element={<Navigate to='/messages' />} />
          <Route
            path='/messages'
            element={
              <MainContent>
                <Messages />
              </MainContent>
            }
          />
          <Route
            path='/girls'
            element={
              <MainContent>
                <Girls />
              </MainContent>
            }
          />
          <Route
            path='/girl/:modelId'
            element={
              <MainContent>
                <Girl />
              </MainContent>
            }
          />
          <Route
            path='/friends'
            element={
              <MainContent>
                <Friends />
              </MainContent>
            }
          />
          <Route path='/login' element={<Login />} />
          <Route path='/registration' element={<Registration />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route
            path='/profile'
            element={
              <MainContent>
                <Profile />
              </MainContent>
            }
          />
          <Route
            path='/transactional'
            element={
              <MainContent>
                <Transactional />
              </MainContent>
            }
          />
          <Route path='*' element={<Navigate to='/girls' replace />} />
        </Routes>
      </div>
    </>
  )
}

export default UsersRoutes
