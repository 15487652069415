// videoChatSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IVideoChatStore } from 'store/reducer/videoChat/types'
import { RoomResponse } from 'store/apiMain/mainApi'
import { IRoomsStore } from 'store/reducer/rooms/types'

const initialState: IRoomsStore = {
  rooms: [],
}

const roomsSlice = createSlice({
  name: 'rooms',
  initialState,
  reducers: {
    setRooms: (state, action: PayloadAction<RoomResponse[]>) => {
      const roomMap: Map<string, RoomResponse> = new Map(state.rooms.map((room: RoomResponse) => [room.id, room] as [string, RoomResponse]))
      action.payload.forEach((room: RoomResponse) => {
        roomMap.set(room.id, room)
      })
      state.rooms = Array.from(roomMap.values())
    },
    setRoom: (state, action: PayloadAction<RoomResponse>) => {
      const roomIndex = state.rooms.findIndex((room: RoomResponse) => room.id === action.payload.id)
      if (roomIndex !== -1) {
        // Обновляем существующую комнату
        state.rooms[roomIndex] = action.payload
      } else {
        state.rooms.push(action.payload)
      }
    },
    resetSetRooms: () => initialState,
  },
})

export const { setRooms, setRoom } = roomsSlice.actions

export default roomsSlice.reducer
