import React, { useEffect, useState } from 'react'
import {
  RelationshipDeleteByIdApiArg,
  RelationshipFullDto,
  RelationshipGetFullAllApiArg,
  RelationshipPartialUpdateByIdApiArg,
  RelationshipPartialUpdateDto,
  useRelationshipDeleteByIdMutation,
  useRelationshipGetFullAllQuery,
  useRelationshipPartialUpdateByIdMutation,
  useUserGetFullMeQuery,
} from 'store/apiMain/mainApi'
import { SvgProfileMens1, SvgSearch4 } from '../../../../images/svg'
import './scss/index.scss'
import { useSelector } from 'react-redux'
import { selectRole } from '../../../../store/reducer/token/selector'
import { toastSuccess } from '../../../../elements/Notification/Notification'
import Item from './Item/Item'
import Tabs from './Tabs/Tabs'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { getSocketNotification } from '../../../../store/reducer/socket/selector'
import { selectorUpdateFriends } from '../../../../store/reducer/friends/selectors'
import Loading from '../../../../elements/Loading/Loading'

const Content: any = ({ filter, refetchTabs }: any) => {
  const { t } = useTranslation()
  const role = useSelector(selectRole)
  const [search, setSearch] = useState('')
  const { data: user, refetch } = useUserGetFullMeQuery()
  const [activeTab, setActiveTab] = useState(undefined)
  const [payload, setPayload] = useState<RelationshipGetFullAllApiArg>({
    // @ts-ignore
    userId: (user as any)?.[role?.toLowerCase()]?.id || undefined,
    statuses: undefined,
    name: undefined,
    isFavorite: undefined,
    isOnline: undefined,
    showDeleted: undefined,
    showDisabled: undefined,
    ids: undefined,
    pointOfViewRole: undefined,
    initiatedBy: undefined,
    page: undefined,
    pageSize: undefined,
  })
  useEffect(() => {
    setPayload({ ...payload, name: search })
  }, [search])
  useEffect(() => {
    if (activeTab === undefined) {
      setPayload({ ...payload, initiatedBy: undefined })
    }
    if (activeTab === 'Sent') {
      setPayload({
        ...payload,
        initiatedBy: role === 'CLIENT' ? 'CLIENT' : 'MODEL',
      })
    }
    if (activeTab === 'Received') {
      setPayload({
        ...payload,
        initiatedBy: role === 'CLIENT' ? 'MODEL' : 'CLIENT',
      })
    }
  }, [activeTab])
  useEffect(() => {
    // @ts-ignore
    setPayload({ ...payload, userId: user?.[role.toLowerCase()]?.id })
  }, [user])
  useEffect(() => {
    if (filter === 'FRIENDS') {
      setPayload({
        ...payload,
        // @ts-ignore
        statuses: 'FRIENDS',
        isFavorite: undefined,
        isOnline: undefined,
        showDeleted: undefined,
        showDisabled: undefined,
        ids: undefined,
        pointOfViewRole: undefined,
        initiatedBy: undefined,
        page: undefined,
        pageSize: undefined,
      })
    }
    if (filter === 'FRIEND_REQUEST') {
      setPayload({
        ...payload,
        // @ts-ignore
        statuses: 'FRIEND_REQUEST',
        isFavorite: undefined,
        isOnline: undefined,
        showDeleted: undefined,
        showDisabled: undefined,
        ids: undefined,
        pointOfViewRole: undefined,
        initiatedBy: undefined,
        page: undefined,
        pageSize: undefined,
      })
    }
    if (filter === 'BLOCKED') {
      setPayload({
        ...payload,
        // @ts-ignore
        statuses: 'BLOCKED',
        isFavorite: undefined,
        isOnline: undefined,
        showDeleted: undefined,
        showDisabled: undefined,
        ids: undefined,
        pointOfViewRole: undefined,
        initiatedBy: undefined,
        page: undefined,
        pageSize: undefined,
      })
    }
    if (filter === 'ONLINE') {
      // @ts-ignore
      setPayload({
        ...payload,
        // @ts-ignore
        statuses: undefined,
        isFavorite: undefined,
        isOnline: true,
        showDeleted: undefined,
        showDisabled: undefined,
        ids: undefined,
        pointOfViewRole: undefined,
        initiatedBy: undefined,
        page: undefined,
        pageSize: undefined,
      })
    }
    if (filter === 'FAVORITES') {
      // @ts-ignore
      setPayload({
        ...payload,
        // @ts-ignore
        statuses: undefined,
        isFavorite: true,
        isOnline: undefined,
        showDeleted: undefined,
        showDisabled: undefined,
        ids: undefined,
        pointOfViewRole: undefined,
        initiatedBy: undefined,
        page: undefined,
        pageSize: undefined,
      })
    }
  }, [filter])

  const { data: dataFriends, refetch: dataFriendsRefetch, isLoading } = useRelationshipGetFullAllQuery({ ...payload })

  const updateFriends = useSelector(selectorUpdateFriends)

  useEffect(() => {
    dataFriendsRefetch()
  }, [updateFriends])
  const [friendsUpdate, { isSuccess: isSuccessUpdate }] = useRelationshipPartialUpdateByIdMutation()
  const [friendsDelete, { isSuccess: isSuccessDelete }] = useRelationshipDeleteByIdMutation()

  useEffect(() => {
    if (dataFriends) {
      dataFriendsRefetch()
    }
  }, [payload])

  useEffect(() => {
    if (isSuccessDelete) {
      refetchTabs()
      dataFriendsRefetch()
      toastSuccess('Request Delete!')
    }
  }, [isSuccessDelete])

  useEffect(() => {
    if (isSuccessUpdate) {
      refetchTabs()
      dataFriendsRefetch()
      toastSuccess('Success')
    }
  }, [isSuccessUpdate])

  const textTubFunc = (text: string) => {
    if (text === 'FRIENDS') {
      return <p>{t('tabs.friends')}</p>
    }
    if (text === 'FRIEND_REQUEST') {
      return <p>{t('tabs.requests')}</p>
    }
    if (text === 'ONLINE') {
      return <p>{t('tabs.online')}</p>
    }
    if (text === 'FAVORITES') {
      return <p>{t('tabs.favorites')}</p>
    }
    if (text === 'BLOCKED') {
      return <p>{t('tabs.blocked')}</p>
    }
  }
  const noContent = (filter: string) => {
    if (filter === 'FRIENDS') {
      return (
        <div className='noContent'>
          <p>
            {role === 'CLIENT' ? (
              <>
                {' '}
                {t('noContent.noFriends')} <Link to='/girls'>{t('noContent.noFriends_girls')}</Link>
              </>
            ) : null}
            {role === 'MODEL' ? (
              <>
                {' '}
                {t('noContent.noFriends')} <Link to='/model/people'>{t('noContent.noFriends_people')}</Link>
              </>
            ) : null}
          </p>
        </div>
      )
    }
    if (filter === 'FRIEND_REQUEST') {
      return (
        <div className='noContent'>
          <p>{t('noContent.noFriendRequests')}</p>
        </div>
      )
    }
    if (filter === 'ONLINE') {
      return (
        <div className='noContent'>
          <p>{t('noContent.noFriendsOnline')}</p>
        </div>
      )
    }
    if (filter === 'FAVORITES') {
      return (
        <div className='noContent'>
          <p>{t('noContent.noFavoriteFriends')}</p>
        </div>
      )
    }
    if (filter === 'BLOCKED') {
      return (
        <div className='noContent'>
          <p>{t('noContent.noBlockedFriends')}</p>
        </div>
      )
    }
  }

  const socketNotification = useSelector(getSocketNotification)
  useEffect(() => {
    dataFriendsRefetch()
  }, [socketNotification])

  return (
    <div className='contentFriends'>
      <div className='friendsFour__content-top'>{textTubFunc(filter)}</div>
      <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
      <div className='friendsFour__content-inner inner-content'>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <div className='inner-content__search'>
              {dataFriends && dataFriends.content ? (
                <>
                  <div className='inner-content__search___svg'>
                    <SvgSearch4 />
                  </div>
                  <input
                    value={search}
                    onChange={e => {
                      setSearch(e.target.value)
                    }}
                    type='text'
                    placeholder={t('item.search_placeholder')}
                  />
                </>
              ) : null}
            </div>
            <div className='inner-content__box'>
              {dataFriends && dataFriends.content && dataFriends.content.length > 0 ? (
                dataFriends.content.map((e: RelationshipFullDto) => (
                  <Item key={e.relationship?.id} item={e} filter={filter} role={role} friendsDelete={friendsDelete} friendsUpdate={friendsUpdate} />
                ))
              ) : (
                <>{noContent(filter)}</>
              )}
              {dataFriends && dataFriends.content && dataFriends.content.length > 0 ? <div style={{ marginBottom: '30px' }}></div> : null}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Content
