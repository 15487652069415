import React, { useEffect, useRef, useState } from 'react'
import './css/style.scss'
import { SvgWhitePauseButton, SvgWhitePlayButton } from '../../images/svg'
import { useDispatch, useSelector } from 'react-redux'
import { getAudioPlaySelector } from '../../store/reducer/global/selectors'
import { audioPlaySlice } from '../../store/reducer/global/reducer'

function AudioVisualizer({ fileAudio, positionsButton, gradient = 1 }) {
  const canvasRef = useRef(null)
  const [audio, setAudio] = useState(null)
  const [audioContext, setAudioContext] = useState(null)
  const [isPlaying, setIsPlaying] = useState(false)

  const dispatch = useDispatch()
  const audioGlobalSelector = useSelector(getAudioPlaySelector)

  useEffect(() => {
    if (!fileAudio) return

    const audioInstance = new Audio()
    audioInstance.crossOrigin = 'anonymous' // Enable cross-origin
    audioInstance.src = fileAudio
    audioInstance.load()

    setAudio(audioInstance)

    audioInstance.addEventListener('play', () => setIsPlaying(true))
    audioInstance.addEventListener('pause', () => setIsPlaying(false))
    audioInstance.addEventListener('ended', () => setIsPlaying(false))

    return () => {
      if (audioContext) {
        audioContext.close() // Закрытие аудиоконтекста
      }
      audioInstance.pause()
      dispatch(audioPlaySlice(null))

      audioInstance.removeEventListener('play', () => setIsPlaying(true))
      audioInstance.removeEventListener('pause', () => setIsPlaying(false))
      audioInstance.removeEventListener('ended', () => setIsPlaying(false))
    }
  }, [fileAudio])

  useEffect(() => {
    if (audioGlobalSelector && audioGlobalSelector !== fileAudio && audio) {
      audio.pause()
    }
  }, [audioGlobalSelector, fileAudio, audio])

  const initializeAudioContext = () => {
    if (!audioContext) {
      const context = new (window.AudioContext || window.webkitAudioContext)()
      setAudioContext(context)

      const analyser = context.createAnalyser()
      const source = context.createMediaElementSource(audio)

      source.connect(analyser)
      analyser.connect(context.destination)

      analyser.fftSize = 256
      const bufferLength = analyser.frequencyBinCount
      const dataArray = new Uint8Array(bufferLength)

      const canvas = canvasRef.current
      const ctx = canvas.getContext('2d')
      canvas.width = Math.min(window.innerWidth, 200)
      canvas.height = window.innerHeight

      const sliceWidth = (canvas.width * 1.0) / bufferLength

      const animate = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        analyser.getByteFrequencyData(dataArray)

        ctx.lineWidth = 2
        if (gradient === 0) {
          ctx.strokeStyle = '#ff00ae'
          ctx.fillStyle = 'rgba(255,0,234,0.5)'
        }
        if (gradient === 1) {
          ctx.strokeStyle = '#00ffcc'
          ctx.fillStyle = 'rgba(0, 255, 204, 0.5)'
        }
        if (gradient === 2) {
          ctx.strokeStyle = '#ff00ae'
          ctx.fillStyle = 'rgba(255,0,234,0.5)'
        }
        if (gradient === 3) {
          ctx.strokeStyle = '#00ffcc'
          ctx.fillStyle = 'rgba(0, 255, 204, 0.5)'
        }

        ctx.beginPath()
        let x = 0

        ctx.moveTo(x, canvas.height)

        for (let i = 0; i < bufferLength; i++) {
          const v = dataArray[i] / 128.0
          const y = (v * canvas.height) / 2

          ctx.lineTo(x, canvas.height - y)
          x += sliceWidth
        }

        ctx.lineTo(canvas.width, canvas.height)
        ctx.closePath()
        ctx.stroke()
        ctx.fill()

        requestAnimationFrame(animate)
      }

      animate()
    }
  }

  const togglePlayback = () => {
    if (!audio) return

    initializeAudioContext()

    if (isPlaying) {
      audio.pause()
      dispatch(audioPlaySlice(null))
    } else {
      dispatch(audioPlaySlice(fileAudio))
      audio.play()
    }
  }

  return (
    <div className='visualizer-container'>
      {positionsButton === 'left' ? (
        <>
          <div className='controls'>
            <button onClick={togglePlayback}>{isPlaying ? <SvgWhitePauseButton /> : <SvgWhitePlayButton />}</button>
          </div>
          <canvas ref={canvasRef}></canvas>
        </>
      ) : (
        <>
          <canvas ref={canvasRef}></canvas>
          <div className='controls'>
            <button onClick={togglePlayback}>{isPlaying ? <SvgWhitePauseButton /> : <SvgWhitePlayButton />}</button>
          </div>
        </>
      )}
    </div>
  )
}

export default AudioVisualizer
