import React from 'react'
import { useSelector } from 'react-redux'
import { getAccount } from 'store/reducer/accountController/selectors'
import { SvgCoin } from '../../../../../../../../images/svg'

const Coin = () => {
  const dataAmount = useSelector(getAccount)
  return (
    <div className='coin'>
      <button
        className='lk-button lk-chat-toggle'
        onClick={() => {
          console.log(123)
        }}
      >
        <SvgCoin />
        {dataAmount?.coins}
      </button>
    </div>
  )
}

export default Coin
