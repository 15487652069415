import React, { useEffect, useRef } from 'react'
import './scss/index.scss'

interface IProps {
  name: string
  formik: {
    values: any
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
    errors: any
    touched: any
  }
}

const Checkbox = ({ name, formik }: IProps) => {
  const formRef = useRef(null)

  // Determine if the checkbox should be initially checked
  const isChecked = formik.values[name]

  useEffect(() => {
    const handleClick = (e: any) => {
      if (e.target.type === 'checkbox' && e.target.name === name) {
        // Update Formik's state
        formik.setFieldValue(name, !isChecked)

        let checkboxCL = e.target.classList
        let pState = 'pristine'

        if (checkboxCL.contains(pState)) {
          checkboxCL.remove(pState)
        }
      }
    }

    // Attach the click event listener to the form
    const form = formRef.current
    // @ts-ignore
    form && form.addEventListener('click', handleClick)

    // Cleanup function to remove event listener
    return () => {
      // @ts-ignore
      form && form.removeEventListener('click', handleClick)
    }
  }, [isChecked, name, formik])

  return (
    <div className='Checkbox'>
      <div ref={formRef}>
        <label>
          <input
            className={`cb ${isChecked ? '' : 'pristine'}`}
            type='checkbox'
            checked={isChecked}
            onChange={() => formik.setFieldValue(name, !isChecked)}
          />
        </label>
      </div>
    </div>
  )
}

export default Checkbox
